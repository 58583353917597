<template>
  <date-picker
      v-model="computedValue"
      mode="date"
      :masks="masks"
      is-range
      :model-config="modelConfig"
      @input="updateData"
      :popover="{ visibility: 'click' }"
  >
    <template v-slot="{ inputValue, inputEvents, isDragging }">
      <div class="row no-gutter d-flex">
        <div class="" style="padding-left: 10px">
          <input
              ref="startInput"
              :value="inputValue.start"
              v-on="inputEvents.start"
              class="form-control"
              @blur="isOpenCalendar = false"
              :disabled="isDisabled"
              :readonly="isReadonly"
              :placeholder="placeHolderText"

          />
        </div>
        <div class="">
          <i class="bx bx-right-arrow-alt" style="font-size: 20px; margin-top: 3px; color: black"></i>
        </div>
        <div class="" style="padding-right: 10px">
          <input
              :value="inputValue.end"
              v-on="inputEvents.end"
              class="form-control"
              :disabled="isDisabled"
              :readonly="isReadonly"
              :placeholder="placeHolderText"
          />
        </div>
      </div>
    </template>
  </date-picker>
</template>

<script>
import DatePicker from 'v-calendar/src/components/DatePicker';
export default {
  name: 'date-range-picker',
  props: {
    placeHolderText: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    value: {
      type: Object,
      default: {
        end: null,
        start: null,
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
  },
  components: {
    DatePicker,
  },
  methods: {
    updateData(val) {
      this.$emit('input', val)
      this.$emit('onChange');
    },
    focusInput() {
      if (!this.isClicked) {
        this.$refs.startInput.click();
        this.isClicked = true;
      }
      this.$refs.startInput.focus();
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        return val;
      }
    }
  },
  data() {
    return {
      isClicked: false,
      masks: {
        input: 'DD/MM/YYYY',
      },
      modelConfig: {
        type: 'string',
        mask: 'DD/MM/YYYY'
      },
    }
  },
};

</script>

<style scoped>

</style>
