<template>
  <div class="">
      <div class="row no-gutter header-content">
          <div class="col-12 col-md-4 chart-title">
              Estimates Processed Summary
          </div>
          <div class="col-12 col-md-6 header-right d-flex">
              <div class="navbar-nav nav dropdown dropdown-options  show inline" style="margin-left: 99px;">
                  <button type="button"
                          class="btn waves-effect waves-light ex-options-button"
                          id="dropdownMenuLink1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true">
                      Export
                      <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                  <i class="fa fa-chevron-down pull-right"></i>
                              </span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
                       aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('csv')">.CSV</a>
                      <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('xlsx')">.XLS</a>
                  </div>
              </div>
              <div class="navbar-nav nav dropdown dropdown-options  show inline">
                  <button type="button"
                          class="btn waves-effect waves-light ex-options-button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true">
                      {{adjustedBy}}
                      <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                  <i class="fa fa-chevron-down pull-right"></i>
                              </span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
                       aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Week';    selectedColumn = '';">Week</a>
                      <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Month';   selectedColumn = '';">Month</a>
                      <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Quarter'; selectedColumn = '';">Quarter</a>
                      <a class="dropdown-item text-primary p-1 clickable" @click="adjustedBy = 'Year';    selectedColumn = '';">Year</a>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="!isEmptyReportsData" class="mt-2 text-right chart-interval-block">
          <div>
              {{getDate}}
          </div>
      </div>
      <p class="no-data-text">{{selectedColumn}}</p>
      <div class="chart-wrapper col-12 col-md-8">
          <vue-apex-chart
              v-if="isHasData"
              width="1000"
              height="500"
              type="bar"
              :series="computedSeries"
              @click="onClick"
              :options="computedChartOptions"
          ></vue-apex-chart>

          <div v-if="isHasData">
            <div class="navbar-nav nav dropdown dropdown-options  show inline" style="right: -827px; margin-bottom: 5px;">
              <button type="button"
                      class="btn waves-effect waves-light ex-options-button"
                      v-on:click="showFilters = !showFilters">
                Filters
                <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                  <i v-if="!showFilters" class="fa fa-chevron-down pull-right"></i>
                  <i v-if="showFilters" class="fa fa-chevron-up pull-right"></i>
                </span>
              </button>

            </div>
            <div v-if="showFilters" class="active-filters-control">

              <div class="filters-checkboxes">
                <span class="filters-checkboxes-title">Display By Insurer</span>
                <label class="form-check-inline checkbox filters-checkbox" style="margin-right: 35px">
                  <input v-model="isDisplayByInsurer" class="form-check-input" type="checkbox" name="ins">
                  <span class="icon"><i class='bx bx-check'></i></span><span  class="text">Insurer</span>
                </label>
                <label class="form-check-inline checkbox filters-checkbox">
                  <input v-model="isDisplayByNonInsurance" class="form-check-input" type="checkbox" name="nis">
                  <span class="icon"><i class='bx bx-check'></i></span><span class="text" style="width: 122px">Non-Insurance</span>
                </label>
              </div>

              <div class="filters-checkboxes">
                <span class="filters-checkboxes-title">Display Difference</span>
                <label class="form-check-inline radio filters-checkbox">
                  <input class="form-check-input" type="radio"
                         v-model="isDisplayDifference"
                         :value="true">
                  <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                </label>
                <label class="form-check-inline radio filters-checkbox">
                  <input class="form-check-input" type="radio"
                         v-model="isDisplayDifference"
                         :value="false">
                  <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                </label>
              </div>

              <div class="filters-checkboxes">
                <span class="filters-checkboxes-title">Display By Estimator</span>
                <label class="form-check-inline radio filters-checkbox">
                  <input class="form-check-input" type="radio"
                         v-model="isDisplayByEstimator"
                         :value="true">
                  <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                </label>
                <label class="form-check-inline radio filters-checkbox">
                  <input class="form-check-input" type="radio"
                         v-model="isDisplayByEstimator"
                         :value="false">
                  <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                </label>
              </div>


            </div>
          </div>

        <b-table-simple v-if="isHasData && isDisplayByEstimator" hover responsive class="report-table rs-scroll rs-scroll--y">
          <b-thead>
            <b-tr>
              <b-th v-if="adjustedBy === 'Week'">Day</b-th>
              <b-th v-else>{{adjustedBy}}</b-th>
              <b-th v-for="estimator in computedEstimators" :key="estimator.id">{{estimator.name}}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in chartDataForTable">
              <b-td>{{ item[adjustedBy === 'Week' ? 'day' : adjustedBy.toLowerCase()] }}</b-td>
              <b-td v-for="estimator in computedEstimators" :key="estimator.id">
                <div v-if="findCountByEstimatorId(item[adjustedBy === 'Week' ? 'day' : adjustedBy.toLowerCase()], estimator.id) === 0">
                  -
                </div>
                <div v-else>
                  {{findCountByEstimatorId(item[adjustedBy === 'Week' ? 'day' : adjustedBy.toLowerCase()], estimator.id)}}
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

          <b-table
                  v-if="isSelectedColumn"
                  responsive
                  hover
                  :items="estimateDetailData"
                  :fields="fieldsForEstimateDetail"
                  class="report-table"
          >
              <template v-slot:cell(total)="data">
                  {{data.item.total|formatMoney}}
              </template>
              <template v-slot:cell(gst)="data">
                  {{data.item.gst|formatMoney}}
              </template>
              <template v-slot:cell(subtotal)="data">
                  {{data.item.subtotal|formatMoney}}
              </template>
          </b-table>
          <b-table
                  v-if="!isSelectedColumn && isHasData && !isDisplayByEstimator"
                  responsive
                  hover
                  :items="chartDataForTable"
                  :fields="fields"
                  class="report-table"
          >
              <template v-slot:cell(count)="data">
                  <div v-if="data.item.count === 0">
                      -
                  </div>
                  <div v-else>
                      {{data.item.count}}
                  </div>
              </template>
              <template v-slot:cell(insuranceCount)="data">
                <div v-if="data.item.insuranceCount === 0">
                  -
                </div>
                <div v-else>
                  {{data.item.insuranceCount}}
                </div>
              </template>
              <template v-slot:cell(nonInsuranceCount)="data">
                <div v-if="data.item.nonInsuranceCount === 0">
                  -
                </div>
                <div v-else>
                  {{data.item.nonInsuranceCount}}
                </div>
              </template>
          </b-table>
          <p class="no-data-text" v-if="!isHasData">No data for graph</p>
      </div>
  </div>
</template>

<script>
  import {appConfig} from '../../../../config';
  import Multiselect from 'vue-multiselect';
  import _ from 'lodash';
  import {mapActions, mapGetters} from "vuex";
  import moment from 'moment';
  import Axios from "axios";
  import VueApexCharts from 'vue-apexcharts';

  export default {
      name: "ProcessedSummary",
      data() {
          return {
              showFilters: false,
              isDisplayByInsurer: true,
              isDisplayByNonInsurance: true,
              isDisplayDifference: true,
              isDisplayByEstimator: false,
              months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
              fieldsForEstimateDetail: [
                  {label: "Estimate number", key: "estimateNumber", sortable: true, tdClass: '',},
                  {label: "Estimate Creation Date", key: "estimateCreationDate", sortable: true, tdClass: '',},
                  {label: "Rego", key: "rego", sortable: true, tdClass: '',},
                  {label: "Vehicle Owner", key: "vehicleOwner", sortable: true, tdClass: '',},
                  {label: "Subtotal", key: "subtotal", sortable: true, tdClass: '',},
                  {label: "GST", key: "gst", sortable: true, tdClass: '',},
                  {label: "Grandtotal", key: "total", sortable: true, tdClass: '',},
              ],
              estimateDetailData: [],
              adjustedBy: 'Month',
              intervalUpdateReports: null,
              selectedColumn: '',
          };
      },
      computed: {
          ...mapGetters({
              getterEPS: 'report/getEPS',
          }),
          computedSeries() {
            let data = [{
              data: [],
            }];
            if (this.isDisplayDifference) {
              data = [
                {
                  name: 'Insurer',
                  data: []
                },
                {
                  name: 'Non-Insurance',
                  data: []
                }
              ]
            }
            if (!this.chartDataForTable) {
              return  data;
            }
            let reportData = this.adjustedBy === 'Month' || this.adjustedBy === 'Quarter' ? this.chartDataForTable.slice().reverse() : this.chartDataForTable.slice();
            _.forEach(reportData, (itm) => {
              if (this.isDisplayDifference) {
                data[0].data.push(itm.insuranceCount)
                data[1].data.push(itm.nonInsuranceCount)
              } else {
                data[0].data.push(itm.count)
              }
            })

            return data;
          },
          currentMonth() {
            return new Date().getMonth();
          },
          computedChartOptions() {
            let options = {
              chart: {
                id: 'vuechart-example',
                stacked: true,
                toolbar: {
                  show: false,
                },
              },
              colors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411', '#f4359e', '#b91383', '#16d620', '#b77322', '#3b3eac', '#5574a6', '#329262', '#329262', '#651067', '#8b0707', '#e67300', '#6633cc', '#aaaa11', '#22aa99', '#994499', '#316395', '#b82e2e', '#66aa00', '#dd4477', '#0099c6', '#990099', '#109618', '#ff9900', '#dc3912', '#3366cc', '#ca24d6', '#378784', '#ab8965', '#141e7d', '#edc7c7', '#167d76', '#d5f74a', '#3022c9', '#705658'],
              xaxis: {
                categories: []
              },
              legend: {
                showForNullSeries: false,
                position: 'top',
                height: 38,
              },
              tooltip: {
                marker: {show: true},
                enabled: true,
                y: {
                  title: {
                    formatter: (val) => {
                      if (this.isDisplayDifference) {
                        return val + ': '
                      }
                      return 'Estimate Numbers: '
                    },
                  },
                },
                style: {
                  fontSize: '13px',
                  fontFamily: 'Nunito Sans',
                  fontWeight: 'bold',
                },
              },
              dataLabels: {
                enabled: false,
              },
            }
            let result = [];
            if (this.adjustedBy === 'Month') {
              let prevMonths = [];
              let self = this;
              _.forEach(this.months, function (item) {
                if (self.months.indexOf(item) > self.currentMonth) {
                  prevMonths.push(item)
                }
              })

              let currentMonths = [];
              _.forEach(self.months, function (item) {
                let index = self.months.indexOf(item)
                if (index <= self.currentMonth) {
                  currentMonths.push(item)
                }
              });

              _.forEach(prevMonths, function (item) {
                result.push(item)
              })
              _.forEach(currentMonths, function (item) {
                result.push(item)
              });
            } else if (this.adjustedBy === 'Week') {
              result = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
            } else if (this.adjustedBy === 'Quarter') {
              let q1 = 'January - March';
              let q2 = 'April - June';
              let q3 = 'July - September';
              let q4 = 'October - December';

              let i1 = [0, 1, 2];
              let i2 = [3, 4, 5];
              let i3 = [6, 7, 8];
              let i4 = [9, 10, 11];

              if (i1.includes(this.currentMonth)) {
                result = [
                  q2,
                  q3,
                  q4,
                  q1
                ]
              } else if (i2.includes(this.currentMonth)) {
                result = [
                  q3,
                  q4,
                  q1,
                  q2,
                ]
              } else if (i3.includes(this.currentMonth)) {
                result = [
                  q4,
                  q1,
                  q2,
                  q3,
                ]
              } else if (i4.includes(this.currentMonth)) {
                result = [
                  q1,
                  q2,
                  q3,
                  q4,
                ]
              }
            } else if (this.adjustedBy === 'Year') {
              for (let key in this.getterEPS.year) {
                result.push(key);
              }
            }

            options.xaxis.categories.splice(0, options.xaxis.categories.length);
            _.forEach(result, item => {
              options.xaxis.categories.push(item);
            })
            return options;
          },
          computedEstimators() {
            let result = [];
            if (this.adjustedBy === 'Month') {
              for (let month in this.getterEPS.month) {
                for (let estimator in this.getterEPS.month[month].estimators) {
                  result.push(this.getterEPS.month[month].estimators[estimator]);
                }
              }
            } else if (this.adjustedBy === 'Week') {
              for (let week in this.getterEPS.week) {
                for (let estimator in this.getterEPS.week[week].estimators) {
                  result.push(this.getterEPS.week[week].estimators[estimator]);
                }
              }
            } else if (this.adjustedBy === 'Year') {
              for (let year in this.getterEPS.year) {
                for (let estimator in this.getterEPS.year[year].estimators) {
                  result.push(this.getterEPS.year[year].estimators[estimator]);
                }
              }
            } else if (this.adjustedBy === 'Quarter') {
              for (let quarter in this.getterEPS.quarter) {
                for (let estimator in this.getterEPS.quarter[quarter].estimators) {
                  result.push(this.getterEPS.quarter[quarter].estimators[estimator]);
                }
              }
            }
            result = _.uniqBy(result, 'id');
            return result;
          },
          isSelectedColumn() {
              return Boolean(this.selectedColumn != '');
          },
          isHasData() {
              if (this.isEmptyReportsData) {
                  return false;
              }
              if (this.adjustedBy === 'Week') {
                  let isEmptyWeek = true;
                  for (let day in this.getterEPS.week) {
                      if (!_.isEmpty(this.getterEPS.week[day])) {
                          isEmptyWeek = false;
                          break;
                      }
                  }
                  return !this.isEmptyReportsData && !isEmptyWeek;
              } else if (this.adjustedBy === 'Year') {
                  let isEmptyYear = true;
                  for (let year in this.getterEPS.year) {
                      if (!_.isEmpty(this.getterEPS.year[year])) {
                          isEmptyYear = false;
                          break;
                      }
                  }
                  return !this.isEmptyReportsData && !isEmptyYear;
              } else if (this.adjustedBy === 'Month') {
                  let isEmptyMonth = true;
                  for (let month in this.getterEPS.month) {
                      if (!_.isEmpty(this.getterEPS.month[month])) {
                          isEmptyMonth = false;
                          break;
                      }
                  }
                  return !this.isEmptyReportsData && !isEmptyMonth;
              } else if (this.adjustedBy === 'Quarter') {
                  let isEmptyQuarter = true;
                  for (let quarter in this.getterEPS.quarter) {
                      if (!_.isEmpty(this.getterEPS.quarter[quarter])) {
                          isEmptyQuarter = false;
                          break;
                      }
                  }
                  return !this.isEmptyReportsData && !isEmptyQuarter;
              }
          },
          getDate() {
              let lastIndex = this.computedChartOptions.xaxis.categories.length - 1;
              if (this.isEmptyReportsData) {
                  return '';
              }
              if (this.adjustedBy === "Week") {
                  let monday = moment().startOf('isoweek').toDate();
                  let sunday = moment().endOf('isoweek').toDate();

                  let mDay = String(monday.getDate()).padStart(2, '0');
                  let mMonth = String(monday.getMonth() + 1).padStart(2, '0');
                  let mYear = monday.getFullYear();

                  let mResult = mDay + '/' + mMonth + '/' + mYear;

                  let sDay = String(sunday.getDate()).padStart(2, '0');
                  let sMonth = String(sunday.getMonth() + 1).padStart(2, '0');
                  let sYear = sunday.getFullYear();

                  let sResult = sDay + '/' + sMonth + '/' + sYear;

                  return 'Mon' + ' ' + mResult + ' - ' + 'Sun ' + sResult;
              } else if (this.adjustedBy === 'Month') {
                  let firstMonth = this.computedChartOptions.xaxis.categories[0];
                  let lastMonth = this.computedChartOptions.xaxis.categories[lastIndex];
                  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

                  if (lastMonth === 'December') {
                      let y1 = new Date().getFullYear();

                      return firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y1;
                  }

                  if (months.indexOf(firstMonth) > months.indexOf(lastMonth)) {
                      let y2 = new Date().getFullYear();
                      let y1 = y2 - 1;

                      return firstMonth + ' ' + y1 + ' - ' + lastMonth + ' ' + y2;
                  }
              } else if (this.adjustedBy === 'Quarter') {
                  let firstQuarter = this.computedChartOptions.xaxis.categories[0];
                  let lastQuarter = this.computedChartOptions.xaxis.categories[lastIndex];
                  let quarters = ['January - March', 'April - June', 'July - September', 'October - December'];

                  if (lastQuarter === 'October - December') {
                      let y1 = new Date().getFullYear();

                      return firstQuarter + ' ' + y1 + ' - ' + lastQuarter + ' ' + y1;
                  }

                  if (quarters.indexOf(firstQuarter) > quarters.indexOf(lastQuarter)) {
                      let y2 = new Date().getFullYear();
                      let y1 = y2 - 1;

                      return firstQuarter + ' ' + y1 + ' to ' + lastQuarter + ' ' + y2;
                  }
              } else if (this.adjustedBy === 'Year') {
                  let firstYear = this.computedChartOptions.xaxis.categories[0];
                  let lastYear = this.computedChartOptions.xaxis.categories[lastIndex];

                  return firstYear + ' - ' + lastYear;
              }
          },
          isEmptyReportsData() {
              return _.isEmpty(this.getterEPS);
          },
          fields() {
              let data = [];
              if (this.adjustedBy === 'Month') {
                  data.push(
                      {
                          label: "Month",
                          key: "month",
                          sortable: true,
                          tdClass: 'clickable',
                      },
                  );
              } else if (this.adjustedBy === 'Week') {
                  data.push(
                      {
                          label: "Day",
                          key: "day",
                          sortable: true,
                          tdClass: 'clickable',
                      },
                  );
              } else if (this.adjustedBy === 'Year') {
                  data.push(
                      {
                          label: "Year",
                          key: "year",
                          sortable: true,
                          tdClass: 'clickable',
                      },
                  );
              } else if (this.adjustedBy === 'Quarter') {
                  data.push(
                      {
                          label: "Quarter",
                          key: "quarter",
                          sortable: true,
                          tdClass: 'clickable',
                      },
                  );
              }

              if (this.isDisplayByInsurer && this.isDisplayByNonInsurance && this.isDisplayDifference) {
                data.push(
                    {
                      label: 'Estimates - Insurer',
                      key: 'insuranceCount',
                      sortable: true,
                      tdClass: 'clickable',
                    }
                );
                data.push(
                    {
                      label: 'Estimates - Non-Insurance',
                      key: 'nonInsuranceCount',
                      sortable: true,
                      tdClass: 'clickable',
                    }
                );
                data.push(
                    {
                      label: 'Total',
                      key: 'count',
                      sortable: true,
                      tdClass: 'clickable',
                    }
                );
              } else if (this.isDisplayByNonInsurance && !this.isDisplayByInsurer) {
                data.push(
                    {
                      label: 'Estimate Numbers',
                      key: 'nonInsuranceCount',
                      sortable: true,
                      tdClass: 'clickable',
                    }
                );
              } else if (!this.isDisplayByNonInsurance && this.isDisplayByInsurer) {
                data.push(
                    {
                      label: 'Estimate Numbers',
                      key: 'insuranceCount',
                      sortable: true,
                      tdClass: 'clickable',
                    }
                );
              } else {
                data.push(
                    {
                      label: 'Estimate Numbers',
                      key: 'count',
                      sortable: true,
                      tdClass: 'clickable',
                    }
                );
              }
              return data;
          },
          chartDataForTable() {
              if (this.isEmptyReportsData) {
                  return null;
              }
              if (this.adjustedBy === 'Month') {
                  let d = new Date();
                  let currentMonth = d.getMonth();
                  let months = [
                      {
                          month: 'January',
                          count: this.getterEPS.month.January.count,
                          insuranceCount: this.getterEPS.month.January.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.January.nonInsuranceCount,
                      },
                      {
                          month: 'February',
                          count: this.getterEPS.month.February.count,
                          insuranceCount: this.getterEPS.month.February.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.February.nonInsuranceCount,
                      },
                      {
                          month: 'March',
                          count: this.getterEPS.month.March.count,
                          insuranceCount: this.getterEPS.month.March.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.March.nonInsuranceCount,
                      },
                      {
                          month: 'April',
                          count: this.getterEPS.month.April.count,
                          insuranceCount: this.getterEPS.month.April.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.April.nonInsuranceCount,
                      },
                      {
                          month: 'May',
                          count: this.getterEPS.month.May.count,
                          insuranceCount: this.getterEPS.month.May.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.May.nonInsuranceCount,
                      },
                      {
                          month: 'June',
                          count: this.getterEPS.month.June.count,
                          insuranceCount: this.getterEPS.month.June.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.June.nonInsuranceCount,
                      },
                      {
                          month: 'July',
                          count: this.getterEPS.month.July.count,
                          insuranceCount: this.getterEPS.month.July.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.July.nonInsuranceCount,
                      },
                      {
                          month: 'August',
                          count: this.getterEPS.month.August.count,
                          insuranceCount: this.getterEPS.month.August.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.August.nonInsuranceCount,
                      },
                      {
                          month: 'September',
                          count: this.getterEPS.month.September.count,
                          insuranceCount: this.getterEPS.month.September.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.September.nonInsuranceCount,
                      },
                      {
                          month: 'October',
                          count: this.getterEPS.month.October.count,
                          insuranceCount: this.getterEPS.month.October.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.October.nonInsuranceCount,
                      },
                      {
                          month: 'November',
                          count: this.getterEPS.month.November.count,
                          insuranceCount: this.getterEPS.month.November.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.November.nonInsuranceCount,
                      },
                      {
                          month: 'December',
                          count: this.getterEPS.month.December.count,
                          insuranceCount: this.getterEPS.month.December.insuranceCount,
                          nonInsuranceCount: this.getterEPS.month.December.nonInsuranceCount,
                      },
                  ];
                  let prevMonths = months.slice(currentMonth + 1);
                  let currentMonths = months.slice(0, currentMonth + 1);
                  return _.concat(currentMonths.reverse(), prevMonths.reverse());

              } else if (this.adjustedBy === 'Week') {
                  return [
                      {
                          day: 'Monday',
                          count: this.getterEPS.week.Monday.count,
                          insuranceCount: this.getterEPS.week.Monday.insuranceCount,
                          nonInsuranceCount: this.getterEPS.week.Monday.nonInsuranceCount,
                      },
                      {
                          day: 'Tuesday',
                          count: this.getterEPS.week.Tuesday.count,
                          insuranceCount: this.getterEPS.week.Tuesday.insuranceCount,
                          nonInsuranceCount: this.getterEPS.week.Tuesday.nonInsuranceCount,
                      },
                      {
                          day: 'Wednesday',
                          count: this.getterEPS.week.Wednesday.count,
                          insuranceCount: this.getterEPS.week.Wednesday.insuranceCount,
                          nonInsuranceCount: this.getterEPS.week.Wednesday.nonInsuranceCount,
                      },
                      {
                          day: 'Thursday',
                          count: this.getterEPS.week.Thursday.count,
                          insuranceCount: this.getterEPS.week.Thursday.insuranceCount,
                          nonInsuranceCount: this.getterEPS.week.Thursday.nonInsuranceCount,
                      },
                      {
                          day: 'Friday',
                          count: this.getterEPS.week.Friday.count,
                          insuranceCount: this.getterEPS.week.Friday.insuranceCount,
                          nonInsuranceCount: this.getterEPS.week.Friday.nonInsuranceCount,
                      },
                      {
                          day: 'Saturday',
                          count: this.getterEPS.week.Saturday.count,
                          insuranceCount: this.getterEPS.week.Saturday.insuranceCount,
                          nonInsuranceCount: this.getterEPS.week.Saturday.nonInsuranceCount,
                      },
                      {
                          day: 'Sunday',
                          count: this.getterEPS.week.Sunday.count,
                          insuranceCount: this.getterEPS.week.Sunday.insuranceCount,
                          nonInsuranceCount: this.getterEPS.week.Sunday.nonInsuranceCount,
                      },
                  ];
              } else if (this.adjustedBy === 'Quarter') {
                  let q1 = [0, 1, 2];
                  let q2 = [3, 4, 5];
                  let q3 = [6, 7, 8];
                  let q4 = [9, 10, 11];

                  let d = new Date();
                  let currentMonth = d.getMonth();

                  let result = [];

                  if (q1.includes(currentMonth)) {
                      result = [
                          {
                            quarter: 'January - March',
                            count: this.getterEPS.quarter['January - March'].count,
                            insuranceCount: this.getterEPS.quarter['January - March'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['January - March'].nonInsuranceCount,
                          },
                          {
                            quarter: 'October - December',
                            count: this.getterEPS.quarter['October - December'].count,
                            insuranceCount: this.getterEPS.quarter['October - December'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['October - December'].nonInsuranceCount,
                          },
                          {
                            quarter: 'July - September',
                            count: this.getterEPS.quarter['July - September'].count,
                            insuranceCount: this.getterEPS.quarter['July - September'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['July - September'].nonInsuranceCount,
                          },
                          {
                            quarter: 'April - June',
                            count: this.getterEPS.quarter['April - June'].count,
                            insuranceCount: this.getterEPS.quarter['April - June'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['April - June'].nonInsuranceCount,
                          },
                      ];
                  } else if (q2.includes(currentMonth)) {
                      result = [
                          {
                            quarter: 'April - June',
                            count: this.getterEPS.quarter['April - June'].count,
                            insuranceCount: this.getterEPS.quarter['April - June'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['April - June'].nonInsuranceCount,
                          },
                          {
                            quarter: 'January - March',
                            count: this.getterEPS.quarter['January - March'].count,
                            insuranceCount: this.getterEPS.quarter['January - March'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['January - March'].nonInsuranceCount,
                          },
                          {
                            quarter: 'October - December',
                            count: this.getterEPS.quarter['October - December'].count,
                            insuranceCount: this.getterEPS.quarter['October - December'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['October - December'].nonInsuranceCount,
                          },
                          {
                            quarter: 'July - September',
                            count: this.getterEPS.quarter['July - September'].count,
                            insuranceCount: this.getterEPS.quarter['July - September'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['July - September'].nonInsuranceCount,
                          },
                      ];
                  } else if (q3.includes(currentMonth)) {
                      result = [
                          {
                            quarter: 'July - September',
                            count: this.getterEPS.quarter['July - September'].count,
                            insuranceCount: this.getterEPS.quarter['July - September'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['July - September'].nonInsuranceCount,
                          },
                          {
                            quarter: 'April - June',
                            count: this.getterEPS.quarter['April - June'].count,
                            insuranceCount: this.getterEPS.quarter['April - June'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['April - June'].nonInsuranceCount,
                          },
                          {
                            quarter: 'January - March',
                            count: this.getterEPS.quarter['January - March'].count,
                            insuranceCount: this.getterEPS.quarter['January - March'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['January - March'].nonInsuranceCount,
                          },
                          {
                            quarter: 'October - December',
                            count: this.getterEPS.quarter['October - December'].count,
                            insuranceCount: this.getterEPS.quarter['October - December'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['October - December'].nonInsuranceCount,
                          },
                      ];
                  } else if (q4.includes(currentMonth)) {
                      result = [
                          {
                            quarter: 'October - December',
                            count: this.getterEPS.quarter['October - December'].count,
                            insuranceCount: this.getterEPS.quarter['October - December'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['October - December'].nonInsuranceCount,
                          },
                          {
                            quarter: 'July - September',
                            count: this.getterEPS.quarter['July - September'].count,
                            insuranceCount: this.getterEPS.quarter['July - September'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['July - September'].nonInsuranceCount,
                          },
                          {
                            quarter: 'April - June',
                            count: this.getterEPS.quarter['April - June'].count,
                            insuranceCount: this.getterEPS.quarter['April - June'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['April - June'].nonInsuranceCount,
                          },
                          {
                            quarter: 'January - March',
                            count: this.getterEPS.quarter['January - March'].count,
                            insuranceCount: this.getterEPS.quarter['January - March'].insuranceCount,
                            nonInsuranceCount: this.getterEPS.quarter['January - March'].nonInsuranceCount,
                          },
                      ];
                  }

                  return result;
              } else if (this.adjustedBy === 'Year') {
                  let data = [];
                  for (let year in this.getterEPS.year) {
                      data.push(
                          {
                              year: year,
                              count: this.getterEPS.year[year].count,
                              insuranceCount: this.getterEPS.year[year].insuranceCount,
                              nonInsuranceCount: this.getterEPS.year[year].nonInsuranceCount,
                          });
                  }
                  return data;
              }
          },
      },
      methods: {
          ...mapActions({
              actionLoadReports: 'report/loadEPSAndAEVAndEVS',
          }),
          onClick(event, context, config){
            if (this.selectedColumn === this.computedChartOptions.xaxis.categories[config.dataPointIndex] || config.dataPointIndex === -1) {
              this.selectedColumn = '';
              return;
            }
            this.selectedColumn = this.computedChartOptions.xaxis.categories[config.dataPointIndex];
            if (this.adjustedBy == 'week') {
              return;
            }
            NProgress.start();
            Axios.get('/fe/reports/estimate/details/' + _.toLower(this.adjustedBy) + '/' + _.toLower(this.selectedColumn.replace(/\s+/g, '')))
                .then(response => {
                  this.estimateDetailData = response.data.data;
                })
                .catch(error => {
                  console.log(error);

                }).finally(() => {
              NProgress.done();
            });
          },
          findCountByEstimatorId(key, id) {
            let estimator = null;
            if (this.adjustedBy === 'Month') {
              estimator = _.find(this.getterEPS.month[key].estimators, (est) => {
                return Number(est.id) === Number(id);
              });
            } else if (this.adjustedBy === 'Week') {
              estimator = _.find(this.getterEPS.week[key].estimators, (est) => {
                return Number(est.id) === Number(id);
              });
            } else if (this.adjustedBy === 'Year') {
              estimator = _.find(this.getterEPS.year[key].estimators, (est) => {
                return Number(est.id) === Number(id);
              });
            } else if (this.adjustedBy === 'Quarter') {
              estimator = _.find(this.getterEPS.quarter[key].estimators, (est) => {
                return Number(est.id) === Number(id);
              });
            }
            if (!estimator) {
              return 0
            } else if (this.isDisplayByInsurer && !this.isDisplayByNonInsurance) {
              return estimator.insuranceCount;
            } else if (!this.isDisplayByInsurer && this.isDisplayByNonInsurance) {
              return estimator.nonInsuranceCount;
            } else {
              return estimator.count;
            }
          },
          getCurrentFilters() {
            let filters = '';
            if (this.isDisplayByNonInsurance === false || this.isDisplayByInsurer === false) {
              if (this.isDisplayByInsurer === true) {
                filters += 'isDisplayByInsurer;';
              }
              if (this.isDisplayByNonInsurance === true) {
                filters += 'isDisplayByNonInsurance;';
              }
            }
            if (this.isDisplayDifference === true) {
              filters += 'isDisplayDifference;';
            }
            if (this.isDisplayByEstimator === true) {
              filters += 'isDisplayByEstimator;';
            }
            if (filters == '') {
              return false;
            }
            return filters;
          },
          exportReport(format) {
              let token = localStorage.getItem('token');
              if (this.isSelectedColumn) {
                  let url = appConfig.baseAPIURL;
                  url += '/fe/reports/download/estimates/details/';
                  url += _.toLower(this.adjustedBy);
                  url += '/';
                  url += _.toLower(this.selectedColumn.replace(/\\s+/g, ''));
                  url += '/';
                  url += format;
                  url += '?at=' + token;
                  window.open(url);
              } else {
                  let type = 'ps';
                  let period = _.toLower(this.adjustedBy);
                  let value = 'count';
                  if (this.isDisplayByInsurer && !this.isDisplayByNonInsurance) {
                    value = 'insuranceCount';
                  } else if (!this.isDisplayByInsurer && this.isDisplayByNonInsurance) {
                    value = 'nonInsuranceCount';
                  }
                  let url = appConfig.baseAPIURL + `/fe/reports/download/estimates/${type}/${period}/${value}/${format}?at=${token}`;

                  if (value === 'count' && this.isDisplayDifference && !this.isDisplayByEstimator) {
                    url += '&displayDifference=yes'
                  }

                  if (this.isDisplayByEstimator) {
                    url += '&byEstimator=yes'
                  }

                  window.open(url);
              }
              return;
          }
      },
      mounted() {
          NProgress.start();
          this.actionLoadReports();
          let self = this;
          this.intervalUpdateReports = setInterval(() => {
              if (!_.isEmpty(self.getterEPS) && !_.isEmpty(self.computedSeries) && !_.isEmpty(self.computedChartOptions) && !_.isEmpty(self.chartDataForTable)) {
                  NProgress.done();
              }
          }, 1000);
      },
      beforeDestroy() {
          clearInterval(this.intervalUpdateReports);
      },
      components: {
          Multiselect,
        "vue-apex-chart": VueApexCharts,
      },
  };
</script>

<style scoped>
.active-filters-control {
  display: flex;
  flex-direction: column;
  width: 880px;
  position: relative;
  left: 96px;
  border: 1px solid rgba(27, 30, 56, 0.25);
}

.filters-checkboxes {
  padding: 10px 0 15px 15px;
  display: flex;
}

.filters-checkboxes-title {
  display: inline-block !important;
  width: 200px;
}

.filters-checkbox {
  display: flex;
  margin-right: 30px;
  display: inline-block !important;
  width: 100px;
}
</style>
