<script>
    import Axios from 'axios';

    export default {
        name: 'parts',
        data: function () {
            return {
                estimates: [],
                filter: '',
                estimatesOrdered: [],
                estimatesOrderedPerPage: 50,
                estimatesOrderedCurrentPage: 1,
                estimatesOrderedFiltered: [],
                current_page: 1,
                total_pages: 0,
                total_records: 0,
                start_num: 0,
                end_num: 0,
                fields: [
                    {label: 'Estimates Number', key: 'estimate_number', sortable: true, tdClass: 'text-center clickable number', thClass: 'text-center bg-navyblue number'},
                    {label: 'Customer Name', key: 'customer_name', sortable: true, tdClass: 'clickable customer', thClass: 'bg-navyblue customer'},
                    {label: 'Rego', key: 'rego_number', sortable: true, tdClass: 'text-center clickable rego', thClass: 'text-center bg-navyblue rego'},
                    {label: 'Order Nbr', key: 'order_nbr', sortable: true, tdClass: 'text-center clickable make', thClass: 'text-center bg-navyblue make'},
                    {label: 'Date Ordered', key: 'date_ordered', sortable: true, tdClass: 'text-center clickable model', thClass: 'text-center bg-navyblue model'},
                    {label: 'Due Date', key: 'date_due', sortable: true, tdClass: 'text-center clickable colour', thClass: 'text-center bg-navyblue colour'},
                    {label: '% Delivered', key: 'delivered_percent', sortable: true, tdClass: 'text-center clickable created-on', thClass: 'text-center bg-navyblue created-on'},
                ],
            };
        },
        computed: {
            estimatesOrderedRowsCount: function () {
                return this.estimatesOrdered.length;
            },
            filteredEstimatesOrderedRowsCount: function () {
                return this.estimatesOrderedFiltered.length;
            },

            pageOrdered: function () {
                let status = [];

                if (this.filteredEstimatesOrderedRowsCount > 0) {
                    status["from"] = (this.estimatesOrderedCurrentPage - 1) * this.estimatesOrderedPerPage + 1;
                    status["to"] = this.estimatesOrderedCurrentPage * this.estimatesOrderedPerPage;
                } else {
                    status["from"] = 0;
                    status["to"] = 0;
                }

                if (this.filteredEstimatesOrderedRowsCount % this.estimatesOrderedPerPage > 0 && this.estimatesOrderedCurrentPage > this.filteredEstimatesOrderedRowsCount / this.estimatesOrderedPerPage) {
                    status["to"] = this.filteredEstimatesOrderedRowsCount;
                }

                status["total_filtered"] = this.filteredEstimatesOrderedRowsCount;
                status["total_unfiltered"] = this.estimatesOrderedRowsCount;

                return status;
            }

        },
        methods: {
            loadEstimates: function (pageNumber) {
                NProgress.start();
                Axios.get('/fe/parts', {
                    params: {
                        pageNum: pageNumber,
                        sort_by: 'estimate_id',
                        sort_type: 'DESC'
                    }
                })
                    .then(response => {
                        this.estimates = response.data.data;
                        // this.current_page = response.data.current_page
                        // this.total_pages = response.data.total_pages
                        // this.total_records = response.data.total_records
                        // this.start_num = response.data.start_num
                        // this.end_num = response.data.end_num

                        for (let i = 0; i < this.estimates.length; i++) {
                            const q = this.estimates[i];
                            this.estimatesOrdered.push(q);

                        }

                        NProgress.done();
                    })
                    .catch(error => {
                        console.log(error);
                        NProgress.done();
                    });
            },

            paginationClick(pageNumber) {
                //   console.log(pageNumber)
                this.loadEstimates(pageNumber);
            },
            onOrderedEstimatesFiltered: function (fi) {
                this.estimatesOrderedFiltered = fi;
                this.estimatesOrderedCurrentPage = 1;
            },
            onEstimatesRowClick: function (item, index, event) {
                this.$router.push('/parts/ordered/' + item.estimate_id);
            }
        },
        mounted: function () {
            this.loadEstimates(1);
        },
        components: {
            //   EstimatesEdit,
            //   Paginate,
            //   DataTables
        }
    };
</script>

<template>
    <div>
        <div class="page-header">
        <h4>View Parts </h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Parts</li>
            </ol>
        </div>
        <div class="row">
            <div class="col-md-12">

                <div class="box box-block bg-white estimates">
                    <tabs>
                        <tab name="Ordered" id="estimates-ordered"  :class-name="'tab-content-table'">
                            <b-table
                                    responsive
                                    hover
                                    :items="estimatesOrdered" :fields="fields" :filter="filter" :current-page="estimatesOrderedCurrentPage" :per-page="estimatesOrderedPerPage"
                                     @filtered="onOrderedEstimatesFiltered" @row-clicked="onEstimatesRowClick"></b-table>
                            <div class="row">
                                <div class="col-md-6">
                                    <span>Showing {{pageOrdered.from}} to {{pageOrdered.to}} of {{pageOrdered.total_filtered}} estimates</span><span
                                        v-if="filteredEstimatesOrderedRowsCount < estimatesOrderedRowsCount"> &#40;filtered from {{pageOrdered.total_unfiltered}} total estimates&#41;</span>
                                </div>
                                <div class="col-md-6 text-md-right">
                                    <b-pagination align="right" :total-rows="filteredEstimatesOrderedRowsCount" v-model="estimatesOrderedCurrentPage" :per-page="estimatesOrderedPerPage"
                                                  @change="onOrderedEstimatesPageChange"></b-pagination>
                                </div>
                            </div>
                            <!-- <data-tables id="estimates-table-active" :tableColumns="columns" :tableData="estimatesActive" :tableOrder="[]" @onRowClick="editEstimate" class="table table-striped table-bordered dataTable"></data-tables> -->
                        </tab>


                        <template slot="nav-item-right">
                            <div class="tabs-nav-right-search">
                                <b-form-input v-model="filter" type="text" class="form-control search-input" placeholder="Search..." debounce="100"></b-form-input>
                            </div>
                        </template>
                    </tabs>
                </div>

                <!--<div v-if="!estimateToBeEdited" class="box box-block bg-white">

                    <p class="font-90 text-muted mb-1">This is a list of active estimates.</p>

                    <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <thead class="bg-navyblue">
                            <tr>
                                <th class="text-center">Estimate Number</th>
                                <th class="text-center">Card Number</th>
                                <th>Customer</th>
                                <th class="text-center">Rego</th>
                                <th class="text-center">Make</th>
                                <th class="text-center">Model</th>
                                <th class="text-center">Colour</th>
                                <th class="text-center">Insurance</th>
                                <th  class="text-center">Date</th>
                                <th  class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="q in estimates" @click="editEstimate(q.estimate_id)">
                                <th class="text-center" scope="row">{{q.estimate_number}}</th>
                                <td class="text-center" scope="row">{{q.card_number}}</td>
                                <td>{{q.customer_name}}</td>
                                <td class="text-center">{{q.rego_number}}</td>
                                <td class="text-center">{{q.make}}</td>
                                <td class="text-center">{{q.model}}</td>
                                <td class="text-center">{{q.colour}}</td>
                                <td class="text-center">{{q.insurance}}</td>
                                <td  class="text-center">{{q.created_on}}</td>
                                <td  class="text-center">{{q.status}}</td>
                            </tr>
                            <tr v-if="estimates.length == 0">
                                <td colspan="10" class="text-center">No Estimates found</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>

                    <div class="row dataTables_wrapper">
                        <div class="col-md-5">
                            <div class="dataTables_info" id="table-1_info" role="status" aria-live="polite" v-if="total_records > 0">Showing {{start_num}} to {{end_num}} of {{total_records}} entries</div>
                        </div>

                        <div class="col-md-7">
                            <div class="dataTables_paginate paging_simple_numbers">
                                <paginate v-if="total_pages > 0"
                                    :initial-page="current_page - 1"
                                    :page-count="total_pages"
                                    :click-handler="paginationClick"
                                    :prev-text="'Previous'"
                                    :next-text="'Next'"
                                    :container-class="'pagination'"
                                    :page-class="'page-item'"
                                    :page-link-class="'page-link'"
                                    :prev-class="'page-item'"
                                    :prev-link-class="'page-link'"
                                    :next-class="'page-item'"
                                    :next-link-class="'page-link'">
                                </paginate>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>

        </div>
    </div>
</template>

<style scoped>
    tbody tr {
        cursor: pointer;
    }
</style>
