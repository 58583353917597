<template>
  <div class="mb-0-75 border pt-0-5 pb-0-5 dashboard-row-element" @click="goToEmail(email)">
    <div class=" email-block-position">
      <div class="d-flex pl-0 dashboard-email-text" style="width: 70%">
        <span class="text-overflow"> From: {{ email.from }}</span>
      </div>
      <div class="d-flex p-0 dashboard-email-text justify-content-end" style="text-align: right; width: 30%">
        <div class="dashboard-email-time">
          <strong style="display: block">Received </strong>
          <strong style="white-space: nowrap">{{ email.createdOn | formatDateShort}} {{ email.createdOn | formatTime }}</strong>
        </div>
      </div>
    </div>
    <div class="row mt-0-5">
      <div class="col" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
        Subject: {{ email.subject }}
      </div>
    </div>
    <i :class="{'active-email-icon': !email.isViewed }" class='bx bx-envelope theme-color font-20 pull-right email-icon'></i>
  </div>
</template>

<script>
    export default {
      name: "email-summary",
      methods: {
        goToEmail(email) {
          this.$router.push(
            {name: 'CardsEdit', params: {action: 'goToEmail', card_id: email.cardId, emailId: email.id}}
          );
        }
      },
      props: {
        email: {
          type: [Object, null],
          default() {
            return {
              "id": 0,
              "recipient": "test@claimlinq.com.au",
              "html_text": "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
              "subject": "Email subject for A00311",
              "timestamp": null,
              "created_on": "2021-10-15 13:00:35",
              "createdOn": "2021-10-15 13:00:35",
              "from": "test@test.ru",
              "in_out": "in",
              "files": []
            };
          }
        },
      },
    };
</script>

<style scoped>
    .email-icon {
      color: #cecece;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .active-email-icon {
      color: #5E79FF !important;
    }
    .text-overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 75ch;
    }

    .font-20 {
      font-size: 20px;
    }

    .dashboard-row-element {
      background-color: white;
      padding: 10px 15px !important;
      position: relative;
    }

    .wrap-text {
      flex-wrap: wrap;
    }

    .email-block-position {
      display: flex;
      justify-content: space-between;
    }

    .email-block-position .dashboard-email-text {
      padding-right: 20px;
    }

    .dashboard-email-time {
      display: flex;
      padding-right: 30px;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    @media screen and (max-width: 440px) {
      .email-block-position {
        flex-direction: column;
      }

      .email-block-position .dashboard-email-text {
        width: 100% !important;
      }

      .email-block-position .dashboard-email-time {
        padding-right: 0 !important;
      }
    }

    @media (min-width: 991px) and (max-width: 1440px) {
      .email-block-position {
        flex-direction: column;
      }

      .email-block-position .dashboard-email-text {
        width: 100% !important;
      }

      .email-block-position .dashboard-email-time {
        padding-right: 0 !important;
      }
    }
</style>
