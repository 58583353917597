<template>
  <div style="transform: translateY(-10px)">
    <div class="tabs-component tabs-component-2" style="padding-top: 0px">
      <div class="tabs-component-w">
        <ul class="nav nav-tabs nav-tabs-2  float-sm-left">
          <li style='padding-top: 0px'  class="tabs-component-tab nav-item">
            <span href="" class="tabs-component-tab-a nav-link" :class="{'active': true}">Send To Customer</span>
          </li>
        </ul>
      </div>
    </div>
    <div style="margin-top: 15px; padding-left: 15px" class="card row no-gutter">
      <div class="col-lg-5 col-12 ">
        <div class="card-header bg-navyblue">
          <strong>Template - Send to Customer</strong>
        </div>
        <p class="description-header"> Set the text that you would like to appear on each SMS when sending your customer a copy of the estimate from the
          <span style="font-weight: bold">Options \ Send to Customer</span> button.</p>
        <div class="card-block bg-white">

          <div class="form-group row no-gutter rs-toggle">
            <label class="col-sm-5 col-form-label">
              SMS Status
              <p class="description-text">Applies this as a default to every <br/> SMS sent</p>
            </label>
            <div class="col-sm-5">
              <div class="d-flex" style="padding-top: 20px">
                <toggle-button
                    v-model="value.sms.sendToCustomer.status"
                    class="mb-0 rs-toggle__button"
                    color="#5E79FF"
                    :disabled="!isStatusActive"
                    :sync="true"
                    :width="40"
                    :labels="false"/>
                <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                  <template v-if="value.sms.sendToCustomer.status">Active</template>
                  <template v-else>Not Active</template>
                </span>
              </div>
            </div>
          </div>

          <div class="form-group row no-gutter">
            <label class="col-sm-5 col-form-label">
              SMS Message Text
              <p class="description-text">Set your standard message. <br/> Every text will be sent with a <br/> link for your customer to view <br/> the estimate online</p>
            </label>
            <div class="col-sm-5">
              <textarea style="height: 150px; width: 250px;" :readonly="!isStatusActive" v-model="value.sms.sendToCustomer.messageText" class="form-control"></textarea>
              <p class="limit-text" :class="{'error-text': value.sms.sendToCustomer.messageText.length > value.maxSmsTextLength }">Limit of {{value.maxSmsTextLength}} characters</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Axios from 'axios';
import {appConfig} from '../../config'
import XeroIntegration from './integrations/Xero'
import {mapGetters} from "vuex";

export default {
  name: "Sms",
  props: {
    value: {}
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    })
  },
};
</script>

<style scoped>
.limit-text {
  color: #A2ABAE;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
}

.error-text {
  color: red;
}

.tab-content .active {
  width: 100%;
}

.tabs-component-2::after {
  content: '';
  width: 100%;
  display: block;
  background: #e3e4e7;
  height: 1px;
  clear: both;
}

.nav-item:hover {
  cursor: pointer;
}

.description-text {
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.description-header {
  padding: 15px 15px 0px 15px;
}
</style>
