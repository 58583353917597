<script>
    import Axios from 'axios';
    import Paginate from 'vuejs-paginate';
    import {mapActions, mapGetters} from "vuex";
    import NumberFormatter from '../utility/number-formatter';
    import {VueContext} from 'vue-context';
    import BlockPagination from '../utility/block-pagination';
    import accounting from "accounting";
    import SearchFilter2 from '../../components/search-filter/search-filter-2'
    import _ from "lodash";

    export default {
        name: 'invoices',
        data: function () {
            return {
              searchFilter: '',

                invoices: [],

                invoicesAll: [],
                invoicesAllFiltered: [],

                invoicesAwaitingPayment: [],
                //invoicesAwaitingPaymentPerPage: 20,
                invoicesAwaitingPaymentFiltered: [],

                invoicesPaid: [],
                //invoicesPaidPerPage: 20,
                invoicesPaidFiltered: [],

                dpOptions: {
                    autoclose: true,
                    todayHighlight: true,
                    format: 'dd/mm/yyyy'
                },
                current_page: 1,
                total_pages: 0,
                total_records: 0,
                start_num: 0,
                end_num: 0,
                contextOpenEvent: null,

                fieldsAll: [
                    {
                      label: "Number",
                      key: "number",
                      sortable: true,
                      tdClass: 'text-center clickable number text-align-left font-bold',
                      thClass: 'text-center bg-navyblue number'
                    },
                    {
                      label: "Vehicle Owner",
                      key: "vehicle_owner",
                      sortable: true,
                      tdClass: 'clickable customer font-bold',
                      thClass: 'bg-navyblue customer'
                    },
                    {
                      label: "Insurer",
                      key: "insurer",
                      sortable: true,
                      tdClass: 'clickable insurer',
                      thClass: 'bg-navyblue insurer'
                    },
                    {
                      label: "Rego",
                      key: "rego_number",
                      sortable: true,
                      tdClass: 'text-center clickable rego',
                      thClass: 'text-center bg-navyblue rego'
                    },
                    {
                      label: "Created Date",
                      key: "created_date",
                      sortable: true,
                      sortByFormatted: (value, key, item) => {
                        return item.sortCreatedDate;
                      },
                      tdClass: 'text-center clickable make',
                      thClass: 'text-center bg-navyblue make'
                    },
                    {
                      label: "Due Date",
                      key: "due_date",
                      sortable: true,
                      tdClass: 'text-center clickable model',
                      thClass: 'text-center bg-navyblue model',
                      sortByFormatted: (value, key, item) => {
                        return item.sortDueDate;
                      },
                    },
                    {
                      label: "Paid Date",
                      key: "paidDate",
                      sortable: true,
                      sortByFormatted: (value, key, item) => {
                        return item.sortPaidDate;
                      },
                      tdClass: 'clickable colour',
                      thClass: 'text-center bg-navyblue colour'
                    },
                    {
                      label: "Paid",
                      key: "paid",
                      sortable: true,
                      sortByFormatted: (value, key, item) => {
                        return accounting.unformat(item.paid);
                      },
                      tdClass: 'text-align-right clickable currency',
                      thClass: 'text-center bg-navyblue currency'
                    },
                    {
                      label: "Due",
                      key: "due",
                      sortable: true,
                      sortByFormatted: (value, key, item) => {
                        return accounting.unformat(item.due);
                      },
                      tdClass: 'text-align-right clickable currency',
                      thClass: 'text-center bg-navyblue currency'
                    },
                    {
                      label: "Payment Type",
                      key: "paymentType",
                      sortable: true,
                      tdClass: 'text-align-right clickable currency',
                      thClass: 'text-center bg-navyblue currency'
                    },
                    {
                      label: "Status",
                      key: "status_name",
                      sortable: true,
                      tdClass: 'text-center clickable status font-bold',
                      thClass: 'text-center bg-navyblue status'
                    }
                ],
                fieldsDraft: [
                    {label: "Number", key: "number", sortable: true, tdClass: 'text-center clickable number text-align-left font-bold', thClass: 'text-center bg-navyblue number'},
                    {label: "Vehicle Owner", key: "vehicle_owner", sortable: true, tdClass: 'clickable customer font-bold', thClass: 'bg-navyblue customer'},
                    {label: "Insurer", key: "insurer", sortable: true, tdClass: 'clickable insurer', thClass: 'bg-navyblue insurer'},
                    {label: "Rego", key: "rego_number", sortable: true, tdClass: 'text-center clickable rego', thClass: 'text-center bg-navyblue rego'},
                    {label: "Draft Date", key: "created_date", sortable: true, tdClass: 'text-center clickable date', thClass: 'text-center bg-navyblue date'},
                    {label: "Due Date", key: "due_date", sortable: true, tdClass: 'clickable date', thClass: 'text-center bg-navyblue date'},
                    {label: "Due", sortable: true, key: "due", tdClass: 'text-center clickable booking-date', thClass: 'text-center bg-navyblue booking-date'}
                ],
                fieldsAwaitingPayment: [
                    {
                      label: "Number",
                      key: "number",
                      sortable: true,
                      tdClass: 'text-center clickable number text-align-left font-bold',
                      thClass: 'text-center bg-navyblue number'
                    },
                    {
                      label: "Vehicle Owner",
                      key: "vehicle_owner",
                      sortable: true,
                      tdClass: 'clickable customer font-bold',
                      thClass: 'bg-navyblue customer'
                    },
                    {
                      label: "Insurer",
                      key: "insurer",
                      sortable: true,
                      tdClass: 'clickable insurer',
                      thClass: 'bg-navyblue insurer'
                    },
                    {
                      label: "Rego",
                      key: "rego_number",
                      sortable: true,
                      tdClass: 'text-center clickable rego',
                      thClass: 'text-center bg-navyblue rego'
                    },
                    {
                      label: "Created Date",
                      key: "created_date",
                      sortable: true,
                      sortByFormatted: (value, key, item) => {
                        return item.sortCreatedDate;
                      },
                      tdClass: 'text-center clickable colour',
                      thClass: 'text-center bg-navyblue colour'
                    },
                    {
                      label: "Due Date",
                      key: "due_date",
                      sortable: true,
                      sortByFormatted: (value, key, item) => {
                        return item.sortDueDate;
                      },
                      tdClass: 'clickable insurance',
                      thClass: 'text-center bg-navyblue insurance'
                    },
                    {
                      label: "Overdue By",
                      key: "overdue",
                      sortable: true,
                      tdClass: 'clickable insurance',
                      thClass: 'text-center bg-navyblue insurance'
                    },
                    {
                      label: "Paid",
                      sortable: true,
                      sortByFormatted: (value, key, item) => {
                        return accounting.unformat(item.paid);
                      },
                      key: "paid",
                      tdClass: 'text-align-right clickable booking-date',
                      thClass: 'text-center bg-navyblue booking-date'
                    },
                    {
                      label: "Due",
                      sortable: true,
                      key: "due",
                      sortByFormatted: (value, key, item) => {
                        return accounting.unformat(item.due);
                      },
                      tdClass: 'text-align-right clickable booking-date',
                      thClass: 'text-center bg-navyblue booking-date'
                    },
                    {
                      label: "Sent Status",
                      sortable: true,
                      key: "sent_status",
                      tdClass: 'text-center clickable booking-date',
                      thClass: 'text-center bg-navyblue booking-date'
                    }
                ],
                fieldsPaid: [
                    {
                      label: "Number",
                      key: "number",
                      sortable: true,
                      tdClass: 'text-center clickable number text-align-left font-bold',
                      thClass: 'text-center bg-navyblue number'
                    },
                    {
                      label: "Vehicle Owner",
                      key: "vehicle_owner",
                      sortable: true,
                      tdClass: 'clickable customer font-bold',
                      thClass: 'bg-navyblue customer'
                    },
                    {
                      label: "Insurer",
                      key: "insurer",
                      sortable: true,
                      tdClass: 'clickable insurer',
                      thClass: 'bg-navyblue insurer'
                    },
                    {
                      label: "Rego",
                      key: "rego_number",
                      sortable: true,
                      tdClass: 'text-center clickable rego',
                      thClass: 'text-center bg-navyblue rego'
                    },
                    {
                      label: "Created Date",
                      key: "created_date",
                      sortByFormatted: (value, key, item) => {
                        return item.sortCreatedDate;
                      },
                      sortable: true,
                      tdClass: 'text-center clickable colour',
                      thClass: 'text-center bg-navyblue colour'
                    },
                    {
                      label: "Paid Date",
                      key: "paid_date",
                      sortByFormatted: (value, key, item) => {
                        return item.sortPaidDate;
                      },
                      sortable: true,
                      tdClass: 'text-center clickable colour',
                      thClass: 'text-center bg-navyblue colour'
                    },
                    {
                      label: "Paid",
                      sortable: true,
                      key: "paid",
                      sortByFormatted: (value, key, item) => {
                        return accounting.unformat(item.paid);
                      },
                      tdClass: 'text-align-right clickable booking-date',
                      thClass: 'text-center bg-navyblue booking-date'
                    },
                    {
                      label: "Sent Status",
                      sortable: true,
                      key: "sent_status",
                      tdClass: 'text-center clickable booking-date font-bold',
                      thClass: 'text-center bg-navyblue booking-date'
                    }
                ],

            };
        },
        computed: {
            ...mapGetters({
                getterAllInvoices: 'invoice/getAllInvoices',
                getterAwaitingPaymentInvoices: 'invoice/getAwaitingPaymentInvoices',
                getterPaidInvoices: 'invoice/getPaidInvoices',
                isCustomerUser: 'isCustomerUser',
                'vendorInfo': 'vendorInfo',
                getterPerPage: 'invoice/getInvoicesPerPage',
                getterInvoicesAllCurrentPage: 'invoice/getInvoicesAllCurrentPage',
                getterInvoicesAwaitingPaymentCurrentPage: 'invoice/getInvoicesAwaitingPaymentCurrentPage',
                getterInvoicesPaidCurrentPage: 'invoice/getInvoicesPaidCurrentPage',
                getterSortByForInvoicesAll: 'invoice/getSortByForInvoicesAll',
                getterSortDescForInvoicesAll: 'invoice/getSortDescForInvoicesAll',
                getterSortByForInvoicesAwaitingPayment: 'invoice/getSortByForInvoicesAwaitingPayment',
                getterSortDescForInvoicesAwaitingPayment: 'invoice/getSortDescForInvoicesAwaitingPayment',
                getterSortByForInvoicesPaid: 'invoice/getSortByForInvoicesPaid',
                getterSortDescForInvoicesPaid: 'invoice/getSortDescForInvoicesPaid',
                isStatusActive: 'isStatusActive',
                getterBusinessName : 'getBusinessName',
              getterToken: 'invoice/getToken',
              getterTokens: 'invoice/getTokens',
              getterSearchFilterObj: 'invoice/getSearchFilterObj',
              isPDRTechnicianUser: 'isPDRTechnicianUser',
              isTechnicalUser: 'isTechnicalUser',
              isPanelTechnicianUser: 'isPanelTechnicianUser',
              isPaintTechnicianUser: 'isPaintTechnicianUser',
              isStripperFitterUser: 'isStripperFitterUser',
              isDisplayBoardUser: 'isDisplayBoardUser',
              getterInsurerCardColor: 'getInsurerCardColor',
            }),
          computedInvoicesAll() {
            return this.filterInvoices(this.invoicesAll)
          },
          computedInvoicesAwaitingPayment() {
            return this.filterInvoices(this.invoicesAwaitingPayment)
          },
          computedInvoicesPaid() {
            return this.filterInvoices(this.invoicesPaid)
          },
          isCanSearchBySomeFields() {
            return !this.isPDRTechnicianUser && !this.isPanelTechnicianUser && !this.isPaintTechnicianUser && !this.isStripperFitterUser && !this.isDisplayBoardUser
          },
          computedToken() {
            return this.getterToken
          },
          computedSearchFilters() {
            let invoiceNumbers = {};
            let estimateNumbers = {};
            let insurers = {};
            let regos = {};
            let makes = {};
            let models = {};
            let vehicleOwner = {};


            this.getterAllInvoices.forEach(invoice => {
              if (invoice.number) {
                invoiceNumbers[invoice.number.trim().toLowerCase()] = {label: invoice.number.trim()}
              }
            })

            this.getterAllInvoices.forEach(invoice => {
              if (invoice.estimateNumber) {
                estimateNumbers[invoice.estimateNumber.trim().toLowerCase()] = {label: invoice.estimateNumber.trim()}
              }
            })

            this.getterAllInvoices.forEach(invoice => {
              if (invoice.vehicle_owner) {
                vehicleOwner[invoice.vehicle_owner.trim().toLowerCase()] = {label: invoice.vehicle_owner.trim()}
              }
            })

            this.getterAllInvoices.forEach(invoice => {
              if (invoice.rego_number) {
                regos[invoice.rego_number.trim().toLowerCase()] = {label: invoice.rego_number.trim()}
              }
            })

            this.getterAllInvoices.forEach(invoice => {
              if (invoice.make) {
                makes[invoice.make.trim().toLowerCase()] = {label: invoice.make.trim()}
              }
            })

            this.getterAllInvoices.forEach(invoice => {
              if (invoice.model) {
                models[invoice.model.trim().toLowerCase()] = {label: invoice.model.trim()}
              }
            })

            this.getterAllInvoices.forEach(invoice => {
              if (invoice.insurer) {
                insurers[invoice.insurer.trim().toLowerCase()] = {label: invoice.insurer.trim()}
              }
            })

            invoiceNumbers = _.orderBy(Object.values(invoiceNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            estimateNumbers = _.orderBy(Object.values(estimateNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            vehicleOwner = _.orderBy(Object.values(vehicleOwner), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            regos = _.orderBy(Object.values(regos), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            makes = _.orderBy(Object.values(makes), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            models = _.orderBy(Object.values(models), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
            insurers = _.orderBy(Object.values(insurers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

            let result = [
              {
                id: 'invoiceNumber',
                label: 'Invoice Number',
                iconClass: 'bx-user',
                options: [
                  invoiceNumbers,
                ]
              },
              {
                id: 'estimateNumber',
                label: 'Estimate Number',
                iconClass: 'bx-user',
                options: [
                  estimateNumbers,
                ]
              },
              {
                id: 'insurer',
                label: 'Insurer',
                iconClass: 'bx-user',
                options: [
                  insurers,
                ]
              },
              {
                id: 'rego',
                label: 'Rego',
                iconClass: 'bx-user',
                options: [
                  regos,
                ]
              },
              {
                id: 'make',
                label: 'Make',
                iconClass: 'bx-user',
                options: [
                  makes,
                ]
              },
              {
                id: 'model',
                label: 'Model',
                iconClass: 'bx-store',
                options: [
                  models
                ]
              },
              {
                id: 'vehicleOwner',
                label: 'Vehicle Owner',
                iconClass: 'bx-store',
                options: [
                  vehicleOwner
                ]
              },
            ]

            if (!this.isCanSearchBySomeFields) {
              result = _.filter(result, (r) => {
                return r.id !== 'estimateNumber' && r.id !== 'insurer'
              })
            }
            return result
          },
          searchFilterObj() {
            return this.getterSearchFilterObj
          },
          isInvoiceNumberFilterActive() {
            return this.getIsActiveFilter('invoiceNumber')
          },
          computedInvoiceNumberFilter() {
            return this.getActiveFilter('invoiceNumber')
          },

          isEstimateNumberFilterActive() {
            return this.getIsActiveFilter('estimateNumber')
          },
          computedEstimateNumberFilter() {
            return this.getActiveFilter('estimateNumber')
          },

          isVehicleOwnerFilterActive() {
            return this.getIsActiveFilter('vehicleOwner')
          },
          computedVehicleOwnerFilter() {
            return this.getActiveFilter('vehicleOwner')
          },

          isInsurerNameFilterActive() {
            return this.getIsActiveFilter('insurer')
          },
          computedInsurerNameFilter() {
            return this.getActiveFilter('insurer')
          },

          isRegoFilterActive() {
            return this.getIsActiveFilter('rego')
          },
          computedRegoFilter() {
            return this.getActiveFilter('rego')
          },

          isMakeFilterActive() {
            return this.getIsActiveFilter('make')
          },
          computedMakeFilter() {
            return this.getActiveFilter('make')
          },

          isModelFilterActive() {
            return this.getIsActiveFilter('model')
          },
          computedModelFilter() {
            return this.getActiveFilter('model')
          },
            filter: {
              get() {
                return this.$store.getters.invoiceFilter;
              },
              set(value) {
                this.$store.commit('setInvoiceFilter', value);
              }
            },
            computedSortByForInvoicesAll() {
              return this.getterSortByForInvoicesAll;
            },
            computedSortDescForInvoicesAll() {
              return this.getterSortDescForInvoicesAll;
            },
            computedSortByForInvoicesAwaitingPayment() {
              return this.getterSortByForInvoicesAwaitingPayment;
            },
            computedSortDescForInvoicesAwaitingPayment() {
              return this.getterSortDescForInvoicesAwaitingPayment;
            },
            computedSortByForInvoicesPaid() {
              return this.getterSortByForInvoicesPaid;
            },
            computedSortDescForInvoicesPaid() {
              return this.getterSortDescForInvoicesPaid;
            },
            invoicesPerPage() {
              return this.getterPerPage;
            },
            invoicesAllCurrentPage() {
              return this.getterInvoicesAllCurrentPage;
            },
            invoicesAwaitingPaymentCurrentPage() {
              return this.getterInvoicesAwaitingPaymentCurrentPage
            },
            invoicesPaidCurrentPage() {
              return this.getterInvoicesPaidCurrentPage;
            },
            invoicesAllFiltered2() {
                return this.invoicesAllFiltered;
            },
            invoicesAwaitingPaymentFiltered2() {
                return this.invoicesAwaitingPaymentFiltered;
            },
            invoicesPaidFiltered2() {
                return this.invoicesPaidFiltered;
            },
            computedPerPage() {
                return this.invoicesPerPage;
            },
        },
        methods: {
            ...mapActions({
                actionLoadInvoices: 'invoice/loadInvoices',
            }),
          getInsurerNameStyle(id) {
            if (!id) {
              return null
            }
            let data = this.getterInsurerCardColor?.[id];
            if (data) {
              return {
                color: data.color,
                backgroundColor: data.background,
                fontWeight: 800,
                padding: '3px 7px',
                borderRadius: '5px',
                textAlign: 'center',
                display: 'inline-block'
              }
            }
            return null
          },
          getIsActiveFilter(field) {
            for (let key in this.searchFilterObj) {
              if (this.searchFilterObj[key][field]) {
                return true
              }
            }
            return false
          },
          getActiveFilter(field) {
            let filter = {            }
            filter[field] = ''
            let obj = Object.assign({}, this.searchFilterObj)
            for (let key in obj) {
              if (obj[key][field]) {
                filter = obj[key]
                break
              }
            }
            filter = _.split(filter[field], ';')
            return _.filter(filter, (f) => {return _.trim(f)})
          },
          filterInvoices(value) {
            return _.filter(value, (itm) => {
              if (!this.isInvoiceNumberFilterActive && !this.isInsurerNameFilterActive
                  && !this.isRegoFilterActive && !this.isMakeFilterActive && !this.isModelFilterActive && !this.isVehicleOwnerFilterActive && !this.isEstimateNumberFilterActive) {
                return true
              }
              let b = false;
              if (this.isInvoiceNumberFilterActive) {
                if (_.includes((this.computedInvoiceNumberFilter), _.trim(itm.number))) {
                  b = true
                }
              }

              if (this.isCanSearchBySomeFields && this.isInsurerNameFilterActive && _.trim(itm.insurer)) {
                if (_.includes((this.computedInsurerNameFilter), _.trim(itm.insurer))) {
                  b = true
                }
              }

              if (this.isCanSearchBySomeFields &&this.isEstimateNumberFilterActive && _.trim(itm.estimateNumber)) {
                if (_.includes((this.computedEstimateNumberFilter), _.trim(itm.estimateNumber))) {
                  b = true
                }
              }

              if (this.isVehicleOwnerFilterActive && _.trim(itm.vehicle_owner)) {
                if (_.includes((this.computedVehicleOwnerFilter), _.trim(itm.vehicle_owner))) {
                  b = true
                }
              }

              if (this.isRegoFilterActive && _.trim(itm.rego_number)) {
                if (_.includes((this.computedRegoFilter), _.trim(itm.rego_number))) {
                  b = true
                }
              }

              if (this.isMakeFilterActive && _.trim(itm.make)) {
                if (_.includes((this.computedMakeFilter), _.trim(itm.make))) {
                  b = true
                }
              }
              if (this.isModelFilterActive && _.trim(itm.model)) {
                if (_.includes((this.computedModelFilter), _.trim(itm.model))) {
                  b = true
                }
              }
              return b
            })
          },
          onSearchFilterChange(filters) {
            console.log(filters, 'filters')
            this.$store.commit('invoice/setSearchFilterObj', filters)
            if (filters && filters[0] && filters[0].search) {
              this.$store.commit('setInvoiceFilter', filters[0].search);
              // this.filter = filters.search;
            } else {
              this.$store.commit('setInvoiceFilter', '');
              // this.filter = '';
            }
            this.countOfFilterChange += 1
          },
            setSortByAndSortDescForInvoicesAll(e) {
              this.$store.commit('invoice/setSortByForInvoicesAll', e.sortBy);
              this.$store.commit('invoice/setSortDescForInvoicesAll', e.sortDesc);
            },
            setSortByAndSortDescForInvoicesAwaitingPayment(e) {
              this.$store.commit('invoice/setSortByForInvoicesAwaitingPayment', e.sortBy);
              this.$store.commit('invoice/setSortDescForInvoicesAwaitingPayment', e.sortDesc);
            },
            setSortByAndSortDescForInvoicesPaid(e) {
              this.$store.commit('invoice/setSortByForInvoicesPaid', e.sortBy);
              this.$store.commit('invoice/setSortDescForInvoicesPaid', e.sortDesc);
            },
            filterForTable(row, filter) {
              let formattedFilter = _.trim(_.toLower(filter))
              if (row.number && row.number.toLowerCase().includes(formattedFilter)) {
                return true;
              }
              // else if (row.vehicle_owner && row.vehicle_owner.toLowerCase().includes(formattedFilter)) {
              //   return true;
              // }
              else if (this.isCanSearchBySomeFields && row.insurer && row.insurer.toLowerCase().includes(formattedFilter)) {
                return true;
              }
              else if (row.rego_number && row.rego_number.toLowerCase().includes(formattedFilter)) {
                return true;
              } else if (row.model && row.model.toLowerCase().includes(formattedFilter)) {
                return true;
              } else if (this.isCanSearchBySomeFields && row.estimateNumber && row.estimateNumber.toLowerCase().includes(formattedFilter)) {
                return true;
              }
              // else if (row.created_date && row.created_date.includes(formattedFilter)) {
              //   return true;
              // } else if (row.due_date && row.due_date.includes(formattedFilter)) {
              //   return true;
              // } else if (row.paid && row.paid.includes(formattedFilter)) {
              //   return true;
              // } else if (row.due && row.due.includes(formattedFilter)) {
              //   return true;
              // } else if (row.status_name && row.status_name.toLowerCase().includes(formattedFilter)) {
              //   return true;
              // } else if (row.claimNumber && row.claimNumber.toLowerCase().includes(formattedFilter)) {
              //   return true;
              // } else if (row.sent_status && row.sent_status.toLowerCase().includes(formattedFilter)) {
              //   return true;
              // } else if (row.overdue && row.overdue.toString().includes(formattedFilter)) {
              //   return true;
              // } else if (row.paidDate && row.paidDate.includes(formattedFilter)) {
              //   return true;
              // } else if (row.paymentType && row.paymentType.toLowerCase().includes(formattedFilter)) {
              //   return true;
              // }
              return false;
            },
            markAsUnpaid(id){
              this.saveInvoice({'unpaid': true}, id)
            },
            saveInvoice: function (data, id) {
              let invoice_id = id
              NProgress.start();
              Axios({
                method: 'post',
                responseType: 'json',
                url: `/fe/invoice/${invoice_id}`,
                validateStatus: function (status) {
                  return status < 500;
                },
                data: JSON.stringify({data})
              })
                  .then(response => {
                      toastr.success('Invoice updated');
                      NProgress.done();
                      this.loadInvoices()
                  })
                  .catch(error => {
                    toastr.error('error')
                    NProgress.done();
                    console.log(error);
                  });

            },
            sendToXero(id) {
                NProgress.start();
                Axios.post(`/fe/invoice/${id}/send-to-xero`)
                    .then(response => {
                        if (response.data && response.data._status) {
                            toastr.success('Successfully sent to Xero');
                            this.loadInvoices();
                        } else {
                            toastr.error(response.data._error);
                        }
                    })
                    .catch(e => console.error(e))
                    .finally(() => NProgress.done());
            },
            viewOnXero(id) {
                NProgress.start();
                Axios.post(`/fe/invoice/${id}/view-on-xero`)
                    .then(response => {
                        if (response.data && response.data._status) {
                            window.open(response.data.url, '__blank');
                        }
                    })
                    .catch(e => console.error(e))
                    .finally(() => NProgress.done());
                this.$refs.contextMenuForInvoices.close();
            },
            loadInvoices: function (pageNumber) {
                this.setData();
                //  NProgress.start();
                this.actionLoadInvoices()
                    .then((t) => {
                        this.setData();
                        //  NProgress.done();
                    })
                    .catch((error) => {
                        console.log(error);
                        //  NProgress.done();
                    });

            },
            setData() {
                this.invoicesAll = this.getterAllInvoices;
                this.invoicesAwaitingPayment = this.getterAwaitingPaymentInvoices;
                this.invoicesPaid = this.getterPaidInvoices;

                this.invoicesAllFiltered = this.getterAllInvoices;
                this.invoicesAwaitingPaymentFiltered = this.getterAwaitingPaymentInvoices;
                this.invoicesPaidFiltered = this.getterPaidInvoices;
            },
            onAllInvoicesFiltered: function (fi) {
                this.invoicesAllFiltered = fi;
                this.$store.commit('invoice/setInvoicesAllCurrentPage', 1)
            },
            onAwaitingPaymentInvoicesFiltered: function (fi) {
                this.invoicesAwaitingPaymentFiltered = fi;
                this.$store.commit('invoice/setInvoicesAwaitingPaymentCurrentPage', 1)
            },
            onPaidInvoicesFiltered: function (fi) {
                this.invoicesPaidFiltered = fi;
                this.$store.commit('invoice/setInvoicesPaidCurrentPage', 1)
            },

            onInvoicesRowClick: function (item, index, event) {
                if (item.is_excess_invoice) {
                    this.$router.push('/invoice/view/' + item.id + '/excess');
                } else {
                    this.$router.push('/invoice/view/' + item.id);
                }
            },
            onRowContextMenu(item, index, event) {
                if (!this.isStatusActive) {
                  return;
                }
                event.preventDefault();
                let row = event.target.parentElement;
                let rows = row.parentElement.rows;
                for (let i = 0; i < rows.length; i++) {
                    let elem = rows[i];
                    elem.style.backgroundColor = "";
                }
                row.style.backgroundColor = "#F3F3F3";
                if (this.vendorInfo.activeXeroAuth && !this.isCustomerUser) {
                    this.$refs.contextMenuForInvoices.open(event, {item: item, index: index});
                } else {
                    this.$refs.contextMenuForInvoices.close();
                }

            },
            contextOpen: function (event, w, e) {
                this.contextOpenEvent = event;
            },
            contextClose: function () {
                let event = this.contextOpenEvent;
                if (event) {
                    let row = event.target.parentElement;
                    let rows = row.parentElement.rows;
                    for (let i = 0; i < rows.length; i++) {
                        let elem = rows[i];
                        elem.style.backgroundColor = "";
                    }
                }
            },
        },
        mounted: function () {
            this.loadInvoices(1);

          if (!this.isCanSearchBySomeFields && this.getterTokens.length == 7) {
            this.$store.commit('invoice/setTokensNew',
                {
                  0: [],
                  1: [],
                  2: [],
                  3: [],
                  4: [],
                }
            )
          }
        },
        components: {
            Paginate,
            // DataTables
            NumberFormatter,
            VueContext,
            BlockPagination,
          SearchFilter2
        }
    };
</script>

<template>
    <div class="invoices-page">
        <div class="page-header">
            <h4>View Invoices</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Invoices</li>
            </ol>
            <h4 class="header-display-name">{{ getterBusinessName }}</h4>
            <div class="page-header-nav-btn float-right" v-if="isStatusActive">
                <router-link to="/invoice/create" class="btn btn-primary">
                    <i class="ti-plus"></i> New Invoice
                </router-link>
            </div>
        </div>
        <div class="box-block bg-white invoices">
            <tabs :options="{useUrlFragment: false, defaultTabHash: 'invoices-all' }">
                <tab name="All" id="invoices-all" :class-name="'tab-content-table'">
                    <div style="overflow-x: auto;" class="pagination-block-bottom">
                        <b-table
                                class="rs-scroll rs-scroll--y noselect"
                                responsive
                                hover
                                :items="computedInvoicesAll"
                                :fields="fieldsAll"
                                :filter="filter"
                                :filter-function="filterForTable"
                                :sort-desc="computedSortDescForInvoicesAll"
                                :sort-by="computedSortByForInvoicesAll"
                                @sort-changed="setSortByAndSortDescForInvoicesAll"
                                :current-page="invoicesAllCurrentPage"
                                :per-page="invoicesPerPage"
                                @filtered="onAllInvoicesFiltered"
                                @row-clicked="onInvoicesRowClick"
                                @row-contextmenu="onRowContextMenu"
                        >
                          <template v-slot:cell(insurer)="data">
                            <div v-if="data.item.insurer" :style="getInsurerNameStyle(data.item.insurerCardColorId)">
                              {{ data.item.insurer }}
                            </div>
                          </template>
                        </b-table>
                    </div>
                    <block-pagination
                            :role="'invoices'"
                            :arrayOfSomeone="invoicesAll"
                            :arrayOfSomeoneFiltered="filter ? invoicesAllFiltered2: computedInvoicesAll"
                            :currentPage="invoicesAllCurrentPage"
                            @changedCurrentPage="value => $store.commit('invoice/setInvoicesAllCurrentPage', value)"
                            :perPage="invoicesPerPage"
                            :perPageForMultipleTables="computedPerPage"
                            @changedPerPage="value => $store.commit('invoice/setInvoicesPerPage', value)"
                    >
                    </block-pagination>

                </tab>
                <tab name="Awaiting Payment" id="invoices-awaiting-payment" :class-name="'tab-content-table'">
                    <div style="overflow-x: auto;" class="pagination-block-bottom">
                        <b-table
                                class="rs-scroll rs-scroll--y noselect"
                                responsive
                                hover
                                :items="computedInvoicesAwaitingPayment"
                                :fields="fieldsAwaitingPayment"
                                :filter="filter"
                                :filter-function="filterForTable"
                                :sort-desc="computedSortDescForInvoicesAwaitingPayment"
                                :sort-by="computedSortByForInvoicesAwaitingPayment"
                                @sort-changed="setSortByAndSortDescForInvoicesAwaitingPayment"
                                :current-page="invoicesAwaitingPaymentCurrentPage"
                                :per-page="invoicesPerPage"
                                @filtered="onAwaitingPaymentInvoicesFiltered"
                                @row-clicked="onInvoicesRowClick"
                                @row-contextmenu="onRowContextMenu"
                        >
                          <template v-slot:cell(insurer)="data">
                            <div v-if="data.item.insurer" :style="getInsurerNameStyle(data.item.insurerCardColorId)">
                              {{ data.item.insurer }}
                            </div>
                          </template>
                        </b-table>
                    </div>
                    <block-pagination
                            :role="'invoices'"
                            :arrayOfSomeone="invoicesAwaitingPayment"
                            :arrayOfSomeoneFiltered="filter? invoicesAwaitingPaymentFiltered2 : computedInvoicesAwaitingPayment"
                            :currentPage="invoicesAwaitingPaymentCurrentPage"
                            @changedCurrentPage="value => $store.commit('invoice/setInvoicesAwaitingPaymentCurrentPage', value)"
                            :perPage="invoicesPerPage"
                            :perPageForMultipleTables="computedPerPage"
                            @changedPerPage="value => $store.commit('invoice/setInvoicesPerPage', value)"
                    >
                    </block-pagination>
                </tab>
                <tab name="Paid" id="invoices-paid" :class-name="'tab-content-table'">
                    <div style="overflow-x: auto;" class="pagination-block-bottom">
                        <b-table
                                class="rs-scroll rs-scroll--y noselect"
                                responsive
                                hover
                                :items="computedInvoicesPaid"
                                :fields="fieldsPaid"
                                :filter="filter"
                                :filter-function="filterForTable"
                                :sort-desc="computedSortDescForInvoicesPaid"
                                :sort-by="computedSortByForInvoicesPaid"
                                @sort-changed="setSortByAndSortDescForInvoicesPaid"
                                :current-page="invoicesPaidCurrentPage"
                                :per-page="invoicesPerPage"
                                @filtered="onPaidInvoicesFiltered"
                                @row-clicked="onInvoicesRowClick"
                                @row-contextmenu="onRowContextMenu"
                        >
                          <template v-slot:cell(insurer)="data">
                            <div v-if="data.item.insurer" :style="getInsurerNameStyle(data.item.insurerCardColorId)">
                              {{ data.item.insurer }}
                            </div>
                          </template>
                        </b-table>
                    </div>
                    <block-pagination
                            :role="'invoices'"
                            :arrayOfSomeone="invoicesPaid"
                            :arrayOfSomeoneFiltered="filter ? invoicesPaidFiltered2 : computedInvoicesPaid"
                            :currentPage="invoicesPaidCurrentPage"
                            @changedCurrentPage="value => $store.commit('invoice/setInvoicesPaidCurrentPage', value)"
                            :perPage="invoicesPerPage"
                            :perPageForMultipleTables="computedPerPage"
                            @changedPerPage="value => $store.commit('invoice/setInvoicesPerPage', value)"
                    >
                    </block-pagination>
                </tab>
                <template slot="nav-item-right">
                    <div class="tabs-nav-right-search tabs-nav-right-search-font-weight">
<!--                        <b-form-input v-model="filter" type="text" class="form-control search-input" placeholder="Search..." debounce="300"></b-form-input>-->
                      <search-filter2
                          type="estimates"
                          v-model="searchFilter"
                          class="search-filter"
                          :filters="computedSearchFilters"
                          :filterValue="filter"
                          :limit-length="50"
                          :isShowSearIcon="true"
                          @change="onSearchFilterChange"
                          @updateToken="value => $store.commit('invoice/setToken', value)"
                          @updateTokens="value => $store.commit('invoice/setTokens', value)"
                          :tokenCopy="computedToken"
                          :tokensCopy="getterTokens"
                          height="41px"
                          aria-expanded="false"
                          data-toggle="dropdown"
                          :isMyltipleOptions="true"
                          :isMyltiple="true"
                          :optionsLength="isCanSearchBySomeFields ? 7 : 5"
                      ></search-filter2>
                    </div>
                    <div class="tabs-nav-right-btn" v-if="isStatusActive" style="position: relative; bottom: 8px">
                        <router-link to="/invoice/create" class="btn btn-primary">
                            <i class="ti-plus"></i> New Invoice
                        </router-link>
                    </div>
                </template>
            </tabs>
        </div>
        <vue-context ref="contextMenuForInvoices" @open="contextOpen" @close="contextClose">
            <ul slot-scope="scope">
                <li v-if="vendorInfo.activeXeroAuth && scope && scope.data && !scope.data.item.xeroInvoiceId && !isCustomerUser && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="sendToXero(scope.data.item.id)">Send to XERO</li>
                <li v-if="vendorInfo.activeXeroAuth && scope && scope.data &&  scope.data.item.xeroInvoiceId && !isCustomerUser && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="sendToXero(scope.data.item.id)">Update on XERO</li>
                <li v-if="vendorInfo.activeXeroAuth && scope && scope.data &&  scope.data.item.xeroInvoiceId && !isCustomerUser && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="viewOnXero(scope.data.item.id)">View on XERO</li>
                <li @click="markAsUnpaid(scope.data.item.id)">Mark As Unpaid</li>
            </ul>
        </vue-context>

    </div>
</template>

<style>
    @media (min-width: 626px) {
        .V3 .invoices-page .tabs-component .tab-header.float-sm-right .tabs-nav-right-btn {
            margin-left: 15px;
        }
    }

    @media (max-width: 625px) {
        .V3 .invoices-page .tabs-component .tab-header.float-sm-left {
            float: none !important;
        }

        .V3 .invoices-page .tabs-component .tab-header.float-sm-left,
        .V3 .invoices-page .tabs-component .tab-header.float-sm-right > div {
            margin-bottom: 10px !important;
            display: block;
            text-align: left;
            margin-right: 30px;
        }

        .V3 .invoices-page .tabs-component .tab-header.float-sm-right {
            margin-top: 10px;
            float: none !important;
        }

        .V3 .invoices-page .tabs-component .tab-header.view-header-buttons {
            padding-left: 15px;
        }
    }
</style>

<style scoped>
    .tabs-nav-right-search .search-filter >>> .form-control:focus{
      outline: 0 !important;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__input{
      font-size: 13px !important;
      font-weight: 800;
      color: black;
    }
    .tabs-nav-right-search .search-filter >>> .search-filter__box{
      border: 3px solid #5e77fd;
      border-radius: 7px;
    }
  .search-filter >>> input::placeholder{
    color: #a6a6a6;
    font-weight: 700;
  }
  .tabs-nav-right-search .search-filter >>> .form-control:focus{
    outline: 0 !important;
  }
  .tabs-nav-right-search .search-filter >>> .search-filter__input{
    font-size: 13px !important;
    font-weight: 800;
    color: black;
  }
  .tabs-nav-right-search .search-filter >>> .search-filter__box{
    border: 3px solid #5e77fd;
    border-radius: 7px;
  }
    .search-filter >>> input::placeholder{
      color: #bbbbc2;
    }
    .search-filter >>> input:focus{
      background-color: #ebf0fc;
    }
    .tabs-nav-right-search .search-filter >>> .form-control:focus{
      outline: 0 !important;
    }
    .search-filter >>> .search-filter__box__focused{
      background-color: #ebf0fc;
      border: 3px solid rgba(94, 121, 255, 0.5) !important;
      box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
    }
    .search-filter >>> .search-filter__li--input{
      border-bottom: 0;
    }
    .search-filter >>> .search-filter__li{
      border-bottom: 0 !important;
    }
    .search-filter >>> .search-filter__box{
      border: 2px solid rgba(28, 31, 57, 0.25);
    }

    .tabs-nav-right-search-font-weight >>> input{
      font-weight: 700;
    }
    .tabs-nav-right-search-font-weight >>> input::placeholder{
      font-weight: 600;
    }
    .page-header{
      touch-action: none;
    }
    .invoices >>> .tabs-component{
      touch-action: none;
    }
    .tabs-nav-right-search {
        display: block;
        margin: 10px 0 0 0;
    }

    .row.no-gutters {
        margin-right: 0;
        margin-left: 0;
    }

    .row.no-gutters > [class^="col-"],
    .row.no-gutters > [class*=" col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    tbody tr {
        cursor: pointer;
    }

    .action-icon i {
        font-size: 14px;
        color: #BFBBBA;
    }

    .action-icon.active i {
        color: #142742
    }

    .action-icon.active[tooltip] {
        position: relative;
        display: inline-block;
        transition: .3s;
    }

    .action-icon.active[tooltip]::before {
        content: "";
        position: absolute;
        bottom: 25px;
        left: 50%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top-color: #333333;
        border-bottom: 0;
        margin-left: -5px;
        margin-bottom: -5px;
        opacity: 0;
        transition: .3s;
    }

    .action-icon.active[tooltip]:hover::before {
        opacity: 1;
    }

    .action-icon.active[tooltip-position='left']::before {
        left: 0%;
        top: 50%;
        margin-left: -12px;
        transform: translatey(-50%) rotate(-90deg)
    }

    .action-icon.active[tooltip-position='top']::before {
        left: 50%;
    }

    .action-icon.active[tooltip-position='buttom']::before {
        top: 100%;
        margin-top: 8px;
        transform: translateX(-50%) translatey(-100%) rotate(-180deg)
    }

    .action-icon.active[tooltip-position='right']::before {
        left: 100%;
        top: 50%;
        margin-left: 1px;
        transform: translatey(-50%) rotate(90deg)
    }

    .action-icon.active[tooltip]::after {
        content: attr(tooltip);
        position: absolute;
        left: 50%;
        top: -6px;
        transform: translateX(-50%) translateY(-100%);
        background: #333333;
        text-align: center;
        color: #FFFFFF;
        padding: 4px 2px;
        font-size: 10px;
        font-weight: 600;
        min-width: 155px;
        border-radius: 0px;
        pointer-events: none;
        z-index: 99;
        opacity: 0;
        transition: .3s;
    }

    .action-icon.active[tooltip-position='left']::after {
        left: 0%;
        top: 50%;
        margin-left: -8px;
        transform: translateX(-100%) translateY(-50%);
    }

    .action-icon.active[tooltip-position='top']::after {
        left: 50%;
    }

    .action-icon.active[tooltip-position='buttom']::after {
        top: 100%;
        margin-top: 8px;
        transform: translateX(-50%) translateY(0%);
    }

    .action-icon.active[tooltip-position='right']::after {
        left: 100%;
        top: 50%;
        margin-left: 8px;
        transform: translateX(0%) translateY(-50%);
    }

    .action-icon.active[tooltip]:hover::after, [tooltip]:hover::before {
        opacity: 1
    }

    @media screen and (max-width: 768px) {
      .header-display-name {
          display: none;
      }
      .tabs-nav-right-search{
        margin: 10px 0 !important;
      }
      .estimates >>>.tabs-component .tab-header.nav-tabs.nav-tabs-2{
        border-bottom: 5px solid #e3e4e7 !important;
        padding: 0;
        margin: 0 15px;
      }
      .search-filter{
        padding-left: 15px !important;
        padding-right: 15px;
      }
      .box-block >>> .tab-header.nav.nav-tabs {
        padding: 0 !important;
        margin-left: 15px;
        margin-right: 15px;
        border-bottom: 5px solid #e1e2e5 !important;
      }
    }
    @media screen and (max-width: 460px) {
      .invoices >>> .tabs-component {
        top: 116px;
      }
    }
</style>

<style>
    /* 90 */
    th.number {
        width: 5%;
        min-width: 90px;
    }

    /* 130 */
    th.customer {
        width: 10.5%;
        min-width: 130px;
    }

    /* 120 */
    th.insurer,
    th.rego,
    th.make,
    th.model,
    th.currency,
    th.status,
    th.date,
    th.booking-date {
        width: 10%;
        min-width: 120px;
    }

    .b-table td {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: normal;
    }
</style>
