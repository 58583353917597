<script>
export default {
	name: 'modal-wizard-tab',
	props: {
		title: {
			type: String,
			default: ''
		},
		titleIconClass: {
			type: String,
			default: null
		},
		description: {
			type: String,
			default: ''
		},
		beforeChange: {
			type: Function
		},
		tabData: { }
	},
	data: function () {
		return {
			tabId: '',
			active: false,
			validationError: null
		}
	},
	inject: ['addTab', 'removeTab'],
	mounted: function () {
		this.addTab(this)
	},
	// watch: {
	// 	tabContent: function () {
	// 		console.log(this.tabContent)
	// 	}
	// }
}
</script>

<template>
	<div :id="tabId" class="tab-pane" :class="{active: active}" role="tabpanel">
		<slot v-bind="tabData"></slot>
	</div>
</template>

<style>
.modal-header.bg-primary{
    background-color: #142742 !important;
}
</style>
