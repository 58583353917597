import {Actions} from './actions';
import {Mutations} from './mutations';
import {Getters} from './getters';

const state = {
   isOverLimit: false,
};

export default {
    namespaced: true,
    mutations: Mutations,
    actions: Actions,
    state,
    getters: Getters,
};
