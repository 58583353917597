<template>

  <div>

    <div style="overflow-x: auto;" class="billing-invoice">
      <b-table
          v-if="!isShowInvoice"
          class="rs-scroll rs-scroll--y"
          responsive
          hover
          :items="invoicesAll"
          :fields="invoicesFields"
          @row-clicked="onInvoicesRowClick"
      >

        <template v-slot:cell(status)="data">
          <span style="text-transform: capitalize" v-if="data.item.status == 'payed'">Paid</span>
          <span style="text-transform: capitalize" v-else>{{ data.item.status }}</span>
        </template>

        <template v-slot:cell(dateInvoice)="data">
          {{ data.item.dateInvoice | formatDate }}
        </template>

        <template v-slot:cell(paidDate)="data">
          <span v-if="data.item.datePaid">{{ data.item.datePaid | formatDate }}</span>
          <span v-else>—</span>
        </template>

        <template v-slot:cell(subtotal)="data">
          {{ data.item.subtotal | formatMoney }}
        </template>

        <template v-slot:cell(gst)="data">
          {{ data.item.gst | formatMoney }}
        </template>

        <template v-slot:cell(total)="data">
          {{ data.item.total | formatMoney }}
        </template>

        <template v-slot:cell(amountDue)="data">
          {{ data.item.amountDue | formatMoney }}
        </template>

        <template v-slot:cell(actions)="data">
          <a href="#" @click.prevent="downloadInvoicePdf(data.item.id)"><span class="icon"><i class='bx bxs-file-pdf'></i></span></a>
          <span class="icon"><i class='bx bx-file'></i></span>
        </template>

      </b-table>
    </div>
    <div v-if="isShowInvoice">
        <div class="close-invoice-block">
          <span @click="isShowInvoice = false;" class="close">×</span>
        </div>
        <div class="invoice">
          <header class="invoice-header">
            <div class="invoice-logo">
              <img src="@/assets/logos/logo-invoice.png" style="width: 180px;height: 37px;">
            </div>
            <div class="invoice-info">
              <h1 class="invoice-title color-blue">TAX INVOICE</h1>
              <table class="header-table">
                <tr>
                  <td class="font-14 font-bold">Repairshop Services</td>
                </tr>
                <tr>
                  <td>Suite 703, Level 7, 155 King Street, Sydney NSW 2000</td>
                </tr>
                <tr>
                  <td><span class="font-bold">ABN</span>:  32 128 413 632</td>
                </tr>
              </table>
              <table class="header-table">
                <tr>
                  <td><strong>Invoice To:</strong></td>
                </tr>
                <tr>
                  <td>{{ vendorInfo.business_name }}</td>
                </tr>
                <tr>
                  <td>ABN: {{ vendorInfo.abn }}</td>
                </tr>
              </table>

              <table class="header-table">
                <tr>
                  <td><strong>Invoice Number :</strong> <span>{{ currentInvoice.number }}</span></td>
                </tr>
                <tr>
                  <td><strong>Invoice Date :</strong> <span>{{ currentInvoice.dateInvoice | formatDate }}</span></td>
                </tr>
                <tr v-if="currentInvoice && currentInvoice.dateDue">
                  <td><strong>Due Date :</strong> <span>{{ currentInvoice.dateDue | formatDate }}</span></td>
                </tr>
              </table>
            </div>
          </header>

        <div class="line-invoice-reference"></div>

        <main>
          <table class="main-table mt-2">
            <thead>
            <tr>
              <th style="border-radius: 3px 0px 0px 3px">Description</th>
              <th style="border-radius: 0px">Qty</th>
              <th style="border-radius: 0px">Unit Price</th>
              <th style="border-radius: 0px">GST</th>
              <th style="border-radius: 0px 3px 3px 0px">Amount</th>
            </tr>
            </thead>
            <tbody>

            <!--
            <tr class="border-bottom-without-plus">
              <td class="border-bottom-without-plus">
                <p class="font-weight-bold">Invoice Period: </p>
                <p class="font-weight-bold">Plan Type: </p>
                <p class="font-weight-bold">Includes Data Storage: </p>
                <p class="font-weight-bold">Data Storage Consumed: </p>
              </td>
              <td>1</td>
              <td>$1.00</td>
              <td>$0.00</td>
              <td class="text-right">$1.00</td>
            </tr>

            <tr class="border-bottom-without-plus">
              <td class="border-bottom-without-plus">
                <p class="font-weight-bold">Data Storage Add-On: </p>

              </td>
            </tr>
            -->

            <tr v-for="item in currentInvoice.items" class="border-bottom-without-plus">
              <td class="border-bottom-without-plus">
                <p v-if="item.name" class="font-weight-bold">{{ item.name }}</p>
                <p v-if="item.description">{{ item.description }}</p>

                <template v-if="item.data && item.data.additional_fields">
                  <p v-for="field in item.data.additional_fields">
                    <span class="font-weight-bold">{{ field.label }}:</span>
                    {{ field.text }}
                  </p>
                </template>

              </td>
              <td class="border-bottom-without-plus">{{ item.qty }}</td>
              <td class="border-bottom-without-plus">{{ item.unitPrice | formatMoney }}</td>
              <td class="border-bottom-without-plus">{{ item.gst | formatMoney }}</td>
              <td class="border-bottom-without-plus text-right">{{ item.subtotal | formatMoney }}</td>
            </tr>

            </tbody>
            <tfoot class="parts-total">
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-bold text-right border-bottom-without-plus">Subtotal</td>
              <td class="text-right  border-bottom-without-plus">{{ currentInvoice.subtotal | formatMoney }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-bold text-right border-bottom-without-plus">GST</td>
              <td class="text-right border-bottom-without-plus">{{ currentInvoice.gst | formatMoney }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-bold text-right  border-bottom-without-plus"><strong>Total</strong></td>
              <td class="text-right border-bottom-without-plus">{{ currentInvoice.total | formatMoney }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-bold text-right border-bottom">Amount Due</td>
              <td class="text-right border-bottom-without-plus">{{ currentInvoice.amountDue | formatMoney }}</td>
            </tr>
            </tfoot>
          </table>

          <div class="line-invoice-reference" style="margin-top: 30px;"></div>

          <div style="margin-top: 20px; padding-bottom: 50px" v-if="currentInvoice.status != 'payed'">
            <p><strong>PAY THIS INVOICE</strong></p>
            <div class="d-flex row mobile-payd-block">
              <div class="credit-card-block credit-card-block-first">
                <div><strong>DIRECT DEPOSIT</strong></div>
                <div class="form-group row">
                  <label for="make" class="col-12 col-form-label pl-0">Bank:
                    <span class="pl-1" style="padding-top: 6.5px; font-weight: normal">
                            Australia Zealand Banking
                          </span>
                  </label>
                </div>
                <div class="form-group row">
                  <label for="make" class="col-12 col-sm-5 col-form-label pl-0">ACCOUNT NAME:</label>
                  <div class="col-12 col-sm-7" style="padding-top: 6.5px">
                    REPAIRSHOP SERVICES
                  </div>
                </div>
                <div class="form-group row">
                  <div>
                    <span>BSB: 014002</span>  <span class="pl-2">ACCOUNT: 486584359</span>
                  </div>
                </div>
              </div>
              <stripe-form :value="currentInvoice.amountDue" :inner-invoice-id="currentInvoice.id" @invoice-payed="invoicePayed"></stripe-form>
            </div>
            <button @click="downloadInvoicePdf(currentInvoice.id)" style="background-color: #5E79FF; color: white; float: right; margin-top: 20px" class="btn btn-primary waves-effect waves-light">Print PDF</button>
          </div>


        </main>
      </div>
    </div>

  </div>

</template>

<script>
import {mapGetters} from "vuex";
import Axios from "axios";
import {appConfig} from '../../../config.js';
import _ from 'lodash';
import StripeForm from '../../utility/stripe-form';

export default {
  name: "invoices",
  components: {
    StripeForm,
  },
  data(){
    return {
      invoices: [],
      isShowInvoice: false,
      invoicesFields: [
        // {label: "ID", key: "id", sortable: true, tdClass: 'text-center clickable number text-align-left font-bold', thClass: 'text-center bg-navyblue number'},
        {label: "Number", key: "number", sortable: true, tdClass: 'text-center clickable number text-align-left font-bold', thClass: 'text-center bg-navyblue number'},
        {label: "Status", key: "status", sortable: true, tdClass: 'clickable customer font-bold', thClass: 'bg-navyblue customer'},
        {label: "Issued Date", key: "dateInvoice", sortable: true, tdClass: 'clickable insurer', thClass: 'bg-navyblue insurer'},
        {label: "Paid Date", key: "paidDate", sortable: true, tdClass: 'text-center clickable rego', thClass: 'text-center bg-navyblue rego'},
        {label: "Amount", key: "subtotal", sortable: true, tdClass: 'text-center clickable make', thClass: 'text-center bg-navyblue make'},
        {label: "GST", key: "gst", sortable: true, tdClass: 'text-center clickable make', thClass: 'text-center bg-navyblue make'},
        {label: "Total", key: "total", sortable: true, tdClass: 'text-center clickable make', thClass: 'text-center bg-navyblue make'},
        {label: "Balance Due", key: "amountDue", sortable: true, tdClass: 'text-center clickable make', thClass: 'text-center bg-navyblue make'},
        {label: "Actions", key: "actions", sortable: false, tdClass: 'text-center clickable make', thClass: 'text-center make'},
      ],
      currentInvoice: null,
    };
  },
  mounted() {
    this.getInvoices();
  },
  methods: {
    invoicePayed(){
      this.getInvoices();
      this.isShowInvoice = false;
      this.currentInvoice = null;
    },
    downloadInvoicePdf(id) {
      let token = localStorage.getItem('token');
      let expand_url = '?at=' + token;
      let url = appConfig.baseAPIURL + '/inner-invoice/pdf/' + id + expand_url;
      window.open(url);
    },
    scrollToTop() {
      setTimeout(() => {
        document.getElementsByClassName('modal-dialog')[0].scrollIntoView();
        return true;
      }, 100);
    },
    getInvoices(){
      NProgress.start();
      Axios.get('/fe/inner-invoices')
          .then(response => {
            if(response.data && response.data._status){
              this.invoices = response.data.data;
            }
          })
          .catch(e => console.error(e))
          .finally(() => {
            NProgress.done();
          })
    },
    showInvoice(invoice){
      this.isShowInvoice = true;
      this.currentInvoice = invoice;
    },
    onInvoicesRowClick(invoice){
      this.showInvoice(invoice);
      console.log(this.vendorInfo)
    }
  },
  computed: {
    ...mapGetters({
      vendorInfo: 'vendorInfo',
      isStatusActive: 'isStatusActive',
    }),
    invoicesAll(){
      return  _.orderBy(this.invoices, ['dateInvoice'], ['desc']);
    },
  }
}
</script>

<style scoped>
  p {
    margin: 5px 0;
  }

  .line-invoice-reference {
    height: 10px;
    display: block;
    background: #e3e4e7;
  }

  .credit-card-block {
    border: 2px solid #e3e4e7;
    border-radius: 3px;
    padding: 10px;
    width: 40%;
  }

  .invoice .main-table tbody td, .invoice .main-table tfoot td {
    border-bottom: 1px solid #c6c7ce;
  }

  .bxs-file-pdf,
  .bx-file {
    font-size: 25px;
    color: #5E79FF;
  }

  .invoice-title {
    font-size: 25px;
    font-weight: 900;
    color: #5E79FF;
  }

  .invoice-info {
    max-width: 200px;
  }

  .font-weight-bold {
    font-size: 13px;
  }

  .border-bottom-without-plus {
    padding: 10px 15px !important;
    border-bottom: 1px solid #c6c7ce !important;
  }

  .invoice .main-table tfoot td {
    border-bottom: none;
  }

  .invoice-header {
    width: 100%;
  }

  .invoice {
    margin: 0 auto !important;
  }

  .close-invoice-block {
    display: flex;
    justify-content: end;
    position: sticky;
    top: 260px;
  }

  .close {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: Nunito Sans,sans-serif;
    box-sizing: inherit;
    outline: none!important;
    font-weight: 700;
    line-height: 1;
    opacity: .2;
    text-shadow: none;
    color: #1f233d;
    font-size: 50px;
    position: sticky;
    z-index: 999;
    top: 250px;
    right: 30px;
  }

  @media screen and (max-width: 1070px) {
    .invoice {
      width: 600px;
    }
    .line-invoice-reference {
      width: 600px;
    }
    .credit-card-block {
      width: 50%;
    }
    .credit-card-block-last {
      margin-left: 10px;
    }
    .btn-invoice-pay {
      width: 85px !important;
    }
  }

  @media screen and (max-width: 845px) and (min-width: 768px) {
    .invoice {
      width: 500px;
    }
    .line-invoice-reference {
      width: 500px;
    }
    .credit-card-block {
      width: 80%;
    }
    .credit-card-block-last {
      margin-left: 0 !important;
      margin-top: 10px;
    }
    .mobile-payd-block {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 630px) {
    .invoice {
      width: 400px;
    }
    .line-invoice-reference {
      width: 400px;
    }
    .credit-card-block {
      width: 80%;
    }
    .credit-card-block-last {
      margin-left: 0 !important;
      margin-top: 10px;
    }
    .mobile-payd-block {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 430px) {
    .invoice {
      width: 100%;
      margin: 0;
    }
    .invoice-header {
      flex-direction: column;
      padding-left: 15px;
    }
    .invoice-logo {
      margin-bottom: 20px;
    }
    .credit-card-block {
      width: 100%;
    }
    .credit-card-block-last {
      margin-left: 0 !important;
      margin-top: 10px;
    }
    .mobile-payd-block {
      flex-direction: column;
    }
    .close {
      top: -20px;
    }
    .line-invoice-reference {
      width: 100%;
    }
    .invoice .main-table thead th {
      padding: 8px 5px;
    }
    .border-bottom-without-plus {
      padding: 10px 5px !important;
    }
    .invoice .main-table th {
      text-align: center;
    }
    .invoice .main-table tbody td {
      text-align: center;
    }
    .invoice .main-table tbody td:first-child {
      text-align: left;
    }
  }
</style>

<style>
  .billing-invoice .table thead th {
    padding: 13px 15px !important;
  }

  .V3 .billing .tabs-component.tabs-component-2 {
    top: 218px;
  }

  @media screen and (max-width: 1245px) {
    .V3 .billing .tabs-component.tabs-component-2 {
      top: 267px;
    }
    .billing .close-invoice-block {
      top: 317px;
    }
  }

  @media screen and (max-width: 1087px) {
    .V3 .billing .tabs-component.tabs-component-2 {
      top: 317px;
    }
    .billing .close-invoice-block {
      top: 245px;
    }
  }

  @media screen and (max-width: 768px) {
    .V3 .billing .tabs-component.tabs-component-2 {
      top: 254px;
    }
    .billing .close-invoice-block {
      top: 245px;
    }
  }

  @media screen and (max-width: 491px) {
    .V3 .billing .tabs-component.tabs-component-2 {
      top: 305px;
    }
    .billing .close-invoice-block {
      top: 245px;
    }
  }

</style>
