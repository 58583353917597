import {Actions} from './actions';
import {Mutations} from './mutations';
import {Getters} from './getters';

const state = {
  isShowReportsList: true,
  selectedOption: '',
  estimate: {
    processedSummary: {},
    valueSummary: {},
    averageValue: {},
    valuesByInsurerAndNonInsuranceSummary: {},
    valuesByInsurer: {},
  },
  invoice: {
    processedSummary: {},
    valueSummary: {},
    averageValue: {},
    valuesByInsurerAndNonInsuranceSummary: {},
    valuesByInsurer: {},
  },
};

export default {
  namespaced: true,
  mutations: Mutations,
  actions: Actions,
  state,
  getters: Getters,
};
