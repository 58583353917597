<template>
  <div class="">
    <div class="form-group w-auto action-btns d-flex m-auto" style="margin-top: 15px">

      <button v-if="!isEdit" class="update-btn btn btn-secondary" @click="onEdit" style="margin-right: 15px">
        Edit Tracker
      </button>
      <template v-else>
        <button class="update-btn btn btn-secondary" @click="cancelEdit" style="margin-right: 15px">
          Cancel
        </button>
        <button v-if="trackers && trackers.length" @click="trackers.push(null)" class="update-btn btn btn-secondary" style="margin-right: 15px">
          Create Tracker
        </button>
        <button @click="saveTrackers" class="close-active-btn btn btn-primary" style="width: 100px">Save
        </button>
      </template>
    </div>
    <div v-if="isEdit" class="number-of-estimates d-flex">
      <div v-for="(itm, index) in trackers" class="number-of-estimates-item-empty">
        <div class="number-of-estimates-item-empty-border">
          <div class="number-of-estimates-item-empty-title">{{getTrackerName(itm)}}</div>
          <div class=""
               @focusout="closePopupMenu"
               tabindex="0">
            <i @click="showPopupMenu(itm + index)"
               :id="itm + index"
               class='bx bx-dots-vertical-rounded' style="cursor: pointer"></i>
            <ul v-if="showMenuItem == itm + index"
                class="card-menu-popup"
                :class="{'card-menu-popup-open-left': showMenuPositionLeft}"
            >
              <li v-if="!itm" class="parent">
                <div class="title">
                  <i v-if="isRevertMenuCheck(itm + index)"
                     class="bx bx-chevron-left"></i>
                  Add Status
                  <i v-if="!isRevertMenuCheck(itm + index)"
                     class="bx bx-chevron-right"></i>
                </div>
                <div class="sub-menu">
                  <ul class="sub-menu-inner">
                    <li v-for="status in computedStatuses" @click="addTrackers(status.ce_status_id, index)" >
                      {{status.name}}
                    </li>
                  </ul>
                </div>
              </li>
              <li @click="removeTrackers(itm, index)" class="parent">
                <div class="title"
                     :class="{'parent-title-flex-end': showMenuPositionLeft}"
                >
                  Remove Status
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>

      <template v-if="trackers && trackers.length == 0">
        <div v-for="(itm, index) in countOfEmptyItms" class="number-of-estimates-item-empty">
          <div class="number-of-estimates-item-empty-border">
            <div class=""
                 @focusout="closePopupMenu"
                 tabindex="0">
              <i @click="showPopupMenu(itm + index)"
                 class='bx bx-dots-vertical-rounded' style="cursor: pointer"></i>
              <ul v-if="showMenuItem === itm + index"
                  class="card-menu-popup"
              >
                <li class="parent">
                  <div class="title">
                    <i v-if="!isRevertMenuCheck(itm + index)"
                       class="bx bx-chevron-left"></i>
                    Add Status
                    <i v-if="isRevertMenuCheck(itm + index)"
                       class="bx bx-chevron-right"></i>
                  </div>
                  <div class="sub-menu">
                    <ul class="sub-menu-inner">
                      <li v-for="status in computedStatuses" @click="addTrackers(status.ce_status_id, -1)" >
                        {{status.name}}
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </template>
    </div>
    <div v-else class="number-of-estimates d-flex">

      <div class="number-of-estimates-item" v-for="tracker in trackers">
        <div class="number-of-estimates-item-header">
          <div class="number-of-estimates-item-header-title">
            Nbr of days in <span>{{getTrackerName(tracker)}}</span>
          </div>
          <div class="number-of-estimates-item-header-num">{{getTrackerCountResult(tracker)}}</div>
        </div>
        <b-table :items="getTrackerItems(tracker)" :fields="getTrackerFields(tracker)" :busy="isBusy" outlined>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(count)="data">
            <span class="tracker-link" @click="redirectToCards(tracker, data.item.period)">{{data.value}}</span>
          </template>

        </b-table>
      </div>



      <template v-if="trackers && trackers.length == 0">
        <div v-for="itm in countOfEmptyItms" class="number-of-estimates-item-empty">
          <div class="number-of-estimates-item-empty-border">
            <i class='bx bx-dots-vertical-rounded'>
            </i>
          </div>

        </div>
      </template>



    </div>
  </div>


</template>

<script>
import Axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "trackers",
  data() {
    return {
      showMenuPositionLeft: false,
      showMenuItem: null,
      isEdit: false,
      trackers: [],
      trackersBeforeEdit: [],
      countOfEmptyItms: [1],
      isBusy: false,
      removeTracker: [],
      addTracker: [],
    }
  },
  props: {
    ceStatuses: {
      type: Array,
      default: () => [],
    }
  },
  components: {
  },
  mounted() {
    // this.getTrackers()
    this.trackers = _.cloneDeep(this.vendorInfo.trackers ? this.vendorInfo.trackers : [])
    document.addEventListener("scroll", this.closePopupMenu)
  },
  unmounted(){
    window.removeEventListener("scroll", this.closePopupMenu);
  },
  watch: {
    vendorInfo: {
      handler(v) {
        if (!_.isEqual(this.trackers, v.trackers) && !this.isEdit) {
          this.trackers = _.cloneDeep(v.trackers)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      getterAllEstimates: 'estimate/getAllEstimates',
      vendorInfo: 'vendorInfo',
    }),
    computedStatuses() {
      let trackers = this.trackers.map((el) => {return Number(el)})
      return _.filter(this.ceStatuses, (s) => {
        return !_.includes(trackers, Number(s.ce_status_id)) && s.original_name != "Card Open"
      })
    },
  },
  methods: {
    isRevertMenuCheck(id) {
      let el = document.getElementById(id)?.getBoundingClientRect();
      if (!el || (window.innerWidth - el.right) <= 330) {
        this.showMenuPositionLeft = true
        return true
      }
      this.showMenuPositionLeft = false
      return false
    },
    showPopupMenu(id) {
      this.showMenuItem = id
    },
    closePopupMenu() {
      this.showMenuItem = null
    },
    cancelEdit() {
      this.isEdit = false
      this.addTracker = [];
      this.removeTracker = []
      this.trackers = this.trackersBeforeEdit
    },
    onEdit() {
      this.isEdit = true
      this.trackersBeforeEdit = _.cloneDeep(this.trackers)
    },
    redirectToCards(tracker, period) {
      let filters = [];
      if (period == '1-2 Days') {
        filters = this.getTrackerCounts(tracker).oneTwo
      } else  if (period == '3-5 Days') {
        filters = this.getTrackerCounts(tracker).twoFive
      } else  if (period == '6-10 Days') {
        filters = this.getTrackerCounts(tracker).fiveTen
      } else  if (period == 'Greater 10 Days') {
        filters = this.getTrackerCounts(tracker).moreTen
      }
      this.$storage.set("views.cards.defaultView", "table-view");
      let trackerFilter = this.getTrackerName(tracker) + ' ' + period
      this.$store.commit('setCardFilter', trackerFilter);
      this.$router.push({ name: 'Cards', params: {filters: filters, trackerFilter: trackerFilter} })
    },
    getTrackerName(d) {
      return _.find(this.ceStatuses, (c) => {
        return Number(d) == Number(c.ce_status_id)
      })?.name
    },
    getTrackerCountResult(id) {
      let counts = this.getTrackerCounts(id);
      return counts.oneTwo.length + counts.twoFive.length + counts.fiveTen.length + counts.moreTen.length
    },
    getTrackerCounts(id) {
      let obj = {
        'oneTwo': [],
        'twoFive': [],
        'fiveTen': [],
        'moreTen': [],
      }
      _.forEach(this.getterAllEstimates, (e) => {
        if (Number(e.cardStatus) == Number(id)) {
          if (e.cardStatusLastChangeDays == 1 || e.cardStatusLastChangeDays == 2) {
            obj.oneTwo.push(e.card_id)
          } else if (e.cardStatusLastChangeDays > 2 && e.cardStatusLastChangeDays <= 5) {
            obj.twoFive.push(e.card_id)
          } else if (e.cardStatusLastChangeDays > 5 && e.cardStatusLastChangeDays <= 10) {
            obj.fiveTen.push(e.card_id)
          } else if (e.cardStatusLastChangeDays > 10) {
            obj.moreTen.push(e.card_id)
          }
        }
      })

      return obj
    },
    getTrackerItems(id) {
      let counts = this.getTrackerCounts(id)
      return [
        { period: '1-2 Days', count: counts.oneTwo.length},
        { period: '3-5 Days', count: counts.twoFive.length},
        { period: '6-10 Days', count: counts.fiveTen.length},
        { period: 'Greater 10 Days', count: counts.moreTen.length}
      ]
    },
    getTrackerFields(id) {
      return [
        {
          label: "",
          key: "period",
        },
        {
          label: "In " + this.getTrackerName(id),
          key: "count",
        },
      ]
    },
    saveTrackers() {
      NProgress.start();
      Axios.post('/fe/trackers', {addTracker: _.filter(this.addTracker, (id) =>  {return Number(id)}), removeTracker: _.filter(this.removeTracker, (id) =>  {return Number(id)})}
      ).then(res => {
        if (res.data._status) {
          this.trackers = _.filter(this.trackers, (id) =>  {return Number(id)})
          this.isEdit = false
          this.$store.dispatch('loadVendorInfo');
          this.addTracker = [];
          this.removeTracker = []
        }
      }).catch(e => {
        console.log(e);
      })
          .finally(()=>{
            NProgress.done();
          })
    },
    addTrackers(id, index) {
      this.closePopupMenu()
      if (_.includes(this.removeTracker, id)) {
        this.removeTracker = _.filter(this.removeTracker, (rId) => {
          return Number(rId) !== Number(id)
        })
      }
      this.addTracker.push(id)
      if (index == -1) {
        this.trackers.push(id)
      } else {
        this.trackers.splice(index, 1, id)
      }
    },
    removeTrackers(id, index) {
      this.closePopupMenu()
      if (_.includes(this.addTracker, id)) {
        if (id == null) {
         let nInd = _.find(this.addTracker, (i) => {return i == null})
          if (nInd !== -1) {
            this.addTracker.splice(nInd, 1)
          }
        } else {
          this.addTracker = _.filter(this.addTracker, (rId) => {
            return Number(rId) !== Number(id)
          })
        }
      }
      if (_.includes(this.trackers, id)) {
        if (id == null) {
          this.trackers.splice(index, 1)
        } else {
          this.trackers = _.filter(this.trackers, (rId) => {
            return Number(rId) !== Number(id)
          })
        }
      }
      this.removeTracker.push(id)
    },
    getTrackers() {
      Axios.get('fe/trackers').then((r) => {

      })
    },
  }
}
</script>

<style scoped>
.parent-title-flex-end{
  justify-content: end !important;
}
.card-menu-popup{
  position: absolute;
  right: -125px;
  top: 25px
}
.card-menu-popup-open-left{
  right: 0 !important;
}
.card-menu-popup-open-left .sub-menu{
  left: -150px !important;
  transform: scaleX(-1);
}
.card-menu-popup-open-left .sub-menu .sub-menu-inner{
  transform: scaleX(-1);
}
.sub-menu{
  max-height: 200px;
  overflow-x: auto;
  scrollbar-width: thin;
}
.number-of-estimates-item-empty-title{
  font-weight: 900;
  font-size: 15px;
  text-align: center;
  margin-top: 20%;
}
.form-group button:focus{
  background-color: #fdfdfd;
}
.form-group button:hover{
  background-color: #EEEEEE;
}
.tracker-link {
  font-weight: bold;
  color: #0275d8;
  cursor: pointer;
}
.tracker-link:hover {
  color: #014c8c;
}
.number-of-estimates-item-empty{
  flex: 20%;
  padding: 5px;
  max-width: 300px;
}
.number-of-estimates-item-empty-border{
  border: 1px solid #c5c5c5;
  position: relative;
  height: 250px;
}
.number-of-estimates-item-empty .bx-dots-vertical-rounded{
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 8px;
}
.number-of-estimates-item >>> td,.number-of-estimates-item >>> th{
  padding: 3px 9px;
  border-right: 1px solid rgba(128, 128, 128, 0.55);
}
/*.number-of-estimates-item >>> tbody tr:nth-child(1) td{*/
/*  padding: 9px;*/
/*  font-weight: bold;*/

/*}*/
.number-of-estimates{
  margin-top: 10px;
}
.number-of-estimates-item{
  margin: 0 5px;
  flex: 20%;
  max-width: 300px;
}
.number-of-estimates-item-header{
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 20px 20px;
  text-align: center;
  margin-bottom: 10px;
  height: 140px;
}
.number-of-estimates-item-header:first-child{
  margin-left: 0;
}
.number-of-estimates-item-header:last-child{
  margin-right: 0;
}
.number-of-estimates-item-header-title{
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
}
.number-of-estimates-item-header-title span{
  font-weight: 900;
}
.number-of-estimates-item-header-num{
  font-size: 25px;
  font-weight: 900;
}

.assign-to-context {
  height: auto;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 1620px) {
  .number-of-estimates-item{
    margin: 0;
    padding: 0 5px;
    flex: 33.333%;
    max-width: 33.333%;
  }
  .number-of-estimates-item-empty{
    flex: 33.333%;
    max-width: 33.333%;
  }
  .number-of-estimates{
    flex-flow: wrap;
  }
}


@media screen and (max-width: 1100px) {
  .number-of-estimates-item{
    flex: 50%;
    max-width: 50%;
  }
  .number-of-estimates-item-empty{
    flex: 50%;
    max-width: 50%;
  }
  .number-of-estimates{
    justify-content: space-around;
  }
}

@media screen and (max-width: 800px) {
  .number-of-estimates-item{
    flex: 100%;
    max-width: 80%;
  }
  .number-of-estimates-item-empty{
    flex: 100%;
    max-width: 80%;
  }
  .cards-all-snapshot-main-total_item{
    flex: 50%;
    max-width: 50%;
  }
}

</style>
