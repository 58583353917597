<template>

  <div style="margin-left: 30px" class="credit-card-block credit-card-block-last">
    <div><strong>ONLINE</strong></div>
    <p style="margin-bottom: 0">Pay by Credit Card (1.75% + 30c processing fee)</p>

    <div class="stripe-logo"></div>

    <div style="margin-bottom: 16px;" class="card-field" id="card-number"></div>

    <div class="d-flex flex-row" style="justify-content: space-between">
      <div class="col-12 col-sm-5" style="padding-left: 0px;">
        <div class="card-field" id="card-expiry"></div>
      </div>


      <div class="col-12 col-sm-3" style="padding-left: 0px;">
        <div class="card-field" id="card-cvc"></div>
      </div>
      <div class="col-12 col-sm-4" style="padding: 0px;">
        <button id="custom-button" style="background-color: #5E79FF; color: white; width: 97px; min-width: 50px" class="btn btn-primary w-min-sm waves-effect waves-light btn-invoice-pay" @click="createToken" :disabled="disabled">Pay {{ this.value | formatMoney }}</button>
<!--        <button style="background-color: #5E79FF; color: white; width: 97px; min-width: 50px" class="btn btn-primary w-min-sm waves-effect waves-light btn-invoice-pay">Pay {{ currentInvoice.amountDue | formatMoney }}</button>-->
      </div>
    </div>
    <div style="margin-left: 15px; margin-top: 10px">
      <div id="card-error"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {appConfig} from '../../config';

export default {
  name: 'StripeForm',
  props: {
    value: {
      type: String,
      default: '0.00',
    },
    innerInvoiceId: {
      type: [String,Number],
      default: null,
    },
  },
  data () {
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      disabled: false,
      stripe: null,
    };
  },
  computed: {
    valueWithFee(){
      return (this.value * 1.0175 + 0.30).toFixed(2) * 1;
    },
  },
  mounted () {
    this.includeStripe('js.stripe.com/v3/', function(){
      this.configureStripe();
    }.bind(this) );
  },
  beforeDestroy () {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
    _.forEach(document.querySelectorAll('iframe'), el => {
      if (_.includes(el.src,'js.stripe.com')) {
        el.parentNode.removeChild(el);
      }
    })
  },
  methods: {
    configureStripe() {
      // Style Object documentation here: https://stripe.com/docs/js/appendix/style
      const style = {
        base: {
          color: 'black',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: 'red',
          iconColor: 'red',
        },
      };

      this.stripe = Stripe( appConfig.stripePublicKey,{
        locale: 'en'
      } );
      let stripeEl = this.stripe.elements()
      this.cardNumber = stripeEl.create('cardNumber', { style });
      this.cardNumber.mount('#card-number');
      this.cardExpiry = stripeEl.create('cardExpiry', { style });
      this.cardExpiry.mount('#card-expiry');
      this.cardCvc = stripeEl.create('cardCvc', { style });
      this.cardCvc.mount('#card-cvc');
    },
    includeStripe( URL, callback ){
      let documentTag = document, tag = 'script',
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    async createToken () {
      // await this.stripe.handleCardAction();

      toastr.info('Processing your payment...');
      this.disabled = true;
      axios.post('/fe/inner-invoice/stripe-get-intent', { sum: this.value, innerInvoiceId: this.innerInvoiceId })
          .then(async response => {
            // console.log('get intent', response.data);
            // 4000 0000 0000 9995
            // 4000 0025 0000 3155
            if(!response.data._status){
              this.disabled = false;
              toastr.error('Cannot get intent!');
              return;
            }
            const paymentId = response.data.paymentId;
            const { token, error } = await this.stripe.createToken(this.cardNumber);
            if (error) {
              // handle error here
              this.disabled = false;
              document.getElementById('card-error').innerHTML = error.message;
              return;
            }
            axios.post('/fe/inner-invoice/stripe-token-processing', { token, paymentId })
                .then(async response => {
                  if(!response.data._status){
                    this.disabled = false;
                    toastr.error('Cannot process your payment!');
                    return;
                  }
                  let { paymentIntent, error } = await this.stripe.retrievePaymentIntent(response.data.intent.client_secret);
                  if(error){
                    this.disabled = false;
                    document.getElementById('card-error').innerHTML = error.message;
                    return;
                  }
                  // console.log('paymentIntent2', paymentIntent);
                  // console.log('token', paymentIntent);

                  this.stripe.confirmCardPayment(paymentIntent.client_secret)
                      .then(response => {
                        if(response.error){
                          console.log('error', response.error)
                          this.disabled = false;
                          document.getElementById('card-error').innerHTML = response.error.message;
                          toastr.error(response.error.message)
                          return;
                        }
                        axios.post('/fe/inner-invoice/stripe-confirm', { paymentId })
                            .then(response => {
                              if(response.data?._status){
                                toastr.success('Payment succeed!')
                                this.disabled = false;
                                this.$emit('invoice-payed', {
                                  innerInvoiceId: this.innerInvoiceId
                                });
                              } else {
                                toastr.error('Payment failed!')
                              }
                            })
                            .catch(e => console.error(e))
                            .finally(() => {
                              this.disabled = false;
                            })
                      })
                      .catch(e => console.log(e))


                  // this.stripe.handleCardAction(paymentIntent.client_secret)
                  //     .then(response => {
                  //       console.log('handle', response)
                  //     })
                  //     .catch(e => console.error(e));
                })
                .catch(e => console.error(e));

            return;

          })
          .catch(e => console.error(e));

    },
  }
};
</script>

<style scoped>

    .card-field {
      height: 38px;
      box-sizing: border-box;
      border: 1px solid rgba(28, 31, 57, 0.25);
      background-clip: padding-box;
      border-radius: 3px;
      color: #1C1F39;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      display: block;
      width: 100%;
      background-color: #FFFFFF;
      padding-top:  10px;
      padding-left: 10px;
    }


    #card-error {
      color: red;
    }

    .stripe-logo {
      margin: 6px 0 10px;
      height: 20px;
      background: url(/public/img/logos/Powered-by-Stripe-Outline-Logo-Small.png) left center no-repeat;
      background-size: contain;
    }
</style>
