<script>
export default {
	name: 'summernote',
	props: {
		value: {
			default: '',
			type: Text
		},
		airMode: {
			default: false,
			type: Boolean
		},
		height: {
			default: 150,
			type: Number
		},
		placeholder: {
			default: 'Type here...',
			type: Text
		}
	},
	data: function () {
		return {
			sm: null,
			isChanging: false
		}
	},
	computed: {
		stext: function () {
			return this.value
		}
	},
	mounted: function () {
		let me = this
		this.sm = $(this.$el)
		
		this.sm.summernote({
			// airMode: this.airMode,
			// popover: {
			// 	air: [
			// 		['font', ['bold', 'underline', 'italic']]
			// 	]
			// },
  toolbar: [
    // [groupName, [list of button]]
    ['style', ['bold', 'italic', 'underline']],
    // ['font', ['strikethrough', 'superscript', 'subscript']],
    // ['fontsize', ['fontsize']],
    // ['color', ['color']],
    // ['para', ['ul', 'ol', 'paragraph']],
    // ['height', ['height']]
  ],
			height: this.height,
			placeholder: this.placeholder,
			callbacks: {
				onInit: function () {
					// console.log('onInit', me.stext)
					me.sm.summernote("code", me.stext)
				},
				onChange: function (contents) {
					// console.log('onChange:', contents, $editable)
					me.$emit("input", contents)
				}
			}
		})/*.on("summernote.change", function () {
			if (!me.isChanging) {
				me.isChanging
			}
		})*/

	}
}
</script>

<template>
  <textarea></textarea>
</template>