<script>
import Axios from 'axios'
import { appConfig } from '../../config.js'

export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      remember: false,
    }
  },
  computed: {
    isLoggedIn () {
      return Boolean(this.$store.state.token && this.$store.state.token !== undefined && this.$store.state.token !== 'undefined' && this.$store.state.token.length > 1)
    },
    isHideOldLogin () {
      return Boolean(appConfig.isHideOldLogin)
    },
  },
  mounted () {
    console.log('isHideOldLogin', this.isHideOldLogin)
    console.log('MOUNTED: /Login')
    if (this.isLoggedIn) {
      console.log('MOUNTED-REDIRECT: /')
      this.$router.push('/')
    } else if (this.isHideOldLogin) {
      console.log('MOUNTED-REDIRECT-HOME', this.isHideOldLogin)
      document.location.replace(appConfig.homeURL + '/')
    }
  },
  methods: {
    auth () {
      this.$nextTick(() => {
        this.$store.dispatch('loadMenu')
        this.$store.dispatch('loadUserInfo').then(() => {
          this.$store.dispatch('loadVendorInfo').then(() => {
            this.$store.dispatch('loadDashboardSummary')
            this.$store.dispatch('dashboard/loadNotices')
            this.$store.dispatch('card/loadCards', { page: 1, withTimestamp: false })
            this.$store.dispatch('estimate/loadEstimates', { page: 1, withTimestamp: false })
            this.$store.dispatch('invoice/loadInvoices', { page: 1, withTimestamp: false })
            this.$store.dispatch('dashboard/loadData', false)
            this.$store.dispatch('board/loadInBoard', { page: 1, withTimestamp: false })
            this.$store.dispatch('board/loadDepartureBoard', { page: 1, withTimestamp: false })
          }).then(() => {
            this.$store.dispatch('loadVehicleMakeModels')
            this.$store.dispatch('loadVehicleScheduleNumbers')
            this.$store.dispatch('loadPartCategories')
            this.$store.dispatch('loadButterfly')
            this.$store.dispatch('loadUsers')
            this.$store.dispatch('loadBusinessTypes')
            this.$store.dispatch('report/loadReports')
            this.$store.dispatch('loadCustomerVehicles')
            this.$store.dispatch('loadPartsSearchLocation')
          })
        })
      })
    },
    onClickSignUp () {
      this.$router.push('/register')
    },
    onClickForgotUsername () {
      this.$router.push('/auth/forgot/username')
    },
    onClickForgotPassword () {
      this.$router.push('/auth/forgot/password')
    },
    login: function () {
      const username = this.username
      const password = this.password
      const remember = this.remember
      NProgress.start()
      this.$store.dispatch('login', { username, password, remember }).then(() => {
        this.auth()
        NProgress.done()
        this.$router.push('/')
      })
          .catch(error => {
            NProgress.done()
          })
    }
  }
}
</script>

<template>
  <div v-if="!this.isHideOldLogin" class="container-fluid bg-white p-0 login">
    <div class="header mb-2">
      <div class="gradient auth-bg"></div>
      <div class="h-content">
        <div class="row clearfix">
          <div class="col-md-6 mt-1 mb-3">
            <div class="main-logo">
              <router-link to="/login" class="logo-link"></router-link>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-7 col-lg-6 col-md-4"></div>
            <div class="col-xl-5 col-lg-6 col-md-6">
              <div class="box box-block mb-3 col-md-6 registration-block">
                <form class="form-material" method="POST" @submit.prevent="login">
                  <div class="form-group">
                    <div class="input-group">
                      <input type="text" class="form-control" name="username" placeholder="Username" v-model="username">
                    </div>
                    <div class="w-100 text-right mb-1.t">
                      <a href="" @click.prevent="onClickForgotUsername">Forgot your Username?</a>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <input type="password" class="form-control text-white" name="password" placeholder="Password"
                             v-model="password">
                    </div>
                  </div>
                  <div class="form-group clearfix mt-2">
                    <div>
                      <label class="pin-job left-element ">
                        <input type="checkbox" v-model="remember" style="margin-left: 4px;  visibility: hidden;"/>
                        <span class="pin_content pin-checkmark repair-fixed" style="top: 0px; left: 1px;"><i
                            class='bx bx-check'></i></span>
                        <span class="custom-control-description">Remember me</span>
                      </label>
                      <div class="float-xs-right">
                        <a href="" @click.prevent="onClickForgotPassword">Forgot your Password?</a>
                      </div>

                    </div>
                    <div class="float-xs-right">
                      <label class="custom-control custom-checkbox">
                        <span class="custom-control-description">Terms &amp; Conditions</span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group mt-2 mb-1">
                    <button type="submit" class="btn btn-outline-primary btn-block btn-lg b-a-radius-0-25">Sign in
                    </button>
                  </div>
                </form>
                <!--                                    <div class="row">-->
                <!--                                        <div class="col-xs-6">-->
                <!--                                            <button type="button" class="btn bg-facebook btn-block label-left btn-lg b-a-radius-0-25">-->
                <!--                                                <span class="btn-label"><i class="ti-facebook"></i></span>-->
                <!--                                                Facebook-->
                <!--                                            </button>-->
                <!--                                        </div>-->
                <!--                                        <div class="col-xs-6">-->
                <!--                                            <button type="button" class="btn bg-googleplus btn-block label-left btn-lg b-a-radius-0-25">-->
                <!--                                                <span class="btn-label"><i class="ti-google"></i></span>-->
                <!--                                                Google+-->
                <!--                                            </button>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <div class="row">
                  <div class="col-xs-12 mt-3">
                    <p class="text-sm-center">Do not have an account? <a href="" @click.prevent="onClickSignUp">Sign Up
                      Today Free</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- End of container -->
    </div><!-- End of header -->
    <!--        <div class="container">&lt;!&ndash; Start of container - new addition &ndash;&gt;-->
    <!--            <div class="col-lg-4 col-md-6 col-xs-12">-->
    <!--                <div class="box box-block bg-white tile tile-1 mb-2">-->
    <!--                    <div class="t-icon right"><span class="bg-theme"></span><i class="ti-package"></i></div>-->
    <!--                    <div class="t-content">-->
    <!--                        <h6 class="text-uppercase mb-1"><a href="/" alt="" title=""/>Latest Products</a></h6>-->
    <!--                        <h5 class="mb-1">Product Name</h5>-->
    <!--                        <div class="text-xl-center img-wrap">-->
    <!--                            <a href="/"/><img src="/img/photos-3/tool.jpg" alt="" title="" class="img-fluid img-thumbnail mb-2"/></a>-->
    <!--                            <span class="clearfix"></span>-->
    <!--                        </div>-->
    <!--                        <span> </span>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div class="col-lg-4 col-md-6 col-xs-12">-->
    <!--                <div class="box box-block bg-white tile tile-1 mb-2">-->
    <!--                    <div class="t-icon right"><span class="bg-theme"></span><i class="ti-announcement"></i></div>-->
    <!--                    <div class="t-content">-->
    <!--                        <h6 class="text-uppercase mb-1"><a href="/" alt="" title=""/>In the News</a></h6>-->
    <!--                        <h5 class="mb-1 text-truncate">News Title goes here Lorem ipsum sin dolor</h5>-->
    <!--                        <div class="news-list pt-0-5 pr-0-5 mb-3">-->
    <!--                            <div class="mb-1">-->
    <!--                                Sept <span class="tag tag-danger mr-0-5 mb-0-5"> 12th </span>-->
    <!--                                <span class="clearfix"></span>-->
    <!--                                <span class="font-90">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vitae odio eu velit scelerisque aliquet. Aliquam vitae tempus diam. </span>-->
    <!--                            </div>-->
    <!--                            <div class="mb-2">-->
    <!--                                Sept <span class="tag tag-danger mr-0-5 mb-0-5"> 12th </span>-->
    <!--                                <span class="clearfix"></span>-->
    <!--                                <span class="font-90">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vitae odio eu velit scelerisque aliquet. Aliquam vitae tempus diam. </span>-->
    <!--                            </div>-->
    <!--                            <div class="mb-2">-->
    <!--                                Sept <span class="tag tag-danger mr-0-5 mb-0-5"> 12th </span>-->
    <!--                                <span class="clearfix"></span>-->
    <!--                                <span class="font-90">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vitae odio eu velit scelerisque aliquet. Aliquam vitae tempus diam. </span>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div class="col-lg-4 col-md-6 col-xs-12">-->
    <!--                <div class="box box-block bg-white tile tile-1 mb-2">-->
    <!--                    <div class="t-icon right"><span class="bg-theme"></span><i class="ti-mouse-alt"></i></div>-->
    <!--                    <div class="t-content">-->
    <!--                        <h6 class="text-uppercase mb-1"><a href="/" alt="" title=""/>Advertise here</a></h6>-->
    <!--                        <h5 class="mb-1">Benefits</h5>-->
    <!--                        <span> </span>-->
    <!--                        <button class="btn btn-outline-primary mt-2">Online Application Form</button>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->

    <!--        </div>&lt;!&ndash; End of container &ndash;&gt;-->
  </div>
</template>

<style>
.V3 .login input {
  opacity: 1;
  color: #FFFFFF;
}

.V3 .login input:focus {
  color: #FFFFFF;
}

.V3 .login .form-control::placeholder {
  color: #FFFFFF;
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
}

/* WebKit, Edge */
.V3 .login .form-control::-webkit-input-placeholder {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
}

/* Firefox 4-18 */
.V3 .login .form-control:-moz-placeholder {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
}

/* Firefox 19+ */
.V3 .login .form-control::-moz-placeholder {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
}

/* IE 10-11 */
.V3 .login .form-control:-ms-input-placeholder {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
}

/* Edge */
.V3 .login .form-control::-ms-input-placeholder {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
}

</style>

<style>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.login .logo {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  letter-spacing: 5px;
}

.login .gradient.auth-bg {
  background: #192742 url("../../assets/img/photos-3/bg-photo.png") no-repeat;
  background-size: cover;
}

.login .header .box {
  border: none;
  background: rgb(33, 35, 48);
  padding: 2rem;
  border-radius: 5px;
}

.login .header .box .form-material .form-control {
  background-image: linear-gradient(#DEE9FF, #DEE9FF), linear-gradient(rgba(255, 255, 255, 0.125), rgba(255, 255, 255, 0.125));
}

.form-material .form-control {
  padding: 0.5rem 0.75rem !important;
}

.login .header .box .bg-facebook:hover,
.login .header .box .bg-googleplus:hover {
  opacity: .7
}

.login .custom-checkbox .custom-control-indicator {
  border-radius: .125rem;
}

.checked.custom-control-indicator {
  color: #fff;
  background-color: #0275d8;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #84c6ff;
}

.login .forgot-pass {
  opacity: .5;
  font-weight: 100;
}

.login .btn-outline-primary {
  color: #7A89A9;
  border-color: #7A89A9;
}

.login .btn-outline-primary:hover {
  background: #7A89A9;
  color: #FFFFFF
}

.login .t-content {
  height: 240px;
}

.login .t-content .img-wrap {
  display: inline-block;
  vertical-align: middle;
}

.login .t-content .img-fluid.img-thumbnail {
  height: 170px;
}

.login .bg-theme {
  background: #7A89A9;
}

.login .news-list {
  height: 200px;
  overflow-y: auto;
}


/* For 1x devices */
.main-logo {
  width: 212px;
  height: 54px;
  background-image: url('../../assets/logos/brandslogo.png');
  background-repeat: no-repeat;
}

/* For 2x devices */
@media only screen and ( -webkit-min-device-pixel-ratio: 2 ), only screen and ( -webkit-min-device-pixel-ratio: 1.5 ), only screen and ( -moz-min-device-pixel-ratio: 2), only screen and ( -o-min-device-pixel-ratio: 3/2), only screen and ( -o-min-device-pixel-ratio: 2/1), only screen and ( min--moz-device-pixel-ratio: 1.5), only screen and ( min-device-pixel-ratio: 1.5), only screen and ( min-device-pixel-ratio: 2) {
  .main-logo {
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .logo-link {
    display: block;
    height: 42px;
    width: 200px;
  }

  .header .btn {
    white-space: normal !important;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 100% !important;
  }

  .header .gradient {
    display: none;
  }

  .header-block {
    background: #1f233d;
    height: 100px;
    width: 100vh;
    top: -13px;
  }

  .box-block {
    margin: 0 auto;
  }

  .header .h-content {
    padding: 0;
  }

  .logo-link {
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }
}

/* For 3x devices */
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 480dpi) {
  .main-logo {
    background-image: url('../../assets/logos/logo3x.png');
    background-size: 100% auto;
  }
}


</style>

<style scoped>
.registration-block {
  width: 410px;
  right: 50px;
}

@media screen and (max-width: 768px) {
  .registration-block {
    right: 0;
  }
}

.pin-job {
  cursor: pointer;
}

.pin-checkmark {
  top: 145px;
  left: 28px;
  height: 16px;
  width: 16px;
}

.bx-check:before {
  content: "\ea0f";
}

input:checked ~ .pin-checkmark {
  background-color: #5e79ff;
  border-color: #5e79ff;
}

.pin-checkmark i {
  margin: auto;
  width: 16px;
  margin-top: 3px;
  display: none;
}

input:checked ~ .pin-checkmark i {
  display: block;
  font-size: 18px !important;
}

.custom-control-description {
  margin-left: 5px;
}
</style>
