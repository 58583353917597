<template>
  <div class="container-fluid bg-white p-0 login">
    <div class="header">
      <div class="gradient auth-bg col-xl-6 col-lg-6 col-md-6"></div>
      <span class="close" @click.prevent="$router.push('/login')">×</span>
        <div class="h-content">
          <div class="row clearfix">
            <div class="col-md-6 mt-3 mb-3">
              <div class="main-logo">
                <router-link to="/login" class="logo-link"></router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-7 col-lg-7 col-md-7"></div>
              <div class="col-xl-5 col-lg-5 col-md-5 mobile-position-register">

                <div class="box box-block mb-3">
                  <h4>Sign up and create an Account today.</h4>
                  <h4>You're just a few steps away from getting started</h4>
                  <h6>Already signed up? <a href="" @click.prevent="onClickLogIn">Log In</a></h6>
                  <form class="form-material" method="POST" @submit.prevent="register" style="margin-top: 60px">
                    <div class="form-group" style="margin-bottom: 40px !important;">
                      <float-label>
                        <input
                            ref="email"
                            @keydown.up="focusOnElement('country')"
                            @keydown.down="focusOnElement('username')"
                            autocomplete="off"
                            type="email"
                            class="form-control"
                            placeholder="Your email address"
                            name="email"
                            v-model="userEmail"
                            @blur="checkUserEmail"
                            @focus="isBusyUserEmail = false; isValidUserEmail = true; isInputInFocus = true"
                        />
                      </float-label>
                      <div class="w-100 text-right">
                        <span v-if="errorUserEmail && userEmail!=='' && !isUserEmailUseOnClosedAcc" class="error-text">
                          <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;This email address is already registered, please use a different one.
                        </span>
                        <span v-if="errorUserEmail && userEmail!=='' && isUserEmailUseOnClosedAcc" class="error-text">
                          <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;We cannot create a new account with this email address. Contact Support.
                        </span>
                        <span v-if="errorUserEmail2 && userEmail!==''" class="error-text">
                          <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Check your email address again.
                        </span>
                      </div>
                    </div>
                    <div class="form-group" style="margin-bottom: 40px !important;">
                      <float-label>
                        <input
                            ref="username"
                            @keydown.up="focusOnElement('email')"
                            @keydown.down="focusOnElement('password')"
                            autocomplete="off"
                            type="text"
                            class="form-control"
                            placeholder="Create a Username"
                            name="username"
                            v-model="username"
                            @keyup="checkUsername"
                            @change="checkUsername"
                        />
                      </float-label>
                      <div class="w-100 text-right">
                        <span v-if="errorUsername && username !== ''" class="error-text">
                          <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;This Username is already in use. Try adding extra letters and/or numbers
                        </span>
                      </div>
                    </div>
                    <div class="form-group" style="margin-bottom: 40px !important;">
                      <div class="row no-gutter">
                        <div class="col-12 col-sm-10">
                          <float-label>
                            <input
                                ref="password"
                                @keydown.up="focusOnElement('username')"
                                @keydown.down="focusOnElement('country')"
                                autocomplete="off"
                                :type="isShowPassword? 'text' : 'password'"
                                style="border-radius: 4px 0px 0px 4px !important;"
                                class="form-control"
                                placeholder="Create a Password"
                                v-model="password"
                                name="password"
                                @keyup="checkPassword"
                                @change="checkPassword"
                            />
                          </float-label>
                        </div>
                        <div class="col-12 col-sm-2 eye-section">
                          <i @click="isShowPassword = !isShowPassword" style="font-size: 24px;"
                            :class="{ 'fa fa-eye-slash': isShowPassword, 'fa fa-eye': !isShowPassword }"
                            id="tooltip-target"></i>
                            <b-tooltip v-if="isShowPassword" target="tooltip-target" triggers="hover" placement="bottom" customClass="ps-tooltip">Hide Password</b-tooltip>
                            <b-tooltip v-else target="tooltip-target" triggers="hover" placement="bottom" customClass="ps-tooltip">View Password</b-tooltip>
                        </div>
                      </div>
                      <div class="container-fluid w-100 text-right">
                        <span v-if="errorPassword && password !== ''" class="error-text mb-sm-1">
                          <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Password has to contain at least 8 characters
                        </span>
                      </div>
                    </div>
                    <div class="form-group form-padd">
                      <div class="multiselect-style">
                        <multiselect
                            ref="country"
                          :options="getOptions"
                          :showLabels="false"
                          :option-height="29"
                          :max-height="203"
                          :close-on-select="true"
                          placeholder="Country of Registration"
                          v-model="country"
                        />
                      </div>
                    </div>

                    <div class="form-group mt-2 mb-1">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        :disabled="!isCheckPassword || !isCheckUsername || !country || isBusyUserEmail || !isValidUserEmail || isInputInFocus || !isGetResponse">
                        Sign Me Up</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Axios from "axios";
import FloatLabel from 'vue-float-label/components/FloatLabel'
import Multiselect from 'vue-multiselect';

export default {
  name: "register",
  components: {
    multiselect: Multiselect,
    FloatLabel,
  },
  data() {
    return {
      username: "",
      userEmail: "",
      password: "",
      isShowPassword: false,
      isCheckUsername: false,
      isInputInFocus: false,
      isGetResponse: false,
      isBusyUserEmail: false,
      isValidUserEmail: true,
      isUserEmailUseOnClosedAcc: false,
      isCheckPassword: false,
      country: '',
    };
  },
  methods: {
    focusOnElement(name) {
      if (name === 'country') {
        this.$refs[name].$el.focus();
      } else {
        this.$refs[name].focus();
      }
    },
    onClickLogIn(){
      this.$router.push('/login')
    },
    checkUserEmail: function() {
      this.isInputInFocus = false;
      let userEmail = this.userEmail;
      let self = this;
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.isValidUserEmail = re.test(this.userEmail);

      if (!this.isValidUserEmail) {
        return;
      }
      this.isGetResponse = false;
      Axios({
        url: "/auth/check-email",
        data: { 'email': userEmail },
        method: "POST"
      })
        .then(resp => {
          if (resp && resp.data && resp.data.status == false) {
            self.isBusyUserEmail = false;
            self.isValidUserEmail = true
            self.isUserEmailUseOnClosedAcc = false;
          } else {
            if (resp.data.isClosed) {
              this.isUserEmailUseOnClosedAcc = true;
            } else {
              this.isUserEmailUseOnClosedAcc = false;
            }
            self.isBusyUserEmail = true;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.isGetResponse = true;
        });
    },
    checkUsername: function() {
      let username = this.username;
      let self = this;

      Axios({
        url: "/auth/check-username",
        data: {'username': username },
        method: "POST"
      })
        .then(resp => {
          if (resp && resp.data && resp.data.status == false) {
            self.isCheckUsername = true;
          } else {
            self.isCheckUsername = false;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    checkPassword(){
      this.isCheckPassword = this.password.length > 7;
    },
    register: function(){
      let user = {};
      user.email = this.userEmail;
      user.username = this.username
      user.password = this.password;
      user.country = this.country;
      user.isFreeAccount =  false;
      user.isAccountWithSupportPack = false;

      if (this.$route.params.support_pack) {
        this.$router.push({
          name: 'Registration',
          params: {
            support_pack: this.$route.params.support_pack,
            user: user,
          }
        })
      } else {
        this.$router.push({
          name: 'SelectPlan',
          params: {user: user}});
      }
    },
  },
  computed: {
    errorUsername() {
      if (!this.isCheckUsername) {
        return true;
      }
      return false;
    },
    errorUserEmail() {
      if (this.isBusyUserEmail) {
        return true;
      }
      return false;
    },
    errorUserEmail2(){
      if (!this.isValidUserEmail) {
        return true
      }
      return false
    },
    errorPassword() {
      if (!this.isCheckPassword) {
        return true;
      }
      return false;
    },
    getOptions() {
      return ["Australia"];
    }
  },
  mounted() {
    if (this.$route.params.support_pack === 'spl1') {
      this.$router.push({
        name: 'Register',
        params: {
          support_pack: 'pack-1',
        }
      })
    } else if (this.$route.params.support_pack === 'spl2') {
      this.$router.push({
        name: 'Register',
        params: {
          support_pack: 'pack-2',
        }
      })
    }
  },
}
</script>

<style>
  .vfl-label {
    color: black;
    font-size: 13px;
  }
</style>

<style scoped>
  .login .gradient {
    position: absolute;
    height: 100% !important;
  }

  .eye-section {
    border-radius: 0px 4px 4px 0px;
    border: 1px solid rgba(27, 30, 56, 0.2) !important;
    text-align: center;
    padding-top: 9px;
    height: 43px;
  }
  .error-text {
    color:#FF5E5E
  }
  .login .gradient.auth-bg {
    background: #1f233d !important;
  }

  .logo-text {
    color: white;
    margin: 20% 0 0 20%;
  }

  .header {
    color: black;
    position: static;
  }

  .login .header .box {
    background: none;
  }

  .box-block {
    max-width: 450px;
  }

  .box-block h4, h6 {
    text-align: center;
  }

  h6 {
    margin: 30px 0;
    font-size: 16px;
  }

  input::placeholder {
    /*padding-left: 15px;*/
    color: #ADADAD !important;
  }

  .V3 .login input {
    color: black;
  }

  .form-padd {
    padding-bottom: 19px;
  }

  .checkbox-block {
    margin: 50px 0 50px 25px;
  }

  .btn.disabled, .btn:disabled {
    opacity: 1;
  }

  .header .gradient {
    opacity: 1;
  }

  .form-group-label {
    padding-left: 20px;
  }

  .V3 label.radio {
    width: 100%;
  }

  .btn-primary {
    width: 100%;
    border-radius: 3px;
    font-size: 16px !important;
  }

  .main-logo {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    .main-logo {
      background-image: url('../../../assets/logos/logo3x.png');
      background-size: 100% auto;
    }
  }

  .logo-link {
    display: block;
    height: 42px;
    width: 200px;
  }

  @media screen and (max-width: 1200px) {
    .container {
      width: 1140px;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .header .gradient {
      display: none;
    }
    .header-block {
      background: #1f233d;
      height: 100px;
      top: -13px;
    }
    .box-block {
      margin:0 auto;
    }
    .header .h-content {
      padding: 0;
    }
  }

  .logo-link {
    display: block;
    height: 42px;
    width: 200px;
  }

  .close {
      color: #1f233d;
      font-size: 50px;
      padding-right: 20px;
      padding-top: 20px;
      position: relative;
      z-index: 999;
  }

  .form-group .form-control {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    padding: 9px 13px;
    border: 1px solid rgba(27, 30, 56, 0.2) !important;
    border-radius: 4px !important;
    height: 43px !important;
    color: #1C1F39;
  }

  .form-group .form-control::placeholder {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    color: #ADADAD !important;
    opacity: 1;
    padding-left: 2px;
  }

  .btn.disabled, .btn:disabled {
    cursor: not-allowed;
    opacity: .65;
    background: #adaeb2;
    border-color: #adaeb2;
  }

  .V3 .btn-primary.disabled:hover, .V3 .btn-primary:disabled:hover {
    background: #98999c;
    border-color: #98999c;
  }

  .form-group .form-control:focus {
    border: 2px solid rgba(94, 121, 255, 0.5) !important;
    box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%);
  }

  .multiselect--active {
    border: 2px solid rgba(94, 121, 255, 0.5) !important;
    box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%);
    border-radius: 6px !important;
  }

  .ps-tooltip {
    left: -15px !important;
  }
</style>

<style>
  .multiselect-style .multiselect .multiselect__tags {
    font-family: "Nunito Sans";
    font-size: 16px !important;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px !important;
    border-radius: 4px !important;
    height: 43px !important;
    color: #1C1F39;
    padding: 5px 30px 5px 13px;
  }

  .multiselect-style .multiselect .multiselect__tags .multiselect__placeholder {
    padding-top: 5px;
  }

  .multiselect-style .multiselect .multiselect__single {
    font-family: "Nunito Sans";
    font-size: 16px !important;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px !important;
    color: #1C1F39;
    padding: 5px 0;
  }

  .multiselect-style .multiselect .multiselect__option {
    font-family: "Nunito Sans";
    font-size: 16px !important;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px !important;
    color: #1C1F39;
  }

  .multiselect-style .multiselect .multiselect__input {
    font-size: 16px !important;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px !important;
    padding: 5px 0;
  }

  .multiselect-style .multiselect .multiselect__select {
    top: 4px !important;
  }

  .multiselect-style .multiselect--active .multiselect__select {
    top: 12px !important;
  }

  .login .multiselect__input {
    min-width: auto;
  }

  .vfl-label-on-input {
    top: -2em;
  }

  .vfl-label-on-input {
    color: #1C1F39;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.25px;
    line-height: 22px;
  }
</style>
