<script>
export default {
  name: 'site-footer'
}
</script>




<template>
		<footer class="footer">
			<div class="container-fluid">
				<div class="row text-xs-center">
					<div class="col-sm-6 text-sm-left mb-0-5 mb-sm-0 d-flex">
						<P class="mb-0">FOR SUPPORT &nbsp;|&nbsp; P: &nbsp;</P>
						<a href="tel:0280692447">02 8069 2447</a>
						<p class="mb-0">&nbsp; | &nbsp;E: &nbsp;</p>
						<a href="mailto:support@repair-shop.com.au">support@repair-shop.com.au</a>
					</div>
					<div class="col-sm-6 text-sm-right">

					</div>
				</div>
			</div>
		</footer>
</template>