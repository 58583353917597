
export const Actions = {
  setLastTimestamp(context, timestamp) {
    if (timestamp && timestamp > 0) {

    } else {
      timestamp = 0;
    }
    context.commit('updaterSetLastTimestamp', timestamp);
  },

  setEmail(context, timestamp) {
    context.commit('updaterSetCheckEmail', timestamp);
  },

  setFiles(context, timestamp) {
    context.commit('updaterSetCheckFiles', timestamp);
  },

  setImages(context, timestamp) {
    context.commit('updaterSetCheckImages', timestamp);
  },

  setDashboard(context, timestamp) {
    context.commit('updaterSetCheckDashboard', timestamp);
  },

  setPromos(context, timestamp) {
    context.commit('updaterSetCheckPromos', timestamp);
  },

  setActivity({commit, state}, timestamp) {
    if (timestamp === 0 || timestamp === null) {
      commit('updaterSetCheckActivity', null);
    } else if (timestamp > 0 && state.isNeedUpdateActivity === null) {
      commit('updaterSetCheckActivity', timestamp);
    }
  },

  resetActivity({commit}) {
    commit('updaterSetCheckActivity', null);
  },

  resetFiles({commit}) {
    commit('updaterSetCheckFiles', null  )
    ;
  },

  resetImages({commit}) {
    commit('updaterSetCheckImages', null);
  },

  resetPromos({commit}) {
    commit('updaterSetCheckPromos', null)
  },

  resetDashboard({commit}) {
    commit('updaterSetCheckDashboard', null);
  },
}
