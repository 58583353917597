<template>
    <div class="container-fluid bg-white p-0 login">
        <div class="header">
            <div class="gradient auth-bg col-xl-6 col-lg-6 col-md-6"></div>
            <span class="close" @click="$router.push('/login')">×</span>
            <div class="h-content">
                <div class="row clearfix width-container">
                    <div class="col-md-6 main-logo-container">
                        <div class="main-logo">
                            <router-link to="/login" class="logo-link"></router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-7 col-lg-7 col-md-7"></div>
                        <div class="col-xl-5 col-lg-5 col-md-5">
                            <div class="box box-block mb-3">
                                <form class="form-material" method="POST">
                                    <h2 class="header-text">Password Reset</h2>
                                    <p>Reset your password by entering you new password details below</p>
                                    <br/>

                                    <div class="form-group">
                                        <label for="firstname">New Password</label>
                                        <input
                                                type="password"
                                                class="form-control"
                                                placeholder="Enter New Password"
                                                name="pass"
                                                v-model="pass"
                                        />
                                        <div class="w-100 text-right" v-if="errors.password1">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;{{errors.password1}}
                                </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="pass">Retype New Password</label>
                                        <input
                                                type="password"
                                                class="form-control"
                                                :class="{'error': errors.password2}"
                                                placeholder="Retype Password"
                                                name="pass"
                                                v-model="retrypass"
                                        />
                                        <div class="w-100 text-right" v-if="errors.password2">
                                <span class="error-text">
                                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;{{errors.password2}}
                                </span>
                                        </div>
                                        <p class="small mt-2">*Must be more than 8 characters long.</p>
                                    </div>
                                    <div class="form-group">
                                        <button :disabled="isLoading || !isSetPassword" @click.prevent="sendRequest" class="btn btn-primary" type="submit">Reset My Password</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Axios from "axios";

    export default {
        name: "PasswordResetPage",
        data() {
            return {
                pass: "",
                retrypass: "",
                code: "",
                error: 0,
                errors: {
                    'password1': '',
                    'password2': ''
                },
                isLoading: false,
            };
        },
        mounted() {
            this.code = this.$route.params.code;
        },
        computed: {
            isSetPassword() {
                return !(_.isEmpty(this.pass) || _.isEmpty(this.retrypass) || this.pass.length <= 8);
            }
        },
        methods: {
            sendRequest() {
                if (!this.isFormValid() || this.isLoading) return;
                this.isLoading = true;
                this.error = 0;
                NProgress.start();
                Axios({
                    url: '/auth/reset-password', data: {
                        code: this.code,
                        password1: this.pass,
                        password2: this.retrypass
                    }, method: 'POST'
                })
                    .then(resp => {
                        if (resp && resp.data) {
                            if (resp.data._status === true) {
                                toastr.success('The password has been changed successfully');
                                this.$router.push('/login');
                            } else {
                                this.error = resp.data._error;
                                toastr.error(resp.data.message);
                            }

                        }
                    })
                    .catch(err => {
                        console.log(err);
                    }).finally(() => {
                    NProgress.done();
                    this.isLoading = false;
                });
            },
            isFormValid() {
                let text1 = '',
                    text2 = '';
                if (_.isEmpty(this.pass)) text1 = 'Please enter new password.';
                if (_.isEmpty(this.retrypass)) text2 = 'Please retype new password.';

                if (!text1 && !text2) {
                    if (this.pass.length <= 8) text1 = 'Password must be more than 8 characters long.';
                    else if (this.pass !== this.retrypass) text2 = 'Passwords do not match.';
                }

                this.errors.password1 = text1;
                this.errors.password2 = text2;
                return _.isEmpty(text1) && _.isEmpty(text2);
            }
        }
    };
</script>

<style scoped>

    .login .gradient.auth-bg {
        background: #333F50 !important;
    }

    .logo-text {
        color: white;
        margin: 20% 0 0 20%;
    }

    .login .gradient {
        position: absolute;
        height: 100% !important;
    }

    .close {
        color: #5e79ff;
        font-size: 50px;
        padding-right: 20px;
        padding-top: 20px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        opacity: 0.5;
    }

    .close:hover {
        opacity: 1;
    }

    .header {
        color: black;
    }

    .header-text {
        font-size: 32px;
    }

    .login .header .box {
        background: none;
    }

    .box-block {
        max-width: 450px;
    }

    .box-block h4, h6 {
        text-align: center;
    }

    h6 {
        margin-top: 30px;
    }

    input::placeholder {
        padding-left: 15px;
        color: #ADADAD !important;
    }

    .V3 .login input {
        color: black;
    }

    .form-padd {
        padding-bottom: 19px;
    }

    .checkbox-block {
        margin: 50px 0 50px 25px;
    }

    .btn-block {
        background: #4472C4;
        color: white;
        border-radius: 3px;
    }

    .header .gradient {
        opacity: 1;
    }

    .form-group-label {
        padding-left: 20px;
    }

    .V3 label.radio {
        width: 100%;
    }

    .header {
        min-height: 100vh;
    }

    .header .btn {
        margin: 0 auto;
    }

    .form-material {
        text-align: center;
    }

    .form-material h2 {
        padding-bottom: 50px;
    }

    .form-material p {
        font-weight: 600;
        padding-bottom: 50px;
        font-size: 16px;
    }

    .form-control {
        color: black;
    }

    input::placeholder {
        color: #ADADAD !important;
    }

    .V3 .login input {
        padding-left: 15px;
        color: black !important;
    }

    .text-sm-right {
        padding-top: 10px;
    }

    .form-group-input-title {
        width: 100%;
        text-align: left;
        padding-left: 15px;
    }

    .text-sm-right {
        padding-top: 10px;
    }

    .form-group {
        margin-top: 80px;
    }

    .btn-primary {
        width: 100%;
        border-radius: 3px;
        font-size: 16px !important;
        background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
    }

    .main-logo-container {
        padding: 32px 72px 0 72px;
    }

    .width-container {
        margin: 0 auto;
        width: 1440px;
    }

    .h-content {
        display: flex;
        justify-content: center;
    }

    .main-logo {
        display: flex;
        justify-content: center;
        height: 39px !important;
        width: 185px !important;
        background-repeat: round;
    }

    .logo-link {
        display: block;
        height: 39px;
        width: 185px;
    }

    @media screen and (max-width: 768px) {
        .main-logo {
            background-image: url('../../assets/logos/logo3x.png');
            background-size: 100% auto;
        }
    }

    .logo-link {
        display: block;
        height: 42px;
        width: 200px;
    }

    .form-group .form-control {
        font-family: "Nunito Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        padding: 9px 13px;
        border: 1px solid rgba(27, 30, 56, 0.2) !important;
        border-radius: 4px !important;
        height: 43px !important;
        color: #1C1F39;
    }

    .form-group .form-control::placeholder {
        font-family: "Nunito Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        color: #ADADAD !important;
        opacity: 1;
        padding-left: 2px;
    }

    .btn.disabled, .btn:disabled {
        cursor: not-allowed;
        opacity: .65;
        background: #adaeb2;
        border-color: #adaeb2;
    }

    .V3 .btn-primary.disabled:hover, .V3 .btn-primary:disabled:hover {
        background: #98999c;
        border-color: #98999c;
    }
    @media screen and (max-width: 767px) {
        .box-block {
            margin-top: 70px !important;
        }
        .main-logo-container .main-logo {
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 350px) {
        .box-block {
            margin-top: 30px !important;
        }
        .width-container {
            margin-top: 50px !important;
        }
    }
</style>
