<template>
  <!--  <div class="uiv">-->
  <time-picker v-model="localValue" :controls="false" :disabled="isDisabled"/>
  <!--  </div>-->
</template>

<script>
import {TimePicker} from 'uiv'
import dayjs from "dayjs";
export default {
  name: "the-time-picker-2",
  components: {
    TimePicker
  },
  data() {
    return {
      localValue: new Date(new Date().setSeconds(0))
    }
  },
  props: {
    value: {},
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  watch:  {
    value: {
      handler(v) {
        this.localValue = new Date(dayjs().format('YYYY-MM-DD') + ' ' + v)
      },
      immediate: true
    },
    localValue: {
      handler(v) {
        let value = new Date(new Date(v).setSeconds(0))
        if (value == 'Invalid Date') {
          this.$emit('changed', null)
        } else {
          this.$emit('changed', dayjs(value).format('HH:mm:ss'))
        }
        this.$emit('input')
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
