<template>
    <div class="tl-content box box-block display-block" style="background-color: #FAFAFA;">
        <span class="arrow left" style="border-color: #FAFAFA;"></span>
        <div>
            <div v-if="isStatusActive" class="navbar-nav nav dropdown dropdown-options show float-sm-right text-right additional-action-menu-style" style="margin-right:1.25rem; position: absolute; right: 7px">
                 <span class="btn ml-0-25 waves-effect waves-light ex-options-button additional-action-menu-style small-margin-actions-style" style="margin-top: -10px; padding-left: 10px !important;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Actions
                    <span class="dropdown-arrow  pull-right" style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);"><i class="fa fa-chevron-down pull-right-action"></i></span>
                 </span>
                <div class="dropdown-menu dropdown-menu-right animated fadeInUp drop-down-menu-actions">
                    <div class="dropdownLinks-actions-links">
                        <a v-if="viewed == 1" @click="markAsUnRead(activity)" class="dropdown-item text-primary clickable dropdown-action-item dropdown-action-item-with-icon"><i
                                class="fa fa-eye-slash activtyPopupIcon" aria-hidden="true"></i> Mark As Unread</a>
                        <a v-else @click="markAsRead(activity)" class="dropdown-item text-primary clickable dropdown-action-item dropdown-action-item-with-icon"><i
                                class="fa fa-eye activtyPopupIcon" aria-hidden="true"></i> Mark As Read</a>
                    </div>
                    <div class="dropdownLinks-actions-links">
                      <a v-if="isProductionManagerUser || isShopManagerUser || isAdministrator" @click="$refs.deleteActivityModal.show()" class="dropdown-item text-primary clickable dropdown-action-item">Delete Entry</a>
                    </div>
                </div>
            </div>
            <div>
                <table class="activity-table">
                    <tr>
                        <td>Transaction:</td>
                        <td>{{a.action_description}}</td>
                    </tr>
                    <tr>
                      <td>Ref:</td>
                      <td>
                        <span class="link-to-invoice-view" @click="goToInvoiceView">{{a.invoiceNumber}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Processed by:</td>
                      <td>{{a.createdBy}}</td>
                    </tr>
  <!--                    <tr>-->
<!--                        <td>Amount Due:</td>-->
<!--                        <td>{{a.amount_due}}</td>-->
<!--                    </tr>-->
                    <tr>
                        <td>Date:</td>
                        <td>{{a.entry_date.replaceAll('-', '/')}} - {{a.entry_time}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <modal-wizard ref="deleteActivityModal" cancelButtonText="Cancel" finishButtonText="Ok" @on-complete="deleteActivity" class="delete-activity-modal">
          <modal-wizard-tab title="Delete Activity" description="" title-icon-class="ti-align-right">
            <template>
              <div class="pt-2 pb-2">Do you want to Delete this Activity list entry?</div>
            </template>
          </modal-wizard-tab>
        </modal-wizard>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'activity-list-default',
        props: ['viewed', 'activity', 'index', 'cardId', 'viewed',],
        data: function () {
            return {
                a: this.activity,
                isMinimized: !!this.viewed,
            };
        },
        methods: {
          goToInvoiceView() {
            if (!this.a.invoiceId) {
              return
            }
            if (this.a.isInvoiceExcess) {
              this.$router.push({name: 'InvoiceExcessView', params: {invoice_id: this.a.invoiceId }});
            } else {
              this.$router.push({name: 'InvoiceView', params: {invoice_id: this.a.invoiceId }});
            }
          },
            deleteActivity: function () {
              this.$emit('deleteActivity', this.a.activity_id);
              this.$refs.deleteActivityModal.hide();
            },
            markAsUnRead: function (a) {
                this.$emit('unread', a);
            },
            markAsRead: function (a) {
                this.$emit('read', a);
            },
            loadActivity: function () {
                this.$emit('loadActivity');
            },
            destroyMe: function () {
                Vue.$destroy(this);
            }
        },
        computed: {
          ...mapGetters({
            isStatusActive: 'isStatusActive',
            isShopManagerUser: 'isShopManagerUser',
            isProductionManagerUser: 'isProductionManagerUser',
            isAdministrator: 'isAdministrator',
          }),
        },
        watch: {},
        components: {},
        mounted: function () {
        }
    };
</script>

<style scoped>
    .link-to-invoice-view {
      color: #0275d8;
      cursor: pointer;
      font-weight: bold;
    }
    .link-to-invoice-view:hover {
      color: #014c8c;
    }
    @media screen and (max-width: 491px) {
        .small-margin-actions-style {
           margin-top: -50px !important;
        }
        .drop-down-menu-actions {
            margin-top: -35px !important;
        }
    }

    .dropdownLinks-actions-links {
        width: 115px;
    }

    .drop-down-menu-actions {
        width: 115px !important;
        border-radius: 3px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgb(27 30 56 / 15%);
        min-width: 115px !important;
        margin: -13px -14px 0 !important;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        z-index: 110;
    }

    .dropdown-action-item {
        text-align: center !important;
        min-height: 20px !important;
    }


    .pull-right-action {
        padding-right: 3px !important;
        margin-left: 10px;
    }
</style>
