<template>
  <div class="cards-all-snapshot-main-graphs d-flex" style="max-width: 33.333%">
    <div class="cards-all-snapshot-main-graphs_item">
      <div class="cards-all-snapshot-main-graphs_item-title">
        Top Bookings by Insurer
      </div>
      <div class="cards-all-snapshot-main-graphs_item-graph" :class="{'empty-chart': _isEmpty(computedGraphTwoSeries[0].data)}">
        <apexchart type="bar" height="200" :options="computedGraphTwoOptions" :series="computedGraphTwoSeries"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import accounting from "accounting";
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "top-insurers",
  props: {
  },
  components: {
    apexchart: VueApexCharts,
  },
  methods: {
    toTimestamp(date) {
      if (typeof(date) !== 'string') {
        return date
      }
      // "2022-07-25 09:00"
      if (date.includes(':')) {
        let [date1, date2] = date.split(' ');
        let [year, month, day] = date1.split('-');
        let [hours, minutes] = date2.split(':');

        let datum = new Date(year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':00');
        return datum.getTime();

      } else {
        let [year, month, day] = date.split('-');
        let datum = new Date(year + '-' + month + '-' + day + 'T00:00:00');
        return datum.getTime();
      }
    },
    _isEmpty(v) {
      return _.isEmpty(v)
    },
    formantCash(n) {
      if (n < 1e3) return n;
      if (n >= 1e3 && n < 1e6) return '$' + Number((n / 1e3)).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return '$' + Number((n / 1e6)).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return '$' + Number((n / 1e9)).toFixed(1) + "B";
      if (n >= 1e12) return '$' + Number((n / 1e12)).toFixed(1) + "T";
    }
  },
  computed: {
    ...mapGetters({
      getBookingItems: 'board/getBookingItems',
      filter: 'board/getBookingFilter',
    }),

    computedEvents() {
      let events = _.filter(this.getBookingItems, (e) => {
        return e.type == 'on-site'
      })
      return _.map(events, (e) => {
        e.start = this.toTimestamp(e.dateStart + ' ' + e.timeStart);
        e.end = this.toTimestamp(e.dateEnd + ' ' + e.timeEnd);
        return e
      });
    },
    computedEventsFiltered() {
      return _.filter(this.computedEvents, (e) => {
        let eventS = e.start;
        let eventE = e.end;
        let filterS = this.computedFilterTimeStamp.start;
        let filterE = this.computedFilterTimeStamp.end;
        return (eventS >= filterS && eventS <= filterE) ||  (eventE >= filterS && eventE <= filterE)
      })
    },
    computedFilterTimeStamp() {
      let r = {
        start: null,
        end: null,
        start2: null,
        end2: null
      }
      if (this.filter == 'Month') {
        r.start2 = dayjs().startOf('month').format('YYYY-MM-DD') + ' 00:00'
        r.end2 = dayjs().endOf('month').format('YYYY-MM-DD') + ' 23:59:59'
      } else if (this.filter == 'Last Week') {
        let now = new Date();
        r.end2 = dayjs(new Date(now.setDate(now.getDate() - now.getDay()))).format('YYYY-MM-DD') + ' 23:59:59';
        r.start2 = dayjs(new Date(now.setDate(now.getDate() - 6))).format('YYYY-MM-DD') + ' 00:00';
      } else if (this.filter == 'Day') {
        r.start2 = dayjs().format('YYYY-MM-DD') + ' 00:00';
        r.end2 = dayjs().format('YYYY-MM-DD') + ' 23:59:59';
      } else if (this.filter == 'Week') {
        r.start2 = dayjs().isoWeekday(1).format('YYYY-MM-DD') + ' 00:00';
        r.end2 = dayjs().isoWeekday(7).format('YYYY-MM-DD') + ' 23:59:59'
      }
      r.start = new Date(r.start2).getTime()
      r.end = new Date(r.end2).getTime()
      return r
    },

    computedGraphTwoSeries() {
      let series = [{
        data: []
      }];
      _.forEach(_.orderBy(this.getEventsCount, 'count', 'desc').slice(0, 5).reverse(), (i) => {
        series[0].data.push({
          x: i.name,
          y: Number(accounting.toFixed(i.count, 2))
        })
      })

      return series

    },
    computedGraphTwoOptions() {
      let self = this;
      let options = {
        chart: {
          type: 'bar',
          height: '5px'
        },
        colors:['#4371c2'],
        plotOptions: {
          bar: {
            borderRadius: 2,
            borderRadiusApplication: 'end',
            horizontal: true,
            barHeight: '30%',
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          tickAmount: 2,
        },
        tooltip: {
          marker: {show: true},
          enabled: true,
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            let color = w?.globals?.colors[dataPointIndex]
            let result ='<div class="apexcharts-tooltip-title" style="font-family: \'Nunito Sans\'; font-size: 13px;">'+ w.globals.labels[dataPointIndex] +'</div><div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;"><span class="apexcharts-tooltip-marker" style="background-color: '+color+';"></span><div class="apexcharts-tooltip-text" style="font-family: \'Nunito Sans\'; font-size: 13px;"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">Onsite Estimate Bookings: </span><span class="apexcharts-tooltip-text-y-value">'+(series[seriesIndex][dataPointIndex])+'</span></div><div class="apexcharts-tooltip-goals-group"><span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value"></span></div><div class="apexcharts-tooltip-z-group"><span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span></div></div></div></div>'

            return result
          },
          y: {
            // title: {
            //   formatter: () => {
            //     return 'Estimate Totals: '
            //   },
            // },
          },
          style: {
            fontSize: '13px',
            fontFamily: 'Nunito Sans',
            fontWeight: 'bold',
          },
        },
      }

      return options
    },
    getEventsCount () {
      let r = []
      _.forEach(this.computedEventsFiltered, (e) => {
        if (e?.card?.insurerId) {
          let rIns = _.find(r, (i) => { return Number(i.insurerId) == Number(e.card.insurerId)})
          if (rIns) {
            rIns.count += 1
          } else {
            r.push({
              name: e.card.insurer,
              insurerId: e.card.insurerId,
              count: 1,
            })
          }
        }
      })

      return r
    },
  }
}
</script>

<style scoped>
.cards-all-snapshot-main-graphs_item:first-child{
  margin-left: 0;
}
.cards-all-snapshot-main-graphs_item:last-child{
  margin-right: 0;
}
.cards-all-snapshot-main-graphs_item-title{
  font-weight: 700;
  font-size: 15px;
  text-align: center;
}
.cards-all-snapshot-main-graphs_item-graph >>> .apexcharts-menu-icon{
  display: none;
}

.cards-all-snapshot-main-graphs{
  margin-top: 10px;
}
.cards-all-snapshot-main-graphs_item{
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  margin: 0 5px;
  padding: 20px 5px;
  flex: 33.333%;
}



@media screen and (max-width: 1100px) {
  .cards-all-snapshot-main-graphs{
    flex-flow: wrap;
  }
  .cards-all-snapshot-main-graphs_item{
    flex: 100%;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 5px !important;
  }

}
</style>
