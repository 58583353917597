<script>
import {mapGetters} from "vuex";
import Axios from "axios";

export default {
  name: "Webtrim",
  props: {
    value: {}
  },
  data() {
    return {
      vendorInfo2: {
        webtrimAuth: {}
      },
    }
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
      'vendorInfo': 'vendorInfo',
    }),
  },
  methods: {
    save() {
      NProgress.start()
      Axios.post('/fe/vendor/update-webtrim-auth', this.vendorInfo2.webtrimAuth)
          .then(response => {
            if (response.data._status) {
              this.$store.commit('loadVendorInfo', this.vendorInfo2)
              toastr.success('Settings updated successfully')
            }
          })
          .catch(error => {
            NProgress.done()
            toastr.error(error)
          })
          .finally(() => {
            NProgress.done()
          })
    }
  },
  mounted() {
    this.vendorInfo2 = _.cloneDeep(this.vendorInfo)
  },
  watch: {
    vendorInfo: {
      handler() {
        this.vendorInfo2 = _.cloneDeep(this.vendorInfo)
      },
      immediate: true
    }
  }
}
</script>

<template>
  <div style="margin-top: 15px; padding-left: 15px" class="card row no-gutter">
    <div class="col-lg-5 col-12">
      <div class="card-header bg-navyblue">
        <strong>Webtrim</strong>
      </div>
      <div v-if="vendorInfo2 && vendorInfo2.webtrimAuth" class="row no-gutter rs-toggle card-block" style="padding-left: 0px">
        <div style="height: 70px">
          <label class="col-sm-7 description col-form-label"
                 for="ps-enabled-input">
            <p class="lable-text">Enable WEBTRIM activation with your <br> Webtrim Account</p>
          </label>
          <div class="col-sm-5">
            <div class="d-flex">
              <toggle-button
                  class="mb-0 rs-toggle__button col-form-label"
                  color="#5E79FF"
                  v-model="vendorInfo2.webtrimAuth.active"
                  :disabled="!isStatusActive"
                  :width="40"
                  :sync="true"
                  style="opacity: 1;"
                  :labels="false"/>
              <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25" style="line-height: 22px;">
                  <template v-if="vendorInfo2.webtrimAuth.active">Active</template>
                  <template v-else style="align-items: center;">Not Active</template>
            </span>
            </div>
          </div>
        </div>
        <div class="form-body">
          <div class="form-group row">
            <label class="col-lg-4 col-form-label" >DB Name</label>
            <div class="col-lg-8">
              <input
                  type="text"
                  class="form-control"
                  v-model="vendorInfo2.webtrimAuth.dbName"
                  :disabled="!isStatusActive"
                  autocomplete="one-time-code"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label" >Host</label>
            <div class="col-lg-8">
              <input
                  type="text"
                  v-model="vendorInfo2.webtrimAuth.host"
                  class="form-control"
                  :disabled="!isStatusActive"
                  autocomplete="one-time-code"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label" >Username</label>
            <div class="col-lg-8">
              <input
                  type="text"
                  class="form-control"
                  v-model="vendorInfo2.webtrimAuth.login"
                  :disabled="!isStatusActive"
                  autocomplete="one-time-code"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"
                   >Password</label>
            <div class="col-lg-8">
              <input
                  type="password"
                  v-model="vendorInfo2.webtrimAuth.password"
                  :disabled="!isStatusActive"
                  class="form-control"
                  autocomplete="one-time-code"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.lable-text {
  color: rgba(0, 0, 0, 0.5);
  padding-right: 10px;
  margin-top: 5px;
}
</style>
