<template>
      <div class="invite-repairer-form text-center">
        <div class="intro-text">
          <div class="form">
            <br />
            <br />
            <h1>Find your Business</h1>
            <p>Let us setup your account with your business details quickly and easily. Search and select your business below.</p>
            <br />
            <form method="POST" @submit.prevent="login">
              <div class="form-group custom-multiselect">
              <div class="register-advanced-search">
                <advanced-search ref="test"
                                 v-model="companyInfo.name"
                                 :searchData="options"
                                 :isLoading="isLoading"
                                 :searchKey="'name'"
                                 placeholder="Search for your Business by Name or ABN"
                                 :isCanBeEmpty="true"
                                 :showOnFocus="true"
                                 :isShowNoResult="true"
                                 :noResultText="'No elements found. Consider changing the search query'"
                                 :sortByName="'name'"
                                 @input="companySearchChanges(companyInfo.name)"
                                 @onSelect="onCompanySelect"
                                 :isShowNew="false"
                                 :max-records="options.length"
                />
              </div>

              </div>
              <p class="register-busines-text text-center">
                Can’t find your business?
                <a href="" @click.prevent="onClickCreateBusiness"
                >Continue to create your business here</a>
              </p>
              <div class="form-group">
                <button
                  ref="nextBtn"
                  v-if="companyInfo.name && companyInfo.abn"
                  type="button"
                  @click="nextStep"
                  class="btn input-btn"
                >Next</button>
              </div>
            </form>
          </div>
        </div>
      </div>
</template>
<script>
  import Multiselect from "vue-multiselect";
  import AdvancedSearch from '../../utility/advanced-search';
  import Axios from "axios";
  export default {
    name: "find-business",
    components: {
      multiselect: Multiselect,
      AdvancedSearch,
    },
    data() {
      return {
        companyInfo: {
          name: '',
          abn: '',
        },
        lastTimeout: null,
        lastRequest: null,
        isLoading: false,
        options: [{
          name: null,
          abn: null,
        }],
      };
    },
    methods: {
      onCompanySelect(company) {
        this.companyInfo = company
      },
      onClickCreateBusiness(){
        this.$emit('nextPage', 2)
        this.$emit('changeStatus', false)
      },
      nextStep(){
        this.$refs.nextBtn.innerText = "Loading your company data...";

        Axios.post(
                '/auth/get-company-data',
                {
                  abn: this.companyInfo.abn,
                },
        ).then(res => {
          this.$refs.nextBtn.innerText = "Next";
          if(res.data.status){
            this.$emit('setData', {type: 1, data: res.data.data})
            this.$emit('nextPage', 2)
          }
        }).catch((e) => {
          console.log(e)
          this.$refs.nextBtn.innerText = "Next";
        });

      },
      companySearchChanges(query){
        this.companyInfo.abn = '';
        if (query == ''){
          return;
        }
        if(this.lastTimeout){
          clearTimeout(this.lastTimeout);
          this.isLoading = false;
        }
        if(this.lastRequest){
          this.lastRequest.cancel();
          this.lastRequest = null;
          this.isLoading = false;
        }

        this.lastTimeout = setTimeout(() => {

          this.lastRequest = Axios.CancelToken.source();
          this.isLoading = true;

          Axios.post(
                  '/auth/find-company',
                  {
                    companyName: query
                  },
                  {
                    cancelToken: this.lastRequest.token
                  }
          ).then(res => {
            this.options = res.data;
            this.isLoading = false;
            this.$refs.searchField.$el.focus();
          }).catch((err) => {
            console.warn(err);
            this.isLoading = false;
          });

        }, 500);

      }
    },
    computed: {

    }
  };
</script>

<style lang="css" scoped>
.form {
  max-width: 771px;
  width: 100%;
  margin: 0 auto;
}
.steps-line {
  max-width: 652px;
  width: 100%;
  margin: 0 auto;
}
.form h1 {
  text-align: center;
  margin-bottom: 0px;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 43px;
  color: #1C1F39
}
.invite-repairer-form {
  justify-content: flex-start;
  margin-top: 5px;
}
.form .input-btn {
  max-width: 432px;
  display: table;
  margin: 0 auto;
  margin-top: 22px;
  font-size: 16px;
}
</style>
<style>
@media (max-width: 528px) {
  .register-advanced-search .advanced-search-spinner {
    margin-left: 90%;
  }
}
.register-advanced-search .sr-option {
  text-align: left;
}

.form .custom-multiselect .multiselect__select {
  display: none;
}

.form .custom-multiselect .multiselect__option--highlight {
  background-color: #F3F3F3 !important;
  color: black !important;
}

.form .custom-multiselect .multiselect__placeholder {
  color: rgba(28, 31, 57, 0.5);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

@media screen and (max-width: 405px) {
    .form .custom-multiselect .multiselect__placeholder {
      font-size: 14px !important;
    }
}

@media screen and (max-width: 370px) {
    .form .custom-multiselect .multiselect__placeholder {
      font-size: 10px !important;
    }
}

.invite-repairer .multiselect__input {
  min-width: auto;
}
</style>

<style scoped>
  .auth-header {
    background-color: #1f233d;
    height: 100px;
  }

  .logo-text {
    color: white;
  }

  .container {
    height: 100%;
  }

  .logo {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .steps-line ul {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
  }

  .numb {
    display: none;
  }

  .form hr {
    margin-top: 50px;
    height: 1px;
    background-color: #1f233d;
  }

  .active {
    color: #5E79FF;
    font-weight: bold;
  }

  .custom-multiselect {
    margin: 0 auto;
  }

  .input-btn {
    background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
    width: 100%;
    color: #fff;
    font-weight: bold;
    border: none;
  }

  .register-busines-text {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    color: #1C1F39
  }
</style>
