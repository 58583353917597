import _ from "lodash";
import Axios from "axios";


export const Actions = {
    init() {
    },
    loadInvoices(context, payload = { page: 1, withTimestamp: true, timestamp:0 }) {
        let timestampValue = 0;
        let page = (payload.page)?payload.page:1;

        if(payload.withTimestamp){
            timestampValue = (!payload.timestamp)?context.getters['getTimestamp']:payload.timestamp;
        }

     //   console.log('payload.page', payload.page);
     //   console.log('payload.withTimestamp', payload.withTimestamp);

        let request =  Axios.get("/fe/invoices", {
            params: {
                page: page,
                time: timestampValue
            }
        })
            .then((response) => {
                if (response.data.invoices) {
                    let dataTimestamp = response.data._timestamp;
                    if(dataTimestamp) context.commit('setTimestamp', dataTimestamp);
                    context.commit('loadInvoices', response.data.invoices);
                    if (response.data._isNext && response.data._isNext === true) {
                       return context.dispatch('loadInvoices', {
                           page: (Number(page) + 1),
                           withTimestamp: payload.withTimestamp,
                           timestamp: timestampValue
                       });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        return request;
    },
    deleteInvoiceById(context, id) {
        context.commit('deleteInvoiceById', id);
    },
    getInvoicesForTableView({dispatch, rootGetters, commit }, payload = 0) {
      let page = 1;
      let url = '/fe/invoices/page/';
      if (!_.isEmpty(payload)) url = '/fe/invoices/' + payload + '/page/';

      let loadPage = () => {
        Axios.post(url + page)
          .then(response => {
            commit('invoicesUpdated', response.data.invoices);
            if (response.data._timestamp && response.data._timestamp > 0 && rootGetters['updater/lastTimestamp'] == 0) {
              dispatch('updater/setLastTimestamp', response.data._timestamp, {root: true});
            }
            if (response.data._page) {
              page = response.data._page;
              loadPage();
            }
          })
          .catch(error => {
            console.log(error);
          });
      };
      loadPage();

    }
};
