<script>
import Axios from 'axios';

export default {
    data() {
        return {
            searchTemplateInput: '',
            templates: [],
            showDropdownChild: false,
        };
    },
    computed: {
        filteredTemplates() {
            return this.templates.filter( template => {
                return template.name.toLowerCase().includes(this.searchTemplateInput.trim().toLowerCase());
            });
        }
    },
    created() {
        console.log("dropdownMenu mixed");
        this.loadEstimateTemplates();
    },
    methods: {
        addTemplate() {
            console.log("addTemplate");
        },
        removeTemplate() {
            console.log("removeTemplate");
        },
        loadEstimateTemplates: function () {
            NProgress.start();
            Axios.get(`/fe/estimate-templates`)
                .then(response => {
                    this.templates = response.data.data;
                })
                .catch(error => {
                    toastr.error("Error");
                })
                .finally(()=>{
                    NProgress.done();
                });
        },
    },
};
</script>
ƒ
<style>
/* Dropdown Menu Item */
.dropdown-item.has-child {
    position: relative;
}
.dropdown-item.has-child .dropdown-menu-child {
    display: none;
    position: absolute;
    top: -10px;
    right: 146px;
    width: auto;
}
.dropdown-item.has-child:hover .dropdown-menu-child {
    display: inline-block;
    opacity: 1;
    visibility: visible;
}

.dropdown-menu-child > input {
    border: 1px solid buttonface;
    padding: 2px 7px;
    margin: 0 10px;
}

.dropdown-menu-child ul > li:hover {
    border: 0 !important;
}

.dropdown-menu-child ul > li a {
    display: block;
    padding: 5px 18px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    color: #1C1F39;
}

.dropdown-menu-child ul > li a:hover {
    color: #5E79FF;
    background-color: rgba(94,121,255,0.1);
}

.dropdown-menu-child ul {
    padding: 0;
    margin-top: 10px;
}

.dropdown-menu-child {
    position: absolute;
    z-index: 9999;
    background-color: #ffffff;
    border: 1px solid #bdbdbd;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    padding-top: 15px;
    transition: 0.4s;
    top: 100%;
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
    min-width: 150px;
}
</style>
