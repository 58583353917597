<template>

  <div class="insurers-page table-view search-for-parts-page">
    <div class="page-header">
      <h4>Search for Parts</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item active">Search for Parts</li>
      </ol>
      <h4 class="header-display-name">BETA</h4>
    </div>
    <div class="box-block bg-white cards">

      <div class="form-group row">

        <div class="card">
          <div class="card-block bg-white">
            <div class="col-lg-12">
              <search-filter-multiple
                  type="claims"
                  v-model="searchFilter"
                  :filters="searchFiltersData"
                  :filterValue="filter"
                  :optionsLength="3"
                  @change="onSearchFilterChange"
                  :placeholder="'Enter part name'"
                  :debug="false"
                  height="41px">
              </search-filter-multiple>
            </div>
          </div>
          <div class="card-block bg-white">
            <div class="col-lg-12">
              <div class="bg-white pagination-block-bottom">
                <b-table
                    class="rs-scroll rs-scroll--y noselect"
                    responsive
                    hover
                    :items="computedParts"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="currentPerPage"
                >

                  <template v-slot:cell(rrp)="data">
                    <div>
                      {{ data.item.rrp | formatMoney}}
                    </div>
                  </template>

                  <template v-slot:cell(cost)="data">
                    <div v-if="data.item.cost != null">
                      {{ data.item.cost | formatMoney}}
                    </div>
                    <div v-else>—</div>
                  </template>

                </b-table>
              </div>

              <block-pagination
                  :role="'parts'"
                  :arrayOfSomeone="computedParts"
                  :arrayOfSomeoneFiltered="computedParts"
                  :currentPage="currentPage"
                  @changedCurrentPage="value => currentPage = value"
                  :perPage="currentPerPage"
                  @changedPerPage="value => currentPerPage = value"
              >
              </block-pagination>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from 'lodash';
import SearchFilterMultiple from './search-filter/multiple-search-filter';
import SearchFilter from './search-filter/search-filter';
import BlockPagination from './utility/block-pagination';
import {mapGetters} from "vuex";

export default {
  name: "parts-search-interface",
  components: {
    SearchFilter,
    SearchFilterMultiple,
    BlockPagination,
  },
  data(){
    return {
      parts: [],
      searchFilter: '',
      searchFilterObj: {
        0: {
          search: '',
        }
      },
      filter: '',
      fields: [
        {
          label: "Name",
          key: "name",
          tdClass: 'clickable text-left font-weight-bold td-text',
          thClass: 'bg-navyblue text-left th-text',
          sortable: true
        },
        {
          label: "Number",
          key: "number",
          tdClass: 'clickable text-left font-weight-bold td-text',
          thClass: 'bg-navyblue text-left th-text',
          sortable: true
        },
        {
          label: "Type",
          key: "type",
          tdClass: 'clickable text-left font-weight-bold td-text',
          thClass: 'bg-navyblue text-left th-text',
          sortable: true
        },
        {
          label: "List Price",
          key: "rrp",
          tdClass: 'clickable text-left font-weight-bold td-text',
          thClass: 'bg-navyblue text-left th-text',
          sortable: true
        },
        {
          label: "Cost",
          key: "cost",
          tdClass: 'clickable text-left font-weight-bold td-text',
          thClass: 'bg-navyblue text-left th-text',
          sortable: true
        },
        {
          label: "Vehicle Make",
          key: "vehicle_make",
          tdClass: 'clickable text-left font-weight-bold td-text',
          thClass: 'bg-navyblue text-left th-text',
          sortable: true
        },
        {
          label: "Model",
          key: "vehicle_model",
          tdClass: 'clickable text-left font-weight-bold td-text',
          thClass: 'bg-navyblue text-left th-text',
          sortable: true
        },
        {
          label: "Series",
          key: "vehicle_series",
          tdClass: 'clickable text-left font-weight-bold td-text',
          thClass: 'bg-navyblue text-left th-text',
          sortable: true
        },
      ],
      currentPage: 1,
      currentPerPage: 50,
      isShowNProgress: true,
    };
  },
  mounted() {
    this.debounceInput();
  },
  computed: {
    ...mapGetters({
      getterVehicleMakes: 'getVehicleMakes',
      getterVehicleModels: 'getVehicleModels',
      getterVehicleSeries: 'getVehicleSeries',
    }),
    computedFilters() {
      let make = null;
      let model = null;
      let series = null;
      let query = null;

      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].hasOwnProperty('make')) {
          make = this.searchFilterObj[key].make;
        }
        if (this.searchFilterObj[key].hasOwnProperty('model')) {
          model = this.searchFilterObj[key].model;
        }
        if (this.searchFilterObj[key].hasOwnProperty('series')) {
          series = this.searchFilterObj[key].series;
        }
        if (this.searchFilterObj[key].hasOwnProperty('search')) {
          query = this.searchFilterObj[key].search;
        }
      }

      return {
        query: query,
        model: model,
        series: series,
        make: make,
      }
    },
    computedVehicleSeries() {
      let self = this;
      let series = _.filter(this.getterVehicleSeries, (series) => {
          if (self.computedFilters.model && self.computedFilters.make) {
            return series.make === self.computedFilters.make && series.model === self.computedFilters.model;
          } else if (self.computedFilters.make) {
            return series.make === self.computedFilters.make;
          } else if (self.computedFilters.model) {
            return series.model === self.computedFilters.model;
          } else {
            return true;
          }
      });

      let result = [];
      _.forEach(series, (s) => {
        result.push({
          label: s.series.trim(),
        })
      });
      result = _.orderBy(result,['label'],['asc']);
      result = _.uniqBy(result, 'label');

      return result;
    },
    computedVehicleModels() {
      let self = this;
      let models = _.filter(this.getterVehicleModels, (model) => {
        if (self.computedFilters.make) {
          return model.make === self.computedFilters.make;
        } else {
          return true;
        }
      });

      let result = [];
      _.forEach(models, (m) => {
        result.push({
          label: m.model.trim(),
        })
      });
      result = _.orderBy(result,['label'],['asc']);
      result = _.uniqBy(result, 'label');
      return result;
    },
    computedVehicleMakes() {
      let result = [];
      _.forEach(this.getterVehicleMakes, (m) => {
        result.push({
          label: m.trim(),
        })
      });
      result = _.orderBy(result,['label'],['asc']);

      return result;
    },
    computedParts() {
      let parts = this.parts;
      let self = this;

      if (this.computedFilters.make) {
        parts = _.filter(parts, function (item) {
          return (item.vehicle_make == self.computedFilters.make);
        });
      }

      if (this.computedFilters.model) {
        parts = _.filter(parts, function (item) {
          return (item.vehicle_model == self.computedFilters.model);
        });
      }

      if (this.computedFilters.series) {
        parts = _.filter(parts, function (item) {
          return (item.vehicle_series == self.computedFilters.series);
        });
      }

      return parts;
    },

    searchFiltersData() {
      if (true) {
        let makes = {};
        let models = {};
        let series = {};


        makes = this.computedVehicleMakes;
        models = this.computedVehicleModels;
        series = this.computedVehicleSeries;

        // Create searchFilter filters
        return [
          {
            id: 'make',
            label: 'Make',
            iconClass: 'bx-car',
            options: [
              makes,
            ]
          },
          {
            id: 'model',
            label: 'Model',
            iconClass: 'bxs-network-chart',
            options: [
              models,
            ]
          },
          {
            id: 'series',
            label: 'Series',
            iconClass: 'bxs-key',
            options: [
              series
            ]
          },
        ];
      } else {
        return [];
      }
    }
  },

  methods: {
    debounceInput: _.debounce(function (e) {
      this.partsSearchRun();
    }, 500),
    onSearchFilterChange(filters) {
      this.searchFilterObj = filters;
      this.debounceInput();
      console.log('onSearchFilterChange', filters);
    },
    partsSearchRun(filters) {
      if (this.isShowNProgress) {
        NProgress.start();
      }

      let make = this.computedFilters.make;
      let model = this.computedFilters.model;
      let series = this.computedFilters.series;
      let query = this.computedFilters.query;

      axios.post('/additionals/parts-search-run', { make, model, series, query })
          .then(response => {
            if(response.data._status){
              this.parts = response.data.result.slice();
              console.log(this.parts, 'parts')
            } else {
              this.parts = [];
            }
          })
          .catch(e => console.error(e))
          .finally(() => {
            if (this.isShowNProgress) {
              NProgress.done();
            }
            this.isShowNProgress = false;
          });

    }
  }
}
</script>

<style>
.V3 .search-for-parts-page .search-filter__input {
  height: 33px;
  width: 100%;
}
.V3 .search-for-parts-page .dropdown-menu.search-filter__dropdown-menu {
  margin-top: 10px;
  overflow: overlay !important;
  border: 1px solid rgba(0,0,0,.15);
}

.V3 .search-for-parts-page .dropdown {
  display: block;
}

.V3 .search-for-parts-page .dropdown-item:focus, .dropdown-item:hover{
  color: #5E79FF;
  background-color: rgba(94, 121, 255, 0.1);
}

.V3 .search-for-parts-page .table.b-table > thead > tr th{
  vertical-align: middle;
}
</style>
