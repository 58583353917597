<script>
    import Axios from 'axios';
    import FileUploader from '../../utility/file-uploader';
    import GoogleLocation from '../../utility/google-location';
    import Email from '../../utility/email-validator';
    import AddressSuggestions from '../../utility/address-suggestions';
    import {appConfig} from "../../../config";
    import VueDropzone from "vue2-dropzone";
    import {mapGetters} from "vuex";
    import Multiselect from "vue-multiselect";
    import _ from "lodash";

    export default {
        name: 'business',
        data: function () {
            return {
                isCheckedEmail: true,
                optionsForBusinessTypes: [],
                updateDataByMultiselect: {
                  postal_state: '',
                  physical_state: '',
                  postal_country: '',
                  business_type_id: '',
                  postal_contry: '',
                  physical_country: '',
                },
                optionsForState: [
                  {value: 'Select your state', key: '',},
                  {value: 'Queensland', key: 'QLD',},
                  {value: 'Northern Territory', key: 'NT',},
                  {value: 'New South Wales', key: 'NSW',},
                  {value: 'Victoria', key: 'VIC',},
                  {value: 'Tasmania', key: 'TAS',},
                  {value: 'South Australia', key: 'SA',},
                  {value: 'Western Australia', key: 'WA',},
                ],
                optionsForCountry: [
                  {value: 'Select your country', key: '',},
                  {value: 'Australia', key: 'AU',},
                  {value: 'New Zealand', key: 'NZ',},
                ],
                intervalUpdateData: null,
                logoImage: null,
                vendorInfoLoaded: false,
                same_as_postal: false,
                addressOptions: {
                    type: "ADDRESS",
                    scrollOnFocus: false,
                    triggerSelectOnBlur: false,
                    triggerSelectOnEnter: false,
                    addon: 'none',
                    url: appConfig.baseAPIURL + '/fe/suggestions',
                    headers: {"Authorization": localStorage.getItem('token')},
                    hint: ' ',
                    noSuggestionsHint: ' ',
                },
                companyLogoDropzoneOptions: {
                    headers: {Authorization: localStorage.getItem("token")},
                    resizeWidth: 900,
                    resizeQuality: 0.7,
                    url: appConfig.baseAPIURL + "/fe/vendor/photo/upload",
                    method: "post",
                    maxFilesize: 50,
                    // maxFiles: 2,     //Can be used to limit the maximum number of files that will be handled by this Dropzone
                    thumbnailWidth: 200,
                    timeout: 300000,
                    addRemoveLinks: false,
                    acceptedFiles: "image/jpeg, image/jpg, image/png",
                    clickable: ['.browse-btn'],
                    dictFileTooBig:
                        "Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again"
                }

            };
        },
        methods: {
            updatedDemoStatus() {
                if (!this.isStatusActive) {
                  return;
                }
                this.value.demo.status = !this.value.demo.status;
                if (!this.value.demo.status) {
                    this.$refs.enableDemoData.show();
                } else {
                    this.$refs.disableDemoData.show();
                }
            },
            confirmedUpdateDemoStatus() {
                let status = null;
                if (!this.value.demo.status) {
                    this.value.demo = {status: true};
                    this.$refs.enableDemoData.hide();
                    status = true;
                } else {
                    this.$refs.disableDemoData.hide();
                    this.value.demo = {status: false};
                    status = false;
                }
                NProgress.start();
                Axios.post('/fe/settings/demo-data', {demo: this.value.demo})
                    .then(response => {
                        if (response.data._status) {
                            setTimeout(() => {
                                //this.$router.go()
                                location.reload();
                            }, 2000);
                        }
                    })
                    .catch(error => {
                        toastr.error(error);
                    }).finally(() => {
                    NProgress.done();
                });
            },
            onPhysicalAddressSelect: function (address) {
                if (address && address.status === true) {
                    if (address.country) {
                        this.vendorInfo.physical_country = address.country;
                    } else {
                        this.vendorInfo.physical_country = '';
                    }
                    this.vendorInfo.physical_address = address.fullAddress;
                    if (address.unit) {
                        this.vendorInfo.physical_level_unit_lot = address.unit;
                    } else {
                        this.vendorInfo.physical_level_unit_lot = '';
                    }
                    if (address.postal_code) {
                        this.vendorInfo.physical_postcode = address.postal_code;
                    } else {
                        this.vendorInfo.physical_postcode = '';
                    }
                    if (address.state) {
                        this.vendorInfo.physical_state = address.state;
                    } else {
                        this.vendorInfo.physical_state = '';
                    }
                    if (address.suburb) {
                        this.vendorInfo.physical_suburb = address.suburb;
                    } else {
                        this.vendorInfo.physical_suburb = '';
                    }
                    if (address.street_number && address.street) {
                        this.vendorInfo.physical_street = address.street_number + ' ' + address.street;
                    } else if (address.street_number) {
                        this.vendorInfo.physical_street = address.street_number;
                    } else if (address.street) {
                        this.vendorInfo.physical_street = address.street;
                    } else {
                        this.vendorInfo.physical_street = '';
                    }
                }
            },
            onPostalAddressSelect: function (address) {
                if (address && address.status === true) {
                    if (address.country) {
                        this.vendorInfo.postal_country = address.country;
                    } else {
                        this.vendorInfo.postal_country = '';
                    }
                    this.vendorInfo.postal_address = address.fullAddress;
                    if (address.unit) {
                        this.vendorInfo.postal_level_unit_lot = address.unit;
                    } else {
                        this.vendorInfo.postal_level_unit_lot = '';
                    }
                    if (address.postal_code) {
                        this.vendorInfo.postal_postcode = address.postal_code;
                    } else {
                        this.vendorInfo.postal_postcode = '';
                    }
                    if (address.state) {
                        this.vendorInfo.postal_state = address.state;
                    } else {
                        this.vendorInfo.postal_state = '';
                    }
                    if (address.suburb) {
                        this.vendorInfo.postal_suburb = address.suburb;
                    } else {
                        this.vendorInfo.postal_suburb = '';
                    }
                    if (address.street_number && address.street) {
                        this.vendorInfo.postal_street = address.street_number + ' ' + address.street;
                    } else if (address.street_number) {
                        this.vendorInfo.postal_street = address.street_number;
                    } else if (address.street) {
                        this.vendorInfo.postal_street = address.street;
                    } else {
                        this.vendorInfo.postal_street = '';
                    }

                }
            },
            updateInfo: function () {
                if (!this.isCheckedEmail) {
                    toastr.error('Your changes could not be saved, please check the form fields again');
                    return;
                }
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: '/fe/vendor/info',
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify({data: this.updatedVendorInfo, logo_image: ''})
                })
                // Axios.post('/fe/vendor/info', JSON.stringify({data: this.updatedVendorInfo, logo_image: this.logo_image}))
                    .then(response => {
                        NProgress.done();
                        if (response.status == 200) {
                            toastr.success(response.data.msg);
                            this.$store.dispatch('loadVendorInfo');
                        } else {
                            toastr.error(response.data.msg);
                        }
                    })
                    .catch(error => {
                        NProgress.done();
                        toastr.error(error);
                    });
            },
            addImgUpload($event) {
                $event.previewElement.children[
                $event.previewElement.children.length - 1
                    ].style.display = "none";
            },
            completeImgUpload($event) {
                $event.previewElement.children[
                $event.previewElement.children.length - 1
                    ].removeAttribute("style");
            },
            sendImgUpload($event) {
                $event.previewElement.children[
                $event.previewElement.children.length - 1
                    ].style.display = "none";
            },
            successImgUploadBusinessProfile(file, response) {
                if (response.status) {
                    this.logoImage = file.dataURL;
                    this.$store.dispatch('loadVendorInfo');
                } else {
                    setTimeout(() => {
                        this.$refs.imgUploadDropBusiness.removeFile(file);
                    }, 1000);
                }
            },
            clickDeleteCompanyLogo: function () {
                NProgress.start();
                Axios.post('/fe/vendor/photo/delete').then(response => {
                    toastr.success('Company logo has been removed');
                    this.logoImage = '';
                    this.$store.dispatch('loadVendorInfo');
                }).catch(error => {
                    toastr.error('Company logo has not been removed');
                }).finally(() => {
                    NProgress.done();
                });
            }
        },
        props: {
            value: {},
        },
        computed: {
            ...mapGetters([
                'vendorInfo',
                'isShopManagerUser',
                'isStatusActive',
            ]),
            computedPostalState: {
              get: function () {
                let vm = this;
                try {
                  let item = _.find(this.optionsForState, function (itm) {
                    return itm.key == vm.updateDataByMultiselect.postal_state;
                  });
                  return item;
                } catch (e) {
                  return {};
                }
              },
              set: function (item) {
                if (item) {
                  this.updateDataByMultiselect.postal_state = item.key;
                }
              },
            },
            computedPhysicalState: {
              get: function () {
                let vm = this;
                try {
                  let item = _.find(this.optionsForState, function (itm) {
                    return itm.key == vm.updateDataByMultiselect.physical_state;
                  });
                  return item;
                } catch (e) {
                  return {};
                }
              },
              set: function (item) {
                if (item) {
                  this.updateDataByMultiselect.physical_state = item.key;
                }
              },
            },
            computedPostalCountry: {
              get: function () {
                let vm = this;
                try {
                  let item = _.find(this.optionsForCountry, function (itm) {
                    return itm.key == vm.updateDataByMultiselect.postal_country;
                  });
                  return item;
                } catch (e) {
                  return {};
                }
              },
              set: function (item) {
                if (item) {
                  this.updateDataByMultiselect.postal_country = item.key;
                }
              },
            },
            computedPhysicalCountry: {
              get: function () {
                let vm = this;
                try {
                  let item = _.find(this.optionsForCountry, function (itm) {
                    return itm.key == vm.updateDataByMultiselect.physical_country;
                  });
                  return item;
                } catch (e) {
                  return {};
                }
              },
              set: function (item) {
                if (item) {
                  this.updateDataByMultiselect.physical_country = item.key;
                }
              },
            },
            businessTypes: function () {
                return this.$store.state.businessTypes;
            },
            updatedVendorInfo: function () {
                return {
                    vendor_id: this.vendorInfo.vendor_id,
                    display_name: this.vendorInfo.display_name,
                    entity_name: this.vendorInfo.entity_name,
                    business_name: this.vendorInfo.business_name,
                    abn: this.vendorInfo.abn,
                    phone: this.vendorInfo.phone,
                    phone_area_code: this.vendorInfo.phone_area_code,
                    email: this.vendorInfo.email,
                    location: this.vendorInfo.location,
                    business_type_id: this.updateDataByMultiselect.business_type_id,
                    license_number: this.vendorInfo.license_number,
                    branch: this.vendorInfo.branch,
                    org_description: this.vendorInfo.org_description,

                    postal_address: this.vendorInfo.postal_address,
                    postal_level_unit_lot: this.vendorInfo.postal_level_unit_lot,
                    postal_street: this.vendorInfo.postal_street,
                    postal_suburb: this.vendorInfo.postal_suburb,
        postal_state: this.updateDataByMultiselect.postal_state,
                    postal_postcode: this.vendorInfo.postal_postcode,
        postal_country: this.updateDataByMultiselect.postal_country,

                    physical_address: this.vendorInfo.physical_address,
                    physical_level_unit_lot: this.vendorInfo.physical_level_unit_lot,
                    physical_street: this.vendorInfo.physical_street,
                    physical_suburb: this.vendorInfo.physical_suburb,
        physical_state: this.updateDataByMultiselect.physical_state,
                    physical_postcode: this.vendorInfo.physical_postcode,
        physical_country: this.updateDataByMultiselect.physical_country,

                    website: this.vendorInfo.website,
                    logo_picture: this.vendorInfo.logo
                };
            },
            drOptions: function () {
                return {
                    defaultFile: this.vendorInfo.logo_picture ? '/img/brands/' + this.vendorInfo.logo_picture : '',
                    messages: {
                        'default': 'Drag and drop an image here or click',
                        'replace': 'Drag and drop or click to add new image',
                        'remove': 'Remove',
                        'error': 'Ooops, something went wrong.'
                    }
                };
            }
        },
        beforeDestroy() {
          clearInterval(this.intervalUpdateData)
        },
        mounted: function () {
            this.$store.dispatch('loadVendorInfo');
            this.$store.dispatch('loadBusinessTypes');
            this.drOptions.defaultFile = this.updatedVendorInfo.logo_picture;
            this.intervalUpdateData = setInterval(() => {
              if (!_.isEmpty(this.vendorInfo)  && !_.isEmpty(this.businessTypes)) {
                this.updateDataByMultiselect.postal_country = this.vendorInfo.postal_country;
                this.updateDataByMultiselect.postal_state = this.vendorInfo.postal_state;
                this.updateDataByMultiselect.business_type_id = this.vendorInfo.business_type_id;
                this.updateDataByMultiselect.physical_state = this.vendorInfo.physical_state;
                this.updateDataByMultiselect.physical_country = this.vendorInfo.physical_country;
                let self = this;
                _.forEach(this.businessTypes, (bt) => {
                  self.optionsForBusinessTypes.push({key: bt.business_type_id, value: bt.type_name});
                });
                clearInterval(this.intervalUpdateData)
              }
            }, 100)
        },
        watch: {
            vendorInfo(val) {
                this.logoImage = val.logo;
                this.vendorInfoLoaded = true;
            },
            same_as_postal: function () {
                if (this.same_as_postal) {
                    this.updatedVendorInfo.postal_address = this.updatedVendorInfo.physical_address;
                    this.updatedVendorInfo.postal_level_unit_lot = this.updatedVendorInfo.physical_level_unit_lot;
                    this.updatedVendorInfo.postal_street = this.updatedVendorInfo.physical_street;
                    this.updatedVendorInfo.postal_suburb = this.updatedVendorInfo.physical_suburb;
                    this.updatedVendorInfo.postal_state = this.updatedVendorInfo.physical_state;
                    this.updatedVendorInfo.postal_postcode = this.updatedVendorInfo.physical_postcode;
                    this.updatedVendorInfo.postal_country = this.updatedVendorInfo.physical_country;
                }
            }
        },
        components: {
            FileUploader,
            GoogleLocation,
            Multiselect,
            Email,
            AddressSuggestions,
            VueDropzone
        }
    };
</script>

<template>
    <div>
        <div class="row settings-page">
            <div class="col-md-5 float-md-right mb-1">
                <div class="w-100" v-if="vendorInfoLoaded">
                    <div v-if="vendorInfo.logoFileId > 0">
                        <div class="company-logo w-100">
                            <img :src="logoImage" class="company-logo w-100"
                                 alt="Company Logo"/>
                        </div>
                        <button
                                @click="clickDeleteCompanyLogo"
                                type="button"
                                class="btn btn-danger w-100 mt-2"
                        >Delete Logo
                        </button>
                    </div>
                    <vue-dropzone
                            v-else
                            v-on:vdropzone-file-added="addImgUpload"
                            v-on:vdropzone-success="successImgUploadBusinessProfile"
                            v-on:vdropzone-complete="completeImgUpload"
                            v-on:vdropzone-sending="sendImgUpload"
                            ref="imgUploadDropBusiness"
                            id="imgUploadDropBusiness"
                            class="dropify-wrapper dropzoneContainer"
                            style="height: auto;"
                            :options="companyLogoDropzoneOptions"
                            :useCustomSlot="true"
                    >
                        <div class="dropify-message" style="transform: translateY(0%);">
                            <i class="
                            bx bx-cloud-upload"></i>
                            <p class="drop-down-text">Drag and Drop Logo Here</p>
                            <p class="text-or mb-0-5">Or</p>
                            <div class="text-sm-center mb-1 ex-image-upload-buttons">
                                <button
                                        type="button"
                                        class="btn btn-primary waves-effect waves-light browse-btn"
                                >Browse Logo
                                </button>
                            </div>
                        </div>
                    </vue-dropzone>
                </div>
                <div v-if="isShopManagerUser" class="card" style="margin-top: 30px">
                    <div class="card-header">Demo Data</div>
                    <div class="card-block m-0 pl-0 pr-0 mb-0 pb-0">
                        <div class="form-group row">
                            <label class="col-sm-6 col-form-label">
                                <p>Enable Demo Data</p>
                                <p style="color: rgba(0, 0, 0, 0.5);"> Enable Demo data to use with your Trial of RepairShop. Disable to remove this data when your ready to Use Repairshop in your
                                    business</p>
                            </label>
                            <div class="col-sm-6">
                                <div class="d-flex" style="margin-top: 40px">
                                    <toggle-button v-model="value.demo.status"
                                                   @input="updatedDemoStatus"
                                                   id="ps-enabled-input"
                                                   class="mb-0 rs-toggle__button"
                                                   color="#5E79FF"
                                                   :sync="true"
                                                   :width="40"
                                                   :labels="false"/>
                                    <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                <template v-if="value.demo.status">Enable</template>
                                <template v-else>Disable</template>
                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-7 float-md-left">
                <div class="card">
                    <div class="card-header">Business Details</div>
                    <div class="card-block m-0 pl-0 pr-0 mb-0 pb-0">
                        <div class="form-group row">
                            <label for="display_name" class="col-sm-3 col-form-label">Display
                                Name</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.display_name"
                                       class="form-control" id="display_name"
                                       placeholder="Display Name">
                            </div>
                        </div>
                        <div class="form-group row">
                          <label for="display_name" class="col-sm-3 col-form-label">Entity Name</label>
                          <div class="col-sm-9">
                            <input type="text" v-model="updatedVendorInfo.entity_name"
                                   class="form-control" id="entity_name"
                                   placeholder="Entity Name">
                          </div>
                        </div>
                        <div class="form-group row">
                            <label for="business_name" class="col-sm-3 col-form-label">Legal/Trading
                                Name</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.business_name"
                                       class="form-control" id="business_name"
                                       placeholder="Business Name">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="business_type" class="col-sm-3 col-form-label">Business
                                Type</label>
                            <div class="col-sm-9">
                                <!--<input type="text" class="form-control" id="business_type" placeholder="Display Name">-->
                <multiselect
                    v-model="updateDataByMultiselect.business_type_id"
                    :options="optionsForBusinessTypes.map(op => op.key)"
                    :custom-label="opt => optionsForBusinessTypes.find(x => x.key == opt).value"
                    :showLabels="false"
                    :option-height="29"
                    :max-height="203"
                    :close-on-select="true"
                ></multiselect>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="license_number" class="col-sm-3 col-form-label">Repairer License
                                Number</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.license_number"
                                       class="form-control" id="license_number"
                                       placeholder="Repairer License Number">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="abn" class="col-sm-3 col-form-label">Australian Business Number
                                (ABN)</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.abn" class="form-control"
                                       id="abn" placeholder="ABN Number">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="branch" class="col-sm-3 col-form-label">Branch (If
                                Applicable)</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.branch"
                                       class="form-control" id="branch" placeholder="Branch">
                            </div>
                        </div>
                        <div class="form-group row mb-3">
                            <label for="org_description" class="col-sm-3 col-form-label">Organisation
                                Description</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.org_description"
                                       class="form-control" id="org_description"
                                       placeholder="Organisation Description">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">Contact Details</div>
                    <div class="card-block m-0 pl-0 pr-0 mb-0 pb-0">
                        <div class="form-group row">
                            <label for="postal_address"
                                   class="col-sm-12 pt-0 mt-0 pb-0 mb-0 col-form-label text-uppercase text-grey">
                                Postal Address
                            </label>
                        </div>
                        <div class="form-group row">
                            <label for="postal_address" class="col-sm-3 col-form-label">Postal Address</label>
                            <div class="col-sm-9">

                                <address-suggestions
                                        type="text"
                                        :refsAddress="'modalContactForm'"
                                        :model.sync="updatedVendorInfo.physical_address"
                                        id="postal_address"
                                        placeholder="Search for an Address"
                                        :options="addressOptions"
                                        @onSelect="onPhysicalAddressSelect"
                                >
                                </address-suggestions>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="postal_level_unit_lot" class="col-sm-3 col-form-label">Level/Unit/Lot
                                Nbr</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.physical_level_unit_lot"
                                       class="form-control" id="postal_level_unit_lot"
                                       placeholder="Lot, Unit, or Level number">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="postal_street" class="col-sm-3 col-form-label">Street
                                Nbr/Name</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.physical_street"
                                       class="form-control" id="postal_street"
                                       placeholder="Street Number or Name">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="postal_suburb" class="col-sm-3 col-form-label">Suburb</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.physical_suburb"
                                       class="form-control" id="postal_suburb" placeholder="Suburb">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="postal_state" class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                                <!--<input type="text" class="form-control" id="state" placeholder="State">-->
                            <multiselect
                                v-model="computedPhysicalState"
                                :options="optionsForState"
                                :showLabels="false"
                                :option-height="29"
                                :max-height="203"
                                :close-on-select="true"
                                track-by="key"
                                label="value"
                            ></multiselect>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <label for="postal_postcode"
                                   class="col-sm-3 col-form-label">Postcode</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.physical_postcode"
                                       class="form-control" id="postal_postcode" placeholder="Postcode">
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <label for="postal_country" class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                            <multiselect
                                v-model="computedPhysicalCountry"
                                :options="optionsForCountry"
                                :showLabels="false"
                                :option-height="29"
                                :max-height="203"
                                :close-on-select="true"
                                track-by="key"
                                label="value"
                            ></multiselect>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="postal_address"
                                   class="col-sm-12 pt-0 mt-0 pb-0 mb-0 col-form-label text-uppercase text-grey">
                                Business Address
                            </label>
                        </div>

                        <div class="form-group row">
                            <label for="physical_address" class="col-sm-3 col-form-label">Business Address</label>
                            <div class="col-sm-9">
                                <input v-model="same_as_postal" class="checkbox-details"
                                       type="checkbox">
                                <span class="checkbox-details-label">Same as postal address</span>
                                <address-suggestions
                                        type="text"
                                        :refsAddress="'modalContactForm'"
                                        :model.sync="updatedVendorInfo.postal_address"
                                        id="physical_address"
                                        placeholder="Search for an Address"
                                        :options="addressOptions"
                                        @onSelect="onPostalAddressSelect"
                                >
                                </address-suggestions>

                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="physical_level_unit_lot" class="col-sm-3 col-form-label">Level/Unit/Lot
                                Nbr</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.postal_level_unit_lot"
                                       class="form-control" id="physical_level_unit_lot"
                                       placeholder="Lot, Unit, or Level number">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="physical_street" class="col-sm-3 col-form-label">Street
                                Nbr/Name</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.postal_street"
                                       class="form-control" id="physical_street"
                                       placeholder="Street Number or Name">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="physical_suburb" class="col-sm-3 col-form-label">Suburb</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.postal_suburb"
                                       class="form-control" id="physical_suburb" placeholder="Suburb">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="physical_state" class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                                <!--<input type="text" class="form-control" id="state" placeholder="State">-->
                            <multiselect
                                v-model="computedPostalState"
                                :options="optionsForState"
                                :showLabels="false"
                                :option-height="29"
                                :max-height="203"
                                :close-on-select="true"
                                track-by="key"
                                label="value"
                            ></multiselect>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <label for="physical_postcode"
                                   class="col-sm-3 col-form-label">Postcode</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.postal_postcode"
                                       class="form-control" id="physical_postcode"
                                       placeholder="Postcode">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="physical_country"
                                   class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                            <multiselect
                                  v-model="computedPostalCountry"
                                  :options="optionsForCountry"
                                  :showLabels="false"
                                  :option-height="29"
                                  :max-height="203"
                                  :close-on-select="true"
                                  track-by="key"
                                  label="value"
                              ></multiselect>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Business
                                Email</label>
                            <email
                                :email="updatedVendorInfo.email"
                                @onChange="(value) => updatedVendorInfo.email = value"
                                @checked="(value) => isCheckedEmail = value"
                                class="col-sm-9"
                            ></email>
                        </div>
                        <div class="form-group row mb-0 pb-0">
                            <label for="vendor_phone" class="col-sm-3 col-form-label">Phone</label>
                            <div class="col-sm-9 m-0 p-0">
                                <div class="row m-0 p-0">
                                    <div class="col-xs-2 mr-0 pr-0"><input type="text"
                                                                           v-model="updatedVendorInfo.phone_area_code"
                                                                           class="form-control" id="area_code"
                                                                           placeholder="Area Code"></div>
                                    <div class="col-xs-10"><input type="text"
                                                                  v-model="updatedVendorInfo.phone"
                                                                  class="form-control" id="vendor_phone"
                                                                  placeholder="Number"></div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group row">
                            <label for="vendor_location" class="col-sm-3 col-form-label">Location</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.vendor_location" class="form-control" id="vendor_location" placeholder="Location">
                            </div>
                        </div> -->
                        <div class="form-group row">
                            <label for="website" class="col-sm-3 col-form-label">Website</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="updatedVendorInfo.website"
                                       class="form-control"
                                       id="website" placeholder="Website">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <button type="button" @click="updateInfo()"
                                        class="btn btn-primary w-min-sm mb-0-25 waves-effect waves-light pull-right w-min-lg">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-wizard ref="enableDemoData" cancelButtonText="Cancel" finishButtonText="Ok" @on-complete="confirmedUpdateDemoStatus" class="demo-data-modal">
            <modal-wizard-tab title="Enable Demo data" description="" title-icon-class="ti-align-right">
                <template>
                    <div class="pt-2 pb-2">Do you want to Enable Demo data? <br>This operation may take several minutes.</div>
                </template>
            </modal-wizard-tab>
        </modal-wizard>

        <modal-wizard ref="disableDemoData" cancelButtonText="Cancel" finishButtonText="Ok" @on-complete="confirmedUpdateDemoStatus" class="demo-data-modal">
            <modal-wizard-tab title="Turn off Demo data" description="" title-icon-class="ti-align-right">
                <template>
                    <div class="pt-2 pb-2">Do you want to Remove Demo data? <br>This operation may take several minutes.</div>
                </template>
            </modal-wizard-tab>
        </modal-wizard>
    </div>
</template>
<style>
    .demo-data-modal .modal-content {
        width: 300px;
    }

    @media (min-width: 576px) {
        .demo-data-modal .modal-content {
            width: 450px;
            margin: 0 auto;
        }
    }

    .checkbox-details {
        width: 25px;
        height: 25px;
    }

    .checkbox-details-label {
        position: relative;
        display: inline-block;
        bottom: 8px;
  padding-left: 5px;
    }

    @media (min-width: 767px) {
        .demo-data-modal .modal {
            left: 0 !important;
            top: 0 !important
        }
    }

    @media (min-width: 576px) {
        .demo-data-modal .modal-dialog {
            margin: 370px auto;
        }
    }
</style>
