<template>
  <div style="margin-top: 15px; padding-left: 15px" class="card row no-gutter">
    <div class="col-lg-5 col-12 ">
      <div class="card-header bg-navyblue">
        <strong>Partssearch</strong>
      </div>
      <div class="card-block bg-white">
        <div class="form-group row no-gutter">
          <label for="partssearch-api-input"
                 tabindex="0"
                 class="col-sm-5 col-form-label">API Key</label>
          <div class="col-sm-7">
            <input id="partssearch-api-input"
                   class="form-control"
                   :readonly="!isStatusActive"
                   placeholder="API Key"
                   v-model="value.partssearch.api_key">
          </div>
        </div>

        <div class="form-group row no-gutter rs-toggle">
          <label class="col-sm-5 col-form-label"
                 for="ps-enabled-input">
            Active
          </label>
          <div class="col-sm-7">
            <div class="d-flex">
              <toggle-button v-model="value.partssearch.enabled"
                             id="ps-enabled-input"
                             :disabled="!isStatusActive"
                             class="mb-0 rs-toggle__button"
                             color="#5E79FF"
                             :sync="true"
                             :width="40"
                             :labels="false"/>
              <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                <template v-if="value.partssearch.enabled">Active</template>
                                <template v-else>Not Active</template>
                            </span>
            </div>
          </div>
        </div>

        <div class="form-group row no-gutter">
          <label tabindex="0" class="col-sm-5 col-form-label" >
            Default Location
            <p style="color: rgba(0, 0, 0, 0.5); padding-right: 25px; margin-top: 5px; text-align: justify">Choose the default Shop location from the Partssearch shop locations in your Account. Shop locations can be added automatically with each request for Pricing.</p>
          </label>
          <div class="col-sm-7">
            <multiselect
                v-model="computedLocation"
                :options="locationsOptions"
                :showLabels="false"
                :option-height="29"
                :max-height="203"
                placeholder="Locations"
                :close-on-select="true"
                track-by="id"
                label="name"
            ></multiselect>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import Multiselect from "vue-multiselect";

export default {
    name: "PartsSearch",
    props: {
      value: {}
    },
    components: {
      Multiselect,
    },
    computed: {
      ...mapGetters({
        isStatusActive: 'isStatusActive',
        getterPartsSearchLocations: 'getPartsSearchLocations',
      }),
      computedLocation: {
        get: function () {
          let vm = this;
          try {
            let item = _.find(this.locationsOptions, function (itm) {
              return Number(itm.id) === Number(vm.value.partssearch.partssearchDefaultLocation);
            });
            return item;
          } catch (e) {
            return {};
          }
        },
        set: function (item) {
          if (item) {
            this.value.partssearch.partssearchDefaultLocation = item.id;
          }
        }
      },
      locationsOptions() {
        if (!this.getterPartsSearchLocations) {
          return  []
        }
        return this.getterPartsSearchLocations;
      },
    },
}
</script>

<style scoped>

</style>
