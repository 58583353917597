import { render, staticRenderFns } from "./car-scheme-sedan-svg.vue?vue&type=template&id=27630de7&scoped=true"
import script from "./car-scheme-sedan-svg.vue?vue&type=script&lang=js"
export * from "./car-scheme-sedan-svg.vue?vue&type=script&lang=js"
import style0 from "./car-scheme-sedan-svg.vue?vue&type=style&index=0&id=27630de7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27630de7",
  null
  
)

export default component.exports