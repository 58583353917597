<template>
  <div>
    <div class="toast-header-wrapper">
      <span>{{toastTitle}}</span>
      <div class="toast-header-buttons">
        <button @click="$emit('dismissAll')" class="dismiss-all">Dismiss All</button>
        <span class="close-toast">×</span>
      </div>
    </div>
    <div class="mt-1">
      <span class="toast-text">{{toastText}}</span>
    </div>
  </div>
 
 
</template>

<script>
export default {
  name: "toast-template",
  props: {
    toastText: {
      type: String,
      default: '',
    },
    toastTitle: {
      type: String,
      default: '',
    }
  }
}
</script>

<style scoped>
  .toast-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toast-header-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 50px;
  }

  .close-toast {
    cursor: pointer !important;
    margin-left: 30px;
    padding-bottom: 5px;
  }
</style>
