export const Getters = {
  isShowReportsList: state => {
    return state.isShowReportsList;
  },

  selectedOption: state => {
    return state.selectedOption;
  },

  getEPS: state => {
    return state.estimate.processedSummary;
  },
  getAEV: state => {
    return state.estimate.averageValue;
  },
  getEVS: state => {
    return state.estimate.valueSummary;
  },
  getEINS: state => {
    return state.estimate.valuesByInsurerAndNonInsuranceSummary;
  },
  getEVI: state => {
    return state.estimate.valuesByInsurer;
  },


  getIPS: state => {
    return state.invoice.processedSummary;
  },
  getAIV: state => {
    return state.invoice.averageValue;
  },
  getIVS: state => {
    return state.invoice.valueSummary;
  },
  getIINS: state => {
    return state.invoice.valuesByInsurerAndNonInsuranceSummary;
  },
  getIVI: state => {
    return state.invoice.valuesByInsurer;
  }
}
