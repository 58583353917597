<template>
    <div>
    <span @click="showOrHideOptions" class="text-grey" :class="{'display-none': isNew}" style="position: absolute; top: 15px; transform:translateY(-50%); right: 2rem;">
      <i class="fa" :class="{'fa-angle-down': !show, 'fa-angle-up': show}"></i>
    </span>
        <span :class="{'display-none': !isNew, 'text-grey': true}" style="position:absolute;top: 50%; transform:translateY(-50%);right:2rem;">NEW</span>
        <template v-if="isActiveLookup || isActiveLookupAlwaysOn || isActiveLookupWithCompliance">
            <div class="">
                <input
                        ref="asbLookup"
                        :placeholder="placeholder"
                        :id="id"
                        :class="{'lookup-text': isChangeLookupText}"
                        :disabled="isDisabled"
                        @keyup="onKeyUp"
                        @keyup.enter.prevent="selectedRego"
                        v-model="asbValue"
                        class="form-control"
                        type="text"
                        style="opacity: 1;"
                >
                <vue-simple-spinner v-if="isLoadingData && asbValue" :size="23" class="input-spinner" ></vue-simple-spinner>
            </div>
        </template>
        <template v-else>
            <input ref="asb" type="text" v-model="asbValue" class="form-control" @keypress="keyPress" @keydown="keyDown" @keydown.down.prevent="keyDownPrevent" @keydown.up.prevent="keyUp"
                   @keyup.enter.prevent="keyEnter" style="opacity: 1"
                   @keyup.esc.prevent="onEscape" @blur="onBlur" @focus="onFocus" :disabled="isDisabled" :placeholder="placeholder" :id="id" @change="onChange"
            >
        </template>
        <span :class="{'error-text-rego': !isVINField, 'error-text-vin': isVINField}" v-if="isShowError"><span class="lookup-text">-</span> No Rego Found</span>
        <div v-if="show" :class="{'search-box-container': true}">
            <div class="search-box">
                <div class="search-results">
                    <ul class="sr-options" v-if="isActiveLookupAlwaysOn || isActiveLookup || isActiveLookupWithCompliance">
                        <li v-if="(isActiveLookup || isActiveLookupAlwaysOn || isActiveLookupWithCompliance) && resultForLookup "
                            ref="resultLookup"
                            @click="selectedRego"
                            @keyup.enter.prevent="selectedRego"
                            :class="{'selected': true}"
                            class="lookup-text sr-option">
                            <strong>{{resultForLookup}}</strong>
                        </li>
                        <li v-if="(isActiveLookup || isActiveLookupWithCompliance) && isShowNewLookupMessage && searchLabel && asbValue && asbValueExists === -1"
                            :class="{'selected': false, 'sr-option': true}"
                            style="cursor: unset;">+ New {{searchLabel}} <strong :class="{'lookup-text-gray': isChangeLookupText, 'lookup-text': !isChangeLookupText}" @click="searchLookup(asbValue)"
                                                                                 style="cursor: pointer">LOOKUP NEVDIS</strong></li>
                    </ul>
                    <ul v-if="searchKey" class="sr-options" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
                        <li :class="{'selected': index == currentPosition, 'sr-option': true, 'lookup-text': !sr[key].includes('  ( -  -  - )') && sr[key].split(' ')[0].toLowerCase() === asbValue.toLowerCase()}" v-for="(sr, index) in searchResult" @mouseover="mouseOver(index)" @click="keyEnter"
                            v-html="sr[key].replace(regTerm, '<b>$&</b>')"></li>
                        <li v-if="searchLabel && asbValue && asbValueExists === -1 && isShowNew && searchResult.length === 0"
                            :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}"
                            @mouseover="mouseOver(searchResultCount)" @click="keyEnter">+ New {{searchLabel}}<br/><strong>{{asbValue}}</strong></li>
                    </ul>
                    <ul v-if="!searchKey"
                        @mouseenter="mouseEnter"
                        @mouseleave="mouseLeave"
                        class="sr-options">
                        <li v-for="(sr, index) in searchResult"
                            @mouseover="mouseOver(index)"
                            @click="keyEnter"
                            v-html="sr.replace(regTerm, '<b>$&</b>')"
                            :class="{'selected': index == currentPosition, 'sr-option': true, 'lookup-text': !sr.includes('  ( -  -  - )') && sr.split(' ')[0].toLowerCase() === asbValue.toLowerCase()}"></li>
                        <li v-if="searchLabel && asbValue && asbValueExists === -1 && isShowNew && searchResult.length === 0"
                            :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}"
                            @mouseover="mouseOver(searchResultCount)"
                            @click="keyEnter">
                            + New {{searchLabel}}<br/><strong>{{asbValue}}</strong>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <modal-wizard ref="lookup_modal" @on-complete="confirmedBillableLookup"
                      finishButtonText="Continue" cancelButtonText="Cancel" id="lookup_modal"
                      class="lookup-modal" :class="{'lookup-modal-for-new-card': isNewCard, 'lookup-modal-for-card-edit': isCardEdit, 'lookup-modal-for-estimate-edit': isEstimateEdit}">
            <modal-wizard-tab title="" description="" title-icon-class="ti-align-right">
                <template>
                    <div style="text-align: center; margin-bottom: 10px">Your about to initiate a <span class="lookup-text">REGO/VIN LOOKUP</span></div>
                    <span style="text-align: center">Select a state in which the vehicle was registered in</span>
                    <div class="form-group row pt-1">
                        <label class="col-sm-3 col-3 col-form-label" style="font-size: 14px">State</label>
                        <div class="col-sm-9 col-9 pr-0 additional-input-rego-new-card-mobile">
                            <multiselect
                                    ref="vehicleOwnerAddressState"
                                    v-model="computedState"
                                    :options="addressStates"
                                    :showLabels="false"
                                    :option-height="29"
                                    :max-height="203"
                                    :close-on-select="true"
                                    placeholder="Select State"
                                    track-by="key"
                                    label="value"
                                    :tabindex="2"
                            >
                            </multiselect>
                        </div>
                    </div>
                </template>
            </modal-wizard-tab>
        </modal-wizard>
    </div>
</template>

<script>
    import advancedSearch from './advanced-search';
    import {mapGetters} from 'vuex';
    import Axios from 'axios';
    import Spinner from 'vue-simple-spinner';
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'AdvancedSearchForLookup',
        extends: advancedSearch,
        props: {
            isVINField: {
                type: Boolean,
                default: false
            },
            isActiveLookup: {
                type: Boolean,
                default: false,
            },
            isActiveLookupAlwaysOn: {
                type: Boolean,
                default: false,
            },
            isActiveLookupWithCompliance: {
                type: Boolean,
                default: false,
            },
            state: {
                type: String,
                default: '',
            },
            isNewCard: {
                type: Boolean,
                default: false,
            },
            isCardEdit: {
                type: Boolean,
                default: false,
            },
            isEstimateEdit: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isLoadingData: false,
                state2: this.state,
                isShowError: false,
                resultForLookup: '',
                dataForVehicle: {},
                isChangeLookupText: false,
                isChoseSearchWithLookup: false,
                isConfirmedSearchWithLookup: false,
                showLookupAfterTimeout: false,
                timeoutId: null,
            };
        },
        computed: {
            ...mapGetters({
                addressStates: 'addressStates',
            }),
            isShowNewLookupMessage() {
                return !!(
                    ((this.asbValue.length == 6 || this.showLookupAfterTimeout) && !this.isVINField)
                    || (this.asbValue.length == 17 && this.isVINField)
                );
            },
            computedState: {
                get: function () {
                    let vm = this;
                    try {
                        let item = _.find(this.addressStates, function (itm) {
                            return itm.key === vm.state2;
                        });
                        return item;
                    } catch (e) {
                        return {};
                    }
                },
                set: function (item) {
                    this.state2 = item.key;
                },
            },
        },
        methods: {
            showOrHideOptions() {
              if (!this.isDisabled) {
                this.show = !this.show
              }
            },
            searchLookupIfAlwaysOn(asbValue) {
                if (!this.isActiveLookupAlwaysOn || (asbValue.length != 6 && !this.isVINField)) {
                    return;
                }
                this.searchLookup(asbValue);
            },
            confirmedBillableLookup() {
                if (!_.isEmpty(this.state2)) {
                    this.$refs.lookup_modal.hide();
                    this.isConfirmedSearchWithLookup = true;
                    this.searchLookup(this.asbValue);
                }
            },
            searchLookup(value) {
              let vm=this;
                if (!(this.isActiveLookupAlwaysOn && this.isVINField)) {
                    if (!this.isConfirmedSearchWithLookup && this.isShowNewLookupMessage) {
                        this.$refs.lookup_modal.show();
                        return;
                    }
                }
                this.isChoseSearchWithLookup = true;
                this.$emit('onChooseSearchWithLookup', !this.isChoseSearchWithLookup);
                this.isShowError = false;
                this.show = true;
                this.isLoadingData = false;
                if (value.length < 6 && !this.showLookupAfterTimeout && !this.isVINField) {
                    this.resultForLookup = '';
                } else if (value.length < 17 && this.isVINField) {
                    this.resultForLookup = '';
                } else {
                    if (this.state2 === '' && !this.isVINField) {
                        toastr.error('Lookup error. Missing the State the vehicle is registered in.');
                        return;
                    }
                    let dataForRequest = {};
                    if (this.isVINField) {
                        dataForRequest = {
                            'vin': value
                        };
                    } else {
                        dataForRequest = {
                            'rego': value,
                            'state': this.state2
                        };
                    }
                    this.isLoadingData = true;
                    Axios.post('/fe/vehicle/lookup', dataForRequest)
                        .then(response => {
                            if (response.data.data !== null && response.data._status) {
                                this.isChangeLookupText = true;
                                if (!this.isVINField) {
                                    this.dataForVehicle = response.data.data[0];
                                    this.dataForVehicle.customer_vehicle_id = null;
                                    this.dataForVehicle.rego_number = value;
                                    let dom = this.dataForVehicle.dom ? this.dataForVehicle.dom : '';
                                    this.resultForLookup = value + ' - ' + this.dataForVehicle.make + ' ' + this.dataForVehicle.model + ' ' + dom;
                                } else {
                                    this.dataForVehicle = response.data.data[0];
                                    this.dataForVehicle.customer_vehicle_id = null;
                                    this.dataForVehicle.rego_number = this.dataForVehicle.rego;
                                    let dom = this.dataForVehicle.dom ? this.dataForVehicle.dom : '';
                                    this.resultForLookup = this.dataForVehicle.rego_number + ' - ' + this.dataForVehicle.make + ' ' + this.dataForVehicle.model + ' ' + dom;
                                }
                                this.$nextTick(() => {
                                    this.$refs.asbLookup.focus();
                                });
                            } else {
                                this.resultForLookup = '';
                                this.isShowError = true;
                            }
                            this.isConfirmedSearchWithLookup = false;
                            this.isChoseSearchWithLookup = false;
                        })
                        .catch(error => {
                            console.log(error);
                        })
                        .finally(() => {
                            setTimeout(() => vm.isLoadingData = false, 200);
                        });
                }
            },
            onKeyUp() {
                this.isShowError = false;
                this.isChangeLookupText = false;
                this.show = true;
                this.resultForLookup = '';
                this.searchLookupIfAlwaysOn(this.asbValue);
            },
            selectedRego() {
                this.show = false;
                console.log('selectedRego',this.dataForVehicle)
                this.$emit('onSelect', this.dataForVehicle);
            }
        },
        watch: {
            state(value) {
                this.state2 = value;
            },
            value(newVal){
              this.showLookupAfterTimeout = false;
              if(this.timeoutId !== null){
                clearTimeout(this.timeoutId)
              }
              if(newVal.length > 0 && newVal.length < 6){
                this.timeoutId = setTimeout(() => {
                  this.showLookupAfterTimeout = true;
                }, 2000);
              }
            },
        },
        components: {
            'vue-simple-spinner': Spinner,
            Multiselect,
        },
    };
</script>
<style>
    .lookup-text-gray {
        color: gray !important;
    }

    .lookup-modal .modal-content {
        width: 270px;
    }

    .lookup-modal-tab {
        text-align: center;
        font-size: 13px;
        font-weight: 700;
    }

    .lookup-modal-for-estimate-edit .modal.in .modal-dialog {
        transform: translate(39.5%, 77%) !important;
    }

    .lookup-modal-for-card-edit .modal.in .modal-dialog {
        transform: translate(41%, 77%);
    }

    .lookup-modal-for-new-card .modal.in .modal-dialog {
        transform: translate(44.5%, -10%) !important;
    }

    .lookup-text {
        color: #5E79FF !important;
    }

    .error-text-rego {
        color: red;
        position: absolute;
        left: 71px;
        top: 7px;
    }

    .error-text-vin {
        color: red;
        position: absolute;
        left: 163px;
        top: 7px;
    }

    .input-spinner {
        position: absolute;
        top: 4px;
        right: 54px;
        z-index: 10;
        color: #5E79FF;
    }
</style>
