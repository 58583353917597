<template>
      <div class="invite-repairer-form text-center">
        <div class="intro-text">
          <div class="form">
            <br />
            <br />
            <h1>Add your personal user account details</h1>
            <p>Just a few more bits of information needed that will be used to create you an account with Full Access to Repair-Shop</p>
            <br />
            <form method="POST" @submit.prevent="login">
              <div class="form-group">
                <label for="firstname" class="form-group-input-title">First Name</label>
                <input
                    ref="firstName"
                    @keydown.up="focusOnElement('role')"
                    @keydown.down="focusOnElement('lastName')"
                    autocomplete="off"
                    type="text"
                    class="form-control input-field"
                    name="firstname"
                    placeholder="First Name"
                    v-model="firstName"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="lastname" class="form-group-input-title">Last Name</label>
                <input
                    ref="lastName"
                    @keydown.up="focusOnElement('firstName')"
                    @keydown.down="focusOnElement('workPhone')"
                    autocomplete="off"
                    type="text"
                    class="form-control input-field"
                    name="lastname"
                    placeholder="Last Name"
                    v-model="lastName"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="workphone" class="form-group-input-title">Work Phone</label>
                <input
                    ref="workPhone"
                    @keydown.up="focusOnElement('lastName')"
                    @keydown.down="focusOnElement('mobilePhone')"
                    autocomplete="off"
                    type="text"
                    class="form-control input-field"
                    name="workphone"
                    placeholder="Work Phone"
                    v-model="workPhone"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="mobilephone" class="form-group-input-title">Mobile Phone</label>
                <input
                    ref="mobilePhone"
                    @keydown.up="focusOnElement('workPhone')"
                    @keydown.down="focusOnElement('role')"
                    autocomplete="off"
                    type="text"
                    class="form-control input-field"
                    name="mobilephone"
                    placeholder="Mobile Phone"
                    v-model="mobilePhone"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="role" class="form-group-input-title">User account role</label>
                <input
                    ref="role"
                    @keydown.up="focusOnElement('mobilePhone')"
                    @keydown.down="focusOnElement('firstName')"
                    autocomplete="off"
                    type="text"
                    class="form-control input-field"
                    name="role"
                    placeholder="Mobile Phone"
                    v-model="role"
                    style="opacity: 1"
                    @keydown.tab.prevent="$refs.nextButton.focus()"
                    readonly
                />
              </div>
              <div class="form-group">
                <div class="btn-group">
                  <button
                      ref="nextButton"
                      type="button"
                      class="btn input-btn"
                      :disabled="!nextAllowed"
                      @click="register"
                  >Submit and Create My Account</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

</template>

<script>
import Multiselect from "vue-multiselect";
import Axios from "axios";
export default {
  name: "InviteAddPersonalUserAccount",
  components: {
    multiselect: Multiselect
  },
  data(){
    return {
      firstName: '',
      lastName: '',
      workPhone: '',
      role: 'Admin',
      mobilePhone: '',
    };
  },
  props: {
    registrationData: {
      type: Object,
      default: {},
    }
  },
  mounted() {
  },
  methods: {
    focusOnElement(name) {
      this.$refs[name].focus();
    },
    register(){
      this.registrationData.account.firstName   = this.firstName;
      this.registrationData.account.lastName    = this.lastName;
      this.registrationData.account.workPhone   = this.workPhone;
      this.registrationData.account.mobilePhone = this.mobilePhone;
      NProgress.start();
      Axios.post(
        '/auth/registration',
        this.registrationData
      ).then(res => {
        if (res.data.status){
          this.$router.push('/register/account-created');
        } else {
          this.$router.push('/register/found-problem');
        }
      }).catch(e => {
        this.$router.push('/register/found-problem');
        console.log(e);
      })
      .finally(()=>{
        NProgress.done();
      })

    },
  },
  computed: {
    nextAllowed() {
      return !!(
        this.firstName
        && this.lastName
        && this.workPhone
        && this.mobilePhone
      );
    },
  }
};
</script>

<style lang="css" scoped>
  form {
    max-width: 522px;
    margin: 0 auto;
  }
  .form {
    max-width: 771px;
    width: 100%;
    margin: 0 auto;
  }
  .steps-line {
    max-width: 652px;
    width: 100%;
    margin: 0 auto;
  }
  .form h1 {
    text-align: center;
    margin-bottom: 0px;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 43px;
    color: #1C1F39
  }
  .invite-repairer-form {
    justify-content: flex-start;
    margin-top: 5px;
  }
  .form .input-btn {
    display: table;
    margin: 0 auto;
    margin-top: 30px;
  }
  .form .post-code {
    width: 109px;
  }
  .form .another-location {
    margin-top: 15px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    background: none;
    border: 0;
    padding: 0;
  }
  .form .another-location i {
    font-size: 23px;
    margin-right: 5px;
    line-height: 0;
  }
</style>

<style scoped>
  .auth-header {
    background-color: #1f233d;
    height: 100px;
  }

  .logo-text {
    color: white;
  }

  .container {
    height: 100%;
  }

  .logo {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .steps-line ul {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
  }

  .numb {
    display: none;
  }

  .form hr {
    margin-top: 50px;
    height: 1px;
    background-color: #1f233d;
  }

  .form p {
    margin: 0 auto;
    max-width: 500px;
  }

  .active {
    color: #5E79FF;
    font-weight: bold;
  }

  .custom-multiselect {
    margin: 0 auto;
    width: 70%;
  }

  .form-group-input-title {
    width: 100%;
    text-align: left;
    padding-left: 15px;
    font-size: 16px;
  }

  .post-code {
    width: 100%;
  }

  .input-btn {
    background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
    font-weight: bold;
    border-radius: 3px;
    color: white;
    width: 100%;
    font-size: 16px;
    border: none;
  }

  .remember-checkbox {
    margin-top: 7px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .remember-checkbox input {
    margin-right: 20px;
  }

  .form .another-location {
    color: #4472C4;
    font-weight: 900;
  }

  .bx-plus {
    color: #4472C4;
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
  }

  .btn-group>.btn:first-child {
    margin-left: auto;
  }

  .form-control {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    padding: 9px 13px;
    border: 1px solid rgba(27, 30, 56, 0.2) !important;
    border-radius: 4px !important;
    height: 43px !important;
    color: #1C1F39;
  }

  .form-control::placeholder {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }

  .btn.disabled, .btn:disabled {
    cursor: not-allowed;
    opacity: .65;
    background: #adaeb2;
    border-color: #adaeb2;
  }

  .V3 .btn-primary.disabled:hover, .V3 .btn-primary:disabled:hover {
    background: #98999c;
    border-color: #98999c;
  }
</style>
