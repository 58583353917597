import { render, staticRenderFns } from "./web-camera.vue?vue&type=template&id=97ef01ba&scoped=true"
import script from "./web-camera.vue?vue&type=script&lang=js"
export * from "./web-camera.vue?vue&type=script&lang=js"
import style0 from "./web-camera.vue?vue&type=style&index=0&id=97ef01ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97ef01ba",
  null
  
)

export default component.exports