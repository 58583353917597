<template>
  <div>
    <input
        v-model="value"
        :disabled="isDisabled"
        :readonly="isReadOnly"
        :placeholder="placeholderText"
        type="text"
        @change="$emit('onChange', value)"
        @focus="onFocus"
        @blur="checkEmail"
        style="opacity: 1;"
        class="form-control" >
    <small v-if="!isCheckEmail && value !== null && value !== ''" class="form-text show-help-for-body" >
      Please enter a valid email address
    </small>
  </div>
</template>

<script>
    export default {
      name: "email-validator",
      props: {
        email: {
          type: String,
          default: '',
        },
        isDisabled: {
          type: Boolean,
          default: false,
        },
        isReadOnly: {
          type: Boolean,
          default: false,
        },
        placeholderText: {
          type: String,
          default: 'Email Address',
        }
      },
      data() {
        return {
          isCheckEmail: true,
          value: this.email,
        }
      },
      methods: {
        checkEmail() {
          if (this.value === '') {
            return;
          }
          let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          this.isCheckEmail = re.test(this.value);
          this.$emit('checked', this.isCheckEmail);
        },
        onFocus() {
          this.isCheckEmail = true;
          this.$emit('checked', this.isCheckEmail);
        }
      },
      mounted() {
        this.checkEmail();
      },
      watch: {
        email: {
          handler(newVal) {
            this.value = newVal;
            this.checkEmail();
          },
          immediate: true,
        },
      }
    }
</script>

<style scoped>

</style>
