import ModalWizard from './components/modal-wizard'
import ModalWizardTab from './components/modal-wizard-tab'

export default {
	install (Vue) {
		Vue.component('modal-wizard', ModalWizard)
		Vue.component('modal-wizard-tab', ModalWizardTab)
	},
}

export {ModalWizard, ModalWizardTab}
