<script>
import ForgotUserDataPage from './forgotUserData';
export default {
  name: "ForgotPassUserDataPage",
  extends: ForgotUserDataPage,
  methods: {
    getUrl() {
      return '/auth/forgot-password-support';
    },
    getTitle() {
      return 'Password';
    }
  }
}
</script>



