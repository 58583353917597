import _ from "lodash";

export const Mutations = {
    setSearchFilterObj(state, ui) {
        state.searchFilterObj = ui;
    },
    setToken(state, ui) {
        state.token.splice(0, state.token.length);
        _.forEach(ui, itm => {
            state.token.push(itm)
        })
    },
    setTokens(state, ui) {
        for (let key in state.tokens) {
            delete state.tokens.key
        }
        for (let key in ui) {
            state.tokens[key] = ui[key];
        }
    },
    setTokensNew(state, ui) {
       state.tokens = ui
    },
    setSortDescForCardsAll(state, ui) {
        state.sortDescForCardsAll = ui;
    },
    setSortByForCardsAll(state, ui) {
      state.sortByForCardsAll = ui;
    },
    setSortDescForCardsOpen(state, ui) {
        state.sortDescForCardsOpen = ui;
    },
    setSortByForCardsOpen(state, ui) {
        state.sortByForCardsOpen = ui;
    },
    setSortDescForCardsActive(state, ui) {
        state.sortDescForCardsActive = ui;
    },
    setSortByForCardsActive(state, ui) {
        state.sortByForCardsActive = ui;
    },
    setSortDescForCardsClosed(state, ui) {
        state.sortDescForCardsClosed = ui;
    },
    setSortByForCardsClosed(state, ui) {
        state.sortByForCardsClosed = ui;
    },
    setPerPage(state, ui) {
      state.perPage = ui;
    },
    setCardsAllCurrentPage(state, ui) {
      state.cardsAllCurrentPage = ui;
    },
    setCardsOpenCurrentPage(state, ui) {
        state.cardsOpenCurrentPage = ui;
    },
    setCardsActiveCurrentPage(state, ui) {
        state.cardsActiveCurrentPage = ui;
    },
    setCardsClosedCurrentPage(state, ui) {
        state.cardsClosedCurrentPage = ui;
    },
    loadCards(state, ui) {
        let newCards = _.map(ui, (i) => {
            i.card_id = Number(i.card_id);
            return i;
        });
        state.items = _.unionBy(newCards, state.items, "card_id");
        console.log('LOADED.loadCards')
        return;
        //console.log('ui', ui);
        // let data = _.union(ui, state.items);
        //console.log('data', data);
        // let res = _.map(data, (i) => {
        //     i.card_id = Number(i.card_id);
        //     return i;
        // });
        // res = _.uniqWith(res, function (first, second) {
        //     return (first.card_id != second.card_id) ? false : true;
        // });
        //
        // //console.log('res', res);
        // state.items = res;
        // console.log('LOADED.loadCards')
    },
    updateUserHiddenStatuses(state, ui){
      state.hiddenStatuses.userHiddenStatuses = ui;
    },
    updateVendorHiddenStatuses(state, ui){
      state.hiddenStatuses.vendorHiddenStatuses = ui;
    },
    setTimestamp(state, timestamp = 0) {
        console.log('timestamp', timestamp);
        state.timestamp = timestamp;
    },
    logout(state) {
        state.timestamp = 0;
        state.items = [];
    },
    cardsUpdated (state, ui) {
        let newCards = _.map(ui, (i) => {
            i.card_id = Number(i.card_id);
            return i;
        });
        state.items = _.unionBy(newCards, state.items, "card_id");
    },
    changeCardStatusId (state, ui) {
        let cardIndex = _.findIndex(state.items, (itm) => {
            return Number(itm.card_id) == Number(ui.cardId)
        })
        if (cardIndex !== -1) {
            let cardCopy = _.cloneDeep(state.items[cardIndex])
            cardCopy.card_status = ui.cardStatus
            cardCopy.status_name = ui.statusName
            state.items.splice(cardIndex, 1, cardCopy)
        }
    },
    changeCardBoardSort (state, ui) {
        let cardIndex = _.findIndex(state.items, (itm) => {
            return Number(itm.card_id) == Number(ui.cardId)
        })
        if (cardIndex !== -1) {
            let cardCopy = _.cloneDeep(state.items[cardIndex])
            cardCopy.boardSort = ui.boardSort
            state.items.splice(cardIndex, 1, cardCopy)
        }
    },
    deleteCardById(state, id){
        let removedCardIndex = _.findIndex(state.items, function(card) {
          return parseInt(card.card_id) === parseInt(id);
        });
        if (removedCardIndex !== -1) {
            state.items.splice(removedCardIndex, 1);
        }
      }
}
