<script>
  import {ImageEditor} from '@toast-ui/vue-image-editor';
  import 'tui-image-editor/dist/svg/icon-a.svg';
  import 'tui-image-editor/dist/svg/icon-b.svg';
  import 'tui-image-editor/dist/svg/icon-c.svg';
  import 'tui-image-editor/dist/svg/icon-d.svg';
  // Load Style Code
  import 'tui-image-editor/dist/tui-image-editor.css';

  export default {
    name: "toast-ui",
    props: {
      pathOpenedPhoto: {
        type: String,
        default: null,
        required: true
      }
    },
    components:{
      'tui-image-editor': ImageEditor
    },
    data: (th) => {
      return {
        useDefaultUI: true,
        options: {
          includeUI: {
            loadImage: {
              path: th.pathOpenedPhoto,
              name: 'SampleImage'
            },
            initMenu: 'filter'
          },
          cssMaxWidth: 700,
          cssMaxHeight: 500
        },
        editorInstance: null
      }
    },
    mounted () {
    },
    methods: {
      onAddText(pos) {
      },
      onObjectMoved(props) {
      },
      saveImage(){

        // var gl = this.$refs.tuiImageEditor.$el.querySelector('.upper-canvas').getContext("webgl", {preserveDrawingBuffer: true});
        var image = new Image();
        image.onload = function (){
            image.src = this.$refs.tuiImageEditor.$el.querySelector('.upper-canvas').toDataURL('image/jpeg', 1.0);
            var win = window.open()
            win.document.write("<img src='"+this.$refs.tuiImageEditor.$el.querySelector('.upper-canvas').toDataURL()+"'/>");
            document.getElementById('testID').appendChild(image);
        }
      }
    }
  }
</script>
<template>
    <div class="imageEditorApp">
        <tui-image-editor ref="tuiImageEditor"
              :include-ui="useDefaultUI"
              :options="options"
              @addText="onAddText"
              @objectMoved="onObjectMoved">
        </tui-image-editor>
        <div id="testID"></div>
        <button class="save_image_btn" @click="saveImage()">Save</button>
    </div>
</template>

<style scoped>
    .imageEditorApp {
        width: 1000px;
        height: 800px;
    }
    .save_image_btn, #testID{
        position:relative;
        z-index:222;
    }
</style>
