
export const Mutations = {
    isOverLimitUpdate(state, ui) {
        let limit = 10;
        if (ui.limit !== 0) {
            limit = ui.limit;
        }
        let percents =  100 / Number(limit) * parseFloat(ui.consumed);
        if (percents >= 99) {
            state.isOverLimit = true;
        } else {
            state.isOverLimit = false;
        }
    },

}
