<script>
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
export default {
	name: 'amazon-ads',
	computed: {
		locationHostName: function () {
			return location.host
		}
	}
}

</script>

<template>
<div>
	<!-- <div v-if="locationHostName != 'repair-shop.local'">
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B01497MZB6&asins=B01497MZB6&linkId=89bbb5bdbb24420fefad8f0200ba6814&show_border=false&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B00DP5D51I&asins=B00DP5D51I&linkId=45f31a4cda60aeccac1b9848f641ffb7&show_border=true&link_opens_in_new_window=true"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B002XMS75I&asins=B002XMS75I&linkId=3e8dc96eca1cf4558c5ef8ed68d8aade&show_border=true&link_opens_in_new_window=true"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B01H7PEHEK&asins=B01H7PEHEK&linkId=c83abb2a6cd15e5dd547669241dc3d61&show_border=true&link_opens_in_new_window=true"></iframe> 
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B00KY2VFD0&asins=B00KY2VFD0&linkId=77cf1362243b4746311efc515e0df543&show_border=true&link_opens_in_new_window=true"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B00JS43736&asins=B00JS43736&linkId=ae2d821b7cac26f53fade60658e00839&show_border=true&link_opens_in_new_window=true"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B007QVKFKQ&asins=B007QVKFKQ&linkId=db62a379e668ed7473ad3e8da8508d83&show_border=true&link_opens_in_new_window=true"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B0115SLLLM&asins=B0115SLLLM&linkId=c6520e9a1f0652ebd7639cb4af3e65b2&show_border=true&link_opens_in_new_window=true"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B003DX6OAQ&asins=B003DX6OAQ&linkId=6c17ebaf06a2842b6453031d76596fbc&show_border=true&link_opens_in_new_window=true"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B00JS43W7M&asins=B00JS43W7M&linkId=867cce75945eedcb09a0134199c914b5&show_border=true&link_opens_in_new_window=true"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B00063ZPTC&asins=B00063ZPTC&linkId=f22c0aabd8fa8092430dcec3779c0a83&show_border=false&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B00VKAP4XC&asins=B00VKAP4XC&linkId=d509f7596a67e5efb0fb3640e64461a5&show_border=false&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B00UYYMDP2&asins=B00UYYMDP2&linkId=46c83ec0e15ed5a9ae22406403201aaa&show_border=false&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B00VKAOHGC&asins=B00VKAOHGC&linkId=c299f129ee7930ed1ceb3e549c4213a4&show_border=false&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B00S77PT9A&asins=B00S77PT9A&linkId=989624bd330de9a6503f78859a47348f&show_border=false&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
		<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=autorepairnet-20&marketplace=amazon&region=US&placement=B002ERHHO4&asins=B002ERHHO4&linkId=f2763a35c5f47801d82d6089c58d7543&show_border=false&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"></iframe>
	</div> -->
</div>
</template>