<template>
    <div class="tl-content box box-block display-block" style="background-color: #FAFAFA;">
        <span class="arrow left" style="border-color: #FAFAFA;"></span>
        <div class="table-and-actions">
            <table class="activity-table">
                <tr v-if="isSortTypeExternal && a.action_code === 'UFCL'">
                    <td>Estimate Update Received</td>
                    <td>ClaimLinq</td>
                </tr>
                <template v-if="a.action_code === 'PartssearchOrderedPricesReceived' || a.action_code === 'PartssearchOrderedPricesUpdated'">
                    <tr>
                        <td>Part Pricing Received:</td>
                        <td v-if="a.action_code === 'PartssearchOrderedPricesReceived'">New Ordered pricing received</td>
                        <td v-else>Updated Ordered pricing received</td>
                    </tr>

                    <tr>
                        <td>Created By:</td>
                        <td>PartsSearch</td>
                    </tr>

                </template>
                <template v-if="a.action_code === 'PartssearchListPricesReceived' || a.action_code === 'PartssearchListPricesUpdated'">
                  <tr>
                    <td>Part Pricing Received:</td>
                    <td v-if="a.action_code === 'PartssearchListPricesReceived'">New List pricing received</td>
                    <td v-else>Updated List pricing received</td>
                  </tr>

                  <tr>
                    <td>Created By:</td>
                    <td>PartsSearch</td>
                  </tr>

                </template>

                <template v-if="a.action_code === 'CustomerCheckedIn'">
                  <tr>
                    <td>Customer Checked-In</td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>Checked-In by</td>
                    <td>{{a.action_description}}</td>
                  </tr>

                </template>

                <template v-if="a.action_code === 'IntegrationWebtrimJobExport'">
                  <tr>
                    <td>Job Sent to:</td>
                    <td>{{a.info['Job Sent To']}}</td>
                  </tr>

                  <tr>
                    <td>Sent By:</td>
                    <td>{{a.info['Job Sent By']}}</td>
                  </tr>

                </template>
                <tr v-if="a.action_code === 'SmsEstimateSentToCustomer'">
                    <td>Estimate Sent To</td>
                    <td>{{a.action_details['Estimate Sent To']}}</td>
                </tr>
                <tr v-if="a.action_code === 'SmsEstimateSentToCustomer'">
                    <td>Sent Status</td>
                    <td>{{a.action_details['smsStatus']}}</td>
                </tr>
                <tr v-if="a.action_code === 'SmsEstimateSentToCustomer'">
                  <td>Sent By</td>
                  <td>{{a.action_details['Sent By']}}</td>
                </tr>
                <tr v-if="a.action_code === 'SmsEstimateSentToCustomer'">
                  <td>Text</td>
                  <td>{{a.action_details['smsText']}}</td>
                </tr>

                <template v-if="a.action_code === 'SmsEstimateReceivedFromCustomer'">
                  <tr>
                    <td>From</td>
                    <td>{{ a.action_description }}</td>
                  </tr>
                  <tr>
                    <td>Text</td>
                    <td>{{ a.action_details['stripped_html'] }}</td>
                  </tr>
                </template>

                <tr v-if="a.action_code === 'IntegrationIagInvoiceSent'">
                    <td>Invoice Sent to</td>
                    <td>ORM</td>
                </tr>
              <tr v-if="a.action_code === 'IntegrationIagQuoteReceived'">
                <td>Estimate Update Received</td>
                <td>ORM</td>
              </tr>
              <tr v-if="a.action_code === 'IntegrationIagJobCancelledReceived'">
                <td>Job Cancellation Requested</td>
                <td>From ORM</td>
              </tr>
                <tr v-if="a.action_code === 'IntegrationIagQuoteReceived' || a.action_code === 'IntegrationIagInvoiceSent'">
                    <td>Insurance Claim Number</td>
                    <td>{{a.info.insuranceClaimNumber}}</td>
                </tr>
                <tr v-if="a.action_code === 'IntegrationIagEstimateSent'">
                    <td v-if="a.isSupplement">Supplement Sent To</td>
                    <td v-else>Estimate Sent To</td>
                    <td>ORM</td>
                </tr>
                <tr v-if="a.action_code === 'IntegrationIagEstimateSent'">
                    <td>System Reference Number</td>
                    <td>{{a.info.systemReferenceNumber}}</td>
                </tr>
                <tr v-if="a.action_code === 'IntegrationIagEstimateSent' || a.action_code === 'IntegrationIagInvoiceSent'">
                    <td>Sent status</td>
                    <td v-if="a.info.sentStatus">Success</td>
                    <td v-else>Failed</td>
                </tr>
                <tr v-if="a.action_code === 'IntegrationIagEstimateSent'  || a.action_code === 'IntegrationIagInvoiceSent'">
                    <td>Sent By</td>
                    <td>{{a.info.sentBy}}</td>
                </tr>
                <tr v-if="a.action_code === 'CD'">
                    <td>Duplicate Created</td>
                </tr>
                <tr v-if="a.action_code === 'CD'">
                    <td>Created By:</td>
                    <td>{{a.createdBy}}</td>
                </tr>
                <tr v-if="a.action_code === 'CD'">
                    <td>Reference Card Nbr:</td>
                    <td>{{referenceCardNbr}}</td>
                </tr>

                <tr v-show="a.is_supplement && a.supplement_number && !isORMActivityOrTypeCD">
                    <td>From supplement #</td>
                    <td><span v-html="a.supplement_number"></span></td>
                </tr>


                <template v-if="(a.action_code !== 'CustomerCheckedIn' && !isORMActivityOrTypeCD && !isSortTypeExternal) || a.action_code === 'IUTC'">
                    <tr v-if="myA.length === 4 && !isORMActivityOrTypeCD">
                        <td v-html="myA[0]"></td>
                        <td v-html="myA[1]"></td>
                    </tr>

                    <tr v-if="myA.length === 4 && !isORMActivityOrTypeCD">
                        <td v-html="myA[2]"></td>
                        <td v-html="myA[3]"></td>
                    </tr>

                    <tr v-else-if="myA.length === 2 && !isORMActivityOrTypeCD">
                        <td style="padding-right: 10px; word-break: break-all;" v-html="myA[0]"></td>
                        <td v-html="myA[1]"></td>
                    </tr>

                    <tr v-if="a.sentBy && !isOrmActivity">
                        <td>Sent By</td>
                        <td>{{a.sentBy}}</td>
                    </tr>
                    <tr v-if="a.old_file && !isOrmActivity">
                        <td>File</td>
                        <td><a :href="fileUrl+a.old_file.url" target="_blank">{{a.old_file.name}}</a></td>
                    </tr>

                    <tr v-if="a.createdBy && !a.user && a.action_description && !a.action_description.includes('assigned to') && !isOrmActivity && a.action_code !== 'CustomerCheckedIn'">
                        <td>Created by</td>
                        <td>{{a.createdBy}}</td>
                    </tr>

                    <tr v-if="a.createdBy && !a.user && a.action_description && a.action_description.includes('assigned to') && !isOrmActivity">
                        <td>Assigned by</td>
                        <td>{{a.createdBy}}</td>
                    </tr>
                <tr v-if="a.emailStatus">
                    <td>Delivery Status</td>
                    <td style="text-transform: capitalize;">{{a.emailStatus}}</td>
                </tr>
                    <tr v-if="a.user">
                        <td>Changed by</td>
                        <td><span v-html="a.user"></span></td>
                    </tr>

                    <tr v-if="myA.length > 4 && !isORMActivityOrTypeCD">
                        <td>Description</td>
                        <td v-html="a.action_description"></td>
                    </tr>
                </template>

                <tr class="tl-date text-muted mt-1">
                    <td>Date</td>
                    <td><span v-html="a.entry_date.replaceAll('-', '/')"></span> - <span v-html="a.entry_time"></span></td>
                </tr>

            </table>
            <div v-if="isStatusActive" class="navbar-nav nav dropdown dropdown-options show float-sm-right text-right additional-action-menu-style" style="margin-right:1.25rem; position: absolute; right: 7px">
                 <span class="btn ml-0-25 waves-effect waves-light ex-options-button additional-action-menu-style" style="margin-top: -10px; padding-left: 10px !important;" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="true">Actions
                    <span class="dropdown-arrow  pull-right" style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);"><i
                            class="fa fa-chevron-down pull-right-action"></i></span>
                 </span>
                <div class="dropdown-menu dropdown-menu-right animated fadeInUp drop-down-menu-actions">
                    <div class="dropdownLinks-actions-links">
                        <a v-if="viewed == 1" @click="markAsUnRead(activity)" class="dropdown-item text-primary clickable dropdown-action-item dropdown-action-item-with-icon"><i
                                class="fa fa-eye-slash activtyPopupIcon" aria-hidden="true"></i> Mark As Unread</a>
                        <a v-else @click="markAsRead(activity)" class="dropdown-item text-primary clickable dropdown-action-item dropdown-action-item-with-icon"><i
                                class="fa fa-eye activtyPopupIcon" aria-hidden="true"></i> Mark As Read</a>
                    </div>
                    <div class="dropdownLinks-actions-links">
                        <a v-if="isProductionManagerUser || isShopManagerUser || isAdministrator" @click="$refs.deleteActivityModal.show()"
                           class="dropdown-item text-primary clickable dropdown-action-item">Delete Entry</a>
                    </div>
                </div>
            </div>
            <modal-wizard ref="deleteActivityModal" cancelButtonText="Cancel" finishButtonText="Ok" @on-complete="deleteActivity" class="delete-activity-modal">
                <modal-wizard-tab title="Delete Activity" description="" title-icon-class="ti-align-right">
                    <template>
                        <div class="pt-2 pb-2">Do you want to Delete this Activity list entry?</div>
                    </template>
                </modal-wizard-tab>
            </modal-wizard>


            <!--          <div v-show="a.is_supplement">From supplement #<span v-html="a.supplement_number"></span></div>-->
            <!--            <div v-html="a.action_description"></div>-->
            <!--            <div v-if="a.old_file">File: <a :href="fileUrl+a.old_file.url" target="_blank">{{a.old_file.name}}</a></div>-->
            <!--            <div v-if="a.user">Changed by: <span v-html="a.user"></span></div>-->
            <!--            <div class="tl-date text-muted mt-1"><span v-html="a.entry_date"></span> <span v-html="a.entry_time"></span></div>-->
        </div>
    </div>
</template>

<script>
    import {appConfig} from "../../../config";
    import {mapGetters} from "vuex";

    export default {
        name: 'activity-list-default',
        props: ['viewed', 'activity', 'index', 'cardId', 'viewed',],
        data: function () {
            return {
                a: this.activity,
                myA: '',
                isMinimized: !!this.viewed,
            };
        },
        methods: {
            deleteActivity: function () {
                this.$emit('deleteActivity', this.a.activity_id);
                this.$refs.deleteActivityModal.hide();
            },
            markAsUnRead: function (a) {
                this.$emit('unread', a);
            },
            markAsRead: function (a) {
                this.$emit('read', a);
            },
            loadActivity: function () {
                this.$emit('loadActivity');
            },
            destroyMe: function () {
                Vue.$destroy(this);
            },
            filterA: function (myA) {
                if (myA.includes('to')) {
                    if (myA.includes('customer:')) {
                        myA = this.pushedInMyA('customer:', myA, 1, 0);
                    } else if (myA.includes('insurer:')) {
                        myA = this.pushedInMyA('insurer:', myA, 1, 0);
                    } else {
                        myA = this.pushedInMyA('to', myA, 1, 0);
                    }
                } else if (myA.includes('To')) {
                    myA = this.pushedInMyA('To', myA, 1, 0);
                } else if (myA.includes('created')) {
                    myA = this.pushedInMyA('created', myA, 0, 0);
                } else if (myA.includes('added:')) {
                    myA = this.pushedInMyA('added:', myA, 1, 0);
                } else if (myA.includes('added')) {
                    myA = this.pushedInMyA('added', myA, 0, 0);
                } else if (myA.includes('is')) {
                    myA = this.pushedInMyA('is', myA, 0, 1);
                } else if (myA.includes('-')) {
                    myA = this.pushedInMyA('-', myA, 0, 1);
                }
                return myA;
            },
            pushedInMyA: function (text, myA, count1, count2) {
                let index = myA.indexOf(text) + count1;
                let myACopy = myA;

                myA = [];
                myA.push(myACopy.slice(0, index).join(' '));
                myA.push(myACopy.slice(index + count2,).join(' '));

                return myA;
            },
            isIncludesСommaOrClaim: function (text1, text2) {
                if (this.myA.includes(text1)) {

                    let myACopy = this.myA.split(text2);

                    if (myACopy[1].includes('to') ||
                        myACopy[1].includes('To') ||
                        myACopy[1].includes('created') ||
                        myACopy[1].includes('added') ||
                        myACopy[1].includes('is #') ||
                        myACopy[1].includes('added:') ||
                        myACopy[1].includes('created:')) {

                        let index = this.myA.indexOf(text2);
                        let myACopy = this.myA;

                        let one = this.filterA(myACopy.slice(0, index).split(' '));
                        let two = this.filterA(myACopy.slice(index + 1,).split(' '));

                        this.myA = [];

                        this.myA.push(one[0], one[1], two[0], two[1]);

                    } else {
                        this.myA = this.myA.split(' ');
                        this.myA = this.filterA(this.myA);
                    }
                }
            }

        },
        computed: {
            ...mapGetters({
                isShopManagerUser: 'isShopManagerUser',
                isProductionManagerUser: 'isProductionManagerUser',
                isAdministrator: 'isAdministrator',
              isStatusActive: 'isStatusActive',
            }),
            isSortTypeExternal() {
                return this.a.sort_type === 'External';
            },
            isOrmActivity() {
                return this.a.action_code === 'IntegrationIagQuoteReceived' || this.a.action_code === 'IntegrationIagEstimateSent';
            },
            isORMActivityOrTypeCD() {
                return this.a.action_code === 'CD' || this.a.action_code === 'IntegrationIagQuoteReceived' || this.a.action_code === 'IntegrationIagEstimateSent'  || this.a.action_code === 'IntegrationIagInvoiceSent';
            },
            referenceCardNbr() {
                let arrayOfDescription = this.a.action_description.split(' ');
                let index = arrayOfDescription.length - 1;
                return arrayOfDescription[index];
            },
            fileUrl() {
                return appConfig.baseAPIURL;
            }
        },
        watch: {},
        components: {},
        mounted: function () {
            if (this.a.action_description) {
                this.myA = this.a.action_description;
            }

            this.isIncludesСommaOrClaim(',', ',');
            this.isIncludesСommaOrClaim('ClaimLinq.', '.');

            if (typeof this.myA === 'string') {
                this.myA = this.filterA(this.myA.split(' '));
            }
        }
    };
</script>

<style scoped>
    .dropdown .dropdownLinks-links .dropdown-action-item {
        display: inline-block;
        position: relative;
        width: 100%;
        min-height: 38px;
        padding: 10px 14px 8px 14px;
        clear: both;
        color: #1C1F39;
        text-align: inherit;
        white-space: normal;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        background: 0 0;
        border: 0;
        cursor: pointer;
    }

    .dropdown .dropdownLinks-links .dropdown-action-item:hover {
        background-color: rgba(94, 121, 255, 0.1);
        color: #5E79FF;
        font-weight: bold;
    }

    .dropdownLinks-actions-links {
        width: 115px;
    }

    .drop-down-menu-actions {
        width: 115px !important;
        border-radius: 3px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgb(27 30 56 / 15%);
        min-width: 115px !important;
        margin: -13px -14px 0 !important;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        z-index: 110;
    }

    .dropdown-action-item {
        text-align: center !important;
        min-height: 20px !important;
    }

    .pull-right-action {
        padding-right: 3px !important;
        margin-left: 10px;
    }
</style>
