<script lang="ts">
import {mapGetters} from "vuex";
import {appConfig} from "@/config";
import Axios from "axios";
export default {
  name: "scanner",
  props: {
    msg: String,
  },
  data() {
    return {
      programRunning: false,
      obtainingApiKey: false,
      estimageConfiguratorStatus: true,
      pcs: [],
      currentPcName: "",
      scannerApiKey: null,
      checkProgramInterval: 5000,
      checkConnectedPcsInterval: 10000,
      checkProgramIntervalId: null,
      checkConnectedPcsIntervalId: null,
    }
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    }),
    apiKey(){
      if(!this.programRunning){
        return 'Service not running'
      }
      if(this.obtainingApiKey){
        return 'Setting up new API-Key...'
      }
      if(this.scannerApiKey){
        return this.scannerApiKey;
      }
      return 'No API-Key!'
    },
    downloadUrl: function () {
      return appConfig.baseAPIURL + '/test/scanner-service-dowload';
    }
  },
  methods: {
    removePc(pc_id) {
      if (!confirm("Are you sure?")) {
        return;
      }
      Axios.post('/fe/scanner-service/remove-connected-pc', {
        pcId: pc_id
      })
          .then(res => {
            if (!res || !res.data || !res.data.status) {
              console.log(res.data);
            }
            this.getConnectedPcs();
          }).catch(er => {
        console.log(er);
      });
    },
    checkProgramStatus() {
      try {
        Axios.get('http://localhost:29922/status', {
          timeout: 3000,
          withCredentials: false,
        })
            .then(res => {
              if (res.data && res.data.Status) {
                this.programRunning = (!!res.data.ApiKey) || res.data.NeedPinCode;
                this.scannerApiKey = res.data.ApiKey;
                this.currentPcName = res.data.PcName;
                this.obtainingApiKey = res.data.NeedPinCode;
                if (res.data.NeedPinCode) {
                  this.getNewPincode();
                }
                this.$store.commit('setScannerApiKey', res.data.ApiKey)
              }
            }).catch(er => {
          this.programRunning = false;
        });
      } catch (e) {
        console.warn(e);
      }
    },
    getConnectedPcs() {
      Axios.post('/fe/scanner-service/get-connected-pcs', {
        currentApiKey: this.scannerApiKey
      })
          .then(res => {
            if (res.data && res.data.status) {
              this.$store.commit('setPcs', res.data.pcs)
              this.pcs = res.data.pcs;
            }
          }).catch(er => {
        console.log(er);
      });
    },
    getNewPincode() {
      Axios.get('/fe/scanner-service/get-new-pin', {})
          .then(res => {
            if (res.data && res.data._status) {
              console.log("New PIN %o", res.data);

              Axios.post('http://localhost:29922/setPin', JSON.stringify({
                pin: res.data.pin
              }), {
                withCredentials: false,
              })
                  .then(res => {
                    if (res.data && res.data.Status) {
                      console.log("PIN has been sent");
                    }
                  }).catch(er => {
                console.log(er);
              });

            }
          }).catch(er => {
        console.log(er);
      });
    }
  },
  mounted () {
    this.$nextTick(function (){
      this.checkProgramIntervalId = window.setInterval(() => {
        this.checkProgramStatus();
      }, this.checkProgramInterval);
      this.checkProgramStatus();

      this.checkConnectedPcsIntervalId = window.setInterval(() => {
        this.getConnectedPcs();
      }, this.checkConnectedPcsInterval);
      this.getConnectedPcs();
    });
  },
  beforeDestroy() {
    console.log('Program interval #%s', this.checkProgramIntervalId);
    if (this.checkProgramIntervalId != null) {
      clearInterval(this.checkProgramIntervalId);
      this.checkProgramIntervalId = null;
    }
    console.log('PCs interval #%s', this.checkConnectedPcsIntervalId);
    if (this.checkConnectedPcsIntervalId != null) {
      clearInterval(this.checkConnectedPcsIntervalId);
      this.checkConnectedPcsIntervalId = null;
    }
  },
};
</script>

<template>
  <div class="hello">

    <div class="filler">

      <div class="connecting-holder">
        <div v-if="!scannerApiKey" class="in-process">
          <h3 class="connecting-header">Connecting...</h3>
          <br>
          <svg class="spinner" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/><path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"><animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite"/></path></svg>
        </div>
        <div v-else class="finished">
          <h3 class="connecting-header">Success!</h3>
          <h3 class="connecting-header">This window can be closed</h3>
          <h3 style="font-size: 30vmin; text-align: center"><i class="ti-check"></i></h3>
        </div>

      </div>

    </div>

  </div>
</template>

<style scoped>
.filler {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 9999999999;
}

.connecting-holder {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40vmin;
  min-width: 300px;
}
.spinner {
  width: 100%;
}
.spinner path {
  stroke: rgb(26, 29, 53);
  fill: rgb(26, 29, 53);
}
.connecting-header {
  text-align: center;
  font-size: 4vmin;
  font-weight: bolder;
  color: rgb(26, 29, 53);
}

</style>

<style>
</style>
