var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-actions"},[_c('ul',[_c('li',[(_vm.item.activity && _vm.item.activity.Email && _vm.item.activity.Email.date)?_c('a',{staticClass:"notf-icon position-relative clickable",attrs:{"id":`i-email1-${_vm.name}`},on:{"click":function($event){return _vm.goToEmail(_vm.item)}}},[_c('i',{staticClass:"bx bx-envelope active"}),_c('b-tooltip',{attrs:{"target":`i-email1-${_vm.name}`,"triggers":"hover","custom-class":"ps-tooltip"}},[_vm._v(" Unread "+_vm._s(_vm.item.activity.Email.smsId ? 'SMS' : 'email')+" message at:"),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.item.activity.Email.date))])])],1):_c('a',{staticClass:"notf-icon position-relative clickable",attrs:{"id":`i-email2-${_vm.name}`}},[_c('i',{staticClass:"bx bx-envelope"})])]),_c('li',[_c('a',{staticClass:"notf-icon",attrs:{"id":`i-external-${_vm.name}`,"href":"javascript:void(0);"}},[_c('i',{staticClass:"bx bx-link-external",class:_vm.item.activity && _vm.item.activity.External && _vm.item.activity.External.from ? 'active' : ''}),(_vm.item.activity && _vm.item.activity.External && _vm.item.activity.External.from )?_c('b-tooltip',{attrs:{"target":`i-external-${_vm.name}`,"triggers":"hover","custom-class":"ps-tooltip"}},[_vm._v(" "+_vm._s(_vm.item.activity.External.from == 'ClaimLinq' || _vm.item.activity.External.from == 'ORM' ? 'Authorisation' : 'Integration')+" received from "),_c('strong',[_vm._v(_vm._s(_vm.item.activity.External.from))]),_c('br'),_vm._v(" at: "),_c('strong',[_vm._v(_vm._s(_vm.item.activity.External.date))])]):_vm._e()],1)]),_c('li',[_c('a',{staticClass:"notf-icon",attrs:{"id":`i-assignment-${_vm.name}`,"href":"javascript:void(0);"}},[_c('i',{staticClass:"bx bx-user",class:_vm.item.activity &&
                          _vm.item.activity.Assignment &&
                          _vm.item.activity.Assignment.type
                              ? 'active'
                              : ''}),(  _vm.item.activity &&                           _vm.item.activity.Assignment &&
                          _vm.item.activity.Assignment.type &&
                          _vm.item.activity.Assignment.type == 'Card'                        )?_c('b-tooltip',{attrs:{"target":`i-task-${_vm.name}`,"triggers":"hover","custom-class":"ps-tooltip"}},[_vm._v(" Card Assigned from:"),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.item.activity.Assignment.from))])]):(_vm.item.activity &&
                          _vm.item.activity.Assignment &&
                          _vm.item.activity.Assignment.type &&
                          _vm.item.activity.Assignment.type == 'Task'                        )?_c('b-tooltip',{attrs:{"target":`i-task-${_vm.name}`,"triggers":"hover","custom-class":"ps-tooltip"}},[_vm._v(" Task Assigned from:"),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.item.activity.Assignment.from))])]):_vm._e()],1)]),_c('li',[_c('a',{attrs:{"id":`i-message-${_vm.name}`,"href":"javascript:void(0);"}},[_c('i',{staticClass:"bx bx-message-rounded",class:_vm.item.activity && _vm.item.activity.Message &&  _vm.item.activity.Message.date     ? 'active'    : ''}),(_vm.item.activity &&
                          _vm.item.activity.Message &&
                          _vm.item.activity.Message.date                        )?_c('b-tooltip',{attrs:{"target":`i-message-${_vm.name}`,"triggers":"hover","custom-class":"ps-tooltip"}},[_vm._v(" Message From:"),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.item.activity.Message.from))])]):_vm._e()],1)]),_c('li',[_c('a',{staticStyle:{"padding":"0 2px"}},[_c('div',{staticClass:"card-insurer-colour",style:({backgroundColor: _vm.insurerColor})})])])]),(_vm.item.assignedTo && _vm.item.assignedTo.id)?_c('div',{staticClass:"user-avatar"},[_c('span',{staticClass:"user-avatar-name"},[_vm._v(_vm._s(_vm.getInitalForName({ id: _vm.item.assignedTo.id, name: _vm.item.assignedTo.name }))+" ")]),_vm._m(0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"user-avatar-status"},[_c('i',{staticClass:"bx bxs-time-five"})])
}]

export { render, staticRenderFns }