<template>
  <div class="" style="width: 1200px;">
    <div class="row no-gutter header-content d-flex" style="width: 1088px;">
      <div class="col-12 col-md-6 chart-title">
        Receivables Invoice Details
      </div>
      <div class="col-12 col-md-6 header-right d-flex">
        <div class="navbar-nav nav dropdown dropdown-options  show inline" style="margin-left: 170px;">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  id="dropdownMenuLink1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true">
            Export
            <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
               aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('csv')">.CSV</a>
            <a class="dropdown-item text-primary p-1 clickable" @click="exportReport('xlsx')">.XLS</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutter" style="margin-top: 15px;">
      <div class="col-12 col-md-5">
        <div class="navbar-nav nav dropdown dropdown-options  show inline" style="margin-left: 110px;">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  id="dropdownMenuLink2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  style="width: 400px"
                  aria-expanded="true">
            Date range: <span style="padding-left: 5px">{{ computedSelectedFilter }}</span>
            <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp" style="width: 400px; left: 0px"
               aria-labelledby="dropdownMenuLink">
            <a @click="selectedFilter1('TM')" class="dropdown-item text-primary p-1 clickable">This month <span style="float: right">{{ computedCurrentMonth }}</span></a>
            <a @click="selectedFilter1('TQ')" class="dropdown-item text-primary p-1 clickable">This quarter <span style="float: right;">{{ computedCurrentQuarter }}</span></a>
            <a @click="selectedFilter1('TFY')" class="dropdown-item text-primary p-1 clickable" style="border-bottom: 1px solid rgba(0,0,0,.2);">This financial year <span
                style="float: right;">{{ computedCurrentFinancialYear }}</span></a>
            <a @click="selectedFilter1('LM')" class="dropdown-item text-primary p-1 clickable">Last month <span style="float: right;">{{ computedLastMonth }}</span></a>
            <a @click="selectedFilter1('LQ')" class="dropdown-item text-primary p-1 clickable">Last quarter <span style="float: right;">{{ computedLastQuarter }}</span></a>
            <a @click="selectedFilter1('LFY')" class="dropdown-item text-primary p-1 clickable" style="border-bottom: 1px solid rgba(0,0,0,.2);">Last financial year <span
                style="float: right;">{{ computedLastFinancialYear }}</span></a>
            <a @mouseover="activateDateFilterInput" class="dropdown-item text-primary p-1 clickable has-child">
              Custom Date Range <span style="float: right;">Select or Type</span>
              <div  class="dropdown-menu-child" style="height: 70px; width: 300px; left: 399px; top: -11px">
                <date-picker ref="datePicker" @input="updateDataFilter" v-model="dateFilter"></date-picker>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="navbar-nav nav dropdown dropdown-options  show inline" style="margin-left: 20px">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  id="dropdownMenuLink3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  style="width: 240px"
                  aria-expanded="true">
            Date Search: <span style="padding-left: 5px">{{ computedSelectedFilter2 }}</span>
            <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp" style="width: 240px; left: 0px"
               aria-labelledby="dropdownMenuLink">
            <a @click="selectedFilter2('ID')" class="dropdown-item text-primary p-1 clickable">Invoice Date</a>
            <a @click="selectedFilter2('PD')" class="dropdown-item text-primary p-1 clickable">Payment Date</a>
            <a @click="selectedFilter2('DD')" class="dropdown-item text-primary p-1 clickable">Due Date</a>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-3" style="left: -130px">
        <div class="navbar-nav nav dropdown dropdown-options  show inline">
          <button type="button"
            class="btn waves-effect waves-light ex-options-button"
            v-on:click="showFilters = !showFilters">
            Filters
              <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                <i v-if="!showFilters" class="fa fa-chevron-down pull-right"></i>
                <i v-if="showFilters" class="fa fa-chevron-up pull-right"></i>
              </span>
          </button>

        </div>
        <div v-if="showFilters" class="active-filters-control">
          <div class="filters-checkboxes">
            <span class="filters-checkboxes-title">Display Invoice Types</span>

            <label class="form-check-inline checkbox filters-checkbox">
              <input  class="form-check-input" type="checkbox" name="excess" v-model="isTypeExcess">
              <span class="icon"><i class='bx bx-check'></i></span><span  class="text">Excess</span>
            </label>
            <label class="form-check-inline checkbox filters-checkbox">
              <input v-model="isTypeComplete"  class="form-check-input" type="checkbox" name="complete">
              <span class="icon"><i class='bx bx-check'></i></span><span class="text">Complete</span>
            </label>

          </div>
          <div class="filters-checkboxes">
            <div class=""><span class="filters-checkboxes-title">Display Payment Status</span></div>

            <label class="form-check-inline radio filters-checkbox">
              <input class="form-check-input" type="radio"
                     v-model="isDisplayPaymentStatus"
                     :value="true">
              <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
            </label>

            <label class="form-check-inline radio filters-checkbox">
              <input class="form-check-input" type="radio"
                     v-model="isDisplayPaymentStatus"
                     :value="false">
              <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
            </label>

          </div>
          <div v-if="isDisplayPaymentStatus" class="filters-checkboxes">
            <div class=""><span class="filters-checkboxes-title">Show Payment Status</span></div>
            <label class="form-check-inline checkbox filters-checkbox">
              <input  class="form-check-input" type="checkbox"  v-model="isShowPaymentStatusAwaitingPayment">
              <span class="icon"><i class='bx bx-check'></i></span><span class="text" style="margin-top: -3px">Awaiting Payment</span>
            </label>
            <label class="form-check-inline checkbox filters-checkbox">
              <input  class="form-check-input" type="checkbox" v-model="isShowPaymentStatusPaid">
              <span class="icon"><i class='bx bx-check'></i></span><span class="text">Paid</span>
            </label>
          </div>
          <div v-if="isDisplayPaymentStatus" class="filters-checkboxes">
            <div class=""><span class="filters-checkboxes-title">Show Payment Date</span></div>
            <label class="form-check-inline radio filters-checkbox">
              <input class="form-check-input" type="radio"
                     v-model="isShowPaymentDate"
                     :value="true">
              <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
            </label>

            <label class="form-check-inline radio filters-checkbox">
              <input class="form-check-input" type="radio"
                     v-model="isShowPaymentDate"
                     :value="false">
              <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
            </label>
            </div>
          </div>
        </div>
      </div>
    <div class="row no-gutter" style="margin-top: 20px;  width: calc(100vw - 180px);    padding-left: 110px;">
      <div class="col-12">
        <b-table
            responsive
            hover
            :items="computedInvoiceDetailData"
            :fields="computedFieldsForInvoiceDetail"
            :sort-by="'invoiceNumber'"
            :sort-desc="true"
            class="report-table rs-scroll rs-scroll--y receivables-invoice-details"
        >
          <template v-slot:cell(total)="data">
            {{ data.item.total|formatMoney }}
          </template>
          <template v-slot:cell(gst)="data">
            {{ data.item.gst|formatMoney }}
          </template>
          <template v-slot:cell(subtotal)="data">
            {{ data.item.subtotal|formatMoney }}
          </template>
          <template v-slot:cell(isExcess)="data">
            <span v-if="data.item.isExcess">Excess</span>
            <span v-else>Complete</span>
          </template>
          <template v-slot:cell(isStatusPaid)="data">
            <span v-if="data.item.isStatusPaid">Paid</span>
            <span v-else>Awaiting Payment</span>
          </template>
          <template v-slot:cell(paymentType)="data">
            <span v-if="data.item.paymentType">{{ data.item.paymentType }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:cell(paymentDate)="data">
            <span v-if="data.item.paymentDate">{{ data.item.paymentDate }}</span>
            <span v-else>-</span>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '../../../utility/date-range-picker';
import Axios from "axios";
import _ from "lodash";
import {appConfig} from "../../../../config";

export default {
  name: "receivablesInvoiceDetails",
  data() {
    return {
      filter1: 'TM',
      filter2: 'ID',
      dateFilter: {
        start: '',
        end: ''
      },
      showFilters: false,
      invoiceDetailData: [],
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      q1: [0, 1, 2],
      q2: [3, 4, 5],
      q3: [6, 7, 8],
      q4: [9, 10, 11],

      isTypeExcess: true,
      isTypeComplete: true,
      isDisplayPaymentStatus: false,
      isShowPaymentStatusPaid: true,
      isShowPaymentStatusAwaitingPayment: true,
      isShowPaymentDate: true,

    };
  },
  components: {
    DatePicker,
  },
  computed: {
    computedInvoiceDetailData() {
      let data = this.invoiceDetailData;
      if (this.isTypeComplete && !this.isTypeExcess) {
        data = _.filter(data, (i) => {
          return !i.isExcess;
        });
      }
      if (!this.isTypeComplete && this.isTypeExcess) {
        data = _.filter(data, (i) => {
          return i.isExcess;
        });
      }

      if (this.isDisplayPaymentStatus && this.isShowPaymentStatusAwaitingPayment && !this.isShowPaymentStatusPaid) {
        data = _.filter(data, (i) => {
          return !i.isStatusPaid;
        });
      }

      if (this.isDisplayPaymentStatus && !this.isShowPaymentStatusAwaitingPayment && this.isShowPaymentStatusPaid) {
        data = _.filter(data, (i) => {
          return i.isStatusPaid;
        });
      }

      return data;
    },
    computedFieldsForInvoiceDetail() {
      let fields = [
        {label: "Invoice Number", key: "invoiceNumber", sortable: true, tdClass: '',},
        {label: "Invoice Date", key: "invoiceCreationDate", sortable: true, tdClass: '',},
        {label: "Due Date", key: "dueDate", sortable: true, tdClass: '',},
        {label: "Rego", key: "rego", sortable: true, tdClass: '',},
        {label: "Vehicle Owner", key: "vehicleOwner", sortable: true, tdClass: '',},
        {label: "Subtotal", key: "subtotal", sortable: true, tdClass: '',},
        {label: "GST", key: "gst", sortable: true, tdClass: '',},
        {label: "Grand Total", key: "total", sortable: true, tdClass: '',},
        {label: 'Type', key: 'isExcess', sortable: true, tdClass: ''},
        // {label: 'Status', key: 'isStatusPaid', sortable: true, tdClass: ''},
        {label: 'Payment Type', key: 'paymentType', sortable: true, tdClass: ''},
        // {label: 'Payment Date', key: 'paymentDate', sortable: true, tdClass: ''},
      ];
      if (this.isDisplayPaymentStatus && (this.isShowPaymentStatusAwaitingPayment || this.isShowPaymentStatusPaid)) {
        fields.push({label: 'Status', key: 'isStatusPaid', sortable: true, tdClass: ''});
      }
      if (this.isDisplayPaymentStatus && this.isShowPaymentDate) {
        fields.push({label: 'Payment Date', key: 'paymentDate', sortable: true, tdClass: ''});
      }
      return fields;
    },

    computedUrl() {
      let startDate = '';
      let endDate = '';
      let filter2 = '';
      let numberOfCurrentMonth = this.currentMonth + 1;
      let dateForFirstMonths = this.currentYear + '0' + numberOfCurrentMonth;
      let dateForLastMonths = this.currentYear + numberOfCurrentMonth;
      let dateForFirstMonths2 = this.currentYear + '0' + this.currentMonth;
      let dateForLastMonths2 = this.currentYear + this.currentMonth;

      if (this.filter2 === 'ID') {
        filter2 = 'invoice-date';
      } else if (this.filter2 === 'DD') {
        filter2 = 'due-date';
      } else if (this.filter2 === 'PD') {
        filter2 = 'payment-date';
      }

      if (this.filter1 === 'TM') {
        if (this.currentMonth >= 0 && this.currentMonth <= 8) {
          startDate = dateForFirstMonths + '01';
        } else if (this.currentMonth >= 9 && this.currentMonth <= 11) {
          startDate = dateForLastMonths + '01';
        }

        if (this.currentMonth === 0 || this.currentMonth === 2 || this.currentMonth === 4 || this.currentMonth === 6 || this.currentMonth === 7) {
          endDate = dateForFirstMonths + '31';
        } else if (this.currentMonth === 9 || this.currentMonth === 11) {
          endDate = dateForLastMonths + '31';
        } else if (this.currentMonth === 3 || this.currentMonth === 5 || this.currentMonth === 8) {
          endDate = dateForFirstMonths + '30';
        } else if (this.currentMonth === 10) {
          endDate = dateForLastMonths + '30';
        } else if (this.currentMonth === 1) {
          if (this.isLeapYear) {
            endDate = dateForLastMonths + '29';
          } else {
            endDate = dateForLastMonths + '28';
          }
        }

      } else if (this.filter1 === 'TQ') {
        if (this.q1.includes(this.currentMonth)) {
          startDate = this.currentYear + this.computedQuarters[0].startDate;
          endDate = this.currentYear + this.computedQuarters[0].endDate;
        } else if (this.q2.includes(this.currentMonth)) {
          startDate = this.currentYear + this.computedQuarters[1].startDate;
          endDate = this.currentYear + this.computedQuarters[1].endDate;
        } else if (this.q3.includes(this.currentMonth)) {
          startDate = this.currentYear + this.computedQuarters[2].startDate;
          endDate = this.currentYear + this.computedQuarters[2].endDate;
        } else if (this.q4.includes(this.currentMonth)) {
          startDate = this.currentYear + this.computedQuarters[3].startDate;
          endDate = this.currentYear + this.computedQuarters[3].endDate;
        }
      } else if (this.filter1 === 'TFY') {
        let today = new Date();
        today.setDate(today.getDate() + 1);
        let newDate = new Date(today);
        let currentDay = newDate.getDate();

        if (this.currentMonth >= 0 && this.currentMonth <= 5) {
          startDate = this.lastYear + '0701';
        } else if (this.currentMonth >= 6 && this.currentMonth <= 11) {
          startDate = this.currentYear + '0701';
        }

        if (this.currentMonth >=0 && this.currentMonth <= 8) {
          endDate = dateForFirstMonths + currentDay;
        } else if (this.currentMonth >=9 && this.currentMonth <= 11) {
          endDate = dateForLastMonths + currentDay;
        }
      } else if (this.filter1 === 'LM') {

        if (this.currentMonth >= 1 && this.currentMonth <= 9) {
          startDate = dateForFirstMonths2 + '01';
        } else if (this.currentMonth === 10 || this.currentMonth === 11) {
          startDate = dateForLastMonths2 + '01';
        } else if (this.currentMonth === 0) {
          startDate = this.lastYear + '1201';
        }

       if (this.currentMonth === 1 || this.currentMonth === 3 || this.currentMonth === 5 || this.currentMonth === 7 || this.currentMonth === 8) {
         endDate = dateForFirstMonths2 + '31';
       } else if (this.currentMonth === 10) {
         endDate = dateForLastMonths2 + '31';
       } else if (this.currentMonth === 4 || this.currentMonth === 6 || this.currentMonth === 9) {
         endDate = dateForFirstMonths2 + '30';
       } else if (this.currentMonth === 11) {
         endDate = dateForLastMonths2 + '30';
       } else if (this.currentMonth === 0) {
         endDate = this.lastYear + '1231';
       } else if (this.currentMonth === 2) {
         if (this.isLeapYear) {
           endDate = dateForFirstMonths2 + '29';
         } else {
           endDate = dateForFirstMonths2 + '28';
         }
       }
      } else if (this.filter1 === 'LQ') {
        if (this.q1.includes(this.currentMonth)) {
          startDate = this.lastYear + this.computedQuarters[3].startDate;
          endDate = this.lastYear + this.computedQuarters[3].endDate;
        } else if (this.q2.includes(this.currentMonth)) {
          startDate = this.currentYear + this.computedQuarters[0].startDate;
          endDate = this.currentYear + this.computedQuarters[0].endDate;
        } else if (this.q3.includes(this.currentMonth)) {
          startDate = this.currentYear + this.computedQuarters[1].startDate;
          endDate = this.currentYear + this.computedQuarters[1].endDate;
        } else if (this.q4.includes(this.currentMonth)) {
          startDate = this.currentYear + this.computedQuarters[2].startDate;
          endDate = this.currentYear + this.computedQuarters[2].endDate;
        }
      } else if (this.filter1 === 'LFY') {
          if (this.currentMonth >= 0 && this.currentMonth <= 5) {
            startDate = (this.lastYear - 1) + '0701';
            endDate = this.lastYear + '0630'
          } else if (this.currentMonth >= 6 && this.currentMonth <= 11) {
            startDate = this.lastYear + '0701';
            endDate = this.currentYear + '0630'
          }
      } else if (this.filter1 === 'CDR') {
        if (this.dateFilter) {
          let [startDay, startMonth, startYear] = this.dateFilter.start.split('/');
          let [endDay, endMonth, endYear] = this.dateFilter.end.split('/');

          startDate = startYear + startMonth + startDay;
          endDate = endYear + endMonth + endDay;
        }
      }

      return '/fe/reports/invoice/details/dates/' + startDate + '/' + endDate + '/' + filter2;
    },
    computedDateFilterValue() {
      if (!this.dateFilter || !this.dateFilter.start || !this.dateFilter.end) {
        return '';
      }
      let [startDay, startMonth, startYear] = this.dateFilter.start.split('/');
      let [endDay, endMonth, endYear] = this.dateFilter.end.split('/');

      let startDate = new Date(startYear, Number(startMonth) - 1, startDay);
      let endDate = new Date(endYear, Number(endMonth) - 1, endDay);

      let startResult = startDate.getDate() + ' ' + this.months[Number(startMonth) - 1] + ' ' + startDate.getFullYear();
      let endResult = endDate.getDate() + ' ' + this.months[Number(endMonth) - 1] + ' ' + endDate.getFullYear();

      return startResult + ' - ' + endResult;
    },
    computedLastMonth() {
      if (this.currentMonth === 0) {
        return 'December ' + this.lastYear;
      } else {
        let month = this.months[this.currentMonth - 1];
        return month + ' ' + this.currentYear;
      }
    },
    computedLastQuarter() {
      if (this.q1.includes(this.currentMonth)) {
        return this.computedQuarters[3].name + (this.lastYear);
      } else if (this.q2.includes(this.currentMonth)) {
        return this.computedQuarters[0].name + this.currentYear;
      } else if (this.q3.includes(this.currentMonth)) {
        return this.computedQuarters[1].name + this.currentYear;
      } else if (this.q4.includes(this.currentMonth)) {
        return this.computedQuarters[2].name + this.currentYear;
      }
    },
    computedLastFinancialYear() {
      if (this.currentMonth >= 0 && this.currentMonth <= 5) {
        return '1 July ' + (this.lastYear - 1) + ' - 30 June '+ this.lastYear;
      }
      return '1 July ' + this.lastYear + ' - 30 June '+ this.currentYear;
    },
    computedCurrentMonth() {
      return this.nameOfCurrentMonth + ' ' + this.currentYear;
    },
    computedCurrentQuarter() {
      if (this.q1.includes(this.currentMonth)) {
        return this.computedQuarters[0].name + this.currentYear;
      } else if (this.q2.includes(this.currentMonth)) {
        return this.computedQuarters[1].name + this.currentYear;
      } else if (this.q3.includes(this.currentMonth)) {
        return this.computedQuarters[2].name + this.currentYear;
      } else if (this.q4.includes(this.currentMonth)) {
        return this.computedQuarters[3].name + this.currentYear;
      }
    },
    computedCurrentFinancialYear() {
      let year = this.currentYear;

      if (this.currentMonth >= 0 && this.currentMonth <= 5) {
        year = this.lastYear;
      }

      return '1 July ' + year  + ' - ' + this.currentDate + ' ' + this.nameOfCurrentMonth + ' '+ this.currentYear;
    },
    computedQuarters() {
      return [
        {
          name: '1 January - 31 March ',
          startDate: '0101',
          endDate: '0331',
        },
        {
          name: '1 April - 30 June ',
          startDate: '0401',
          endDate: '0630',
        },
        {
          name: '1 July - 30 September ',
          startDate: '0701',
          endDate: '0930',
        },
        {
          name: '1 October - 31 December ',
          startDate: '1001',
          endDate: '1231',
        },
      ]
    },
    nameOfCurrentMonth() {
      return this.months[this.currentMonth];
    },
    currentDate() {
      return new Date().getDate();
    },
    currentMonth() {
      return new Date().getMonth();
    },
    currentYear() {
      return new Date().getFullYear();
    },
    lastYear() {
      return new Date().getFullYear() - 1;
    },
    isLeapYear() {
      return !((this.currentYear % 4) || (!(this.currentYear % 100) && (this.currentYear % 400)));
    },
    computedSelectedFilter() {
      if (this.filter1 === 'TM') {
        return this.computedCurrentMonth;
      } else if (this.filter1 === 'TQ') {
        return this.computedCurrentQuarter;
      } else if (this.filter1 === 'TFY') {
        return this.computedCurrentFinancialYear;
      } else if (this.filter1 === 'LM') {
        return this.computedLastMonth;
      } else if (this.filter1 === 'LQ') {
        return this.computedLastQuarter;
      } else if (this.filter1 === 'LFY') {
        return this.computedLastFinancialYear;
      } else if (this.filter1 === 'CDR') {
        return this.computedDateFilterValue;
      }
    },
    computedSelectedFilter2() {
      if (this.filter2 === 'ID') {
        return 'Invoice Date';
      } else if (this.filter2 === 'PD') {
        return 'Paid Date';
      } else if (this.filter2 === 'DD') {
        return 'Due Date';
      }
    },
  },
  methods: {
    activateDateFilterInput() {
      this.$refs.datePicker.focusInput();
    },
    updateDataFilter(val) {
      this.selectedFilter1('CDR');
      this.dateFilter = val;
    },
    getCurrentFiltersForDetails() {
      let filters = '';
      if (this.isTypeExcess === false || this.isTypeComplete === false) {
        if (this.isTypeExcess === true) {
          filters += 'isTypeExcess;';
        }
        if (this.isTypeComplete === true) {
          filters += 'isTypeComplete;';
        }
      }
      if (this.isDisplayPaymentStatus === true) {
        filters += 'isDisplayPaymentStatusYes;';
      }
      if (this.isDisplayPaymentStatus === true && this.isShowPaymentStatusAwaitingPayment === true) {
        filters += 'isShowPaymentStatusAwaitingPayment;';
      }
      if (this.isDisplayPaymentStatus === true && this.isShowPaymentStatusPaid === true) {
        filters += 'isShowPaymentStatusPaid;';
      }
      if (this.isDisplayPaymentStatus === true && this.isShowPaymentDate === true) {
        filters += 'isShowPaymentDateYes;';
      }
      if (filters == '') {
        return false;
      }
      return filters;
    },
    exportReport(format) {
      let filters = this.getCurrentFiltersForDetails();
      let token = localStorage.getItem('token');
      let url = appConfig.baseAPIURL;
      url += this.computedUrl;
      url += '/' + format;
      url += '?at=' + token;
      if (filters) {
        url += '&filters=' + filters;
      }
      window.open(url);
      return;
    },
    selectedFilter1(filter) {
      this.filter1 = filter;
      this.loadInvoices();
    },
    selectedFilter2(filter) {
      this.filter2 = filter;
      this.loadInvoices();
    },
    loadInvoices() {
      NProgress.start();
      let url = this.computedUrl;
      Axios.get(url)
          .then(response => {
            if (response.data._status) {
              this.invoiceDetailData = response.data.data;
            } else {
              this.invoiceDetailData = [];
            }
          })
          .catch(error => {
            console.log(error);

          }).finally(() => {
        NProgress.done();
      });
    }
  },
  mounted() {
    this.loadInvoices();
  }
};
</script>

<style>
  .receivables-invoice-details .table thead th {
    vertical-align: middle !important;
  }
</style>

<style scoped>
  .active-filters-control {
    display: flex;
    flex-direction: column;
    width: 509px;
    position: relative;
    left: -360px;
    top: 5px;
    border: 1px solid rgba(27, 30, 56, 0.25);
  }

  .filters-checkboxes {
    padding: 10px 0 15px 15px;
    display: flex;
    justify-content: end;
  }

  .filters-checkboxes:first-child {
    padding-top: 20px;
  }

  .filters-checkboxes-title {
    display: inline-block !important;
    width: 200px;
  }

  .filters-checkbox {
    display: flex;
    margin-right: 30px;
    display: inline-block !important;
    width: 105px;
  }
</style>