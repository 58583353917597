<template>
  <div v-if="value.production" style="margin-top: 15px; padding-left: 15px;" class="card row no-gutter">
    <div class="col-lg-6 col-12">
      <div class="card-header bg-navyblue">
        <strong>Definition</strong>
      </div>
      <p class="description-header" style="padding: 15px">Set the production times that will appear
        in the Estimate / Production Time <br>
        tabs approximate job scheduling.</p>
      <div class="card-block bg-white">
        <div class="form-group row no-gutter rs-toggle">
          <div class="card-block-table card-block-table-1 d-flex">
            <div class="card-block-table-title col-lg-4" style="width: 33%">Category Name</div>
            <div class="card-block-table-title col-lg-1" style="padding: 0 5px; width: 8%">Nbr of Days</div>
            <div class="card-block-table-title col-lg-5" style="width: 42%">Booking Colour</div>
            <div class="card-block-table-title col-lg-1" style="padding-left: 5px; width: 8%">Qty(p/d)</div>
            <div class="card-block-table-title col-lg-1" style="width: 9%"></div>
          </div>

          <draggable
              v-model="value.production.definitions.categories"
              class="dragArea"
              handle=".handle"
              :animation="150"
              :group="{ name: 'items', pull: 'clone', put: false }"
              :delayOnTouchOnly="true"
              :disabled="!isStatusActive"
          >
            <div class="card-block-table-item card-block-table-1-item d-flex handle" v-for="(itm, index) in value.production.definitions.categories" :key="index">
              <div class="card-block-table-item-elem col-lg-4" style="width: 33%">
                <input type="text"
                       class="form-control"
                       v-model="itm.name"
                       placeholder="">
                <span class="error-text" v-if="isAlreadyExist(itm.name)">Name must be uniq</span>
              </div>
              <div class="card-block-table-item-elem col-lg-1" style="padding: 0 5px; width: 8%">
                <input type="text"
                       style="padding: 0 5px; min-width: 30px"
                       class="form-control"
                       @keypress="onlyNumber"
                       v-model="itm.count"
                       placeholder="0">
              </div>
              <div class="card-block-table-item-elem card-block-table-item-elem-multiselect col-lg-5" style=" width: 42%; white-space: nowrap;">
                <multiselect :options="computedGroups"
                             v-model="itm.colorId"
                             :showLabels="false"
                             :disabled="!isStatusActive"
                             style="opacity: 1; height: 32px"
                             :option-height="31.5"
                             :close-on-select="true"
                >
                  <template #singleLabel="{ option }">
                    <span style="top: 3px" class="categories-menu-color-icon" :style="{backgroundColor: getGroup(option).color}"></span> <span style="top: 0px">{{getGroup(option).name}}</span>
                  </template>
                  <template #option="{ option }">
                    <span style="top: 3px" class="categories-menu-color-icon" :style="{backgroundColor: getGroup(option).color}"></span> <span style="top: 0px">{{getGroup(option).name}}</span>
                  </template>
                </multiselect>
              </div>
              <div class="card-block-table-item-elem col-lg-1" style="padding: 0 5px; width: 8%">
                <input type="text"
                       v-model="itm.qty"
                       @keypress="onlyNumber"
                       style="padding: 0 5px; min-width: 30px; opacity: 1"
                       class="form-control"
                       placeholder="0">
              </div>
              <div class="card-block-table-item-elem col-lg-1" style="padding: 0 5px; width: 8%">
                <i class='bx bx-trash' style="cursor: pointer" @click="value.production.definitions.categories.splice(index, 1)"></i>
              </div>
            </div>
          </draggable>


          <div class="card-block-table-item-btn">
            <button class="btn btn-primary" @click="value.production.definitions.categories.push({name: null, count: null, colorId: null, qty: 10 })">+ Add Category</button>
          </div>
        </div>

        <div class="form-group row no-gutter rs-toggle">
          <div class="card-block-table d-flex" style="border-top: 2px solid #d7d7d7; padding-top: 10px; border-bottom: 0;">
            <div class="card-block-table-title col-lg-6"></div>
            <div class="card-block-table-title col-lg-5">Nbr of Days</div>
            <div class="card-block-table-title col-lg-1"></div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-6">
              <span>Weld On Panels</span>
            </div>
            <div class="card-block-table-item-elem col-lg-5">
              <input type="text"
                     class="form-control"
                     @keypress="onlyNumber"
                     v-model="value.production.definitions.numberOfDays.weld"
                     placeholder="0">
            </div>
            <div class="card-block-table-item-elem col-lg-1"></div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-6">
              <span>Mechanical</span>
            </div>
            <div class="card-block-table-item-elem col-lg-5">
              <input type="text"
                     class="form-control"
                     @keypress="onlyNumber"
                     v-model="value.production.definitions.numberOfDays.mechanical"
                     placeholder="0">
            </div>
            <div class="card-block-table-item-elem col-lg-1"></div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-6">
              <span>Hail</span>
            </div>
            <div class="card-block-table-item-elem col-lg-5">
              <input type="text"
                     class="form-control"
                     @keypress="onlyNumber"
                     v-model="value.production.definitions.numberOfDays.hail"
                     placeholder="0">
            </div>
            <div class="card-block-table-item-elem col-lg-1"></div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-6">
              <span>Electrical</span>
            </div>
            <div class="card-block-table-item-elem col-lg-5">
              <input type="text"
                     class="form-control"
                     @keypress="onlyNumber"
                     v-model="value.production.definitions.numberOfDays.electrical"
                     placeholder="0">
            </div>
            <div class="card-block-table-item-elem col-lg-1"></div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-6">
              <span>PPF or Wrap</span>
            </div>
            <div class="card-block-table-item-elem col-lg-5">
              <input type="text"
                     class="form-control"
                     @keypress="onlyNumber"
                     v-model="value.production.definitions.numberOfDays.ppf"
                     placeholder="0">
            </div>
            <div class="card-block-table-item-elem col-lg-1"></div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-6">
              <span>Accessories (Outsrc)</span>
            </div>
            <div class="card-block-table-item-elem col-lg-5">
              <input type="text"
                     class="form-control"
                     @keypress="onlyNumber"
                     v-model="value.production.definitions.numberOfDays.accessories"
                     placeholder="0">
            </div>
            <div class="card-block-table-item-elem col-lg-1"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-12 pl-1">
      <div class="card-header bg-navyblue">
        <strong>Production Days</strong>
      </div>
      <p class="description-header" style="padding: 15px">Set the days in which your workshop is open for use in managing booking.</p>
      <div class="card-block bg-white">
        <div class="form-group row no-gutter rs-toggle">
          <div class="card-block-table d-flex">
            <div class="card-block-table-title col-lg-4">Category Name</div>
            <div class="card-block-table-title col-lg-6">Operation Status</div>
            <div class="card-block-table-title col-lg-2"></div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-4">
              Monday
            </div>
            <div class="card-block-table-item-elem card-block-table-item-elem-multiselect col-lg-6">

              <multiselect :options="['true', 'false']"
                           v-model="value.production.days['1']"
                           :showLabels="false"
                           :disabled="!isStatusActive"
                           style="opacity: 1; height: 32px"
                           :option-height="31.5"
                           :close-on-select="true"
              >
                <template #singleLabel="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
                <template #option="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
              </multiselect>

            </div>
            <div class="card-block-table-item-elem col-lg-2">
            </div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-4">
              Thuesday
            </div>
            <div class="card-block-table-item-elem card-block-table-item-elem-multiselect col-lg-6">
              <multiselect :options="['true', 'false']"
                           v-model="value.production.days['2']"
                           :showLabels="false"
                           :disabled="!isStatusActive"
                           style="opacity: 1; height: 32px"
                           :option-height="31.5"
                           :close-on-select="true"
              >
                <template #singleLabel="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
                <template #option="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
              </multiselect>
            </div>
            <div class="card-block-table-item-elem col-lg-2">
            </div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-4">
              Wednesday
            </div>
            <div class="card-block-table-item-elem card-block-table-item-elem-multiselect col-lg-6">
              <multiselect :options="['true', 'false']"
                           v-model="value.production.days['3']"
                           :showLabels="false"
                           :disabled="!isStatusActive"
                           style="opacity: 1; height: 32px"
                           :option-height="31.5"
                           :close-on-select="true"
              >
                <template #singleLabel="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
                <template #option="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
              </multiselect>
            </div>
            <div class="card-block-table-item-elem col-lg-2">
            </div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-4">
              Thursday
            </div>
            <div class="card-block-table-item-elem card-block-table-item-elem-multiselect col-lg-6">
              <multiselect :options="['true', 'false']"
                           v-model="value.production.days['4']"
                           :showLabels="false"
                           :disabled="!isStatusActive"
                           style="opacity: 1; height: 32px"
                           :option-height="31.5"
                           :close-on-select="true"
              >
                <template #singleLabel="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
                <template #option="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
              </multiselect>
            </div>
            <div class="card-block-table-item-elem col-lg-2">
            </div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-4">
              Friday
            </div>
            <div class="card-block-table-item-elem card-block-table-item-elem-multiselect col-lg-6">
              <multiselect :options="['true', 'false']"
                           v-model="value.production.days['5']"
                           :showLabels="false"
                           :disabled="!isStatusActive"
                           style="opacity: 1; height: 32px"
                           :option-height="31.5"
                           :close-on-select="true"
              >
                <template #singleLabel="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
                <template #option="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
              </multiselect>
            </div>
            <div class="card-block-table-item-elem col-lg-2">
            </div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-4">
              Saturday
            </div>
            <div class="card-block-table-item-elem card-block-table-item-elem-multiselect col-lg-6">
              <multiselect :options="['true', 'false']"
                           v-model="value.production.days['6']"
                           :showLabels="false"
                           :disabled="!isStatusActive"
                           style="opacity: 1; height: 32px"
                           :option-height="31.5"
                           :close-on-select="true"
              >
                <template #singleLabel="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
                <template #option="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
              </multiselect>
            </div>
            <div class="card-block-table-item-elem col-lg-2">
            </div>
          </div>
          <div class="card-block-table-item">
            <div class="card-block-table-item-elem col-lg-4">
              Sunday
            </div>
            <div class="card-block-table-item-elem card-block-table-item-elem-multiselect col-lg-6">
              <multiselect :options="['true', 'false']"
                           v-model="value.production.days['0']"
                           :showLabels="false"
                           :disabled="!isStatusActive"
                           style="opacity: 1; height: 32px"
                           :option-height="31.5"
                           :close-on-select="true"
              >
                <template #singleLabel="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
                <template #option="{ option }">
                  {{ option == 'true' ? 'Open' : (option == 'false' ? 'Closed': '') }}
                </template>
              </multiselect>
            </div>
            <div class="card-block-table-item-elem col-lg-2">
            </div>
          </div>
        </div>
      </div>
      <div class="card-header bg-navyblue">
        <strong>Production Targets</strong>
      </div>
      <div class="card-block-table-item">
        <div class="card-block-table-item-elem col-lg-5">
          <span>Weekly Job Totals Target</span>
        </div>
        <div class="card-block-table-item-elem card-block-table-item-elem-multiselect col-lg-5">
          <number-formatter
              type="text"
              v-model="value.production.targets.job"
              class="noselect form-control numeric"
              :isDisabled="!isStatusActive"
              format="$0,0.00"
          >
          </number-formatter>
        </div>
      </div>
      <div class="card-block-table-item">
        <div class="card-block-table-item-elem col-lg-5">
          <span>Weekly Labour Totals Targets</span>
        </div>
        <div class="card-block-table-item-elem card-block-table-item-elem-multiselect col-lg-5">
          <number-formatter
              type="text"
              v-model="value.production.targets.labour"
              class="noselect form-control numeric"
              :isDisabled="!isStatusActive"
              format="$0,0.00"
          >
          </number-formatter>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import Axios from "axios";
import NumberFormatter from '../../utility/number-formatter'
import Multiselect from "vue-multiselect";
import draggable from 'vuedraggable'

export default {
  name: "the-categories",
  props: {
    value: {}
  },
  components: {
    Multiselect,
    NumberFormatter,
    draggable
  },
  data() {
    return {
      groups: [],
    }
  },
  mounted() {
    this.loadCategories()
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    }),
    computedGroups() {
      return _.map(this.groups, (g) => {
        return g.id
      })
    }
  },
  methods: {
    isAlreadyExist(n) {
      return _.filter(this.value.production.definitions.categories, (v) => {
        return _.toLower(v.name) == _.toLower(n)
      })?.length >= 2
    },
    getGroup(id) {
      let r = _.find(this.groups, (g) => {
        return Number(g.id) == Number(id)
      })
      if (r) {
        return r
      }
      return {
        name: null,
        color: null
      }
    },
    onlyNumber (event) {
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 32 && keyCode !== 45 && keyCode !== 43 && keyCode !== 40 && keyCode !== 41) {
        event.preventDefault();
      }
    },
    loadCategories() {
      Axios.get('/fe/planner-board/groups')
          .then(response => {
            if (response.data.groups && response.data._status) {
              this.groups = response.data.groups
            }
          })
          .finally(()=> {})
    },
  }
}
</script>


<style scoped>
.dragArea .sortable-chosen .card-block-table-item-elem input{
  border-color: #5e77fc;
}
.dragArea .sortable-chosen .card-block-table-item-elem >>> .multiselect__tags {
  border-color: #5e77fc;
}
.card-block-table{
  padding-bottom: 10px;
  border-bottom: 2px solid #d7d7d7;
}
.card-block-table-title{
  font-weight: 700;
}
.card-block-table-item-elem{
  margin-top: 10px;
}
.card-block-table-item-elem .bx-trash{
  font-size: 24px;
  color: #fd5d5d;
  position: relative;
  top: 3px;
}
.card-block-table-item-btn{
  float: right;
  margin-top: 20px;
}
.card-block-table-item-elem span{
  font-weight: bold;
  position: relative;
  top: 5px;
}
@media screen and (max-width: 1390px) {
  .card-block-table-1 .card-block-table-title:first-child{
    width: 25% !important;
  }
  .card-block-table-1 .card-block-table-title:nth-child(3){
    width: 50% !important;
  }
  .card-block-table-1-item .card-block-table-item-elem:first-child{
    width: 25% !important;
  }
  .card-block-table-1-item .card-block-table-item-elem:nth-child(3){
    width: 50% !important;
    padding-left: 8px;
  }
  .card-block-table-1-item .card-block-table-item-elem{
    padding-left: 3px;
    padding-right: 3px;
  }
}
</style>
