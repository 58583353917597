import _ from "lodash";
import Axios from "axios";

export const Actions = {
    loadSettings(context) {
        Axios.get("/fe/settings/vendor")
            .then(response => {
                if (response.data) {
                    context.commit('isOverLimitUpdate', { limit: response.data.billing.info.storage.limit, consumed: response.data.billing.info.storage.consumed});
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },

};
