<script>
	import Axios from 'axios';
	import FileUploader from './utility/file-uploader';

	export default {
	name: 'profile',
	data: function () {
		return {
			profile_image: null,
			password: '',
			new_password: '',
			confirm_new_password: ''
		}
	},
	methods: {
		updateInfo: function () {
			NProgress.start();
			Axios.post('/fe/user/info', JSON.stringify({data: this.updatedUserInfo, profile_image: this.profile_image}))
				.then(response => {
					NProgress.done()
					toastr.success(response.data.msg)
					this.$store.dispatch('loadUserInfo')
				})
				.catch(error => {
					NProgress.done()
					toastr.error(error)
				})
		},
		updatePassword: function () {
			NProgress.start();
			Axios({
				method: 'post',
				responseType: 'json',
				url: '/fe/user/pwd',
				validateStatus: function (status) {
					return status < 500
				},
				data: JSON.stringify({password: this.password, new_password: this.new_password, confirm_new_password: this.confirm_new_password})
			})
			// Axios.post('/fe/user/pwd', JSON.stringify({password: this.password, new_password: this.new_password, confirm_new_password: this.confirm_new_password}))
				.then(response => {
					NProgress.done()
					if (response.status == 200) {
						toastr.success(response.data.msg)
						this.$store.dispatch('loadUserInfo')
					} else {
						toastr.error(response.data.msg)
					}
				})
				.catch((error) => {
					NProgress.done()
					toastr.error(error)
				})
		},
		addImage: function (img) {
		  if (img == '' || img === undefined || img === null) {
			  return
		  } else {
			  this.profile_image = img
		  }
	  	}
	},
	computed: {
		userInfo: function () {
			return this.$store.state.userInfo;
		},
		updatedUserInfo: function () {
			return {
				user_id: this.userInfo.user_id,
				full_name: this.userInfo.full_name,
				user_email: this.userInfo.user_email,
				mobile_phone: this.userInfo.mobile_phone,
				work_phone: this.userInfo.work_phone,
				user_address: this.userInfo.user_address,

				first_name: this.userInfo.first_name,
				last_name: this.userInfo.last_name,
				job_title: this.userInfo.job_title,
				phone_area_code: this.userInfo.phone_area_code,
				website: this.userInfo.website,
				profile_picture: this.userInfo.profile_picture,

				vendor_id: this.userInfo.vendor_id,
				business_name: this.userInfo.business_name,
				abn: this.userInfo.abn,
				vendor_phone: this.userInfo.vendor_phone,
				vendor_email: this.userInfo.vendor_email,
				vendor_location: this.userInfo.vendor_location,
			}
		},
		drOptions: function () {
			return {
				defaultFile: '/img/avatars/' + this.userInfo.profile_picture,
				messages: {
					'default': 'Drag and drop an image here or click',
					'replace': 'Drag and drop or click to add new image',
					'remove':  'Remove',
					'error':   'Ooops, something went wrong.'
				}
			}
		}
	},
	mounted: function () {
		this.$store.dispatch('loadUserInfo')
		this.drOptions.defaultFile = this.updatedUserInfo.profile_picture
	},
	watch: {
		profile_image: function () {
			// this.addImage(this.image)
			// consle.log(this.profile_image)
		}
	},
	components: {
		FileUploader
	}
}
</script>

<template>
<div>
	<div class="page-header">
<h4>Profile</h4>
		<ol class="breadcrumbs">
			<li class="breadcrumb-item home">
				<router-link to="/">Home</router-link>
			</li>
	<li class="breadcrumb-item active">Profile</li>
</ol>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="card card-block bg-white">

				<div class="card-block card bg-white mb-0">

				<div class="row">
					<div class="col-md-3 float-md-right mb-1">
						<file-uploader v-if="userInfo.profile_picture" :options="drOptions" v-model="profile_image" accept="image/*"></file-uploader>
						<p class="font-90 text-muted mb-20 text-center">Click on the above image to replace it with a new one.</p>
					</div>

					<div class="col-md-9">
						<h5 class="mb-1">Basic Information</h5>
						<div class="form-group row">
							<label for="full_name" class="col-sm-2 col-form-label">Name</label>
							<div class="col-sm-10">
								<div class="form-group row mb-0">
									<!-- <input type="text" v-model="updatedUserInfo.full_name" class="form-control" id="full_name" placeholder="Full Name"> -->
									<div class="col-xs-6"><input type="text" v-model="updatedUserInfo.first_name" class="form-control" id="first_name" placeholder="First Name"></div>
									<div class="col-xs-6"><input type="text" v-model="updatedUserInfo.last_name" class="form-control" id="last_name" placeholder="Surname"></div>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<label for="job_title" class="col-sm-2 col-form-label">Job Title</label>
							<div class="col-sm-10">
								<input type="text" v-model="updatedUserInfo.job_title" class="form-control" id="job_title" placeholder="Job Title">
							</div>
						</div>
						<div class="form-group row mb-2">
							<label for="user_address" class="col-sm-2 col-form-label">Location</label>
							<div class="col-sm-10">
								<input type="text" v-model="updatedUserInfo.user_address" class="form-control" id="user_address" placeholder="Suburb">
							</div>
						</div>

						<h5 class="mb-1">Contact Details</h5>
						<div class="form-group row">
							<label for="mobile_phone" class="col-sm-2 col-form-label">Mobile phone</label>
							<div class="col-sm-10">
								<div class="form-group row mb-0">
									<!-- <input type="text" v-model="updatedUserInfo.full_name" class="form-control" id="full_name" placeholder="Full Name"> -->

									<div class="col-xs-10"><input type="text" v-model="updatedUserInfo.mobile_phone" class="form-control" id="mobile_phone" placeholder="Number"></div>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<label for="work_phone" class="col-sm-2 col-form-label">Work phone</label>
							<div class="col-sm-10">
								<div class="form-group row mb-0">
									<!-- <input type="text" v-model="updatedUserInfo.full_name" class="form-control" id="full_name" placeholder="Full Name"> -->

									<div class="col-xs-10"><input type="text" v-model="updatedUserInfo.work_phone" class="form-control" id="work_phone" placeholder="Number"></div>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<label for="user_email" class="col-sm-2 col-form-label">Email</label>
							<div class="col-sm-10">
								<input type="text" v-model="updatedUserInfo.user_email" class="form-control" id="user_email" placeholder="Email" readonly>
							</div>
						</div>
						<div class="form-group row">
							<label for="website" class="col-sm-2 col-form-label">Website</label>
							<div class="col-sm-10">
								<input type="text" v-model="updatedUserInfo.website" class="form-control" id="website" placeholder="Website">
							</div>
						</div>
						<div class="form-group row">
							<div class="col-sm-12">
								<button type="button" @click="updateInfo()" class="btn btn-primary w-min-sm mb-0-25 waves-effect waves-light pull-right w-min-lg">Save</button>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>


			<div class="card card-block bg-white">

				<div class="card-block card bg-white mb-0">

				<div class="row">
					<div class="col-md-9">
						<h5 class="mb-1">Password</h5>
						<div class="form-group row">
							<label for="current_password" class="col-sm-2 col-form-label">Current Password</label>
							<div class="col-sm-10">
								<input type="password" v-model="password" class="form-control" id="current_password" placeholder="Current Password">
							</div>
						</div>
						<div class="form-group row">
							<label for="new_password" class="col-sm-2 col-form-label">New Password</label>
							<div class="col-sm-10">
								<input type="password" v-model="new_password" class="form-control" id="new_password" placeholder="New Password">
							</div>
						</div>
						<div class="form-group row">
							<label for="confirm_new_password" class="col-sm-2 col-form-label">Confirm New Password</label>
							<div class="col-sm-10">
								<input type="password" v-model="confirm_new_password" class="form-control" id="confirm_new_password" placeholder="Confirm New Password">
							</div>
						</div>
						<div class="form-group row">
							<div class="col-sm-12">
								<button type="button" @click="updatePassword()" class="btn btn-primary w-min-sm mb-0-25 waves-effect waves-light pull-right w-min-lg">Change Password</button>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
		<!-- </div>
		<div class="col-md-6">
			<div class="box box-block b-a-success">
				<h5 class="h-underline h-underline-100 h-underline-primary">Business</h5>

				<div class="form-group row">
					<label for="business_name" class="col-sm-3 col-form-label">Business Name</label>
					<div class="col-sm-9">
						<input type="text" v-model="updatedUserInfo.business_name" class="form-control" id="business_name" placeholder="Business Name">
					</div>
				</div>
				<div class="form-group row">
					<label for="abn" class="col-sm-3 col-form-label">ABN</label>
					<div class="col-sm-9">
						<input type="text" v-model="updatedUserInfo.abn" class="form-control" id="abn" placeholder="Australian Business Number">
					</div>
				</div>
				<div class="form-group row">
					<label for="vendor_phone" class="col-sm-3 col-form-label">Phone</label>
					<div class="col-sm-9">
						<input type="text" v-model="updatedUserInfo.vendor_phone" class="form-control" id="vendor_phone" placeholder="Phone">
					</div>
				</div>
				<div class="form-group row">
					<label for="vendor_email" class="col-sm-3 col-form-label">Email</label>
					<div class="col-sm-9">
						<input type="text" v-model="updatedUserInfo.vendor_email" class="form-control" id="vendor_email" placeholder="Email">
					</div>
				</div>
				<div class="form-group row">
					<label for="vendor_location" class="col-sm-3 col-form-label">Location</label>
					<div class="col-sm-9">
						<input type="text" v-model="updatedUserInfo.vendor_location" class="form-control" id="vendor_location" placeholder="Location">
					</div>
				</div>

			</div> -->
		</div>
	</div>
</div>
</template>