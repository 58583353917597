<script>
import { VueContext } from 'vue-context'
import { mapGetters, mapActions } from 'vuex'
import NotificationCell from '../../notification-cell.vue'
import NumberFormatter from '../../utility/number-formatter'
import BlockPagination from '../../utility/block-pagination'
import accounting from 'accounting'
import _ from 'lodash'
import Axios from 'axios'
import SearchFilter, {Filter} from '../../search-filter/search-filter';

export default {
  name: 'estimates',
  data: function () {
    return {
      filterAssignTo: '',
      isFilteredTable: false,
      contextOpenEvent: null,
      estimates: [],
      ceStatuses: [],
      searchFilter: '',
      estimatesAllFiltered: [],

      fields: [
        {
          label: 'Number',
          key: 'estimate_number',
          sortable: true,
          tdClass: 'clickable estimates-table-number',
          thClass: 'bg-navyblue estimates-table-number'
        },
        {
          label: 'Vehicle Owner',
          key: 'customer_name',
          sortable: true,
          tdClass: 'clickable estimates-table-vehicle-owner',
          thClass: 'bg-navyblue estimates-table-vehicle-owner'
        },
        {
          label: 'Rego',
          key: 'rego_number',
          sortable: true,
          tdClass: 'clickable estimates-table-rego',
          thClass: 'bg-navyblue estimates-table-rego'
        },
        {
          label: 'Make',
          key: 'make',
          sortable: true,
          tdClass: 'clickable make',
          thClass: 'bg-navyblue make'
        },
        {
          label: 'Model',
          key: 'model',
          sortable: true,
          tdClass: 'clickable model',
          thClass: 'bg-navyblue model'
        },
        {
          label: 'Insurer',
          key: 'insurer',
          sortByFormatted: (value, key, item) => {
            return item.insurer_name
          },
          sortable: true,
          tdClass: 'clickable insurer',
          thClass: 'bg-navyblue insurer'
        },
        {
          label: 'Date Created',
          key: 'created_on',
          sortable: true,
          sortByFormatted: (value, key, item) => {
            return item.sortCreatedDate
          },
          tdClass: 'clickable created-on',
          thClass: 'bg-navyblue created-on'
        },
        {
          label: 'Amount',
          key: 'amount',
          sortable: true,
          sortByFormatted: (value, key, item) => {
            return accounting.unformat(item.amount)
          },
          tdClass: 'clickable amount',
          thClass: 'bg-navyblue amount'
        },
        {
          label: 'Assigned',
          key: 'assignedTo',
          sortByFormatted: (value, key, item) => {
            if (item.assignedTo && item.assignedTo.name && item.assignedTo.id) {
              return item.assignedTo.name
            }
          },
          sortable: true,
          tdClass: 'clickable colour',
          thClass: 'bg-navyblue assigned'
        },
        {
          label: 'Priority',
          key: 'priority',
          sortable: true,
          tdClass: 'text-center clickable notification ',
          thClass: 'text-center bg-navyblue notification',
        },
        {
          label: 'Notifications',
          key: 'insurance',
          sortable: true,
          tdClass: 'clickable notification',
          thClass: 'bg-navyblue notification'
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
          tdClass: 'clickable status',
          thClass: 'bg-navyblue status'
        },
      ],
      activitiesGetted: true,
    }
  },
  computed: {
    ...mapGetters({
      getterAllEstimates2: 'estimate/getAllEstimates',
      getterEstimatesPerPage: 'estimate/getEstimatesPerPage',
      getterEstimatesAllCurrentPage: 'estimate/getEstimatesAllCurrentPage',
      getterSortBy: 'estimate/getSortBy',
      getterSortDesc: 'estimate/getSortDesc',
      isStatusActive: 'isStatusActive',
      getterVisibleUsers: 'visibleUsers',
      getterBusinessName: 'getBusinessName',
      getterSearchFilterObj: 'estimate/getSearchFilterObj',
      getterToken: 'estimate/getToken',
    }),
    getterAllEstimates() {
      return this.getterAllEstimates2.slice(0, 10)
    },
    isRegoFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('rego'));
    },
    isEstimateFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('estimate'));
    },
    isPartNumberFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('partNumber'));
    },
    isClaimNumberFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('claimNumber'));
    },
    isInsurerFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('insurer') && this.searchFilterObj.insurer !== '');
    },
    isDateFilterActive() {
      return !!(this.searchFilterObj && this.searchFilterObj.dateRange && (this.searchFilterObj.dateRange === 'This Month' || this.searchFilterObj.dateRange === 'Last Month' || (this.searchFilterObj.dateRange.label && this.searchFilterObj.dateRange.type === 'Date')));
    },
    computedEstimateDateFilter() {
      if (this.isDateFilterActive) {
        let filter = {
          start: '',
          end: '',
        }

        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        let lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);

        if (this.searchFilterObj.dateRange === 'This Month') {
          filter.start = new Date(firstDay).getTime();
          filter.end = new Date(lastDay).getTime();
        } else if (this.searchFilterObj.dateRange === 'Last Month') {
          filter.start = new Date(firstDayPrevMonth).getTime();
          filter.end = new Date(lastDayPrevMonth).getTime();
        } else if (this.searchFilterObj.dateRange.label && this.searchFilterObj.dateRange.type === 'Date') {
          filter.start = new Date(this.searchFilterObj.dateRange.startDate).getTime();
          filter.end = new Date(this.searchFilterObj.dateRange.endDate).getTime();
        }

        return filter;
      }
    },
    isHasFilters() {
      return this.isRegoFilterActive || this.isEstimateFilterActive || this.isPartNumberFilterActive || this.isClaimNumberFilterActive;
    },
    computedEstimatesAll() {
      if (this.isInsurerFilterActive) {
        return _.filter(this.getterAllEstimates, (estimate) => {
          return estimate.insurerName == this.searchFilterObj.insurer;
        })
      }
      if (this.isDateFilterActive) {

        return _.filter(this.getterAllEstimates, (estimate) => {
          if (!estimate.created_on) {
            return false;
          }

          let dateParts = estimate.created_on.split('/');

          let createdDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]).getTime();
          return createdDate >= this.computedEstimateDateFilter.start && createdDate <= this.computedEstimateDateFilter.end;
        })
      }

      return this.getterAllEstimates;
    },
    computedEstimatesAllFiltered () {
      if (this.isInsurerFilterActive || this.isDateFilterActive) {
        return this.computedEstimatesAll;
      }
      if (this.isFilteredTable) {
        return this.estimatesAllFiltered
      }
      return this.getterAllEstimates
    },
    computedEstimatesAllFilteredLength () {
      return this.computedEstimatesAllFiltered.length
    },
    computedActiveUsers () {
      if (!this.filterAssignTo || this.filterAssignTo == '') {
        let users = this.getterVisibleUsers.slice();
        users.unshift({
          id: null,
          name: 'None',
          groupId: null,
          group_id: null,
          isActive: true,
          isVisible: true,
          lastName: null,
          firstName: null,
          role: null,
        })
        return users;
      }
      let self = this
      let filterUsers = _.filter(this.getterVisibleUsers, function (user) {
        let search = []
        search.push(_.toLower(user.name))
        return !!(search.join(',').indexOf(_.toLower(self.filterAssignTo)) > -1)
      })
      return filterUsers
    },
    computedSortBy () {
      return this.getterSortBy
    },
    computedSortDesc () {
      return this.getterSortDesc
    },
    estimatesPerPage () {
      return this.getterEstimatesPerPage
    },
    estimatesAllCurrentPage () {
      return this.getterEstimatesAllCurrentPage
    },
    searchFilterObj() {
      return this.getterSearchFilterObj;
    },
    filter: {
      get () {
        return this.$store.getters.estimateFilter
      },
      set (value) {
        console.log('commit.filter',value)
        this.$store.commit('setEstimateFilter', value);
      }
    },
    computedSearchFilters() {
      if (this.getterAllEstimates.length > 0) {
        let insurers = {};

        this.getterAllEstimates.forEach(estimate => {
          if (estimate.insurerName && estimate.insurerName.trim()) {
            insurers[estimate.insurerName.trim().toLowerCase()] = {label: estimate.insurerName.trim()};
          }
        });
        insurers = _.orderBy(Object.values(insurers),['label'],['asc']);

        // Create date template
        const date = [
          {
            id: 'date',
            label: "Date",
            resultObjectOnly: true,
            type: "Date",
            iconClass: "bx-calendar"
          }
        ];

        // Create searchFilter filters
        return [
          {
            id: 'estimate',
            label: 'Estimate',
            iconClass: 'bx-car',
            options: []
          },
          {
            id: 'rego',
            label: 'Rego',
            iconClass: 'bx-user',
            options: []
          },
          {
            id: 'partNumber',
            label: 'Part Number',
            iconClass: 'bx-user',
            options: []
          },
          {
            id: 'claimNumber',
            label: 'Claim Number',
            iconClass: 'bxs-user-badge',
            options: []
          },
          {
            id: 'insurer',
            label: 'Insurer',
            iconClass: 'bx-box',
            options: [
              insurers
            ]
          },
          {
            id: 'dateRange',
            label: 'Date Range',
            iconClass: 'bx-store',
            options: [
              [ {
                label: 'This Month',
              },
                {
                  label: 'Last Month',
                },
                {
                  label: 'Custom Date Range',
                }
              ]
            ]
          }
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions({
      actionLoadEstimates: 'estimate/loadEstimates',
    }),
    isHasPriority(priority) {
      if (priority === 'Low' || priority === 'Medium' || priority === 'High' || priority === 'OnHold') {
        return true
      }
      return  false;
    },
    setEstimatePriority (item, priority) {
      this.$refs.rightClickMenu.close()
      Axios({
        method: 'post',
        responseType: 'json',
        headers: { Autosave: true },
        url: `/fe/card/${item.cardId}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          uid: {
            priority: priority,
          }
        },
      })
          .then((response) => {
            if (response.status === 200) {
              item.priority = priority
              this.loadEstimates()
            }
          })
          .catch((error) => {
          })
    },
    assignedToPerson (item, userId) {
      this.$refs.rightClickMenu.close()
      if (item && item.cardId > 0) {
        let uid = Math.random().toString(36).substr(2, 9) + Date.now()
        let update = {}
        update[uid] = {}
        update[uid].assignedTo = {}
        update[uid].assignedTo.id = userId
        Axios({
          method: 'post',
          responseType: 'json',
          headers: { Autosave: true },
          url: `/fe/card/${item.cardId}`,
          validateStatus: function (status) {
            return status < 500
          },
          data: update,
        })
            .then((response) => {
              if (response.status === 200) {
                this.loadEstimates()
              }
            })
            .catch((error) => {
            })
      }
    },

    setSortByAndSortDesc (e) {
      this.$store.commit('estimate/setSortBy', e.sortBy)
      this.$store.commit('estimate/setSortDesc', e.sortDesc)
    },
    filterForTable (row, filter) {
      let formattedFilter = filter.toLowerCase();

      if (this.isHasFilters) {

        if (this.isRegoFilterActive && row.rego_number && row.rego_number.toLowerCase().includes(formattedFilter)) {
          return true;
        } else if (this.isEstimateFilterActive && row.estimate_number && row.estimate_number.toLowerCase().includes(formattedFilter)) {
          return true;
        } else if (this.isPartNumberFilterActive && row.partNumbers && row.partNumbers.toLowerCase().includes(formattedFilter)) {
          return true;
        } else if (this.isClaimNumberFilterActive && row.claim_number && row.claim_number.toLowerCase().includes(formattedFilter)) {
          return true;
        }

      } else {

        if (row.estimate_number && row.estimate_number.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.customer_name && row.customer_name.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.rego_number && row.rego_number.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.make && row.make.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.model && row.model.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.insurer_name && row.insurer_name.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.created_on && row.created_on.includes(formattedFilter)) {
          return true
        } else if (row.assignedTo.name && row.assignedTo.name.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.amount && row.amount.includes(formattedFilter)) {
          return true
        } else if (row.status && row.status.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.claim_number && row.claim_number.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.partNumbers && row.partNumbers.toLowerCase().includes(formattedFilter)) {
          return true
        } else if (row.driverName && row.driverName.toLowerCase().includes(formattedFilter)) {
          return true
        }

      }


    },
    markAsRead: function (item) {
      this.$store.dispatch('markAsReadEstimateNotification', item.estimateId)

      item.activity.Assignment = {}
      item.activity.Email = {}

    },
    openContextMenu: function (item, index, event) {
      if (!this.isStatusActive) {
        return
      }
      event.preventDefault()
      if (item.activity && ((item.activity.Assignment && item.activity.Assignment.from) || (item.activity.Email && item.activity.Email.date))) {
        let vm = this
        this.$refs.rightClickMenu.open(event, { item })
      }
    },
    contextOpen: function (event, w, e) {
      this.contextOpenEvent = event
    },
    setColorForRow (event, color) {
      let tag = event.target.parentElement.localName
      let row = event.target.parentElement
      let rows = row.parentElement.rows
      if (tag === 'tr') {
        row = event.target.parentElement
        rows = event.target.parentElement.parentElement.rows
      } else if (tag === 'td') {
        row = event.target.parentElement.parentElement
        rows = event.target.parentElement.parentElement.parentElement.rows
      }

      for (let i = 0; i < rows.length; i++) {
        let elem = rows[i]
        elem.style.backgroundColor = ''
      }
      if (color) {
        row.style.backgroundColor = color
      }
    },
    contextClose: function () {
      if (this.contextOpenEvent) {
        this.setColorForRow(this.contextOpenEvent, '')
      }
    },
    openMenuEstimates2: function (item, index, event) {
      event.preventDefault()
      this.setColorForRow(event, '#F3F3F3')
      this.openMenuEstimates(event, index, item)
    },
    openMenuEstimates: function (event, index, item) {
      if (!this.isStatusActive) {
        return
      }
      this.$refs.rightClickMenu.open(event, { item: item, index: index })
      setTimeout(() => {
        [].slice
            .call(this.$refs.contextMenuForEstimates.children)
            .forEach((elem) => {
              if (elem.children.length && elem.querySelector('.subContext')) {
                elem.classList.add('hasSubContext')
                elem.addEventListener('click', (e) => {
                  e.stopPropagation()
                })
                elem.addEventListener('mouseenter', () => {
                  if (
                      window.innerWidth <
                      elem.getBoundingClientRect().right +
                      elem.querySelector('.subContext').getBoundingClientRect()
                          .width
                  ) {
                    elem
                        .querySelector('.subContext')
                        .classList.add('position_sub_context_left')
                  } else
                    elem
                        .querySelector('.subContext')
                        .classList.remove('position_sub_context_left')
                })
              } else elem.classList.remove('hasSubContext')
            })
      }, 0)
    },
    onRightClickByMenu (type, data) {
      var estimate_id = data.item.estimate_id
      Axios({
        method: 'post',
        responseType: 'json',
        headers: { 'Autosave': true },
        url: `/fe/estimate/${estimate_id}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: { state: type }
      })
          .then(response => {
            if (response.status === 200) {
              this.loadEstimates(1)
            }
          })

    },
    loadCEStatuses () {
      this.$store.dispatch('loadCEStatuses').then((res) => {
        if (res.status === 200) {
          this.ceStatuses = res.data

          //   console.log("SCISIS",this.ceStatuses);
          this.getterAllEstimates.map(e => {
            e.ceStatus = this.ceStatuses.findIndex(value => value.name === e.status) + 1
            e.ceStatusPercentage = (this.ceStatuses.findIndex(value => value.name === e.status) + 1) / this.ceStatuses.length * 100
          })

          //     console.log("ESITISI",this.estimatesAll);
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    onAllEstimatesFiltered: function (fi) {
      this.estimatesAllFiltered = fi
      this.isFilteredTable = true
      this.$store.commit('estimate/setEstimatesAllCurrentPage', 1)
    },
    onEstimatesRowClick: function (item, index, event) {
      this.$router.push('/estimates/view/' + item.estimate_id)
    },
    onSearchFilterChange(filters) {
      this.$store.commit('estimate/setSearchFilterObj', filters);
      if (filters && filters.search) {
        this.filter = filters.search;
      } else {
        this.filter = '';
      }
      console.log('onSearchFilterChange', filters);
    },
  },
  mounted: function () {
    this.loadCEStatuses();

    let table = this.$refs.tableEstimates;

    setTimeout(() => {
      if (table && table.isFiltered) {
        this.isFilteredTable = true
        this.estimatesAllFiltered = table.filteredItems
      }
    }, 0)

  },
  components: {
    VueContext,
    NotificationCell,
    NumberFormatter,
    BlockPagination,
    SearchFilter,
  }
}
</script>

<template>
  <div class="estimates-page">
    <div class="page-header">
      <h4>View Estimates </h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item active">View Estimates</li>
      </ol>
      <h4 class="header-display-name">{{ getterBusinessName }}</h4>
    </div>
    <div class="row">
      <div class="box-block bg-white estimates">
        <tabs :options="{useUrlFragment: false, defaultTabHash: 'estimates-all' }">
          <tab name="All" id="estimates-all" :class-name="'tab-content-table'">
            <div class="pagination-block-bottom">
              <b-table
                  class="rs-scroll rs-scroll--y noselect"
                  ref="tableEstimates"
                  responsive
                  hover
                  :items="computedEstimatesAll"
                  :fields="fields"
                  :filter="filter"
                  :filter-function="filterForTable"
                  :current-page="estimatesAllCurrentPage"
                  :per-page="estimatesPerPage"
                  :sort-by="computedSortBy"
                  :sort-desc="computedSortDesc"
                  @sort-changed="setSortByAndSortDesc"
                  @filtered="onAllEstimatesFiltered"
                  @row-clicked="onEstimatesRowClick"
                  @row-contextmenu="openMenuEstimates2"
              >
                <template v-slot:cell(estimate_number)="data">
                  <div class="estimate-number semi-bold">{{ data.item.estimate_number }}</div>
                </template>
                <template v-slot:cell(customer_name)="data">
                  <div class="semi-bold">{{ data.item.customer_name }}</div>
                </template>
                <template v-slot:cell(rego_number)="data">
                  <div class="semi">{{ data.item.rego_number }}</div>
                </template>
                <template v-slot:cell(make)="data">
                  <div class="semi">{{ data.item.make }}</div>
                </template>
                <template v-slot:cell(model)="data">
                  <div class="semi">{{ data.item.model }}</div>
                </template>
                <template v-slot:cell(insurer)="data">
                  <div class="semi">{{ data.item.insurer_name }}</div>
                </template>
                <template v-slot:cell(created_on)="data">
                  <div class="semi">{{ data.item.created_on }}</div>
                </template>
                <template v-slot:cell(priority)="data">
                  <span v-if="data.item.priority === 'High'" style="color: #FF5E5E">
                    High
                  </span>
                  <span v-else-if="data.item.priority === 'Medium'" style="color: #FFDB00">
                    Medium
                  </span>
                  <span v-else-if="data.item.priority === 'Low'" style="color: #18D06B">
                    Low
                  </span>
                  <span v-else-if="data.item.priority === 'OnHold'" style="color: #419abe">
                    On Hold
                  </span>

                </template>
                <template v-slot:cell(amount)="data">
                  <div class="semi">{{ data.item.amount }}</div>
                </template>
                <template v-slot:cell(assignedTo)="data">
                  <div>{{
                      data.item.assignedTo && data.item.assignedTo.id ? data.item.assignedTo.name :
                          '&nbsp'
                    }}
                  </div>
                </template>
                <template v-slot:cell(insurance)="data">
                  <notification-cell v-if="activitiesGetted" class="row no-gutter" :item="data.item"
                                     :name="`All-${data.item.card_id}`"/>
                </template>
                <template v-slot:cell(status)="data">
                  <p class="status-header">{{ data.item.status }}</p>
                  <p class="status-count">
                                      <span>
                                          {{ data.item.ceStatus }} to {{ ceStatuses.length }}
                                      </span>
                  </p>
                  <div class="progress">
                    <div class="bar" :style="{'width':`${data.item.ceStatusPercentage}%`}">
                    </div>
                  </div>
                </template>

              </b-table>
            </div>
            <block-pagination
                :role="'estimates'"
                :arrayOfSomeone="getterAllEstimates"
                :arrayOfSomeoneFiltered="computedEstimatesAllFiltered"
                :countOfFilteredItems="computedEstimatesAllFilteredLength"
                :currentPage="estimatesAllCurrentPage"
                @changedCurrentPage="value => $store.commit('estimate/setEstimatesAllCurrentPage', value)"
                :perPage="estimatesPerPage"
                @changedPerPage="value => $store.commit('estimate/setEstimatesPerPage', value)"
            >
            </block-pagination>
          </tab>
          <template slot="nav-item-right">
            <div class="tabs-nav-right-search" style="min-width: 290px !important">
              <search-filter
                  type="estimates"
                  v-model="searchFilter"
                  :filters="computedSearchFilters"
                  :filterValue="filter"
                  :tokenCopy="getterToken"
                  @change="onSearchFilterChange"
                  @updateToken="value => this.$store.commit('estimate/setToken', value)"
                  :debug="false"
                  height="41px"
                  aria-expanded="false"
                  data-toggle="dropdown"
              />
            </div>
          </template>
        </tabs>
        <vue-context ref="rightClickMenu" @open="contextOpen" @close="contextClose">
          <ul v-if="scope && scope.data && scope.data.item"
              slot-scope="scope" ref="contextMenuForEstimates">
            <li v-if="scope && scope.data && scope.data.item && scope.data.item.activity && (
                          (scope.data.item.activity.Assignment && scope.data.item.activity.Assignment.from)
                          || (scope.data.item.activity.External && scope.data.item.activity.External.date)
                          || (scope.data.item.activity.Email && scope.data.item.activity.Email.date)
                          || (scope.data.item.activity.Message && scope.data.item.activity.Message.from)
                      )"
                @click="markAsRead(scope.data.item)"
            >
              Mark As Read
            </li>
            <li>
              Set Priority
              <i class="icon-right bx bx-chevron-right"></i>
              <ul type="none" class="subContext set-estimate-priority-list">
                <li class="d-flex" @click="setEstimatePriority(scope.data.item, 'High')"><span
                    style="margin-right: 10px; margin-top: 1px;" class="status-color high-color"></span>High Priority
                </li>
                <li class="d-flex" @click="setEstimatePriority(scope.data.item, 'Medium')"><span
                    style="margin-right: 10px; margin-top: 1px;" class="status-color medium-color"></span>Medium
                  Priority
                </li>
                <li class="d-flex" @click="setEstimatePriority(scope.data.item, 'Low')"><span
                    style="margin-right: 10px; margin-top: 1px;" class="status-color low-color"></span>Low Priority
                </li>
                <li class="d-flex" @click="setEstimatePriority(scope.data.item, 'OnHold')"><span
                    style="margin-right: 10px; margin-top: 1px;" class="status-color on-hold-color"></span>On Hold
                </li>
                <li v-if="scope.data && scope.data.item && isHasPriority(scope.data.item.priority)" class="d-flex" @click="setEstimatePriority(scope.data.item, 'Undo')"><span
                    style="margin-right: 10px; margin-top: 1px;" class="status-color undo-priority-color"></span>Undo Priority
                </li>
              </ul>

            </li>
            <li>
              Assign to
              <i class="icon-right bx bx-chevron-right"></i>
              <ul type="none" class="subContext assign-to-context">
                <input class="input-assign-to" type="text" placeholder="Search" v-model="filterAssignTo">
                <li @click="assignedToPerson(scope.data.item, user.id)" v-for="user in computedActiveUsers">{{
                    user.name
                  }}
                </li>
              </ul>

            </li>
          </ul>
        </vue-context>
      </div>
    </div>
  </div>
</template>

<style scoped>
.input-assign-to {
  border: 1px solid buttonface;
  padding: 2px 7px;
  margin: 5px 7px;
  width: 90%;
  border-radius: 5px;
}

.assign-to-context {
  height: auto;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.hasSubContext:hover .subContext {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.position_sub_context_left {
  right: calc(100% + 1px);
  left: auto;
}

.hasSubContext {
  position: relative;
  padding-right: 5px !important;
}

.subContext {
  position: absolute;
  left: calc(100% + 1px);
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
  min-width: 100%;
  width: auto;

  top: 0;
  transition: 0.4s;
  visibility: hidden;
  opacity: 0;
  right: auto;
  transform: translateY(-10px);
}

.hasSubContext ul > li {
  height: auto;
}

@media screen and (max-width: 767px) {
  .hasSubContext:hover:after {
    transform: rotate(90deg);
  }

  .subContext {
    left: 0 !important;
    right: auto !important;
    top: 100% !important;
    width: 100% !important;
  }
}
</style>

<style>
.V3 .estimates-page .set-estimate-priority-list {
  height: auto;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 140px;
}

.estimates-page .no-gutters {
  display: flex;
  justify-content: space-between;
}

.estimates-page .progress {
  margin: 0px;
  padding: 0;
  width: 150px;
  height: 7px;
  overflow: hidden;
  background: #e5e5e5;
  border-radius: 6px;
}

.estimates-page .bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%);;
}


.estimates-page tbody tr {
  cursor: pointer;
}

.estimates-page .action-icon {
  display: block;
  width: 20px;
  height: 20px;
}

.estimates-page .action-icon i {
  font-size: 19px;
  color: black;
}

.estimates-page .action-icon.active i {
  color: #142742
}

.estimates-page .action-icon.active[tooltip] {
  position: relative;
  display: inline-block;
  transition: .3s;
}

.estimates-page .action-icon.active[tooltip]::before {
  content: "";
  position: absolute;
  bottom: 25px;
  left: 50%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #333333;
  border-bottom: 0;
  margin-left: -5px;
  margin-bottom: -5px;
  opacity: 0;
  transition: .3s;
}

.estimates-page .action-icon.active[tooltip]:hover::before {
  opacity: 1;
}

.estimates-page .action-icon.active[tooltip-position='left']::before {
  left: 0%;
  top: 50%;
  margin-left: -12px;
  transform: translatey(-50%) rotate(-90deg)
}

.estimates-page .action-icon.active[tooltip-position='top']::before {
  left: 50%;
}

.estimates-page .action-icon.active[tooltip-position='buttom']::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg)
}

.estimates-page .action-icon.active[tooltip-position='right']::before {
  left: 100%;
  top: 50%;
  margin-left: 1px;
  transform: translatey(-50%) rotate(90deg)
}

.estimates-page .action-icon.active[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: #333333;
  text-align: center;
  color: #FFFFFF;
  padding: 4px 2px;
  font-size: 10px;
  font-weight: 600;
  min-width: 155px;
  border-radius: 0px;
  pointer-events: none;
  z-index: 99;
  opacity: 0;
  transition: .3s;
}

.estimates-page .action-icon.active[tooltip-position='left']::after {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}

.estimates-page .action-icon.active[tooltip-position='top']::after {
  left: 50%;
}

.estimates-page .action-icon.active[tooltip-position='buttom']::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%);
}

.estimates-page .action-icon.active[tooltip-position='right']::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%);
}

.estimates-page .action-icon.active[tooltip]:hover::after, [tooltip]:hover::before {
  opacity: 1
}

.estimates-page .semi-bold {
  font-size: 12px;
  font-weight: bold;
}

.estimates-page .semi {
  font-size: 12px;
  font-weight: 600;
}

.estimates-page .number {
  width: 72px !important;
}

.estimates-page .customer {
  width: 120px !important;
}

.estimates-page .rego {
  width: 60px !important;
}

.estimates-page .make {
  width: 60px !important;
}

.estimates-page .model {
  width: 80px !important;
}

.estimates-page .created-on {
  width: 70px !important
}

.estimates-page .amount {
  width: 70px !important;
}

.estimates-page .assigned {
  width: 80px !important;
}

.estimates-page .notification {
  width: 120px !important;
}

.estimates-page .status {
  width: 150px !important;
}

.estimates-page .insurer {
  width: 170px !important;
}

.V3 .estimates-page .tab-content-table table tr td {
  vertical-align: top;
  padding: 15px;
}

.estimates-page .table .status-header {
  margin-bottom: 7px;
  font-weight: bold;
}

.estimates-page .table .status-count {
  margin-bottom: 2px;
  font-size: 11px;
  opacity: 0.5;
  color: #1C1F39;
}

.V3 .estimates-page .search-filter__dropdown-item i {
  display: none;
}

.V3 .estimates-page .dropdown-menu.search-filter__dropdown-menu {
  margin-top: 12px;
  left: -10px;
}


.V3 .estimates-page .daterangepicker .btn-primary, .daterangepicker .btn-success {
  background-color: #5E79FF !important;
}
</style>
