<template>
  <div class="row business-page">

    <div class="tabs-component tabs-component-2" style="padding-top: 0px; margin-bottom: 15px">
      <div class="tabs-component-w">
        <ul class="nav nav-tabs nav-tabs-2  float-sm-left">
          <li style='padding-top: 0px' @click="selectedTab('details')" class="tabs-component-tab nav-item">
            <span href="" class="tabs-component-tab-a nav-link" :class="{'active': currentTab === 'details'}">Details</span>
          </li>
          <li @click="selectedTab('contacts')" class="tabs-component-tab nav-item">
            <span href="" class="tabs-component-tab-a nav-link" :class="{'active': currentTab === 'contacts'}">Contacts</span>
          </li>
          <li @click="selectedTab('locations')" class="tabs-component-tab nav-item">
            <span href="" class="tabs-component-tab-a nav-link" :class="{'active': currentTab === 'locations'}">Locations</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="">
      <BusinessDetails v-if="currentTab == 'details'" @updateDemoData="$emit('updateDemoData')"  v-model="value" ref="SettingsBusinessProfileDetails"></BusinessDetails>
    </div>

    <div class="">
      <BusinessContacts v-if="currentTab == 'contacts'" ref="SettingsBusinessProfileContacts"></BusinessContacts>
    </div>

    <div class="">
      <BusinessLocations
          v-if="currentTab == 'locations'"
          :add-location-button-pressed="addLocationButtonPressed">
      </BusinessLocations>
    </div>

  </div>

</template>
<script>
/*eslint-disable */

import BusinessDetails from "./business-profile/BusinessDetails";
import BusinessContacts from "./business-profile/BusinessContacts";
import BusinessLocations from "./business-profile/BusinessLocations";

export default {
  name: "settings-business-profile",
  components: {
    BusinessDetails,
    BusinessContacts,
    BusinessLocations,
  },
  data() {
    return {
      currentTab: 'details',
    };
  },
  props: {
    addLocationButtonPressed: Number,
    value: {}
  },
  computed: {
  },
  mounted() {
    if (!_.isEmpty(this.$storage.get("views.advancedSettings.business.defaultView"))) {
      this.currentTab = this.$storage.get("views.advancedSettings.business.defaultView");
    } else {
      let path = this.$route.path.split('/');
      let subTab = path[path.length-1];
      if (subTab === 'details' || subTab === 'contacts' || subTab === 'locations') {
        this.currentTab = subTab;
      } else {
        this.currentTab = 'details';
      }
    }
  },
  methods: {
    selectedTab(tab) {
      this.currentTab = tab;
      this.$storage.set("views.advancedSettings.business.defaultView", tab);
      let params = {name: 'business', params: {tab: tab}};
      this.$router.push(params).catch(()=>{});
    }
  }
};
</script>
<style>
.business-page .nav-item:hover {
  cursor: pointer;
}
</style>
