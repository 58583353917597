import { render, staticRenderFns } from "./value-by-insurer.vue?vue&type=template&id=3cbd7498&scoped=true"
import script from "./value-by-insurer.vue?vue&type=script&lang=js"
export * from "./value-by-insurer.vue?vue&type=script&lang=js"
import style0 from "./value-by-insurer.vue?vue&type=style&index=0&id=3cbd7498&prod&scoped=true&lang=css"
import style1 from "./value-by-insurer.vue?vue&type=style&index=1&id=3cbd7498&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cbd7498",
  null
  
)

export default component.exports