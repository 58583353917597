<template>
  <div style="margin-top: 15px; padding-left: 15px" class="card row no-gutter">
    <div class="col-lg-5 col-12 ">
      <div class="card-header bg-navyblue">
        <strong>Notification - Estimate Booking Confirmation</strong>
      </div>
      <p class="description-header"> Send your a notification confirming
        their Estimate Booking details.
      </p>
      <div class="card-block bg-white">

        <div class="form-group row no-gutter">
          <label class="col-sm-5 col-form-label">Send by Email</label>
          <div class="col-sm-7">
            <div class="d-flex" style="align-items: center">
              <toggle-button
                  v-model="value.estimatesBooking.customerNotifications.sendByEmailActive"
                  id="status"
                  class="mb-0 rs-toggle__button"
                  color="#5E79FF"
                  style="opacity: 1"
                  :sync="true"
                  :width="40"
                  :labels="false"/>
              <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                <template v-if="value.estimatesBooking.customerNotifications.sendByEmailActive">Active</template>
                                <template  v-else>Not Active</template>
                            </span>
            </div>
          </div>
        </div>

        <div class="form-group row no-gutter">
          <label class="col-sm-5 col-form-label">
            <p class="description-text">Add a personalized <br> message to send to your <br> customer.</p>
          </label>
          <div class="col-sm-7">
            <textarea v-model="value.estimatesBooking.customerNotifications.emailPersonalizedMessage" style="height: 150px; width: 250px;"  :readonly="!isStatusActive" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="estimage-line"></div>
      <div class="card-block bg-white" style="margin-top: 15px">

        <div class="form-group row no-gutter">
          <label for="estimate_prefix" class="col-sm-5 col-form-label">Send by SMS</label>
          <div class="col-sm-7">
            <div class="d-flex">
              <toggle-button
                  v-model="value.estimatesBooking.customerNotifications.sendBySmsActive"
                  id="status"
                  class="mb-0 rs-toggle__button"
                  color="#5E79FF"
                  style="opacity: 1"
                  :sync="true"
                  :width="40"
                  :labels="false"/>
              <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                <template v-if="value.estimatesBooking.customerNotifications.sendBySmsActive">Active</template>
                                <template  v-else>Not Active</template>
                            </span>
            </div>
          </div>
        </div>

        <div class="form-group row no-gutter">
          <label class="col-sm-5 col-form-label">
            Use Template
            <p class="description-text">Choose a template form <br> your SMS templates. SMS`s <br> are charged at the standard
              <br> outgoing rate per SMS.</p>
          </label>
          <div class="col-sm-7">
            <multiselect
                :options="templates"
                v-model="computedNotificationSmsId"
                :showLabels="false"
                :option-height="29"
                :max-height="203"
                placeholder="Choose SMS Template"
                :close-on-select="true"
                track-by="id"
                label="name"
            ></multiselect>
          </div>
        </div>

        <div class="form-group row no-gutter">
          <label class="col-sm-5 col-form-label">
            Appends the <br> following text at <br> the end of <br> message.
          </label>
          <div class="col-sm-7">
              <textarea style="height: 150px; width: 250px; resize: none"
                        :readonly="!isStatusActive"
                        v-model="value.estimatesBooking.customerNotifications.smsAppendText"
                        class="form-control"
                        rows="3"
                        placeholder="Data: [Booking Date]

Time: [HH:MM]">

              </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import _ from "lodash";
export default {
  name: "customer-notifications",
  components: {
    Multiselect
  },
  props: {
    value: {},
    templates: {
      type: Array,
      default() {
        return []      }
    }
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    }),
    computedNotificationSmsId: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.templates, function (itm) {
            return itm.id === vm.value?.estimatesBooking?.customerNotifications?.smsTemplateId;
          });
          return item;
        } catch (e) {
          return {};
        }
      },
      set: function (item) {
        this.value.estimatesBooking.customerNotifications.smsTemplateId = item.id;
      },
    }
  }
}
</script>

<style scoped>
.estimage-line {
  width: 100%;
  height: 10px;
  right: 0;
  display: block;
  background: #e3e4e7;
}
.description-text {
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.description-header {
  padding: 15px 15px 0px 15px;
}
</style>
