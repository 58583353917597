import {Actions} from './actions';
import {Mutations} from './mutations';
import {Getters} from './getters';

const state = {
    sortBy: 'estimate_number',
    sortDesc: true,
    searchFilterObj: {
        search: '',
    },
    token: [],
    items: [],
    timestamp: 0,
    estimatesPerPage: 50,
    estimatesAllCurrentPage: 1,
};

export default {
    namespaced: true,
    mutations: Mutations,
    actions: Actions,
    state,
    getters: Getters,
};
