<script>
    import {VueContext} from 'vue-context';

    export default {
        name: 'extVueContext',
        extends: VueContext,
        computed: {
            style() {
                let zoom = 1;
                if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                    zoom = 1;
                } else {
                    zoom = document.body.style.zoom || 1;
                }
                console.log('style', this.top, this.left, zoom);
                let top = this.top / zoom;
                let left = this.left / zoom;
                return this.show
                    ? {top: `${top}px`, left: `${left}px`}
                    : null;
            },
        },
    };
</script>