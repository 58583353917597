<template>
  <div class="row padd-15">
    <div class="estimate-supplements pagination-block-bottom rs-scroll rs-scroll--y">
<!--      <table class="table">-->
<!--        <thead class="bg-navyblue">-->
<!--        <tr>-->
<!--          <th class="text-center">Estimate Number</th>-->
<!--          <th class="text-center">Supplement Number</th>-->
<!--          <th></th>-->
<!--        </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--        <tr v-for="(s, i) in supplements">-->
<!--          <td class="text-center">{{s.estimate_number}}</td>-->
<!--          <td class="text-center">{{s.supplement_number}}</td>-->
<!--          <td class="text-center">-->
<!--            <router-link :to="'/estimates/view/' + s.estimate_id">View</router-link>-->
<!--            <a @click.prevent="removeSupplement(s, i)" class="nav-link" href="" style="margin-left:15px;text-align:center;padding:.5rem 0;color:red;"><i-->
<!--              class="ti-trash"></i></a>-->
<!--          </td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--      </table>-->
      <b-table
              id="estimate-supplements-table"
              bordered
              :items="supplements"
              :fields="fields"
              :current-page="cardsCurrentPage"
              :per-page="cardsPerPage"
              @row-clicked="onSupplimentRowClick"
      >
        <template v-slot:cell(primary_estimate_number)="data">
          <div class="estimate-number">{{data.item.estimate_number}}</div>
        </template>
        <template v-slot:cell(supplement_number)="data">
          <div class="supplement-number">{{data.item.supplement_number}}</div>
        </template>
        <template v-slot:cell(date_created)="data">
          <div class="date-created"></div>
        </template>
        <template v-slot:cell(amount)="data"></template>
        <template v-slot:cell(action)="data">
          <router-link :to="'/estimates/view/' + data.item.estimate_id" class="action-button">
            <i class="bx bx-pencil"></i>
          </router-link>
          <a  class="nav-link action-button" @click.prevent="removeSupplement(data.item, index)"
              href=""
          >
            <i class="bx bx-trash"></i>
          </a>
        </template>
      </b-table>
    </div>
    <div class="row pagination-block">
      <div class="col-md-6 pagination-form-inline pagination-left-block">
        <span class="hint">Showing {{fromSupplements}} to {{toSupplements}} of {{totalSupplements}} Cards</span>
      </div>
      <div class="col-md-6 pagination-right-block">
        <b-pagination align="right"
                      v-model="cardsCurrentPage"
                      :total-rows="totalSupplements"
                      :per-page="cardsPerPage"
                      aria-controls="estimate-supplements-table"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: "estimate-supplements",

      props: ['supplements', 'totalSupplements'],

      data: function() {
        return {
          fields: [
            {
              label: 'Primary Estimate Number',
              key: 'primary_estimate_number',
              sortable: true,
              tdClass: 'clickable'
            },
            {
              label: 'Supplement Number',
              key: 'supplement_number',
              sortable: true,
              tdClass: 'clickable'
            },
            {
              label: 'Date Created',
              key: 'date_created',
              sortable: true,
              tdClass: 'clickable'
            },
            {
              label: 'Amount',
              key: 'amount',
              sortable: true,
              tdClass: 'clickable'
            },
            {
              label: 'Action',
              key: 'action',
              tdClass: 'clickable'
            },
          ],
          cardsPerPage: 10,
          cardsCurrentPage: 1
        }
      },

      computed: {
        fromSupplements: function() {
          if (this.totalSupplements === 0) {
            return 0
          }
          return (this.cardsCurrentPage - 1) * this.cardsPerPage + 1;
        },

        toSupplements: function() {
          const toSupplements = (this.cardsCurrentPage - 1) * this.cardsPerPage + this.cardsPerPage;
          if (toSupplements > this.totalSupplements) return this.totalSupplements;
          return toSupplements;
        }
      },

      methods: {
        onSupplimentRowClick: function (item, index, event) {
          this.$router.push('/estimates/view/' + item.estimate_id);
        },
        removeSupplement (item, index) {
          this.$emit('removeSupplement', item, index);
        }
      },
    }
</script>

<style>
  .V3 .estimate-supplements #estimate-supplements-table thead > tr {
    height: 48px;
    border-radius: 3px;
  }

  .V3 .estimate-supplements #estimate-supplements-table th {
    font-family: "Nunito Sans";
    font-size: 13px;
    font-weight: bold;
  }

  .V3 .estimate-supplements .estimate-number  {
    font-family: "Nunito Sans";
    font-size: 12px;
    font-weight: bold;
  }

  .V3 .estimate-supplements .supplement-number {
    font-family: "Nunito Sans";
    font-size: 12px;
    font-weight: bold;
  }

  .V3 .estimate-supplements .date-created {
    font-family: "Nunito Sans";
    font-size: 12px;
    font-weight: 600;
  }

  .V3 .estimate-supplements .amount {
    font-family: "Nunito Sans";
    font-size: 12px;
    font-weight: 600;
  }

  .V3 .estimate-supplements .nav-link {
    margin-left:15px;text-align:center;padding:.5rem 0;color:red
  }

  .V3 .estimate-supplements .action-button {
    font-size: 19px;
  }

.V3 .estimate-supplements .action-button .bx-pencil{
  color: black;
}

.padd-15 {
  padding: 15px;
}
</style>
