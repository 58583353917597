<template>
    <div v-if="isActive && isShow">
        <a v-if="bannerType==1" class="leaderboard-b-promo" @click.prevent="click(banner)" :href="banner.link" target="_blank">
            <img :src="banner.src" alt="">
        </a>
        <a v-if="bannerType==2" class="medium-rectangle-b-promo" @click.prevent="click(banner)" :href="banner.link" target="_blank">
            <img :src="banner.src" alt="">
        </a>
        <a v-if="bannerType==3" class="medium-b-promo" @click.prevent="click(banner)" :href="banner.link" target="_blank">
            <img :src="banner.src" alt="">
        </a>
        <a v-if="bannerType==4" class="skyscrapper-b-promo" @click.prevent="click(banner)" :href="banner.link" target="_blank">
            <img :src="banner.src" alt="">
        </a>
    </div>
</template>

<script>
    import Axios from "axios";

    export default {
        name: "advert-banner",
        props: {
            bannerType: {
                type: Number,
                default() {
                    return 0;
                }
            },
            isActive: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                banner: {
                    link: '',
                    src: '',
                },
                rotationId: null,
                isShow: false,
                banners: [],
                index: 0,
            };
        },
        computed: {},
        methods: {
            click({slug}){
              Axios.get(`/fe/adv/${slug}/save`)
                  .then(response => {
                    if (response.data._status) {
                      toastr.success('Added to Promo list');
                      this.$store.dispatch('dashboard/loadNotices');
                    }
                  })
                  .catch(error => {
                    console.error('banner click error', error);
                  });
            },
            loadBanner() {
                Axios.get(`/adv/b/?type=` + this.bannerType)
                    .then(response => {
                        if (response.data._status) {
                            this.banners = response.data.data;
                            this.showBanner();
                        }
                    })
                    .catch(error => {
                        console.log('loadBanner', error);
                    });
            },
            showBanner() {
                if (this.bannerType == 2 || this.bannerType == 3) {
                    this.banner = this.banners[0];
                } else if (this.bannerType == 1 || this.bannerType == 4) {
                    this.rotationBanners();
                } else {
                    return;
                }
                this.isShow = true;
            },
            rotationBanners(){
              this.$nextTick(function (){
                this.changeBannerForRotation();
                if(this.stopBannerRotation == false) {
                    this.rotationId = setInterval(this.changeBannerForRotation, 5000);
                }
              });
            },
            changeBannerForRotation() {
              if (this.index + 1 > this.banners.length) {
                this.banner = this.banners[0];
                this.index = 1;
              } else {
                this.banner = this.banners[this.index];
                this.index += 1;
              }
            },
        },
        beforeDestroy() {
          if (this.rotationId != null) {
            clearInterval(this.rotationId);
          }
        },
        mounted() {
            this.loadBanner();
        }

    };
</script>

<style scoped>

</style>