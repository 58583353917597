<template>

  <div class="card col-lg-5 col-12">
    <div class="card-header bg-navyblue">
      <strong>IAG ORM</strong>
    </div>

    <div class="card-block bg-white">

      <div class="form-group row no-gutter">
        <label for="iag-orm-webservice-id"
               tabindex="0"
               class="col-sm-3 col-form-label">Webservice ID</label>
        <div class="col-sm-9">
          <input id="iag-orm-webservice-id"
                 class="form-control"
                 placeholder="Webservice ID"
                 v-model="originalCompanyInfo.iagAuth.webserviceId">
        </div>
      </div>

      <div class="form-group row no-gutter">
        <label for="iag-orm-webservice-password"
               tabindex="0"
               class="col-sm-3 col-form-label">Webservice Password</label>
        <div class="col-sm-9">
          <input id="iag-orm-webservice-password"
                 type="password"
                 class="form-control"
                 placeholder="Webservice Password"
                 v-model="originalCompanyInfo.iagAuth.webservicePassword">
        </div>
      </div>

      <div class="form-group row no-gutter">
        <label for="iag-orm-webservice-repairer-name"
               tabindex="0"
               class="col-sm-3 col-form-label">Repairer Name</label>
        <div class="col-sm-9">
          <input id="iag-orm-webservice-repairer-name"
                 class="form-control"
                 placeholder="Repairer Name"
                 v-model="originalCompanyInfo.iagAuth.repairerName">
        </div>
      </div>

      <div class="form-group row no-gutter">
        <label for="iag-orm-webservice-repairer-code"
               tabindex="0"
               class="col-sm-3 col-form-label">Repairer Code</label>
        <div class="col-sm-9">
          <input id="iag-orm-webservice-repairer-code"
                 class="form-control"
                 placeholder="Repairer Code"
                 v-model="originalCompanyInfo.iagAuth.repairerCode">
        </div>
      </div>

      <div class="form-group row no-gutter">
        <label for="iag-orm-webservice-abn"
               tabindex="0"
               class="col-sm-3 col-form-label">ABN</label>
        <div class="col-sm-9">
          <input id="iag-orm-webservice-abn"
                 class="form-control"
                 placeholder="ABN"
                 v-model="originalCompanyInfo.iagAuth.abn">
        </div>
      </div>

      <div class="form-group row no-gutter rs-toggle">
        <label class="col-sm-3 col-form-label" for="iag-orm-enabled">
          Active
        </label>
        <div class="col-sm-9">
          <div class="d-flex">
            <toggle-button v-model="originalCompanyInfo.iagAuth.active"
                           id="iag-orm-enabled"
                           class="mb-0 rs-toggle__button"
                           color="#5E79FF"
                           :sync="true"
                           :width="40"
                           :labels="false"/>
            <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                <template v-if="isEnabled">Active</template>
                <template v-else>Not Active</template>
            </span>
          </div>
        </div>
      </div>

      <div class="form-group row no-gutter rs-toggle">
        <label class="col-sm-3 col-form-label">
          Service Status
        </label>
        <div class="col-sm-9">
          <template v-if="originalCompanyInfo.iagAuth.status == 1">
            <span class="circle green"></span>
            Running
          </template>
          <template v-else>
            <span class="circle red"></span>
            Not Running
          </template>
        </div>
      </div>

      <div class="form-group row no-gutter">
        <div class="offset-sm-3  col-sm-9">
          <button class="btn btn-primary" @click="runTest">Get Next XML</button>
          <br>
          <br>
          <pre
              style="padding: 8px 12px; border: 1px solid #EEE; border-radius: 5px; overflow: auto"
              v-if="testOutputLog"
          >{{ testOutputLog.resp.join('\n') }}</pre>
          <router-link v-if="testOutputLog && testOutputLog.cardId" class="btn btn-primary" :to="'/cards/view/' + testOutputLog.cardId">Go to Card</router-link>
          <router-link v-if="testOutputLog && testOutputLog.estimateId" class="btn btn-primary" :to="'/estimates/view/' + testOutputLog.estimateId">Go to Estimate</router-link>
        </div>
      </div>

      <div class="form-group row no-gutter">
        <div class="offset-sm-3  col-sm-9">
          <div class="form-group row no-gutter">
            <input
                class="form-control"
                placeholder="Unique Identifier"
                v-model='uniqueId'>
          </div>
          <button class="btn btn-primary" @click="ackXml">Acknowledge XML</button>
          <br>
          <br>
          <pre
              style="padding: 8px 12px; border: 1px solid #EEE; border-radius: 5px; overflow: auto"
              v-if="ackXmlDebug"
          >{{ ackXmlDebug }}</pre>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import Axios from 'axios';
import _ from 'lodash';

export default {
  name: 'Orm',
  props: {},
  created: function () {
    this.debouncedSave = _.debounce(this.saveIagAuthData, 1000);
  },
  data(){
    return {
      debouncedSave: null,
      iagAuthEditable: false,
      testOutputLog: null,
      uniqueId: '',
      ackXmlDebug: null,
      isEnabled: false,
    };
  },
  computed: {
    ...mapGetters({
      originalCompanyInfo: 'vendorInfo',
    }),
  },
  methods: {
    runTest(){
      Axios.get(`/test/iagGetNextXml`)
          .then(response => {
            if(response.data){
              this.testOutputLog = response.data;
            }
          })
          .catch(e => console.error(e))
    },
    ackXml(){
      Axios.post(`/test/iagAckXml`, { uniqueId: this.uniqueId })
          .then(response => {
            if(response.data && response.data._status){
              toastr.success('Success!')
              this.ackXmlDebug = JSON.stringify(response.data.debug, null, 2);
            }
          })
          .catch(e => console.error(e))
    },
    saveIagAuthData(){
      Axios.post(`/fe/vendor/update-iag-auth`, this.originalCompanyInfo.iagAuth)
          .then(response => {
            if(response.data && response.data._status){
              toastr.success('IAG settings has been updated successfully!')
            }
          })
          .catch(e => console.error(e))
    },
  },
  watch: {
    "originalCompanyInfo.iagAuth": {
      deep: true,
      handler () {
        if (this.iagAuthEditable) {
          this.debouncedSave();
        }
        if(this.originalCompanyInfo.iagAuth.webserviceId !== null){
          setTimeout(() => this.iagAuthEditable = true, 500);
        }
      }
    },
  },
}
</script>


<style scoped>
.circle {
  display: inline-block;
  border-radius: 999px;
  width: 16px;
  height: 16px;
}
.circle.green {
  background:#02d43e;
}
.circle.red {
  background:#d43935;
}
.circle.blue {
  background:#3E70C9;
}
.pc-item-holder {
  position: relative;
}
.pc-item-holder .remove-pc-button {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  height: 14px;
  width: 14px;
  color: #d43935;
  opacity: 0;
  transition: opacity 1s;
}
.pc-item-holder .remove-pc-button:hover {
  color: #d43935;
  cursor: pointer;
}
.pc-item-holder:hover .remove-pc-button {
  transition: opacity .3s;
  opacity: 1;
}
</style>