import { render, staticRenderFns } from "./assign-to-modal.vue?vue&type=template&id=461d0cc2&scoped=true"
import script from "./assign-to-modal.vue?vue&type=script&lang=js"
export * from "./assign-to-modal.vue?vue&type=script&lang=js"
import style0 from "./assign-to-modal.vue?vue&type=style&index=0&id=461d0cc2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461d0cc2",
  null
  
)

export default component.exports