<script>
    import { mapGetters } from 'vuex';
    import {VStepper} from 'vue-stepper-component';
    import GoodWizard from '../../utility/vueGoodWizard';
    import ImageSelector from '../../utility/image-selector';
    import Email from '../../utility/email-validator';
    import Axios from 'axios';
    import EstimatePreview from '../estimate-preview';
    import _ from "lodash";

    export default {
        name: 'estimate-options-send-to-customers',
        data: function () {
            return {
                isCanSend: true,
                estimateToCustomer: {
                    vendor: {},
                    customer: {
                      customer_name: '',
                      mobile: '',
                      phone: '',
                    },
                    customer_vehicle: {
                      rego_number: '',
                    },
                    estimate: {
                      vehicleRego: '',
                      vehicleOwner: '',
                      number: '',
                      estimate_number: '',
                      supplement_number: '',
                    },
                    insurance: {
                        rates: {},
                    },
                    properties: {
                      additionalMessageMaxLength: 127,
                      additionalMessageText: '',
                      isCanReceiveSms: false,
                      isCanReceiveSmsError: '',
                      phoneForSms: false,
                      isHideSeries: false,
                    },
                    insurance_repair: false,
                    rates: {},
                    line_items: {
                        lines: {
                            RR: [],
                            Repair: [],
                            Paint: [],
                            Part: [],
                            Materials: [],
                            Sublet: [],
                            Other: [],
                        },
                        summary: {},
                    },
                    terms: '',
                },
                customerContacts: [],
                new_customer_email: '',
                new_customer_name: '',
                images: [],
                selectedImages: [],
                steps: [
                    {
                        label: 'Select Customer',
                        slot: 'estimate-options-send-to-customers-step-1',
                    },
                    {
                        label: 'Estimates Preview',
                        slot: 'estimate-options-send-to-customers-step-2',
                    },
                    {
                        label: 'Select Images',
                        slot: 'estimate-options-send-to-customers-step-3',
                        options: {
                            nextDisabled: true,
                        },
                    }
                ],
                step: 1,
            };
        },
        methods: {
            onClickSMS(isDisabled) {
                if (isDisabled && !this.estimateToCustomer.properties.isCanReceiveSms && this.estimateToCustomer.properties.isCanReceiveSmsError) {
                    toastr.error(this.estimateToCustomer.properties.isCanReceiveSmsError);
                }
            },
            onImageSelect: function (imgs) {
                console.log(imgs);
                this.selectedImages = imgs;
            },
            customerModalFormCompleted: function () {
                if (this.new_customer_email) {
                    this.addNewInsurerContact();
                }

                let estimate_id = this.$route.params.estimate_id;

                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/email/customer/${estimate_id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify({
                        customers: this.customerContacts,
                        images: this.selectedImages,
                    })
                })
                    .then(response => {
                        if (response.status == 200) {
                            toastr.success(response.data.msg);
                            if (this.state == 0) {
                                this.markAs(1);
                            }
                            this.$refs.customerModal.hide();
                        } else {
                            toastr.error(response.data.msg);
                        }
                        NProgress.done();
                    })
                    .catch(error => {
                        NProgress.done();
                        toastr.error(error);
                    });
            },
            addNewCustomerContact: function () {
                this.customerContacts.push({
                    customer_name: this.new_customer_name,
                    email: this.new_customer_email,
                    email_check: false,
                });
                this.new_customer_name = '';
                this.new_customer_email = '';
            },
            goToEstimate() {
              this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab}})
            },
            clickCancel() {
                this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab}})
                return;
            },
            clickSend() {
                if (!this.isCanSend) {
                  return;
                }

                this.isCanSend = false;

                let estimate_id = this.$route.params.estimate_id;

                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/email/customer/${estimate_id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify({
                        customers: this.customerContacts,
                        images: this.selectedImages,
                        sendSms: this.customerContacts[0].sms_check,
                        smsAdditionalMessage: this.customerContacts[0].additional_message,
                    })
                })
                    .then(response => {
                        if (response.status == 200) {
                            toastr.success(response.data.msg);
                            this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab}})
                        } else {
                            toastr.error(response.data.msg);
                        }
                        NProgress.done();
                    })
                    .catch(error => {
                        NProgress.done();
                        toastr.error('error');
                    })
                    .finally(()=>{
                      this.isCanSend = true;
                    });
            },
          getImages(v) {
            let r = [];
            _.forEach(_.cloneDeep(v), (itm) => {
              if (!_.includes(itm.src, 'at=')) {
                itm.src += '/?at=' + localStorage.getItem('token')
              }
              if (!_.includes(itm.previewW220, 'at=')) {
                itm.previewW220 += '/?at=' + localStorage.getItem('token')
              }
              r.push(itm)
            })
            return r
          },
            loadEstimateToCustomers() {
                NProgress.start();
                let estimate_id = this.$route.params.estimate_id;
                let self = this;
                Axios.get(`/fe/estimate/to/customer/${estimate_id}`)
                    .then(response => {
                        self.estimateToCustomer = response.data;
                        self.customerContacts = Array({
                            customer_name: response.data.customer.customer_name,
                            email: response.data.customer.email,
                            additional_message: response.data.properties.additionalMessageText,
                            sms_check: response.data.properties.isCanReceiveSms,
                            email_check: false,
                        });
                        if (response.data.customer.customer_type === 'F') {
                            self.customerContacts.push({
                                customer_name: response.data.customer_vehicle.driverName,
                                email: response.data.customer_vehicle.businessEmail,
                                additional_message: response.data.properties.additionalMessageText,
                                sms_check: response.data.properties.isCanReceiveSms,
                                email_check: false,
                            });
                        }
                        self.images = self.getImages(response.data.images);
                        NProgress.done();
                    })
                    .catch(error => {
                        toastr.error("Error occurred while loading estimate to customer info");
                        console.log(error);
                        NProgress.done();
                    });
            },

        },
        computed: {
            ...mapGetters({
                getterBusinessName : 'getBusinessName',
                isRsEnabled: 'isRsEnabled',
            }),
            computedEstimateId() {
                if (this.estimateToCustomer.estimate.supplement_number > 0) {
                return this.estimateToCustomer.estimate.parent_estimate_id;
                }
                return  this.$route.params.estimate_id
            },
            isHasMobile() {
                return this.estimateToCustomer.properties.isCanReceiveSms;
            },
            isEstimateMethodologyCustom: function () {
                if (this.estimateToCustomer.rates.estimate_methodology == 'custom') {
                    return true;
                }
                return false;
            },
          isEstimateMethodologyDollar: function () {
                if (this.estimateToCustomer.rates.estimate_methodology == 'dollars') {
                    return true;
                }
                return false;
            },
            isRSEstimateMethodology() {
                return !!(this.isRsEnabled && this.estimateToCustomer && this.estimateToCustomer.rates && this.estimateToCustomer.rates.estimate_methodology && this.estimateToCustomer.rates.estimate_methodology == 'rs');
            },
        },
        mounted: function () {
            this.loadEstimateToCustomers();
        },
        components: {
            VStepper,
            GoodWizard,
            ImageSelector,
            EstimatePreview,
            Email,
        }
    };

</script>

<template>
    <div class="estimate-options-send-to-customers">
        <div class="page-header">
            <h4>Edit Estimate - {{estimateToCustomer.estimate.estimate_number}} <span v-if="estimateToCustomer.estimate.supplement_number > 0">- {{estimateToCustomer.estimate.supplement_number}} </span><span v-if="estimateToCustomer.customer.customer_name">| {{estimateToCustomer.customer.customer_name}} </span><span v-if="estimateToCustomer.customer_vehicle.rego_number">| {{estimateToCustomer.customer_vehicle.rego_number}}</span></h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link :to="'/'">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/estimates'">View Estimates</router-link>
                </li>
                <li class="breadcrumb-item">
                    <a href="" @click.prevent="goToEstimate">Edit Estimate</a>
                </li>
                <li class="breadcrumb-item">Options</li>
                <li class="breadcrumb-item active">Send To Customers</li>
            </ol>
            <h4 class="header-display-name">{{ getterBusinessName }}</h4>
        </div>
        <div>

            <good-wizard
                    :steps="steps"
                    :currentStep="step"
                    ref="estimateOptionsSendToCustomers"
            >
                <div slot="estimate-options-send-to-customers-step-1" class="estimate-options-send-to-customers-step-1">
                    <div class="wizard-header">
                        <div class="wizard-header-name">
                            Select Customer
                        </div>
                        <div class="wizard-subheader-name">
                            <div class="wizard-subheader-name-text">
                                Add the Customer to Send the estimate to
                            </div>
                            <div class="wizard-header-button pull-right">
                                <button @click="addNewCustomerContact" class="btn btn-primary button-next">Add Another Name</button>
                            </div>
                        </div>
                    </div>
                    <div class="rs-scroll rs-scroll--y" style="overflow: auto;">
                        <table class="table b-table table-hover customer-table">
                            <thead>
                            <tr>
                                <th>Customer Name</th>
                                <th class="" colspan="2">Email</th>
                                <th class="" style="width: 25px">SMS</th>
                                <th class="">Additional Message</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(c, i) in customerContacts" class="clickable">
                                <td style="min-width: 100px; vertical-align: top;"><input type="text" v-model="c.customer_name" class="form-control"/></td>
                                <td class="customer-email-checkbox" style="width: 25px; vertical-align: top;">
                                    <label class="form-check-inline checkbox" style="padding-top: 3px;">
                                        <input class="form-check-input"
                                               type="checkbox"
                                               v-model="c.email_check"
                                        >
                                        <span class="icon"><i class='bx bx-check'></i></span>
                                    </label>
                                </td>
                                <td style="min-width: 100px; vertical-align: top;">
                                  <email
                                    :email="c.email"
                                    @onChange="(value) => c.email = value"
                                  ></email>
                                </td>
                                <td class="customer-sms-checkbox" style="width: 25px; vertical-align: top;">
                                    <label class="form-check-inline checkbox" style="padding-top: 3px;">
                                        <input
                                                v-model="c.sms_check"
                                                :disabled="!isHasMobile || !c.hasOwnProperty('additional_message')"
                                                class="form-check-input"
                                               type="checkbox"
                                        >
                                        <span :class="{'disabled-checkbox-sms': !isHasMobile || !c.hasOwnProperty('additional_message')}" class="icon" @click="onClickSMS(c.hasOwnProperty('additional_message'))"><i class='bx bx-check'></i></span>
                                    </label>
                                </td>
                                <td>
                                    <input v-if="!c.sms_check" type="text" :readonly="true" :disabled="!isHasMobile || !c.hasOwnProperty('additional_message')" v-model="c.additional_message"
                                           class="form-control">
                                    <div v-else class="">
                                       <textarea v-model="c.additional_message" class="form-control"
                                                 style="height: 150px !important;"
                                       ></textarea>
                                        <div class="">
                                            <p :class="{'error-text': c.additional_message.length > estimateToCustomer.properties.additionalMessageMaxLength}" class="comment-limit-text">Limit of
                                                {{estimateToCustomer.properties.additionalMessageMaxLength}} characters</p>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div slot="estimate-options-send-to-customers-step-2" class="estimate-options-send-to-customers-step-2 rs-scroll rs-scroll--y">
                    <div class="header">
                        Estimate Preview
                    </div>
                    <div class="subheader">
                        Confirm the details in the estimate to be sent
                    </div>
                    <estimate-preview
                            :estimateTo="estimateToCustomer"
                            :isEstimateMethodologyCustom="isEstimateMethodologyCustom"
                            :isEstimateMethodologyDollar="isEstimateMethodologyDollar"
                            :isRSEstimateMethodology="isRSEstimateMethodology"
                    >
                    </estimate-preview>
                </div>
                <div slot="estimate-options-send-to-customers-step-3" class="estimate-options-send-to-customers-step-3">
                    <div class="header">
                        Select Images
                    </div>
                    <div class="subheader">
                        Choose the images to attach by ticking the box next to the image
                    </div>
                    <div class="box box-block">
                        <div class="row send-to-additional-position-image-wizard">
                            <image-selector :images="images" :path="''" @onChange="onImageSelect"></image-selector>
                        </div>
                    </div>
                </div>
            </good-wizard>

            <div class="wizard__buttons">
                <div class=" pull-right">
                <button
                        v-if="$refs.estimateOptionsSendToCustomers"
                        class="btn btn-outline-primary pull-left button-cancel"
                        type="button"
                        @click="clickCancel"
                >
                    Cancel
                </button>
                    <button
                            v-if="$refs.estimateOptionsSendToCustomers && $refs.estimateOptionsSendToCustomers.currentStep != 0"
                        class="btn btn-outline-primary-light button-back ml-0"
                            type="button"
                            @click="$refs.estimateOptionsSendToCustomers.goBack()"
                    >
                        Back
                    </button>
                    <a
                            v-if="$refs.estimateOptionsSendToCustomers && $refs.estimateOptionsSendToCustomers.currentStep != 2"
                            class="btn btn-primary pull-right button-next"
                            type="button"
                            @click="$refs.estimateOptionsSendToCustomers.goNext()"
                            :tabindex="4"
                    >
                        Next
                    </a>
                    <button
                            v-if="$refs.estimateOptionsSendToCustomers && $refs.estimateOptionsSendToCustomers.currentStep == 2"
                            class="btn btn-primary pull-right button-card-estimate"
                            type="button"
                            @click="clickSend"
                            :disabled="!isCanSend"
                            style=""
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .comment-limit-text {
        color: #A2ABAE;
        padding: 5px;
    }

    .error-text {
        color: red !important;
    }
</style>
<style>
    .V3 .new-card {
        max-width: 1028px;
        margin: 0 auto;
    }

    .V3 .estimate-options-send-to-customers .wizard__body {
        padding: 15px;
        width: 100%;
        margin: 0 auto;
    }

    .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-1 {
        width: 60%;
        margin: 0 auto;
    }

    .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-1 table.customer-table .customer-sms-checkbox,
    .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-1 table.customer-table .customer-email-checkbox {
        width: 25px;
        padding-left: 15px;
    }

    .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-1 table.customer-table .customer-sms-checkbox label.checkbox > span.icon,
    .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-1 table.customer-table .customer-email-checkbox label.checkbox > span.icon {
        margin-right: 0px;

    }

    .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-2 {
        width: 100%;
        padding: 0 5px;
        overflow: auto;
    }

    .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-1 .table th {
        color: #1C1F39;
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
    }

    .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-2 .table th {
        color: #1C1F39;
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        vertical-align: top;
    }

    .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-1 .table td,
    .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-2 .table td {
        color: #1C1F39;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
    }

    .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-3 {
        width: 100%;
    }

    .V3 .estimate-options-send-to-customers .wizard__buttons {
        width: 60%;
        margin: 0 auto;
        padding-right: 15px;
    }

    @media screen and (max-width: 991px) {
        .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-1 {
            width: 100%;
        }

        .V3 .estimate-options-send-to-customers .wizard__buttons {
            width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        .V3 .estimate-options-send-to-customers .wizard__body .estimate-options-send-to-customers-step-1 {
            width: 100%;
        }
        .V3 .wizard-header .wizard-header-name {
            margin-right: 150px;
        }
        .V3 .wizard-header .wizard-subheader-name-text {
            margin-right: 150px;
        }
        .V3 .estimate-options-send-to-customers .wizard__buttons {
            width: 100% !important;
        }
    }

    @media screen and (max-width: 460px) {
        .V3 .page-header {
            padding: 5px 0 5px 15px !important;
        }
    }
</style>
<style scoped>
    .V3 .card-new-body >>> .card-block {
        padding: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .wizard__body {
        padding: 15px 30px 0px 30px;
    }


    .V3 .wizard__buttons .pull-left {
        float: left !important;
        margin-right: 10px;
    }

    .V3 .wizard__buttons .pull-right {
        float: right !important;
        margin-left: 10px;
    }

    .V3 .wizard__buttons .btn {
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        height: 38px;
    }

    .V3 .wizard__buttons .button-cancel {
        padding: 10px 21px;
        background: #FFFFFF;
        border: 1px solid rgba(28, 31, 57, 0.25);
        color: #1C1F39;
    }

    .V3 .wizard__buttons .button-card {
        padding: 10px 15px;
        background: #FFFFFF;
        color: #5E79FF;
    }

    .V3 .wizard__buttons .button-card-estimate {
        padding: 10px 15px;
    }

    .V3 .wizard__buttons .button-next {
        padding: 10px 15px;
        width: 83px;
    }

    .V3 .wizard__buttons .button-back {
        padding: 10px 26px;
        background: #FFFFFF;
        border-color: #5E79FF;
        width: 83px;
    }

    .disabled-checkbox-sms {
        cursor: not-allowed;
        background-color: rgba(27, 30, 56, 0.12) !important;
        border: 1px solid rgba(27, 30, 56, 0.12) !important;
    }

    @media screen and (max-width: 500px) {
        .V3 .page-header {
            min-height: 100px !important;
        }
        .page-header h4 {
            height: auto;
        }
    }

</style>

