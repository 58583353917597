<template>
  <b-table class="contacts-table" responsive hover :items="usersAll" :fields="contactFields">
    <template v-slot:cell(username)="data">
      {{ data.item.firstName }} {{ data.item.lastName }}
    </template>

    <template v-slot:cell(address)="data">
      <template v-if="data.item.address">
        {{ getLocation(data.item.address).name }}
      </template>
    </template>

    <template v-slot:cell(role)="data">
      <div v-if="data.item.role == 'PDRTechnician'" class="">
        PDR Technician
      </div>
      <div v-else>
        {{data.item.role.replace(/([A-Z])/g, ' $1').trim() }}
      </div>
    </template>

    <template v-slot:cell(action)="data">
      <div v-if="!data.item.isVisible"
           class="eye hide-icon"
           id="hide-icon-tooltip"
           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Show users as a contact to suppliers', placement:'leftbottom'}"
           @click="onClickShow(data.index)">
        <i class="bx bx-hide"></i>
      </div>
      <div v-else
           class="eye show-icon"
           id="show-icon-tooltip"
           v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Hide users as a contact to suppliers', placement:'leftbottom'}"
           @click="onClickHide(data.index)">
        <i class="bx bx-show"></i>
      </div>
    </template>
  </b-table>
</template>

<script>
/*eslint-disable */
import {mapGetters} from 'vuex';
import Axios from "axios";
import _ from "lodash";
export default {
  name: "settings-business-profile-contacts",
  props: {},
  components: {
  },
  data() {
    return {
      contacts: [],
      contactsVisibility: {
        visible: [],
        inVisible: []
      },
      contactFields: [
        {
          label: "Name",
          key: "username",
          sortable: true,
          tdClass: "table-body font-weight-bold",
          thClass: "table-header"
        },
        {
          label: "Position",
          key: "role",
          sortable: true,
          tdClass: "table-body",
          thClass: "table-header"
        },
        {
          label: "Office Phone",
          key: "workPhone",
          sortable: true,
          tdClass: "table-body",
          thClass: ""
        },
        {
          label: "Mobile Phone",
          key: "mobilePhone",
          sortable: true,
          tdClass: "table-body",
          thClass: "table-header"
        },
        {
          label: "Email",
          key: "email",
          sortable: true,
          tdClass: "table-body",
          thClass: "table-header"
        },
        {
          label: "Location",
          key: "address",
          sortable: true,
          tdClass: "table-body",
          thClass: "table-header"
        },
        {
          label: "Action",
          key: "action",
          sortable: true,
          tdClass: "table-body text-center",
          thClass: "table-header text-center"
        }
      ],
      usersAll: [],
      locations: [],
    }
  },
  mounted() {
    this.loadLocations();
    this.loadUsers();
  },
  methods:{
    loadLocations() {
      Axios.get('/fe/vendor/locations')
          .then(res => {
            if(res && res.data && res.data._status){
              this.locations = res.data.locations;
            }
          })
          .catch(e => toastr.error(e))
    },
    getLocation(id){
      let data = _.find(this.locations, (item) => {
        return id === item.id;
      });
      if (_.isEmpty(data)) {
        return {name: ''};
      }
      return data;
    },
    loadUsers: function (pageNumber = 1, usersLoaded = (users) => {}) {
      NProgress.start();
      Axios.get('/fe/settings/users', {
        params: {
          pageNum: pageNumber,
          sort_by: 'user_id',
          sort_type: 'DESC'
        }
      }).then(response => {
        let users = _.map(response.data.data, item => {
          item.id = Number(item.id);
          return item;
        });
        users = _.sortBy(users, ['id']);
        this.usersAll = users;
        usersLoaded(users);
        NProgress.done();
      }).catch(error => {
        console.log(error);
        NProgress.done();
      });
    },
    onClickShow: function (index) {
      const id = this.usersAll[index].id;
      this.setUserVisibility(id, true)
    },
    onClickHide: function (index) {
      const id = this.usersAll[index].id;
      this.setUserVisibility(id, false)
    },
    setUserVisibility: function (id, visibility = false) {
      NProgress.start();
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/users/${id}`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: {
          id: id,
          isVisible: visibility,
        }
      }).then(response => {
        if (response.status === 200) {
          toastr.success('User has updated')
          this.loadUsers(1);
        } else {
          toastr.error(response.data.msg);
        }
        NProgress.done();
      }).catch(error => {
        console.error(error);
        NProgress.done();
      });
    },
  }
}

</script>
<style scoped>
    .eye {
      font-size: 20px;
      cursor: pointer;
    }

    .contacts-table {
      padding-left: 15px;
    }
</style>
