import _ from "lodash";

export const Getters = {
    getToken: state => {
      return state.token;
    },
    getSearchFilterObj: state => {
      return state.searchFilterObj;
    },
    getSortBy: state => {
      return state.sortBy;
    },
    getSortDesc: state => {
      return state.sortDesc;
    },
    getAllEstimates: state => {
        return state.items;
        return _.orderBy(state.items, ['estimate_id'],['desc']);
    },
    getTimestamp: state => {
        return state.timestamp;
    },
    getEstimatesPerPage: (state) => {
        return state.estimatesPerPage;
    },
    getEstimatesAllCurrentPage: (state) => {
        return state.estimatesAllCurrentPage;
    },
}
