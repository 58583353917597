<template>
    <div class="advanced-search-with-new">
        <span @click="show = !show" class="text-grey" :class="{'display-none': isNew}" style="position: absolute; top: 15px; transform:translateY(-50%); right: 2rem;">
            <i class="fa" :class="{'fa-angle-down': !show, 'fa-angle-up': show}"></i>
        </span>
        <span :class="{'display-none': !isNew, 'text-grey': true}" style="position:absolute;top: 50%; transform:translateY(-50%);right:2rem;">NEW</span>
        <input ref="asb" type="text" v-model="asbValue" class="form-control" @keypress="keyPress" @keydown="keyDown" @keydown.down.prevent="keyDownPrevent" @keydown.up.prevent="keyUp"
               @keyup.enter.prevent="keyEnter"
               style="opacity: 1;"
               @keyup.esc.prevent="onEscape" @blur="onBlur" @focus="onFocus" :disabled="isDisabled" :placeholder="placeholder" :id="id" @change="onChange">
        <div v-if="show" :class="{'search-box-container': true}">
            <div class="search-box">
                <div class="search-results">
                    <ul v-if="searchKey" class="sr-options" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
                        <li :class="{'selected': index == currentPosition, 'sr-option': true}" v-for="(sr, index) in searchResult" @mouseover="mouseOver(index)" @click="keyEnter"
                            v-html="sr[key].replace(regTerm, '<b>$&</b>')"></li>
                        <template>
                            <li :class="{'selected': false && searchResultCount == currentPosition, 'sr-option': true}" v-if="!searchResult.length && searchLabel && asbValue && asbValueExists === -1">
                                No {{searchLabel.toLowerCase()}} found
                            </li>
                            <li :class="{'selected': searchResult.length == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1"
                                @mouseover="mouseOver(searchResult.length)" @click="keyEnter"><strong>Add</strong> {{asbValue}} as a new {{searchLabel.toLowerCase()}}</li>
                            <li :class="{'selected': false && searchResult.length == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1">
                                Or
                            </li>
                            <li :class="{'selected': searchResult.length + 1 == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1"
                                @mouseover="mouseOver(searchResult.length + 1)" @click="onUpdate(asbValue)"><strong>Update</strong> {{searchLabel.toLowerCase()}} as {{asbValue}}</li>
                        </template>
                    </ul>
                    <ul v-else class="sr-options" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
                        <li :class="{'selected': index == currentPosition, 'sr-option': true}" v-for="(sr, index) in searchResult" @mouseover="mouseOver(index)" @click="keyEnter"
                            v-html="sr.replace(regTerm, '<b>$&</b>')"></li>
                        <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1"
                            @mouseover="mouseOver(searchResultCount)" @click="keyEnter">+ New {{searchLabel}}<br/><strong>{{asbValue}}</strong></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import advancedSearch from './advanced-search';
    export default {
        name: 'advancedSearchWithNew',
        extends: advancedSearch,
        watch: {
          asbValue: {
            handler() {
                if (this.searchKey && this.searchLabel && this.asbValue && this.asbValueExists === -1) {
                  setTimeout(() => {
                    if (!this.searchResult.length) {
                      this.currentPosition = this.searchResult.length
                    } else {
                      this.currentPosition = this.searchResult.length + 1
                    }
                  }, 0)
                }
            },
            immediate: true
          }
        },
        methods: {
            onUpdate(val) {
                this.show = false;
                this.$emit('onUpdate', val);
            },
            keyEnter: function (d) {
              if (this.currentPosition >= this.searchResultCount || (this.searchResult.length == this.currentPosition || this.searchResult.length + 1 == this.currentPosition)) {
                if (this.searchResult.length == this.currentPosition) {
                  this.onSelect(null);
                } else if (this.searchResult.length + 1 == this.currentPosition) {
                  this.onUpdate(this.asbValue)
                } else if (this.currentPosition >= this.searchResultCount) {
                  if (this.this.searchKey && this.searchLabel && this.asbValue && this.asbValueExists === -1) {
                    this.onUpdate(this.asbValue)
                  } else {
                    this.onSelect(null);
                  }
                }
              } else {
                this.onSelect(this.searchResult[this.currentPosition]);
              }
            },
        },
        computed: {
          searchResultCount: function () {
            let count = 0;
            if (this.searchKey && this.searchLabel && this.asbValue && this.asbValueExists === -1) {
              count = 1
            }
            return this.searchResult.length + count;
          }
        }
    };
</script>

<style>
.advanced-search-with-new .search-box-container .search-box ul.sr-options li.sr-option.selected {
  background-color: rgba(94, 121, 255, 0.1) !important;
  color: black !important;
}

</style>
