<template>
  <div style="transform: translateY(-10px)" v-if="value && value.estimatesBooking">
    <div class="tabs-component tabs-component-2" style="padding-top: 0px">
      <div class="tabs-component-w">
        <ul class="nav nav-tabs nav-tabs-2 float-sm-left" @click="setTab('Scheduling')" style="padding-right: 0">
          <li style='padding-top: 0px'  class="tabs-component-tab nav-item">
            <span href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedTab == 'Scheduling'}">Scheduling</span>
          </li>
        </ul>
      </div>
      <div class="tabs-component-w">
        <ul class="nav nav-tabs nav-tabs-2 float-sm-left" @click="setTab('Notifications')" style="padding-left: 0; padding-right: 0">
          <li style='padding-top: 0px'  class="tabs-component-tab nav-item">
            <span href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedTab == 'Notifications'}">Customer Notifications</span>
          </li>
        </ul>
      </div>
      <div class="tabs-component-w">
        <ul class="nav nav-tabs nav-tabs-2 float-sm-left" @click="setTab('Booking')" style="padding-left: 0; padding-right: 0">
          <li style='padding-top: 0px'  class="tabs-component-tab nav-item">
            <span href="" class="tabs-component-tab-a nav-link" :class="{'active': selectedTab == 'Booking'}">Re-booking</span>
          </li>
        </ul>
      </div>
    </div>

    <scheduling v-if="selectedTab == 'Scheduling'" v-model="value"></scheduling>
    <customer-notifications v-if="selectedTab == 'Notifications'" v-model="value" :templates="templates"></customer-notifications>
    <re-booking v-if="selectedTab == 'Booking'" v-model="value" :templates="templates"></re-booking>

  </div>

</template>
<script>
import _ from "lodash";
import Axios from "axios";
import Scheduling from './scheduling'
import CustomerNotifications from './customer-notifications'
import ReBooking from './re-booking'

export default {
  name: "EstimatesBooking",
  components: {
    Scheduling,
    CustomerNotifications,
    ReBooking,
  },
  props: {
    value: {}
  },
  data() {
    return{
      templates: [],
      selectedTab: 'Scheduling',
    }
  },
  created() {
    this.loadTemplates()
    if (!_.isEmpty(this.$storage.get("views.advancedSettings.booking.defaultView"))) {
      this.selectedTab = this.$storage.get("views.advancedSettings.booking.defaultView");
    }
  },
  methods: {
    setTab(v) {
      this.selectedTab = v
      this.$storage.set("views.advancedSettings.booking.defaultView", v)
    },
    loadTemplates() {
      Axios.get('/fe/sms-templates').then((r) => {
        if (r.data._status && r.data.templates) {
          this.templates = r.data.templates
        }
      })
    },
  },
};
</script>

<style scoped>
.tab-content .active {
  width: 100%;
}

.tabs-component-2::after {
  content: '';
  width: 100%;
  display: block;
  background: #e3e4e7;
  height: 1px;
  clear: both;
}

.nav-item:hover {
  cursor: pointer;
}

</style>
