import _ from "lodash";

export const Getters = {
  getZoomScaleIn(state){
    return Number(state.zoomScaleIn);
  },
  getZoomScaleDeparture(state){
    return Number(state.zoomScaleDeparture);
  },
  getProduction(state) {
    return state.production
  },
  getAttempts(state) {
    return state.attempts
  },
  getReasons(state) {
    return state.reasons
  },
  getBoardIn(state){
    return _.orderBy(state.itemsIn, ['updatedDate'], ['desc']);
  },
  getBoardDeparture(state){
    return _.orderBy(state.itemsDeparture, ['sortDate', 'sortTime'], ['asc', 'asc']);
  },
  getTimestamp: state => {
    return state.timestamp;
  },
  getBookingItems: state => {
    return state.bookingItems;
  },
  getBookingFilter: state => {
    return state.bookingFilter;
  },
}
