<template>
  <div class="d-flex" style="align-items: center; height: 100%; margin-top: 5px">
    <div class="form-group" style="margin-bottom: 0px !important;">
      <input
          v-model="hrs"
          ref="hrs"
          type="tel"
          pattern="\d*"
          @keydown="onKeyDownHrs($event)"
          :disabled="isDisabled"
          placeholder="HH"
          maxlength="2"
          size="2"
          @focus="selectAllHrs"
          @change="onHrsChange"
          class="form-control text-center"
          style="width: 50px;"
          @keypress="onlyNumber">
    </div>
    <div>&nbsp;<b>:</b>&nbsp;</div>
    <div class="form-group" style="margin-bottom: 0px !important;">
      <input
          ref="minute"
          v-model="minutes"
          type="tel"
          pattern="\d*"
          placeholder="MM"
          @keydown="onKeyDownMinutes"
          @change="onMinutesChange"
          maxlength="2"
          @focus="selectAllMinute"
          size="2"
          class="form-control text-center"
          style="width: 50px;"
          @keypress="onlyNumber">
    </div>
    &nbsp;
    <div class=""><button data-action="toggleMeridian" type="button" class="btn btn-default" style="margin-bottom: 0px !important; width: 42px" @click="changeTimeMode">{{isAM ? 'AM': 'PM'}}</button></div>
  </div>

</template>

<script>
import dayjs from "dayjs";
export default {
  name: "the-time-picker",
  components: {
  },
  data() {
    return {
      minutes: null,
      hrs: null,
      prevMinutes: null,
      prevHrs: null,
      isAM: true
    }
  },
  mounted() {
    this.formatFrom24To12(_.cloneDeep(this.value))
  },
  watch: {
    value: {
      handler(v) {
        this.formatFrom24To12(_.cloneDeep(v))
      },
      immediate: true
    }
  },
  methods: {
    changeTimeMode() {
      this.isAM = !this.isAM
      this.emitCurrentValue()
    },
    emitCurrentValue() {
      let r = null
      console.log(this.hrs, this.minutes, (!this.hrs || !this.minutes), (this.hrs || this.minutes))
      if ((!this.hrs || !this.minutes) && (this.hrs || this.minutes)) {
        return
      }
      if (this.hrs && this.minutes) {
        r = ''
        let hrs = Number(this.hrs)
        if (!this.isAM) {
          if (hrs == 12) {
            hrs = 12
          } else {
            hrs =  hrs + 12
          }
        } else {
          if (hrs == 12) {
            hrs = 0
          }
        }
        r = this.zeroPad(hrs) + ':' + this.zeroPad(Number(this.minutes))
      }
      console.log('result', r)
      this.$emit('changed', r)
      this.$emit('input')
    },
    formatFrom24To12(v) {
      if (!v) {
        return
      }
      if (!_.includes(v, ':')) {
        return
      }
      let hrs = Number(v.split(':')[0])
      let minutes = v.split(':')[1]
      if (hrs >= 0 && hrs < 12) {
        this.isAM = true
        if (hrs == 0) {
          this.hrs = 12
        } else {
          this.hrs = hrs
        }
      } else if ((hrs >= 12 && hrs <= 23)) {
        this.isAM = false
        if (hrs == 12) {
          this.hrs = 12
        } else {
          this.hrs = hrs - 12
        }
      }
      this.minutes = minutes

      this.hrs = this.zeroPad(this.hrs)
      this.minutes = this.zeroPad(this.minutes)
      this.prevHrs = this.hrs
      this.prevMinutes = this.minutes
    },
    zeroPad(num) {
      return String(num).padStart(2, '0')
    },
    onKeyDownHrs(e) {
      if (e.keyCode != '38' && e.keyCode != '40') {
        return
      }
      if (e.keyCode == '38') {
        if (Number(this.hrs) == 12) {
          this.hrs = 1
        } else if (Number(this.hrs) !== 11) {
          this.hrs = Number(this.hrs) + 1
        } else {
          this.hrs = Number(this.hrs) + 1
          this.isAM = !this.isAM
        }
      }
      if (e.keyCode == '40') {
        if (Number(this.hrs) == 12) {
          this.hrs = Number(this.hrs) - 1
          this.isAM = !this.isAM
        } else if (Number(this.hrs) == 1) {
          this.hrs = 12
        } else {
          this.hrs = Number(this.hrs) - 1
        }
      }
      this.hrs = this.zeroPad(this.hrs)
      this.prevHrs = this.hrs
      this.emitCurrentValue()
    },
    onKeyDownMinutes(e) {
      if (e.keyCode != '38' && e.keyCode != '40') {
        return
      }
      if (e.keyCode == '38') {
        if (Number(this.minutes) == 59) {
          this.minutes = 0
          this.onKeyDownHrs({keyCode: 38})
        } else {
          this.minutes = Number(this.minutes) + 1
        }
      }
      if (e.keyCode == '40') {
        if (Number(this.minutes) == 0) {
          this.minutes = 59
          this.onKeyDownHrs({keyCode: 40})
        } else {
          this.minutes = Number(this.minutes) - 1
        }
      }
      this.minutes = this.zeroPad(this.minutes)
      this.prevMinutes = this.minutes
      this.emitCurrentValue()
    },
    onHrsChange() {
      if (this.hrs === '') {
        this.hrs = null
        this.minutes = null
        this.emitCurrentValue()
        return
      }
      this.hrs = this.zeroPad(this.hrs)
      if (Number(this.hrs) > 12 || Number(this.hrs) <= 0) {
        this.hrs = this.prevHrs
      } else {
        this.prevHrs = this.hrs
      }
      this.emitCurrentValue()
    },
    onMinutesChange() {
      if (this.minutes === '') {
        this.hrs = null
        this.minutes = null
        this.emitCurrentValue()
        return
      }
      this.minutes = this.zeroPad(this.minutes)
      if (Number(this.hrs) >= 60 || Number(this.hrs) < 0) {
        this.minutes = this.prevHrs
      } else {
        this.prevHrs = this.minutes
      }
      this.emitCurrentValue()
    },
    selectAllHrs() {
      setTimeout(() => {
        this.$refs.hrs.selectionStart = 0;
        this.$refs.hrs.selectionEnd = this.$refs.hrs.value.length;
      }, 50)
    },
    selectAllMinute() {
      setTimeout(() => {
        this.$refs.minute.selectionStart = 0;
        this.$refs.minute.selectionEnd = this.$refs.minute.value.length;
      }, 50)
    },
    onlyNumber (event) {
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 32 && keyCode !== 45 && keyCode !== 43 && keyCode !== 40 && keyCode !== 41) {
        event.preventDefault();
      }
    },
  },
  props: {
    value: {},
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped>

</style>
