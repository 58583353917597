<script>
import { isPromise, findElementAndFocus, getFocusedTabIndex } from './helpers'

export default {
    name: 'modal-wizard',
    props: {
        showButtonInHeader: {
            type: Object,
            default: function () {
                return {}
            }
        },
        finishButtonTooltip: {
          type: String,
          default: '',
        },
        isDisabledFinishButton: {
          type: Boolean,
          default: false,
        },
        finishButtonText: {
            type: String,
            default: 'Submit'
        },
        nextButtonText: {
            type: String,
            default: 'Next'
        },
        backButtonText: {
            type: String,
            default: 'Back'
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel'
        },
        hideFinishButton: {
            type: Boolean,
            default: false
        },
        hideNextButton: {
            type: Boolean,
            default: false
        },
        hideBackButton: {
            type: Boolean,
            default: false
        },
        hideCancelButton: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            activeTabIndex: 0,
            tabs: [],
            showDialog: false,
            loading: false,
            activeButton: 'next',
            buttons: ['next', 'back', 'cancel']
        }
    },
    provide: function () {
        return {
            addTab: this.addTab,
            removeTab: this.removeTab
        }
    },
    computed: {
        tabTitle: function () {
            if (this.tabCount === 0) {
                return ''
            }
            return this.tabs[this.activeTabIndex].title
        },
        tabTitleIconClass: function () {
            if (this.tabCount === 0) {
                return ''
            }
            return this.tabs[this.activeTabIndex].titleIconClass
        },
        tabDescription: function () {
            if (this.tabCount === 0) {
                return ''
            }
            return this.tabs[this.activeTabIndex].description
        },
        tabCount: function () {
            return this.tabs.length
        },
        tabValidationError: function () {
            if (this.tabCount === 0) {
                return null
            }
            return this.tabs[this.activeTabIndex].validationError
        },
        isLastStep: function () {
            return this.activeTabIndex === this.tabCount - 1
        },
        isFirstStep: function () {
            return this.activeTabIndex === 0
        },
        currentButtons: function () {
            let buttons = []
            if (this.isLastStep) {
                buttons.push('finish')
            }

            if (!this.isLastStep) {
                buttons.push('next')
            }

            if (!this.isFirstStep) {
                buttons.push('back')
            }

            buttons.push('cancel')

            return buttons
        }
    },
    // watch: {
    // 	currentButtons: function () {
    // 		console.log(this.currentButtons)
    // 	}
    // },
    methods: {
        addTab: function (item) {
            var index = this.$slots.default.indexOf(item.$vnode)
            if (index < 0) {
                index = 0
            }
            item.tabId = `${item.title.replace(/ /g, '')}${index}`
            this.tabs.splice(index, 0, item)
            if (index === 0) {
                this.activateTab(0)
            }
        },
        removeTab: function () {},
        nextTab: function () {
            // if (this.activeTabIndex < this.tabCount - 1) {
            // 	this.activeTabIndex += 1
            // }
            // this.activateTab()
            let cb = () => {
                if (this.activeTabIndex < this.tabCount - 1) {
                    this.changeTab(this.activeTabIndex, this.activeTabIndex + 1)
                } else {
                    this.$emit('on-complete')
                }
            }
            this.beforeTabChange(this.activeTabIndex, cb)
        },
        changeTab: function (oldIndex, newIndex, emitChangeEvent = true) {
            let oldTab = this.tabs[oldIndex]
            let newTab = this.tabs[newIndex]

            if (oldTab) {
                oldTab.active = false
            }

            if (newTab) {
                newTab.active = true
            }

            // if (emitChangeEvent && this.activeTabIndex !== newIndex) {
            // 	this.emitTabChange(oldIndex, newIndex)
            // }

            this.activeTabIndex = newIndex
            // this.activateTabAndCheckStep(this.activeTabIndex)
            // this.activateButton()
            this.$emit('on-change-tab', { oldIndex, newIndex, emitChangeEvent })
            return true
        },
        beforeTabChange: function (index, callback) {
            let oldTab = this.tabs[index]

            if (oldTab && oldTab.beforeChange !== undefined) {
                let tabChangeRes = oldTab.beforeChange()
                this.validateBeforeChange(tabChangeRes, callback)
            } else {
                callback()
            }
        },
        setValidationError: function (error) {
            this.tabs[this.activeTabIndex].validationError = error
            this.$emit('on-error', error)
        },
        setLoading: function (value) {
            this.loading = value
            this.$emit('on-loading', value)
        },
        validateBeforeChange: function (promiseFn, callback) {
            this.setValidationError(null)
            // console.log(promiseFn)
            // we have a promise
            if (isPromise(promiseFn)) {
                this.setLoading(true)
                promiseFn
                    .then((res) => {
                        this.setLoading(false)
                        let validationResult = res === true
                        this.executeBeforeChange(validationResult, callback)
                    })
                    .catch((error) => {
                        this.setLoading(false)
                        this.setValidationError(error)
                    })
                // we have a simple function
            } else {
                let validationResult = promiseFn === true
                this.executeBeforeChange(validationResult, callback)
            }
        },
        executeBeforeChange: function (validationResult, callback) {
            this.$emit('on-validate', validationResult, this.activeTabIndex)

            if (validationResult) {
                callback()
            } else {
                this.tabs[this.activeTabIndex].validationError = 'error'
            }
        },
        prevTab: function () {
            if (this.activeTabIndex > 0) {
                this.setValidationError(null)
                this.changeTab(this.activeTabIndex, this.activeTabIndex - 1)
            }
        },
        deactivateTabs: function () {
            this.tabs.forEach((tab) => {
                tab.active = false
            })
        },
        activateTab: function (index) {
            this.deactivateTabs()
            let tab = this.tabs[index]
            if (tab) {
                tab.active = true
            }
        },
        show: function () {
            this.showDialog = true
            $('body').addClass('modal-open')
        },
        hide: function () {
            if (this.loading) {
                console.log('loading... please wait...')
                return
            }
            while (this.tabs.length > 0) {
                this.tabs.pop()
            }
            this.showDialog = false
            this.activeTabIndex = 0
            $('body').removeClass('modal-open')
          this.$emit('on-close');
        },
        cancel(){
          this.$emit('on-cancel');
          this.hide();
        },
        activateButton: function () {
            let pos = this.currentButtons.indexOf(this.activeButton)

            if (pos === -1) {
                this.activeButton = this.currentButtons[0]
            }

            if (
                this.showDialog &&
                this.$refs[this.activeButton + 'ModalWizardButton']
            ) {
                this.$refs[this.activeButton + 'ModalWizardButton'].focus()
            }
        },
        rightKeyPressed: function () {
            let pos = this.currentButtons.indexOf(this.activeButton)
            if (pos === this.currentButtons.length - 1) {
                pos = 0
            } else {
                pos++
            }
            this.activeButton = this.currentButtons[pos]
            this.activateButton()
        },
        leftKeyPressed: function () {
            let pos = this.currentButtons.indexOf(this.activeButton)
            if (pos === 0) {
                pos = this.currentButtons.length - 1
            } else {
                pos--
            }
            this.activeButton = this.currentButtons[pos]
            this.activateButton()
        },
        escapeKeyPressed: function () {
            if (this.$refs.cancelModalWizardButton) {
                this.$refs.cancelModalWizardButton.click()
            } else {
                this.hide()
            }
        }
        // enterKeyPressed: function () {
        // 	if (this.showDialog && this.$refs[this.activeButton + 'ModalWizardButton']) {
        // 		this.$refs[this.activeButton + 'ModalWizardButton'].focus()
        // 	}
        // }
    },
    updated: function () {
        this.activateButton()
    }
}
</script>

<template>
    <div tabindex="0">
        <transition name="slide">
            <div
                v-if="showDialog"
                @click.stop="hide"
                @keyup.esc="escapeKeyPressed"
                role="dialog"
                class="modal fade in display-block modal-wizard"
            >
                <div
                    @click.stop=""
                    role="document"
                    class="modal-dialog modal-lg"
                >
                    <div class="modal-content">
                        <div class="modal-header bg-primary">
                            <h4
                                class="modal-title"
                                style="display: inline-block"
                            >
                                {{ tabTitle }}
                            </h4>
                            <div
                                class="modal-button"
                                style="
                                    display: inline;
                                    text-align: right;
                                    float: right;
                                "
                            >
                                <button
                                    id="backModalWizardButton2"
                                    ref="backModalWizardButton2"
                                    v-if="
                                        !isFirstStep &&
                                        showButtonInHeader[activeTabIndex]
                                    "
                                    @keyup.left="leftKeyPressed"
                                    @keyup.right="rightKeyPressed"
                                    @click="
                                        activeButton = 'back'
                                        prevTab()
                                    "
                                    type="button"
                                    class="btn"
                                    :class="{
                                        'btn-primary': activeButton === 'back',
                                        'btn-secondary': activeButton !== 'back'
                                    }"
                                    :disabled="loading"
                                >
                                    {{ backButtonText }}
                                </button>
                                <button
                                    id="finishModalWizardButton2"
                                    ref="finishModalWizardButton2"
                                    v-if="
                                        isLastStep &&
                                        !hideFinishButton &&
                                        showButtonInHeader[activeTabIndex]
                                    "
                                    @keyup.left="leftKeyPressed"
                                    @keyup.right="rightKeyPressed"
                                    @click="
                                        activeButton = 'finish'
                                        nextTab()
                                    "
                                    type="button"
                                    class="btn"
                                    style="border: 1px solid #ffffff"
                                    :class="{
                                        'btn-primary':
                                            activeButton === 'finish',
                                        'btn-secondary':
                                            activeButton !== 'finish'
                                    }"
                                    :disabled="loading"
                                >
                                    {{ finishButtonText }}
                                </button>
                                <button
                                    id="nextModalWizardButton2"
                                    ref="nextModalWizardButton2"
                                    v-if="
                                        !isLastStep &&
                                        showButtonInHeader[activeTabIndex]
                                    "
                                    @keyup.left="leftKeyPressed"
                                    @keyup.right="rightKeyPressed"
                                    @click="
                                        activeButton = 'next'
                                        nextTab()
                                    "
                                    type="button"
                                    class="btn"
                                    :class="{
                                        'btn-primary': activeButton === 'next',
                                        'btn-secondary': activeButton !== 'next'
                                    }"
                                    style="border: 1px solid #ffffff"
                                    :disabled="loading"
                                >
                                    {{ nextButtonText }}
                                </button>
                                <button
                                    v-if="
                                        !hideCancelButton &&
                                        showButtonInHeader[activeTabIndex]
                                    "
                                    id="cancelModalWizardButton2"
                                    ref="cancelModalWizardButton2"
                                    @keyup.left="leftKeyPressed"
                                    @keyup.right="rightKeyPressed"
                                    @click="
                                        activeButton = 'next'
                                        cancel()
                                    "
                                    type="button"
                                    class="btn"
                                    :class="{
                                        'btn-primary':
                                            activeButton === 'cancel',
                                        'btn-secondary':
                                            activeButton !== 'cancel'
                                    }"
                                    :disabled="loading"
                                >
                                    {{ cancelButtonText }}
                                </button>
                                <button
                                    @click="hide"
                                    type="button"
                                    class="close"
                                    style="margin-left: 15px"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    :disabled="loading"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div
                            v-if="tabDescription"
                            :class="{
                                'modal-body-heading': activeTabIndex === 0
                            }"
                            class="modal-body-title"
                        >
                            {{ tabDescription }}
                        </div>
                        <slot name="htmlDescription"></slot>
                        <div class="modal-body pb-0">
                            <div
                                v-if="tabValidationError"
                                class="text-danger mb-1"
                                v-html="tabValidationError"
                            ></div>
                            <div class="tab-content pt-0 pb-0">
                                <slot></slot>
                            </div>
                        </div>
                        <div class="modal-footer modal-wizard-footer pt-0">
                            <div style="display: inline-block" v-b-tooltip.hover="{customClass: 'ps-tooltip finish-tooltip-custom', disabled: !finishButtonTooltip, title: finishButtonTooltip, placement:'bottomleft'}">
                              <button
                                  id="finishModalWizardButton"
                                  ref="finishModalWizardButton"
                                  v-if="isLastStep && !hideFinishButton"
                                  @keyup.left="leftKeyPressed"
                                  @keyup.right="rightKeyPressed"
                                  @click="
                                    activeButton = 'finish'
                                    nextTab()
                                "
                                  type="button"
                                  class="btn btn-next btn-paid-min-size mr-1"
                                  :class="{
                                    'btn-primary': activeButton === 'finish',
                                    'btn-secondary': activeButton !== 'finish'
                                }"
                                  :disabled="loading || isDisabledFinishButton"
                              >
                                {{ finishButtonText }}
                              </button>
                            </div>
                            <button
                                id="nextModalWizardButton"
                                ref="nextModalWizardButton"
                                v-if="!isLastStep"
                                @keyup.left="leftKeyPressed"
                                @keyup.right="rightKeyPressed"
                                @click="
                                    activeButton = 'next'
                                    nextTab()
                                "
                                type="button"
                                class="btn btn-next btn-next-size mr-1"
                                :class="{
                                    'btn-primary': activeButton === 'next',
                                    'btn-secondary': activeButton !== 'next'
                                }"
                                :disabled="loading"
                            >
                                {{ nextButtonText }}
                            </button>
                            <button
                                id="backModalWizardButton"
                                ref="backModalWizardButton"
                                v-if="!isFirstStep"
                                @keyup.left="leftKeyPressed"
                                @keyup.right="rightKeyPressed"
                                @click="
                                    activeButton = 'back'
                                    prevTab()
                                "
                                type="button"
                                class="btn mr-1"
                                :class="{
                                    'btn-primary': activeButton === 'back',
                                    'btn-secondary': activeButton !== 'back'
                                }"
                                :disabled="loading"
                            >
                                {{ backButtonText }}
                            </button>
                            <button
                                v-if="!hideCancelButton"
                                id="cancelModalWizardButton"
                                ref="cancelModalWizardButton"
                                @keyup.left="leftKeyPressed"
                                @keyup.right="rightKeyPressed"
                                @click="
                                    activeButton = 'next'
                                    hide()
                                "
                                type="button"
                                class="btn"
                                :class="{
                                    'btn-primary': activeButton === 'cancel',
                                    'btn-secondary': activeButton !== 'cancel'
                                }"
                                :disabled="loading"
                            >
                                {{ cancelButtonText }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="showDialog" class="modal-backdrop fade in"></div>
        </transition>
    </div>
</template>

<style>
.modal-wizard .finish-tooltip-custom {
  left: -40px !important;
}

.modal-wizard .tab-content > .tab-pane {
    animation: fade-in-full 0.2s ease-in-out;
}

.modal-body-heading {
    font-weight: 600;
}

.modal-body-title {
    font-weight: 600;
    padding: 15px;
}

.modal-body-title-fw {
    font-weight: 600;
}

.modal-wizard .modal-footer {
    border-top: none;
}

.modal-footer {
    padding: 0 30px 30px 30px;
}

@media (min-width: 767px) {
    .modal-wizard {
        top: 16%;
        left: 16%;
    }
}

@media (max-width: 767px) {
    .modal-wizard {
        top: 10%;
    }
}
</style>

<style scoped>
.btn-paid-min-size {
    min-width: 68px;
}
.btn-next-size {
    height: 40px;
    width: 68px;
}
.V3 h4.modal-title {
    padding-left: 16px;
}
.modal-body-heading {
    padding-left: 30px;
}
</style>
