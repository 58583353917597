<script>
    import { mapGetters } from 'vuex';
    import {VStepper} from 'vue-stepper-component';
    import GoodWizard from '../../utility/vueGoodWizard';
    import ImageSelector from '../../utility/image-selector';
    import Email from '../../utility/email-validator';
    import Axios from 'axios';
    import EstimatePreview from '../estimate-preview';
    import Multiselect from "vue-multiselect";
    import _ from "lodash";

    export default {
        name: 'estimate-options-send-to-insurers',
        data: function () {
            return {
                isCanSend: true,
                new_insurer_name: '',
                new_insurer_email: '',
              allFilesSelected: false,
              allEstimateFilesSelected: true,
              files: [],
              estimatePdfFiles: [
                {
                  type: 'original',
                  show: true,
                  name: `Estimate [EstimateNumber].pdf`,
                  attached: true,
                  messageAttached: "Original estimate to be attached",
                  messageNotAttached: "Original estimate not attached",
                },
                {
                  type: 'adjusted',
                  show: false,
                  name: `Estimate [EstimateNumber] - ADJ.pdf`,
                  attached: false,
                  messageAttached: "Adjusted estimate to be attached",
                  messageNotAttached: "Adjusted estimate not attached",
                }
              ],
                estimateToInsurers: {
                    countSubtotal: 0,
                    vendor: {},
                    customer: {
                      customer_name: '',
                    },
                    customer_vehicle: {
                      rego_number: '',
                    },
                    estimate: {
                      vehicleRego: '',
                      vehicleOwner: '',
                      number: '',
                      estimate_number: '',
                      supplement_number: '',
                    },
                    insurance: {
                        insurer_default_email: '',
                        rates: {},
                    },
                    insurance_repair: false,
                    rates: {},
                    line_items: {
                        lines: {
                            RR: [],
                            Repair: [],
                            Paint: [],
                            Part: [],
                            Materials: [],
                            Sublet: [],
                            Other: [],
                        },
                        summary: {},
                    },
                    terms: '',
                },
                insurerContacts:[],
                images: [],
                selectedImages: [],
                steps: [
                    {
                        label: 'Select Insurers',
                        slot: 'estimate-options-send-to-insurers-step-1',
                    },
                    {
                        label: 'Estimates Preview',
                        slot: 'estimate-options-send-to-insurers-step-2',
                    },
                    {
                        label: 'Select Images',
                        slot: 'estimate-options-send-to-insurers-step-3',
                        options: {
                            nextDisabled: true,
                        },
                    },
                    {
                      label: 'Select Files',
                      slot: 'estimate-options-send-to-insurers-step-4',
                    }
                ],
                step: 1,
                selectedPc: 0,
                pcs: [],
                currentPc: null,
                programRunning: false,
            };
        },
      watch: {
        'estimateToInsurers.estimate.isAdjusted': function (nv) {
          nv = !!nv;
          this.estimatePdfFiles.forEach(fl => {
            if (fl.type == 'adjusted') {
              console.log('Adjusted found', fl);
              fl.show = nv;
              if (!nv) {
                fl.attached = false;
              }
            }
          });
        }
      },
        methods: {
          toggleSelectFile() {
            let selectedFiles = _.filter(this.files, (f) => {
              return f.selected
            })
            this.allFilesSelected = selectedFiles.length == this.files.length
          },
          toggleSelectAllFiles() {
            if (this.allFilesSelected) {
              this.files.forEach(file => file.selected = false);
            } else {
              this.files.forEach(file => file.selected = true);
            }
          },
          toggleCheckboxEstimateFiles() {
            if (this.estimateToInsurers.estimate.isAdjusted) {
              if (this.estimatePdfFiles[0].attached && this.estimatePdfFiles[1].attached) {
                this.allEstimateFilesSelected = true
              } else {
                this.allEstimateFilesSelected = false
              }
            } else {
              if (this.estimatePdfFiles[0].attached) {
                this.allEstimateFilesSelected = true
              } else {
                this.allEstimateFilesSelected = false
              }
            }
          },
          toggleSelectAllEstimateFiles() {
            if (this.allEstimateFilesSelected) {
              this.estimatePdfFiles.forEach(file => file.attached = false);
            } else {
              this.estimatePdfFiles.forEach((file) => {
                if ((this.estimateToInsurers.estimate.isAdjusted && file.type == 'adjusted') || file.type == 'original') {
                  file.attached = true;
                } else {
                  file.attached = false;
                }
              });
            }
          },
            getPcs(){
                Axios.get('http://localhost:8899', {})
                    .then(res => {
                        if(res.data && res.data.Status){
                            this.programRunning = !!res.data.ApiKey;
                            this.currentPc = res.data;
                        }
                    })
                    .catch(error => {

                    })
                    .finally(() => {

                        Axios.post('/test/getConnectedPcs', {
                            currentApiKey: this.currentPc ? this.currentPc.ApiKey : null
                        })
                            .then(res => {
                                if(res.data && res.data.status){
                                    console.log("PCs %o", res.data.pcs)
                                    this.pcs = res.data.pcs;
                                    this.pcs.forEach(pc => {
                                        if(pc.current){
                                            this.selectedPc = pc.id;
                                        }
                                    })
                                }
                            }).catch(er => {
                                console.log(er);
                            });

                    })
            },
            onImageSelect: function (imgs) {
                console.log(imgs);
                this.selectedImages = imgs;
            },
            goToEstimate() {
                this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab}})
            },
            clickCancel() {
                this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab}})
                return;
            },
            clickSend() {
                if (!this.isCanSend) {
                  return;
                }

              let pdfs = [];

              this.estimatePdfFiles.forEach(pdf => {
                if (pdf.attached && pdf.show) {
                  pdfs.push(pdf.type);
                }
              });

                this.isCanSend = false;

                let estimate_id = this.$route.params.estimate_id;

                NProgress.start();

                if(this.estimateToInsurers.insurance.claim_management == "Estimage" && this.insurerContacts[0].cms_check && this.vendorInfo?.features?.integrations?.estimage){
                    Axios({
                        method: 'post',
                        responseType: 'json',
                        url: `/fe/estimate/to/iag-estimage/${estimate_id}`,
                        validateStatus: function (status) {
                            return status < 500;
                        },
                        data: JSON.stringify({
                            insurers: this.insurerContacts,
                            images: this.selectedImages,
                          files: this.selectedFiles,
                            selectedPc: this.selectedPc,
                          pdfs: pdfs,
                        })
                    })
                        .then(response => {
                            if (response.status == 200 && response.data.status) {
                                // toastr.success(response.data.msg);
                                toastr.success("Sent to Estimage");
                                //if (this.state == 0) {
                                //    this.markAs(1);
                                //}
                                this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab}})
                            } else {
                                toastr.error(response.data.msg);
                            }
                            NProgress.done();
                        })
                        .catch(error => {
                            NProgress.done();
                            console.log(error);
                            toastr.error('error');
                        })
                        .finally(()=>{
                          this.isCanSend = true;
                        });
                } else {
                    Axios({
                        method: 'post',
                        responseType: 'json',
                        url: `/fe/email/insurer/${estimate_id}`,
                        validateStatus: function (status) {
                            return status < 500;
                        },
                        data: JSON.stringify({
                            insurers: this.insurerContacts,
                            images: this.selectedImages,
                          pdfs: pdfs,
                          files: this.selectedFiles,
                        })
                    })
                        .then(response => {
                            if (response.status == 200) {
                                toastr.success(response.data.msg);
                                //if (this.state == 0) {
                                //    this.markAs(1);
                                //}
                                this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab}})
                            } else {
                                toastr.error(response.data.msg);
                            }
                            NProgress.done();
                        })
                        .catch(error => {
                            NProgress.done();
                            console.log(error);
                            toastr.error('error');
                        })
                        .finally(()=>{
                          this.isCanSend = true;
                        });
                }
            },
            addNewInsurerContact: function () {
                this.insurerContacts.push({
                    insurer_name: this.new_insurer_name,
                    insurer_email:  this.new_insurer_email,
                });
                if(this.estimateToInsurers.insurance.claim_management == "Estimage" && this.vendorInfo?.features?.integrations?.estimage){
                    this.insurerContacts[this.insurerContacts.length - 1].cms_check = true;
                    this.insurerContacts[this.insurerContacts.length - 1].email_check = false;
                }
                if(this.estimateToInsurers.insurance.claim_management == "Email"){
                    this.insurerContacts[this.insurerContacts.length - 1].cms_check = false;
                    this.insurerContacts[this.insurerContacts.length - 1].email_check = true;
                }
                this.new_insurer_name = '';
                this.new_insurer_email = '';
            },
          getImages(v) {
            let r = [];
            _.forEach(_.cloneDeep(v), (itm) => {
              if (!_.includes(itm.src, 'at=')) {
                itm.src += '/?at=' + localStorage.getItem('token')
              }
              if (!_.includes(itm.previewW220, 'at=')) {
                itm.previewW220 += '/?at=' + localStorage.getItem('token')
              }
              r.push(itm)
            })
            return r
          },
            loadEstimateToInsurers() {
                NProgress.start();
                let estimate_id = this.$route.params.estimate_id;
                let self = this;

                Axios.get(`/fe/estimate/to/insurers/${estimate_id}`)
                    .then(response => {
                        self.estimateToInsurers = response.data;

                        if (this.estimateToInsurers.estimate.isAdjusted) {
                          this.estimatePdfFiles[1].attached = true
                        }

                        let countSubtotal = 0;
                        if (self.estimateToInsurers && self.estimateToInsurers.line_items && self.estimateToInsurers.line_items.lines) {
                            _.forEach(self.estimateToInsurers.line_items.lines, function (i) {
                                if (i.length > 0) {
                                    countSubtotal++;
                                }
                            });
                        }
                        self.countSubtotal = countSubtotal;

                        self.images = self.getImages(response.data.images);

                      self.files = _.orderBy(response.data.files, ['sortDate'], ['desc']);
                      self.files = self.files.map(file => {
                        self.$set(file, 'selected', false);
                        return file;
                      });

                        this.insurerContacts = [];
                        if (self.estimateToInsurers.insurance.insurer_email) {


                          this.new_insurer_name = self.estimateToInsurers.insurance.insurer_name;
                          this.new_insurer_email = self.estimateToInsurers.insurance.insurer_email;

                          if (self.estimateToInsurers.insurance.insurer_supps_email_default) {
                            this.new_insurer_email = self.estimateToInsurers.insurance.insurer_supps_email;
                          }

                          this.addNewInsurerContact();
                        }


                        if (
                            (self.estimateToInsurers.insurance.claim_management == "Estimage" && !self.estimateToInsurers.insurance.insurer_email && self.vendorInfo?.features?.integrations?.estimage)
                            || (self.estimateToInsurers.insurance.claim_management != "Estimage" && self.estimateToInsurers.insurance.assessor_email)) {
                            this.new_insurer_name = self.estimateToInsurers.insurance.assessor_name;
                            this.new_insurer_email = self.estimateToInsurers.insurance.assessor_email;
                            this.addNewInsurerContact();
                        }
                        NProgress.done();
                        if (this.estimateToInsurers.insurance.claim_management == "Estimage" && this.vendorInfo?.features?.integrations?.estimage){
                            this.getPcs();
                        }
                    })
                    .catch(error => {
                        toastr.error("Error occurred while loading estimate to customer info");
                        console.log(error);
                        NProgress.done();
                    });
            },
        },
        computed: {
            ...mapGetters({
                getterBusinessName : 'getBusinessName',
                vendorInfo : 'vendorInfo',
            }),
          isAllFilesSelected() {
            if (this.selectedFiles.length > 0 && this.selectedFiles.length === this.files.length) {
              this.allFilesSelected = true;
              return true;
            } else {
              this.allFilesSelected = false;
              return false;
            }
          },
          selectedFiles() {
            try {
              let arr = _.filter(this.files, {'selected': true});
              let res = [];
              _.forEach(arr, function (i) {
                res.push(i.id);
              });
              return res;
            } catch (e) {
              return [];
            }
          },
            computedSelectedPc: {
              get: function () {
                let vm = this;
                try {
                  let item = _.find(this.computedOptionsForPc, function (itm) {
                    return itm.key == vm.selectedPc;
                  });
                  return item;
                } catch (e) {
                  return {};
                }
              },
              set: function (item) {
                if (item) {
                  this.selectedPc = item.key;
                }
              }
            },
            computedOptionsForPc() {
              let opts = [];
              _.forEach(this.pcs, function (pc) {
                  opts.push({key: pc.id, value: pc.pc_name, active: pc.active, current: pc.current})
              })
              return opts;
            },
            computedEstimateId() {
              if (this.estimateToInsurers.estimate.supplement_number > 0) {
                return this.estimateToInsurers.estimate.parent_estimate_id;
              }
              return  this.$route.params.estimate_id
            },
            isEstimateMethodologyCustom: function () {
                if (this.estimateToInsurers.rates.estimate_methodology == 'custom') {
                    return true;
                }
                return false;
            },
          isEstimateMethodologyDollar: function () {
                if (this.estimateToInsurers.rates.estimate_methodology == 'dollars') {
                    return true;
                }
                return false;
            },
        },
        mounted: function () {
            this.loadEstimateToInsurers();
        },
        components: {
            VStepper,
            GoodWizard,
            ImageSelector,
            EstimatePreview,
            Multiselect,
            Email,
        }
    };

</script>

<template>
    <div class="estimate-options-send-to-insurers">
        <div class="page-header">
            <h4>Edit Estimate - {{estimateToInsurers.estimate.estimate_number}} <span v-if="estimateToInsurers.estimate.supplement_number > 0">- {{estimateToInsurers.estimate.supplement_number}} </span><span v-if="estimateToInsurers.customer.customer_name">| {{estimateToInsurers.customer.customer_name}} </span><span v-if="estimateToInsurers.customer_vehicle.rego_number">| {{estimateToInsurers.customer_vehicle.rego_number}}</span></h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item">  <router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item">
                    <router-link :to="'/estimates'">View Estimates</router-link>
                </li>
                <li class="breadcrumb-item"><a href="" @click.prevent="goToEstimate">Edit Estimate</a></li>
                <li class="breadcrumb-item">Options</li>
                <li class="breadcrumb-item active">Send To Insurers</li>
            </ol>
            <h4 class="header-display-name">{{ getterBusinessName }}</h4>
        </div>
        <div class=" ">
            <good-wizard
                    :steps="steps"
                    :currentStep="step"
                    ref="estimateOptionsSendToInsurers"
            >
                <div slot="estimate-options-send-to-insurers-step-1" class="estimate-options-send-to-insurers-step-1">
                    <div class="wizard-header">
                        <div class="wizard-header-name">
                            Select Insurers
                        </div>
                        <div class="wizard-subheader-name">
                            <div class="wizard-subheader-name-text">
                                Add insurer claims or insurance assessor to send the estimate to
                            </div>
                        </div>
                        <div class="wizard-header-button pull-right">
                            <button @click="addNewInsurerContact" class="btn btn-primary button-next">Add Another Name</button>
                        </div>
                    </div>
                    <div class="insurer-list">
                        <table class="table b-table table-hover insurer-table">
                            <thead>
                            <tr>
                                <th class="" style="width: 300px">Insurer or Assessor Name</th>
                                <th class="" style="width: 200px">Claim Nbr</th>
                                <th class=""  style="width: 25px">CMS</th>
                                <th class="" colspan="2"  style="width: 325px">Email</th>
                                <th class="">Additional Message</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(c, i) in insurerContacts" class="clickable">
                                <td style="width: 300px"><input type="text" v-model="c.insurer_name" class="form-control"/></td>
                                <td style="width: 200px"><input type="text"  class="form-control"/></td>
                                <td class="insurer-cms-checkbox" style="width: 25px">
                                    <label class="form-check-inline checkbox">
                                        <input class="form-check-input"
                                               type="checkbox"
                                               v-model="c.cms_check"
                                        >
                                        <span class="icon"><i class='bx bx-check'></i></span>
                                    </label>
                                </td>
                                <td  class="insurer-email-checkbox" style="width: 25px">
                                    <label class="form-check-inline checkbox">
                                        <input class="form-check-input"
                                               type="checkbox"
                                               v-model="c.email_check"
                                        >
                                        <span class="icon"><i class='bx bx-check'></i></span>
                                    </label>
                                </td>
                                <td  style="width: 300px">
                                    <email
                                      :email="c.insurer_email"
                                      @onChange="value => c.insurer_email = value"
                                    ></email>
                                </td>
                                <td>
                                    <input type="text" v-model="c.additional_message" class="form-control">
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="estimateToInsurers.insurance.claim_management == 'Estimage' && vendorInfo && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.estimage" class="wizard-footer">
                        <hr>
                        <div class="row">
                            <div class="col-md-7">
                                <p class="pull-right mt-0-5">Choose target PC</p>
                            </div>
                            <div class="col-md-5">
                                <multiselect
                                    v-model="computedSelectedPc"
                                    :options="computedOptionsForPc"
                                    :showLabels="false"
                                    :close-on-select="true"
                                    :option-height="29"
                                    track-by="key"
                                    label="value"
                                    :max-height="203"
                                >
                                  <template slot="option" slot-scope="props">
                                    <div :style="{color: props.option.active ? 'green' : 'red'}">{{props.option.value}}
                                      <span v-if="props.option.current">(current pc)</span>
                                      <span v-if="!props.option.active">(offline)</span>
                                    </div>
                                  </template>
                                </multiselect>
                            </div>
                        </div>
                    </div>
                </div>
                <div slot="estimate-options-send-to-insurers-step-2" class="estimate-options-send-to-insurers-step-2 rs-scroll rs-scroll--y">
                    <div class="header">
                        Estimate Preview
                    </div>
                    <div class="subheader">
                        Confirm the details in the estimate to be sent
                    </div>
                    <estimate-preview
                            :estimateTo="estimateToInsurers"
                            :isEstimateMethodologyCustom="isEstimateMethodologyCustom"
                            :isEstimateMethodologyDollar="isEstimateMethodologyDollar"
                            class="rs-scroll--y"
                    >

                    </estimate-preview>
                </div>
                <div slot="estimate-options-send-to-insurers-step-3" class="estimate-options-send-to-insurers-step-3">
                    <div class="header">
                        Select Images
                    </div>
                    <div class="subheader">
                        Choose the images to attach by ticking the box next to the image
                    </div>
                    <div class="box box-block">
                        <div class="row send-to-additional-position-image-wizard">
                            <image-selector :images="images" :path="''" @onChange="onImageSelect"></image-selector>
                        </div>
                    </div>
                </div>
              <div slot="estimate-options-send-to-insurers-step-4" class="estimate-options-send-to-insurers-step-4">
                <div class="header">
                  Select Files
                </div>
                <div class="subheader">
                  Choose the files to attach by ticking the box next to the file
                </div>
                <div class="box box-block">
                  <div class="row">
                    <table class="table b-table select-parts">
                      <thead class="bg-navyblue">
                      <tr>
                        <th class="header-check" style="width: 80px">
                          <label class="form-check-inline checkbox">
                            <input @click="toggleSelectAllFiles" class="form-check-input" type="checkbox" v-model="allFilesSelected">
                            <span class="icon"><i class='bx bx-check'></i></span>
                          </label>
                        </th>
                        <th class="header-name" style="text-align: left; width: 40%">Select All</th>
                        <th class="header-name"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="file in files">
                        <td style="padding-left: 15px; width: 80px">
                          <label class="form-check-inline checkbox">
                            <input class="form-check-input" type="checkbox" @change="toggleSelectFile" v-model="file.selected">
                            <span class="icon"><i class='bx bx-check'></i></span>
                          </label>
                        </td>
                        <td style="width: 40%">
                          {{ file.name }}
                        </td>
                        <td>
                          <strong>Status: </strong>
                          <span v-show="!file.selected" class="text-muted">Not attached</span>
                          <strong v-show="file.selected">To be attached</strong>
                        </td>
                      </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
                <div class="subheader">
                  Choose to add a copy of the Estimate PDF
                </div>
                <div class="box box-block">
                  <div class="row">
                    <table class="table b-table select-parts">
                      <thead class="bg-navyblue">
                      <tr>
                        <th class="header-check" style="width: 80px">
                          <label class="form-check-inline checkbox">
                            <input @click="toggleSelectAllEstimateFiles" class="form-check-input" type="checkbox" v-model="allEstimateFilesSelected">
                            <span class="icon"><i class='bx bx-check'></i></span>
                          </label>
                        </th>
                        <th class="header-name" style="text-align: left; width: 40%">Select All</th>
                        <th class="header-name"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="file in estimatePdfFiles">
                        <tr v-if="file.show">
                          <td style="padding-left: 15px; width: 80px">
                            <label class="form-check-inline checkbox">
                              <input @change="toggleCheckboxEstimateFiles" class="form-check-input" type="checkbox" v-model="file.attached">
                              <span class="icon"><i class='bx bx-check'></i></span>
                            </label>
                          </td>
                          <td style="width: 40%">
                            {{ file.name.replace('[EstimateNumber]', estimateToInsurers.estimate.estimate_number + (estimateToInsurers.estimate.supplement_number > 0 ? '-' + estimateToInsurers.estimate.supplement_number : '')) }}
                          </td>
                          <td>
                            <strong>Status: </strong>
                            <span v-show="!file.attached" class="text-muted">
                              <span v-if="estimateToInsurers.estimate.supplement_number > 0">
                                Supplement PDF not sent.
                              </span>
                              <span v-else>{{ file.messageNotAttached }}</span>
                            </span>
                            <strong v-show="file.attached">
                              <span v-if="estimateToInsurers.estimate.supplement_number > 0">
                                Supplement PDF to be sent.
                              </span>
                              <span v-else>{{ file.messageAttached }}</span>
                            </strong>
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </good-wizard>
            <div class="wizard__buttons">
                <div class=" pull-right">
                    <button
                        class="btn btn-outline-primary pull-left button-cancel mr-0"
                        type="button"
                        @click="clickCancel"
                    >
                        Cancel
                    </button>
                    <button
                            v-if="$refs.estimateOptionsSendToInsurers && $refs.estimateOptionsSendToInsurers.currentStep != 0"
                            class="btn btn-outline-primary-light pull-left button-back mr-0 ml-1"
                            type="button"
                            @click="$refs.estimateOptionsSendToInsurers.goBack()"
                    >
                        Back
                    </button>
                    <button
                            v-if="$refs.estimateOptionsSendToInsurers && $refs.estimateOptionsSendToInsurers.currentStep != 3"
                            class="btn btn-primary pull-right button-next"
                            type="button"
                            @click="$refs.estimateOptionsSendToInsurers.goNext()"
                            :tabindex="4"
                    >
                        Next
                    </button>
                    <button
                            v-if="$refs.estimateOptionsSendToInsurers && $refs.estimateOptionsSendToInsurers.currentStep == 3"
                            class="btn btn-primary pull-right button-card-estimate"
                            type="button"
                            @click="clickSend"
                            :disabled="!isCanSend"
                            style=""
                    >
                        Send
                    </button>

                </div>
            </div>

        </div>
    </div>
</template>
<style>
    .V3 .new-card {
        max-width: 1028px;
        margin: 0 auto;
    }

</style>
<style>

    @media (max-width: 380px) {
        .V3 .estimate-options-send-to-insurers .wizard__steps {
            height: 90px;
        }

        .V3 .estimate-options-send-to-insurers .wizard .sticky {
            height: 100px;
        }
    }

    .V3 .estimate-options-send-to-insurers .wizard__body {
        padding: 15px;
        width: 100%;
        margin: 0 auto;
    }

    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-1 {
        width: 83%;
    }

    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-1 table.insurer-table .insurer-cms-checkbox,
    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-1 table.insurer-table .insurer-email-checkbox {
        width: 25px;
        padding-left: 15px;
    }
    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-1 table.insurer-table .insurer-cms-checkbox label.checkbox > span.icon,
    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-1 table.insurer-table .insurer-email-checkbox label.checkbox > span.icon{
        margin-right:0px;

    }

    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-2 {
        width: 100%;
        overflow: auto;
    }

    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-2 .estimate-preview {
        width: 83%;
        margin: 15px auto;
    }

    /*@media (min-width:766px) and (max-width: 1024px) {*/
    /*    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-2 .estimate-preview {*/
    /*        width: 100%;*/
    /*        margin: 15px 0;*/
    /*        overflow-x: scroll;*/
    /*    }*/
    /*}*/

    /* @media (max-width: 812px) {
      .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-2 .estimate-preview {
        width: 100%;
        margin: 15px 0;
        overflow-x: scroll;
      }
    } */


    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-1 .table th {
        color: #1C1F39;
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
    }

    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-2 .table th {
        color: #1C1F39;
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        vertical-align: top;
    }

    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-1 .table td,
    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-2 .table td {
        color: #1C1F39;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
    }

    .V3 .estimate-options-send-to-suppliers .wizard__body .estimate-options-send-to-suppliers-step-3 {
        width: 100%;
    }

    .V3 .estimate-options-send-to-insurers .wizard__buttons {
        width: 83%;
        margin: 0 0 70px 5px;
        padding-right:15px;
    }

    @media (max-width: 767px) {
        .V3 .estimate-options-send-to-insurers .wizard__buttons {
            width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-1,
        .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-2,
        .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-3 {
            width: 100%;
        }
        .V3 .wizard-header .wizard-header-name {
            margin-right: 200px !important;
        }
        .V3 .wizard-header .wizard-subheader-name-text {
            margin-right: 200px !important;
        }
        .V3 .estimate-options-send-to-customers .wizard__buttons {
            width: 100% !important;
        }
        .search-input-mobile {
            width: 200px !important;
        }
        .V3 .estimate-options-send-to-suppliers .wizard__buttons {
            width: 100% !important;
        }
        .V3 .estimate-options-send-to-suppliers .wizard__buttons {
            margin-top: 20px !important;
        }
    }

    @media screen and (max-width: 400px) {
        .V3 .wizard-header {
            display: flex;
            flex-direction: column;
        }
        .V3 .wizard-header .wizard-header-button {
            position: static;
            margin-left: 0;
            padding-top: 5px;
            width: 100%;
        }
        .V3 .search-input-mobile {
            width: 100% !important;
        }
        .V3 .wizard-header .wizard-header-name {
            margin-right: 0 !important;
        }
        .V3 .wizard-header .wizard-subheader-name-text {
            margin-right: 0 !important;
        }
    }
</style>
<style scoped>
    .estimate-options-send-to-insurers-step-4{
      width: 900px;
      margin: 0 auto;
    }
    .estimate-options-send-to-insurers >>> .wizard .wizard__steps{
      min-width: 550px;
    }
    .estimate-options-send-to-insurers >>> .wizard .wizard__step:nth-child(3) .wizard__step__line{
      border-radius: 0px;
    }
    .wizard__buttons{
      margin: 0 auto !important;
    }
    .V3 .estimate-options-send-to-insurers .wizard__body .estimate-options-send-to-insurers-step-1 {
      margin: 0 auto;
    }
    .V3 .card-new-body >>> .card-block {
        padding: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .wizard__body {
        padding: 15px 30px 0px 30px;
    }

    .V3 .wizard__buttons .pull-left {
        float: left !important;
        margin-right: 10px;
    }

    .V3 .wizard__buttons .pull-right {
        float: right !important;
        margin-left: 10px;
    }

    .V3 .wizard__buttons .btn {
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        height: 38px;
    }

    .V3 .wizard__buttons .button-cancel {
        padding: 10px 21px;
        background: #FFFFFF;
        border: 1px solid rgba(28, 31, 57, 0.25);
        color: #1C1F39;
    }

    .V3 .wizard__buttons .button-card {
        padding: 10px 15px;
        background: #FFFFFF;
        color: #5E79FF;
    }

    .V3 .wizard__buttons .button-card-estimate {
        padding: 10px 15px;
    }

    .V3 .wizard__buttons .button-next {
        padding: 10px 15px;
        width: 83px;
    }

    .V3 .wizard__buttons .button-back {
        padding: 10px 26px;
        background: #FFFFFF;
        border-color: #5E79FF;
        width: 83px;
    }

    @media (max-width: 823px) AND (min-width: 604px) {
        .V3 .card-new .wizard__buttons {
            padding-left: 15px;
            padding-right: 15px;
            width: auto;
            min-width: auto;
        }

        .V3 .compact-sidebar .card-new .wizard__buttons {
            width: 573px;
            margin: 0 auto;
            min-width: 573px;
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    @media (max-width: 603px) {
        .V3 .card-new .wizard__buttons {
            padding-left: 15px;
            padding-right: 15px;
            width: auto;
            min-width: auto;
        }
    }

    @media (max-width: 512px) {
        .V3 .card-new .wizard__buttons button {
            margin-bottom: 10px;
            float: none !important;
            margin-left: 0px;
            margin-right: 0px;
            display: block;
        }
    }

    @media screen and (max-width: 500px) {
        .V3 .page-header {
            min-height: 100px !important;
        }
        .page-header h4 {
            height: auto;
        }
    }

</style>

