<template>
      <div class="invite-repairer-form text-center">
        <div class="intro-text">
          <div class="form">
            <br />
            <br />
            <h1>{{getTitle}} your business details</h1>
            <br />
            <form method="POST" @submit.prevent="login">
              <div class="form-group">
                <label for="Country" class="form-group-input-title">Country</label>
                <input
                    ref="country"
                    @keydown.up="focusOnElement('postcode')"
                    @keydown.down="focusOnElement('companyType')"
                    autocomplete="off"
                    type="text"
                    class="form-control input-field"
                    name="Country"
                    placeholder="Country"
                    v-model="country"
                />
                <div class="w-100 text-right" v-if="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text 2
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-group-input-title">Company Type</label>
                <div class="multiselect-style">
                  <multiselect
                      ref="companyType"
                    :options="getOptions"
                    :showLabels="false"
                    :max-height="203"
                    :close-on-select="true"
                    placeholder="Select Company Type"
                    v-model="type"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="businessname" class="form-group-input-title">Entity Name</label>
                <input
                    ref="entityName"
                    @keydown.up="focusOnElement('companyType')"
                    @keydown.down="focusOnElement('businessName')"
                    autocomplete="off"
                    type="text"
                    class="form-control input-field"
                    name="businessname"
                    placeholder="Entity Name"
                    v-model="entityName"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="businessname" class="form-group-input-title">Registered Business Name</label>
                <div v-if="businessNames.length" class="multiselect-style">
                  <multiselect
                      ref="businessName"
                    @keydown.up="focusOnElement('entityName')"
                    @keydown.down="focusOnElement('abn')"
                      :options="businessNames"
                      :showLabels="false"
                      :max-height="203"
                      :close-on-select="true"
                      placeholder="Select Business Name"
                      v-model="businessName"
                  />
                </div>
                <input
                    v-else
                    ref="businessName"
                    @keydown.up="focusOnElement('entityName')"
                    @keydown.down="focusOnElement('abn')"
                  autocomplete="off"
                  type="text"
                  class="form-control input-field"
                  name="businessname"
                  placeholder="Registered Business Name"
                  v-model="businessName"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="businessnumber" class="form-group-input-title">Australian Business Number(ABN)</label>
                <input
                    ref="abn"
                    @keydown.up="focusOnElement('businessName')"
                    @keydown.down="focusOnElement('acn')"
                  autocomplete="off"
                  type="text"
                  class="form-control input-field"
                  name="businessnumber"
                  placeholder="Australian Business Number"
                  v-model="abn"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="companynumber" class="form-group-input-title">Australian Company Number(ACN)</label>
                <input
                    ref="acn"
                    @keydown.up="focusOnElement('abn')"
                    @keydown.down="focusOnElement('address')"
                  autocomplete="off"
                  type="text"
                  class="form-control input-field"
                  name="companynumber"
                  placeholder="Australian Company Number"
                  v-model="acn"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="businnesadress" class="form-group-input-title">Business Address</label>
                <input
                    ref="address"
                    @keydown.up="focusOnElement('acn')"
                    @keydown.down="focusOnElement('city')"
                  autocomplete="off"
                  type="text"
                  class="form-control input-field"
                  name="businnesadress"
                  placeholder="Business Address"
                  v-model="businessAddress"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label  class="form-group-input-title">City</label>
                <input
                        ref="city"
                        @keydown.up="focusOnElement('address')"
                        @keydown.down="focusOnElement('state')"
                        autocomplete="off"
                        type="text"
                        class="form-control input-field"
                        name="businnesadress"
                        placeholder="Select City"
                        v-model="city"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="State" class="form-group-input-title">State</label>
                <div class="multiselect-style">
                  <multiselect
                      ref="state"
                    :options="getOptionsState"
                    :showLabels="false"
                    :max-height="203"
                    :close-on-select="true"
                    placeholder="Select state"
                    v-model="state"
                  />
                </div>
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="postcode" class="form-group-input-title">Postcode</label>
                <input
                    ref="postcode"
                    @keydown.up="focusOnElement('state')"
                    @keydown.down="focusOnElement('country')"
                    @keydown.tab.prevent="$refs.nextButton.focus()"
                  autocomplete="off"
                  type="text"
                  class="form-control input-field post-code"
                  name="businessnumber"
                  placeholder="Postcode"
                  v-model="postcode"
                />
                <div class="w-100 text-right" v-show="false">
                  <span class="error-text">
                    <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;Any error text
                  </span>
                </div>
              </div>
              <div class="form-group">
                <div class="btn-group">
                  <button
                      ref="nextButton"
                    type="button"
                    class="btn input-btn"
                    :disabled="!nextAllowed"
                    @click="register"
                  >Confirm</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "InviteFillBusinessDetails",
  components: {
    multiselect: Multiselect
  },
  data(){
    return {
      country: '',
      type: '',
      businessName: '',
      businessNames: [],
      entityName: '',
      abn: '',
      acn: '',
      businessAddress: '',
      city:'',
      state: '',
      postcode: '',
    };
  },
  props: {
    registrationData: {
      type: Object,
      default: {}
    },
    isSelectedCompany: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.country = this.registrationData.business.country;
    this.type = this.registrationData.business.type;
    this.businessName = this.registrationData.business.name;
    this.entityName = this.registrationData.business.entityName;
    this.businessNames = this.registrationData.business.businessNames;
    this.abn = this.registrationData.business.abn;
    this.acn = this.registrationData.business.acn;
    this.businessAddress = this.registrationData.business.address;
    this.city = this.registrationData.business.city;
    this.state = this.registrationData.business.state;
    this.postcode = this.registrationData.business.postcode;
  },
  methods: {
    focusOnElement(name) {
      if (name === 'companyType' || (name === 'businessName' && this.businessNames.length) || name === 'state') {
        this.$refs[name].$el.focus();
      } else {
        this.$refs[name].focus();
      }
    },
    register: function(){
      let business = {};

      business.country = this.country;
      business.type = this.type;
      business.name = this.businessName;
      business.entityName = this.entityName;
      business.abn = this.abn;
      business.acn = this.acn;
      business.address = this.businessAddress;
      business.city = this.city;
      business.state = this.state;
      business.postcode = this.postcode;
      business.businessNames = this.businessNames;

      this.$emit('setData', {type: 2, data: business})
      this.$emit('nextPage', 3);
    },
  },
  computed: {
    getTitle() {
      if (this.isSelectedCompany){
        return 'Confirm'
      }
      return 'Fill in'
    },
    getOptions() {
      return ["Australian Public Company", "Australian Private Company"];
    },
    getOptionsState() {
      return ["NSW", "VIC", "QLD", "SA", "WA", "NT", "TAS"];
    },
    nextAllowed(){
      return !! (
        this.country
        && this.type
        && this.businessName
        && this.entityName
        && this.abn
        && this.businessAddress
        && this.city
        && this.state
        && this.postcode
      );
    }
  }
};
</script>

<style lang="css" scoped>
  form {
    max-width: 522px;
    margin: 0 auto;
  }
  .form {
    max-width: 771px;
    width: 100%;
    margin: 0 auto;
  }
  .steps-line {
    max-width: 652px;
    width: 100%;
    margin: 0 auto;
  }
  .form h1 {
    text-align: center;
    margin-bottom: 0px;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 43px;
    color: #1C1F39
  }
  .invite-repairer-form {
    justify-content: flex-start;
    margin-top: 5px;
  }
  .form .input-btn {
    display: table;
    margin: 0 auto;
    margin-top: 40px;
  }
</style>

<style scoped>
  .auth-header {
    background-color: #1f233d;
    height: 100px;
  }

  .logo-text {
    color: white;
  }

  .container {
    height: 100%;
  }

  .logo {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .steps-line ul {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
  }

  .numb {
    display: none;
  }

  .form hr {
    margin-top: 50px;
    height: 1px;
    background-color: #1f233d;
  }

  .active {
    color: #5E79FF;
    font-weight: bold;
  }

  .custom-multiselect {
    margin: 0 auto;
    width: 70%;
  }

  .form-group-input-title {
    width: 100%;
    text-align: left;
    padding-left: 15px;
    font-size: 16px;
  }

  .post-code {
    width: 100%;
  }

  .input-btn {
    background: linear-gradient(135deg, rgb(114, 103, 231) 0%, rgb(98, 89, 239) 49.12%, rgb(78, 69, 238) 100%);
    font-weight: bold;
    border-radius: 4px;
    color: white;
    width: 100%;
    font-size: 16px;
    border: none;
  }

  .btn.disabled, .btn:disabled {
    opacity: 1;
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
  }

  .btn-group>.btn:first-child {
    margin-left: auto;
  }

  .form-control {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    padding: 9px 13px;
    border: 1px solid rgba(27, 30, 56, 0.2) !important;
    border-radius: 4px !important;
    height: 43px !important;
    color: #1C1F39;
  }

  .form-control::placeholder {
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }

  .btn.disabled, .btn:disabled {
    cursor: not-allowed;
    opacity: .65;
    background: #adaeb2;
    border-color: #adaeb2;
  }

  .btn {
    width: 100%;
    border-radius: 3px;
    font-size: 16px !important;
  }
</style>
