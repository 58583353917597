import _ from "lodash";

export const Mutations = {
    addAttempts(state, ui) {
        state.attempts = ui;
    },
    addReasons(state, ui) {
        state.reasons = ui;
    },
    addProduction(state, ui) {
        state.production = ui;
    },
    addBookingItem(state, ui) {
        state.bookingItems.push(ui);
    },
    addBookingItems(state, ui) {
        state.bookingItems = ui;
    },
    removeBookingItems(state, ui) {
      state.bookingItems = _.filter(state.bookingItems, (itm) => {
          return !_.find(ui, (i) => {
              return Number(i) == Number(itm.id)
          })
      })
    },
    updateBookingItems(state, ui) {
        let data = _.union(ui, state.bookingItems);
        let res = _.uniqWith(data, function (first, second) {
            return (first.id != second.id) ? false : true;
        });
        res = _.map(res, (i) => {
            i.id = Number(i.id);
            return i;
        });
        state.bookingItems = res;
      // _.forEach(ui, (itm) => {
      //     let originalItm = _.find(items, (i) => {
      //         return Number(i.id) == Number(itm.id)
      //     })
      //     if (originalItm) {
      //         originalItm = itm;
      //     } else {
      //         items.push(itm)
      //     }
      // })
      //   state.bookingItems = items
    },
    loadInBoard(state, ui) {
        let data = _.union(ui, state.itemsIn);
        let res = _.uniqWith(data, function (first, second) {
            return (first.id != second.id) ? false : true;
        });
        res = _.map(res, (i) => {
            i.id = Number(i.id);
            return i;
        });
        state.itemsIn = res;
    },
    loadDepartureBoard(state, ui) {
        let data = _.union(ui, state.itemsDeparture);
        let res = _.uniqWith(data, function (first, second) {
            return (first.id != second.id) ? false : true;
        });
        res = _.map(res, (i) => {
            i.id = Number(i.id);
            return i;
        });
        state.itemsDeparture = res;
    },
    setZoomScaleIn(state, zoomScale) {
        state.zoomScaleIn = zoomScale;
    },
    setZoomScaleDeparture(state, zoomScale) {
        state.zoomScaleDeparture = zoomScale;
    },
    removeFromDepartureBoard(state, removedBoardIds) {
        _.forEach(removedBoardIds, function (boardId) {
            state.itemsDeparture = _.filter(state.itemsDeparture, function (item) {
                return item.id != boardId;
            });
        });
    },
    removeFromInBoard(state, removedBoardIds) {
        _.forEach(removedBoardIds, function (boardId) {
            state.itemsIn = _.filter(state.itemsIn, function (item) {
                return item.id != boardId;
            });
        });
    },
    setFilter(state, ui) {
        state.bookingFilter = ui
    },
};
