<template>
    <div class="wizard" ref="wizard">
        <ul class="wizard__steps">
            <li data-id="1" :class="{'active': step <= 2, 'complite-step': step > 1}" class="step__item">
              <a @click.prevent="$emit('nextPage', 1)" href="#">
                <span v-if="step<=2" class="step__number">1</span>
                <span v-else class="complite-icon">
                    <i class="bx bxs-check-circle"></i>
                </span>
                Your Business
              </a>
            </li>

            <li class="step__separator d-md-inline-block" :class="{'active__line': step >=3 && step <=5}"></li>

            <li data-id="2" :class="{'active': step === 3, 'complite-step': step > 3}" class="step__item">
              <a href="#" @click.prevent="$emit('nextPage', 3)">
                <span v-if="step !== 4" class="step__number">2</span>
                <span v-else class="complite-icon">
                    <i class="bx bxs-check-circle"></i>
                </span>
                Business Profile
              </a>
            </li>
            <li :class="{'active__line': step === 4}" class="step__separator d-md-inline-block"></li>
            <li data-id="3" :class="{'active': step === 4}" class="step__item"><a @click.prevent="$emit('nextPage', 4)" href="#"><span class="step__number">3</span>Your Details</a></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'vue-good-wizard-2',

        props: {
            step: {
              type: Number,
              default: 1,
            },
        },
        computed: {

        }
    };
</script>

<style scoped>
    .wizard {
        position: relative;
        width: 100%;
    }

    @media screen and (max-width: 350px) {
        .wizard .step__item a {
            font-size: 10px !important;
        }
    }

    .wizard__steps {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .wizard__steps li {
        margin: 0 5px;
    }

    .V3 .wizard .sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 135px;
        z-index: 1000;
        background-color: #FFFFFF;
        height: 80px;
    }

    .step {
        width: 100%;
    }

    .step__list {
        margin: 0;
        padding: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
    }

    .step__list > * {
        margin-right: 10px;
    }

    .step__item a {
        color: rgba(28, 31, 57, 0.5);
        font-size: 16px;
        height: 100%;
        display: flex;
        align-items: center;
        pointer-events: none;
    }

    .complite-step a {
        pointer-events: auto;
    }

    .step__item.active a,
    .step__item a:hover {
        font-size: 16px;
        color: #5E79FF;;
        text-decoration: none;
    }
    .active__line {
    border: 1px solid #5E79FF !important;
    }
    .step__item.active a .step__number,
    .step__item a:hover .step__number {
        background-color: #5E79FF;;
        font-size: 16px;
        color: #ffffff;
        border-color: #5E79FF;;
    }

    .step__number {
        display: inline-block;
        box-sizing: border-box;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        border: 2px solid #5E79FF;;
        background-color: #ffffff;
        text-align: center;
        margin-right: 9px;
        box-sizing: border-box;
        font-family: "Nunito Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        color: #5E79FF;;
        vertical-align: middle;
        padding-top: 3px;
    }

    .step__separator {
        max-width: 100px;
        width: 100%;
        display: inline-block;
        width: 100px;
        height: 1px;
        border: 1px solid rgba(27, 30, 56, 0.25);
    }

    .complite-icon {
        font-size: 30px;
        font-weight: 600;
        margin-right: 9px;
        margin-top: 7px;
    }

    .complite-icon i {
        color: #5E79FF;
    }



    @media screen and (max-width: 768px) {
        .step__number {
            width: 31px;
        }
    }
    @media screen and (max-width: 576px) {
        .step__number {
            width: 35px;
        }
    }
</style>
