<script>
  import Axios from 'axios';
  import Editor from '../editor/Editor';
  import {isMobile} from '../../../deviceDetect/index';

  export default {
    name: 'image-editor',
    components: {
      Editor,
    },
    props: [
      'imageUrl',
      'imageId',
      'isFromEstimate',
      'category',
      'images',
    ],
    data: (th) => {
      return {
        canvasWidth: 800,
        canvasHeight: 600,
        isCrop: true,
        selectedColor: '#ff1ad7',
        isMobile,
        isSaving: false,
        isSelectedDraw: false,
        isSelectedCircle: false,
        isSelectedRectangle: false,
        isSelectedArrow: false,
        isSelectedText: false,
        isCalculatedSize: false,
      }
    },
    methods: {
      setCurrentAction(type) {
        if (type === 'draw') {
          this.isSelectedDraw = true;
          this.isSelectedCircle = false;
          this.isSelectedRectangle = false;
          this.isSelectedArrow = false;
          this.isSelectedText = false;
        } else if (type === 'circle') {
          this.isSelectedDraw = false;
          this.isSelectedCircle = true;
          this.isSelectedRectangle = false;
          this.isSelectedArrow = false;
          this.isSelectedText = false;
        } else if (type === 'rectangle') {
          this.isSelectedDraw = false;
          this.isSelectedCircle = false;
          this.isSelectedRectangle = true;
          this.isSelectedArrow = false;
          this.isSelectedText = false;
        } else if (type === 'arrow') {
          this.isSelectedDraw = false;
          this.isSelectedCircle = false;
          this.isSelectedRectangle = false;
          this.isSelectedArrow = true;
          this.isSelectedText = false;
        } else if (type === 'text') {
          this.isSelectedDraw = false;
          this.isSelectedCircle = false;
          this.isSelectedRectangle = false;
          this.isSelectedArrow = false;
          this.isSelectedText = true;
        }
      },
      setColorForCurrentAction() {
        if (this.isSelectedDraw) {
          this.freeDrawing();
        } else if (this.isSelectedCircle) {
          this.addCircle();
        } else if (this.isSelectedRectangle) {
          this.addSquare()
        } else if (this.isSelectedArrow) {
          this.addArrow();
        } else if (this.isSelectedText) {
          toastr.success('Color changed successfully!\n' +
              'Please choose another icon and draw something and then you can undo it with the back icon.\n' +
              '  Then go back to the text icon again to update the color');
        }
      },
      undo() {
        this.$refs.editor.undo();
      },
      redo() {
        this.$refs.editor.redo();
      },
      deleteEditable() {
        this.$refs.editor.clear();
        this.$refs.editor.setBackgroundImage(this.imageUrl);
      },
      freeDrawing() {
        let customizeFreeDrawing = { stroke: this.selectedColor};
        this.$refs.editor.set("freeDrawing", customizeFreeDrawing);
        this.setCurrentAction('draw');
      },
      addRotateRight() {
        this.$refs.editor.set("rotateRight");
      },
      addRotateLeft() {
        this.$refs.editor.set("rotateLeft");
      },
      addText() {
        if (this.isSelectedText) {
          return;
        }
        let textModeOptions = {
          id: 'title' + Math.random(),
          fill: this.selectedColor,
          fontFamily: 'Verdana',
          fontSize: 20,
          placeholder: 'Add text'
        }

        this.$refs.editor.set("text", textModeOptions);
        this.setCurrentAction('text');
      },
      addCircle() {
        let circleModeParams = {  stroke: this.selectedColor };
        this.$refs.editor.set("circle", circleModeParams);
        this.setCurrentAction('circle');
      },
      addSquare() {
        let customizeRectangle = {
          stroke: this.selectedColor,
        };
        this.$refs.editor.set("rect", customizeRectangle);
        this.setCurrentAction('rectangle');
      },
      addArrow() {
        let customizeArrow = { stroke: this.selectedColor};
        this.$refs.editor.set("arrow", customizeArrow);
        this.setCurrentAction('arrow');
      },
      crop() {
        let cropModeOptions = {
          width: "500",
          height: "300",
          overlayOpacity: "0.9",
          overlayColor: 'transparent'
        };
        this.$refs.editor.set("crop", cropModeOptions);
        this.isCrop = false;
      },
      applyCrop() {
        this.isCrop = true;
        this.$refs.editor.applyCropping();
        this.isCrop = true;
      },
      clickColor(color) {
        this.selectedColor = color;
        this.setColorForCurrentAction();
      },
      toDataURL(url, callback) {
        let xhr = new XMLHttpRequest();
        xhr.onload = function() {
          var reader = new FileReader();
          reader.onloadend = function() {
            callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      },
      onClickSave: function () {
        if (this.isSaving) {
          return
        }

        let self = this;
        if ((this.$refs.editor.canvas.height !== this.canvasHeight || this.$refs.editor.canvas.width !== this.canvasWidth) && this.$refs.editor.isCroppedImage) {
          this.isSaving = true
          Axios.post('/fe/card/upload-files', {file: this.$refs.editor.saveImage()}).then(r => {
            if (r?.data?._status) {
              let data = {}
              let uid = Date.now().toString() + Math.random().toString(36).substr(2, 4)
              data[uid] = {}
              data[uid].image_ids = [r.data.file_id]
              data[uid].imageCategories = []
              data[uid].imageCategories.push({
                file_id: r.data.file_id,
                category: self.category
              })
              data[uid].imagesSort = []
              let imagesCopy = _.cloneDeep(self.images)
              let indexOfImg = _.findIndex(imagesCopy, (i) => {
                return Number(i.id) == Number(self.imageId)
              })
              if (indexOfImg !== -1) {
                let result = _.concat(imagesCopy.slice(0, indexOfImg), {id: r.data.file_id, category: self.category}, imagesCopy.slice(indexOfImg, ))
                _.forEach(result, (im, index) => {
                  data[uid].imagesSort.push({
                    id: im.id, index: index + 1
                  })
                })
              }

              Axios({
                method: 'post',
                responseType: 'json',
                headers: { 'Autosave': true },
                url:  this.isFromEstimate ? `/fe/estimate/${self.$route.params.estimate_id}` : `/fe/card/${self.$route.params.card_id}`,
                validateStatus: function (status) {
                  return status < 500
                },
                data: data
              }).finally(() => {
                this.$emit('saved-image', {index: indexOfImg, category: self.category})
                toastr.success('The image was saved!')
                this.isSaving = false
              })
            }
          })
          return
        }

        this.isSaving = true
        Axios({
          method: 'post',
          responseType: 'json',
          url: '/fe/card/image/update',
          data: {
            id: this.imageId,
            url: this.imageUrl,
            file: this.$refs.editor.saveImage(),
          }
        })
          .then(response => {
            this.isSaving = false
            if (response.status === 200) {
              this.$emit('saved-image', false)
              toastr.success('The image was saved!')
            } else {
              toastr.error(response.data.msg)
            }
          })
          .catch(error => {
            this.isSaving = false
            toastr.error(error)
          })
      },
    },
    mounted: function () {
      let interval = setInterval(() => {
        let img = document.getElementById('img1')
        let wrapperSize = document.getElementById('wrapperSize')
        if (img.clientHeight > 0 && img.clientWidth > 0) {
          let isShowBigImage = (this.$route.name == "EstimatesEditImage" || this.$route.name == "CardsEditImage" || this.isFromEstimate) && !this.isMobile
          this.canvasWidth = wrapperSize.clientWidth - 100
          this.canvasHeight = img.clientHeight / (img.clientWidth / this.canvasWidth)
          if(isShowBigImage || wrapperSize){
            if(img.clientWidth < img.clientHeight){
              this.canvasHeight = window.innerHeight - 60
              this.canvasWidth = img.clientWidth / (img.clientHeight / this.canvasHeight)
            }
            if(img.clientWidth > img.clientHeight){
              if (window.innerWidth < window.innerHeight || (img.clientWidth - img.clientHeight) > (window.innerWidth - window.innerHeight)) {
                this.canvasWidth = window.innerWidth - 100
                this.canvasHeight = img.clientHeight / (img.clientWidth / this.canvasWidth)
              }
              else {
                this.canvasHeight = window.innerHeight - 60
                this.canvasWidth = img.clientWidth / (img.clientHeight / this.canvasHeight)
              }
            }
            if(img.clientWidth == img.clientHeight){
              if (window.innerWidth < window.innerHeight) {
                this.canvasWidth = window.innerWidth - 100
                this.canvasHeight = img.clientHeight / (img.clientWidth / this.canvasWidth)
              }
              else {
                this.canvasHeight = window.innerHeight - 60
                this.canvasWidth = img.clientWidth / (img.clientHeight / this.canvasHeight)
              }
            }
          }
          this.isCalculatedSize = true;
          setTimeout(() => {
            this.$refs.editor.setBackgroundImage(this.imageUrl);
          }, 10)
          clearInterval(interval)
        }
      }, 10)
    },
  }
</script>

<template>

  <div class="w-100" :style="{width: this.canvasWidth + 'px !important', height: this.canvasHeight + 'px !important'}">
    <div class="image-editor-editor-menu">
      <div class="color-item"  :style="{backgroundColor: this.selectedColor}"></div>
      <div @click="undo" class=""><i class='bx bx-undo'></i></div>
      <div @click="redo" class=""><i class='bx bx-redo'></i></div>
      <div @click="deleteEditable" class=""><i class='bx bxs-trash'></i></div>
      <div @click="freeDrawing" class=""><i class='bx bxs-pencil'></i></div>
      <div @click="addText" class=""><i class='bx bx-font'></i></div>
      <div @click="addCircle" class=""><i class='bx bx-circle'></i></div>
      <div @click="addSquare" class=""><i class='bx bx-rectangle'></i></div>
      <div @click="addArrow" class=""><i class='bx bxs-down-arrow-alt' ></i></div>
      <div v-if="isCrop" @click="crop()"><i class='bx bxs-crop'></i></div>
      <div v-else @click="applyCrop()"><i class='bx bx-check-circle'></i></div>
      <div @click="addRotateRight" class=""><i class='bx bx-rotate-right' ></i></div>
      <div @click="addRotateLeft" class=""><i class='bx bx-rotate-left' ></i></div>
    </div>

    <div class="d-flex estimate-window" style="justify-content: center;">
      <Editor v-if="isCalculatedSize" :canvasWidth="canvasWidth" :canvasHeight="canvasHeight" ref="editor"/>
      <div class="image-editor-color-menu">
        <div class="image-editor-color-menu-border">
          <div @click="clickColor('#fe0000')" style="background-color: #fe0000" class="color-item"></div>
          <div @click="clickColor('#ebeb3f')" style="background-color: #ebeb3f" class="color-item"></div>
          <div @click="clickColor('#bc2ce6')" style="background-color: #bc2ce6" class="color-item"></div>
          <div @click="clickColor('#2ec634')" style="background-color: #2ec634" class="color-item"></div>
          <div @click="clickColor('#00baea')" style="background-color: #00baea" class="color-item"></div>
          <div @click="clickColor('#ff1ad7')" style="background-color: #ff1ad7" class="color-item"></div>
          <div @click="clickColor('#1216a5')" style="background-color: #1216a5" class="color-item"></div>
          <div @click="clickColor('#000000')" style="background-color: #000000" class="color-item"></div>
        </div>
      </div>
    </div>

    <div class="d-flex mt-3 w-100 justify-content-end">
      <button class="btn btn-primary mx-0 waves-effect waves-light sec_btn btn-image-editor-save" :disabled="isSaving" @click="onClickSave">
        {{isSaving ? 'Saving ...' : 'Save'}}
      </button>
    </div>

    <img id="img1"  style="visibility: hidden; position: absolute !important; top: -20000px !important;" :src="imageUrl">

  </div>
</template>

<style scoped>
.color-item {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.image-editor-editor-menu {
  width: 50%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  font-size: 20px;
}

.image-editor-color-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}
</style>
