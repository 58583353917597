<template>
  <div class="mb-0-75 border pt-0-5 pb-0-5 dashboard-row-element" @click="goToSms">
    <div class=" email-block-position">
      <div class="d-flex pl-0 dashboard-email-text" style="width: 70%">
        <span class="text-overflow"> From: {{ sms.smsFrom }}</span>
      </div>
      <div class="d-flex p-0 dashboard-email-text justify-content-end" style="text-align: right; width: 30%">
        <div class="dashboard-email-time">
          <strong style="display: block">Received </strong>
          <strong style="white-space: nowrap">{{ sms.smsCreatedOn | formatDateShort}} {{ sms.smsCreatedOn | formatTime }}</strong>
        </div>
      </div>
    </div>
    <div class="row mt-0-5">
      <div class="col" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
        Text: {{ sms.smsText }}
      </div>
    </div>
    <i :class="{'active-email-icon': !sms.isViewed }" class='bx bx-envelope theme-color font-20 pull-right email-icon'></i>
  </div>
</template>

<script>
export default {
  name: "sms-summary",
  methods: {
    goToSms() {
      this.$router.push(
          {name: 'CardsEdit', params: {action: 'goToSms', card_id: this.sms.cardId, smsId: this.sms.smsId}}
      );
    }
  },
  props: {
    sms: {
      type: [Object, null],
      default() {
        return {
          "activityId": "13253",
          "cardId": "1334",
          "estimateId": "1375",
          "smsId": "1375",
          "isViewed": false,
          "smsFrom": "+61482092814",
          "smsTo": "+61482093859",
          "smsText": "Response to message 3",
          "smsCreatedOn": "2022-10-15 00:53:27"
        };
      }
    },
  },
};
</script>

<style scoped>
.email-icon {
  color: #cecece;
  position: absolute;
  top: 10px;
  right: 10px;
}
.active-email-icon {
  color: #5E79FF !important;
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75ch;
}

.font-20 {
  font-size: 20px;
}

.dashboard-row-element {
  background-color: white;
  padding: 10px 15px !important;
  position: relative;
}

.wrap-text {
  flex-wrap: wrap;
}

.email-block-position {
  display: flex;
  justify-content: space-between;
}

.email-block-position .dashboard-email-text {
  padding-right: 20px;
}

.dashboard-email-time {
  display: flex;
  padding-right: 30px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media screen and (max-width: 440px) {
  .email-block-position {
    flex-direction: column;
  }

  .email-block-position .dashboard-email-text {
    width: 100% !important;
  }

  .email-block-position .dashboard-email-time {
    padding-right: 0 !important;
  }
}

@media (min-width: 991px) and (max-width: 1440px) {
  .email-block-position {
    flex-direction: column;
  }

  .email-block-position .dashboard-email-text {
    width: 100% !important;
  }

  .email-block-position .dashboard-email-time {
    padding-right: 0 !important;
  }
}
</style>
