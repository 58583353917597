import { render, staticRenderFns } from "./account-created.vue?vue&type=template&id=64dbf602&scoped=true"
import script from "./account-created.vue?vue&type=script&lang=js"
export * from "./account-created.vue?vue&type=script&lang=js"
import style0 from "./account-created.vue?vue&type=style&index=0&id=64dbf602&prod&scoped=true&lang=css"
import style1 from "./account-created.vue?vue&type=style&index=1&id=64dbf602&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64dbf602",
  null
  
)

export default component.exports