<template>
  <div style="transform: translateY(-10px)">
    <div class="tabs-component tabs-component-2" style="padding-top: 0px">
      <div class="tabs-component-w">
        <ul class="nav nav-tabs nav-tabs-2  float-sm-left">
          <li style='padding-top: 0px'  class="tabs-component-tab nav-item">
            <span href="" class="tabs-component-tab-a nav-link" :class="{'active': true}">Categories</span>
          </li>
        </ul>
      </div>
    </div>

    <the-categories v-model="value"></the-categories>
  </div>
</template>


<script>
import TheCategories from './production/the-categories'

export default {
  name: "Production",
  components: {
    TheCategories,
  },
  props: {
    value: {}
  },

};
</script>

