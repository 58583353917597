<template>

</template>

<script>
import _ from 'lodash'

export default {
  name: 'sorterMethodologyParts',
  data () {
    return {
      orderNtarLtarRsArray: [
        'Custom',
        'FRT BUMPER BAR',
        'L/H FRT GRD',
        'R/H FRT GRD',
        'L/H HEADLAMP',
        'R/H HEADLAMP',
        'BONNET',
        'FRONT GLASS',
        'L/H/F ROAD WHEEL',
        'R/H/F ROAD WHEEL',
        'L/H/F DOOR',
        'L/H/R DOOR',
        'R/H/F DOOR',
        'R/H/R DOOR',
        'L/H SILL PANEL',
        'R/H SILL PANEL',
        'L/H CANT PANEL',
        'R/H CANT PANEL',
        'L/H CANT RAIL',
        'R/H CANT RAIL',
        'TURRET',
        'REAR GLASS',
        'BOOTLID',
        'BOOT LID',
        'TAILGATE',
        'L/H QUARTER PANEL',
        'R/H QUARTER PANEL',
        'REAR BUMPER BAR',
        'L/H TAIL LAMP',
        'R/H TAIL LAMP',
        'R/H/R ROAD WHEEL',
        'L/H/R ROAD WHEEL',
        'RADIATOR SUPPORT',
        'L/H FRONT STRUCTURE',
        'R/H FRONT STRUCTURE',
        'L/H REAR STRUCTURE',
        'R/H REAR STRUCTURE',
        'L/H A-PILLAR',
        'R/H A-PILLAR',
        'L/H CENTER PILLAR',
        'R/H CENTER PILLAR',
        'L/H C-PILLAR (eXTRA-SEDAN)',
        'R/H C-PILLAR (extra-SEDAN)',
        'SEATS',
        'TRIM',
        'DASH',
        'HEADLINING',
        'ENGINE',
        'REAR BODY PANEL',
        'TRAY & TUB',
        'UNITARY TIMES',
      ],
      orderCustomArray: ['Custom',
        'FRONT BAR',
        'GRILLE',
        'FRONT LIGHTS',
        'RADIATOR SUPPORT',
        'CHASSIS FRONT',
        'BONNET',
        'ENGINE BAY',
        'L/H/F GUARD',
        'R/H/F GUARD',
        'L/H/F DOOR',
        'R/H/F DOOR',
        'TURRET',
        'L/H/R DOOR',
        'R/H/R DOOR',
        'L/H BODYSLIDE',
        'L/H BODYSIDE PANEL',
        'L/H BODY SIDE PANEL',
        'L/H BODYSIDE PANEL ',
        'R/H BODYSLIDE',
        'R/H BODYSIDE PANEL',
        'R/H BODY SIDE PANEL',
        'R/H BODYSIDE PANEL ',
        'L/H/R QTR PANEL',
        'R/H/R QTR PANEL',
        'BEAVER PANEL',
        'REAR BUMPER BAR',
        'REAR BUMPER',
        'REAR LIGHTS',
        'BOOTLID',
        'BOOT FLOOR',
        'TAILGATE',
        'REAR MECHANICAL',
        'FRONT MECHANICAL',
        'INTERIOR',
        'MISCELLANEOUS',
        'TRUCK',],
      orderDollarsArray: ['Custom',
        'FRONT BAR',
        'GRILLE',
        'FRONT LIGHTS',
        'RADIATOR SUPPORT',
        'CHASSIS FRONT',
        'BONNET',
        'ENGINE BAY',
        'L/H/F GUARD',
        'R/H/F GUARD',
        'L/H/F DOOR',
        'R/H/F DOOR',
        'TURRET',
        'L/H/R DOOR',
        'R/H/R DOOR',
        'L/H BODYSLIDE',
        'L/H BODYSIDE PANEL',
        'L/H BODY SIDE PANEL',
        'L/H BODYSIDE PANEL ',
        'R/H BODYSLIDE',
        'R/H BODYSIDE PANEL',
        'R/H BODY SIDE PANEL',
        'R/H BODYSIDE PANEL ',
        'L/H/R QTR PANEL',
        'R/H/R QTR PANEL',
        'BEAVER PANEL',
        'REAR BUMPER BAR',
        'REAR BUMPER',
        'REAR LIGHTS',
        'BOOTLID',
        'BOOT FLOOR',
        'TAILGATE',
        'REAR MECHANICAL',
        'FRONT MECHANICAL',
        'INTERIOR',
        'MISCELLANEOUS',
        'TRUCK',],
    }
  },
  methods: {
    sortParts(parts, methodology, selectedParts) {
      let m = _.toLower(methodology)
      let filteredParts = !_.isEmpty(selectedParts) ? _.filter(parts, (p) => {
        return _.includes(selectedParts, p)
      }) : parts;
      if (m == 'zurich') {
        return filteredParts
      }
      let gIndex = 999
      let orderArray = []

      if (m == 'ntar') {
        orderArray = this.orderNtarLtarRsArray
      } else if (m == 'ltar') {
        orderArray = this.orderNtarLtarRsArray
      } else if (m == 'custom') {
        orderArray = this.orderCustomArray
      } else if (m == 'rs') {
        orderArray = this.orderNtarLtarRsArray
      } else if (m == 'dollars') {
        orderArray = this.orderDollarsArray
      }

      return _.sortBy(filteredParts, (item) => {
        let ind = orderArray.indexOf(_.trim(item))
        if (ind === -1) {
          ind = gIndex++
        }
        return ind
      })
    }
  }
}
</script>

<style scoped>

</style>
