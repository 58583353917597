import { render, staticRenderFns } from "./receivablesInvoiceDetails.vue?vue&type=template&id=1da5ce15&scoped=true"
import script from "./receivablesInvoiceDetails.vue?vue&type=script&lang=js"
export * from "./receivablesInvoiceDetails.vue?vue&type=script&lang=js"
import style0 from "./receivablesInvoiceDetails.vue?vue&type=style&index=0&id=1da5ce15&prod&lang=css"
import style1 from "./receivablesInvoiceDetails.vue?vue&type=style&index=1&id=1da5ce15&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1da5ce15",
  null
  
)

export default component.exports