import _ from "lodash";

export const Getters = {
    getSortByForInvoicesAll: (state) => {
        return state.sortByForInvoicesAll;
    },
    getSortDescForInvoicesAll: (state) => {
        return state.sortDescForInvoicesAll;
    },
    getSortByForInvoicesAwaitingPayment: (state) => {
        return state.sortByForInvoicesAwaitingPayment;
    },
    getSearchFilterObj (state) {
        return state.searchFilterObj
    },
    getToken (state) {
        return state.token
    },
    getTokens (state) {
        return state.tokens
    },
    getSortDescForInvoicesAwaitingPayment: (state) => {
        return state.sortDescForInvoicesAwaitingPayment;
    },
    getSortByForInvoicesPaid: (state) => {
        return state.sortByForInvoicesPaid;
    },
    getSortDescForInvoicesPaid: (state) => {
        return state.sortDescForInvoicesPaid;
    },
    getInvoicesPerPage: (state) => {
        return state.invoicesPerPage;
    },
    getInvoicesAllCurrentPage: (state) => {
        return state.invoicesAllCurrentPage;
    },
    getInvoicesAwaitingPaymentCurrentPage: (state) => {
        return state.invoicesAwaitingPaymentCurrentPage;
    },
    getInvoicesPaidCurrentPage: (state) => {
        return state.invoicesPaidCurrentPage;
    },
    getAllInvoices: state => {
        let invoices = _.orderBy(state.items, ['id'],['desc']);
        return invoices;
    },
    getAwaitingPaymentInvoices(state, getters){
        let invoices = _.filter(getters.getAllInvoices, invoice => {
            return invoice.type === 'draft' || invoice.type === 'awaiting';
        });
        return invoices;
    },
    getPaidInvoices(state, getters){
        let invoices = _.filter(getters.getAllInvoices, invoice => {
            return invoice.type === 'paid';
        });
        return invoices;
    },
    getTimestamp: state => {
        return state.timestamp;
    }
}
