import _ from "lodash";
import Axios from "axios";

export const Actions = {
  loadReports(context){
    context.dispatch('loadEPSAndAEVAndEVS');
    context.dispatch('loadEINSAndEVI');
    context.dispatch('loadIPSAndIVSAndAIV');
    context.dispatch('loadIINSAndIVI');
  },
  loadEPSAndAEVAndEVS(context) {
     Axios.get("/fe/reports/estimate/ps")
      .then(response => {
        if (response.data.data && response.data._status) {
          context.commit('loadEPS', response.data.data);
          context.commit('loadAEV', response.data.data);
          context.commit('loadEVS', response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  loadEINSAndEVI(context) {
    Axios.get("/fe/reports/estimate/insurers")
      .then(response => {
        if (response.data.data && response.data._status) {
          context.commit('loadEINS', response.data.data);
          context.commit('loadEVI', response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  loadIPSAndIVSAndAIV(context) {
    Axios.get("/fe/reports/invoice/ps")
      .then(response => {
        if (response.data.data && response.data._status) {
          context.commit('loadIPS', response.data.data);
          context.commit('loadAIV', response.data.data);
          context.commit('loadIVS', response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  loadIINSAndIVI(context) {
    Axios.get("/fe/reports/invoice/insurers")
      .then(response => {
        if (response.data.data && response.data._status) {
          context.commit('loadIINS', response.data.data);
          context.commit('loadIVI', response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

};
