<script>
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
export default {
	name: 'file-uploader',
	props: ['options', 'value'],
	data: function () {
		return {
			dr: null,
			image: null
		}
	},
	mounted: function () {
		// console.log(this.options)
		var vm = this
        $(document).ready(function (){
		    this.dr = $(this.$el).dropify(this.options);
        });
	},
	methods: {
		onFileChange(e) {
			var vm = this
			// console.log(e.target.files)
			var files = e.target.files || e.dataTransfer.files
			if (!files.length) {
				vm.$emit('input', '')
				return
			}
			this.createImage(files[0])
		},
		createImage(file) {
			var image = new Image()
			var reader = new FileReader()
			var vm = this

			image.title = file.name

			reader.onload = (e) => {
				vm.image = e.target.result
				vm.$emit('input', {img: vm.image, name: file.name})
			}
			reader.readAsDataURL(file)
		},
		removeImage: function (e) {
			this.image = ''
			vm.$emit('input', this.image)
		}
	},
	// watch: {
	// 	options: function () {
	// 		// console.log('options changed')
	// 		// this.dr = $(this.$el).dropify(this.options)
	// 	}
	// },
	destroyed: function () {
		$(this.$el).off().dropify('destroy')
	}
}

</script>

<template>
<input type="file" @change="onFileChange" />
</template>
