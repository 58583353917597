import _ from 'lodash'

export const Mutations = {
  setToken(state, ui) {
    state.token = ui;
  },
  setSearchFilterObj (state, ui) {
    state.searchFilterObj = ui;
  },
  setSortBy (state, ui) {
    state.sortBy = ui
  },
  setSortDesc (state, ui) {
    state.sortDesc = ui
  },
  setEstimatesPerPage (state, ui) {
    state.estimatesPerPage = ui
  },
  setEstimatesAllCurrentPage (state, ui) {
    state.estimatesAllCurrentPage = ui
  },
  loadEstimates (state, ui) {
    let data = _.union(ui, state.items)
    let res = _.uniqWith(data, function (first, second) {
      return (first.id != second.id) ? false : true
    })
    state.items = res
  },
  setTimestamp (state, timestamp = 0) {
    console.log('timestamp', timestamp)
    state.timestamp = timestamp
  },
  logout (state) {
    state.timestamp = 0
    state.items = []
  },
  estimatesUpdated (state, ui) {
    state.items = _.unionBy(ui, state.items, 'id')
  },
  deleteEstimateById (state, id) {
    let estimate = _.filter(state.items, function (estimate) {
      return estimate.id == id
    })

    for (let i = 0; i < estimate.length; i++) {
      let index = state.items.indexOf(estimate[i])
      state.items.splice(index, 1)
    }
  },
  deleteEstimateByCardId (state, id) {
    let estimate = _.filter(state.items, function (estimate) {
      return estimate.cardId == id
    })

    for (let i = 0; i < estimate.length; i++) {
      let index = state.items.indexOf(estimate[i])
      state.items.splice(index, 1)
    }
  }
}
