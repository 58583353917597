<template>
  <div class="mb-0-75 border pt-0-5 pb-0-5 dashboard-row-element clickable" @click="goToCard(card)">
    <div class="row">
      <div class="col-8 col-sm-10 pl-0">
        <strong>
          {{ card.card_number }}
          <template v-if="card.rego_number"> - {{ card.rego_number }}</template>
          <template v-if="card.make"> - {{ card.make }}</template>
        </strong>
      </div>
      <div class="col-4 col-sm-2 pl-0 text-align-right">
        <span class="whitespace-nowrap">{{ card.assignedTo.date | formatDateShort }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardsSummary",
  components: {},
  props: {
    card: {
      type: [Object, null],
      default() {
        return {};
      }
    },
  },
  methods: {
    goToCard(card) {
      this.$router.push({name: 'CardsEdit', params: {card_id: card.card_id}});
    }
  },
};
</script>

<style scoped>

  .dashboard-row-element {
    background-color: white;
    padding: 10px 15px !important;
  }

</style>
