import {Actions} from './actions';
import {Mutations} from './mutations';
import {Getters} from './getters';

const state = {
  lastTimestamp: 0,
  isNeedUpdateEmail: null,
  isNeedUpdateActivity: null,
  isNeedUpdateImages: null,
  isNeedUpdateFiles: null,
  isNeedUpdateTask: null,
  isNeedUpdateDashboard: null,
  isNeedUpdatePromos: null,
};

export default {
  namespaced: true,
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
  state,
};
