<template>
  <div class="groups-page">
    <div class="row no gutter d-flex col-12">
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div>
          <div class="card-header bg-navyblue right-menu-align-center">
            <span style="color: black; font-weight: bold">Group Name</span>
          </div>
          <div class="">
            <ul class="group-list">
              <li class="group-list-item" @click="selectGroup('ShopManager')"      :class="{'active': selectedGroup === 'ShopManager'}"     >Shop Manager</li>
              <li class="group-list-item" @click="selectGroup('ProductionManager')" :class="{'active': selectedGroup === 'ProductionManager'}">Production Manager</li>
              <li class="group-list-item" @click="selectGroup('Estimator')"        :class="{'active': selectedGroup === 'Estimator'}"       >Estimators</li>
              <li class="group-list-item" @click="selectGroup('PDRTechnician')"    :class="{'active': selectedGroup === 'PDRTechnician'}"   >PDR Technicians</li>
              <li class="group-list-item" @click="selectGroup('PanelTechnician')"  :class="{'active': selectedGroup === 'PanelTechnician'}" >Panel Technicians</li>
              <li class="group-list-item" @click="selectGroup('PaintTechnician')"  :class="{'active': selectedGroup === 'PaintTechnician'}" >Paint Technicians</li>
              <li class="group-list-item" @click="selectGroup('CustomerService')"  :class="{'active': selectedGroup === 'CustomerService'}" >Customer Service</li>
              <li class="group-list-item" @click="selectGroup('StripperFitter')"   :class="{'active': selectedGroup === 'StripperFitter'}"  >Strippers & Fitters</li>
              <li class="group-list-item" @click="selectGroup('Detailer')"         :class="{'active': selectedGroup === 'Detailer'}"        >Detailers</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-8 col-sm-6">
        <div v-if="selectedGroup === 'ShopManager' || selectedGroup === 'ProductionManager'" class="" style="margin-left: 13px;" >
          <p style="font-weight: 700; margin-top: 15px; font-size: 14px;">Group Member Visibility - Always On</p>
          <p style="width: 90%;">Managers and Members of this group can see assigned items for all users of the organisation</p>
        </div>
        <div v-else class="row no-gutter">
          <div class="">
            <div class="col-12 col-md-6">
              <div class="" >
                <p style="font-weight: 700; margin-top: 15px; font-size: 14px;">Enable Group Member Visibility</p>
                <p>Allow members of the group to see each
                  other's group assigned items. Disable to
                  let members see only their assigned items.</p>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="d-flex" style="margin-top: 15px;">
                <toggle-button
                  v-model="groupSettings[selectedGroup].memberVisibility"
                  id="ps-enabled-input" class="mb-0 rs-toggle__button"
                  color="#5E79FF"
                  @change="changedActiveStatus"
                  :disabled="!isStatusActive"
                  :sync="true"
                  :width="40"
                  :labels="false"/>
                <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                <template v-if="groupSettings[selectedGroup].memberVisibility">Active</template>
                <template v-else>Not Active</template>
              </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="pagination-block-bottom additional-block-groups-users" style="margin-top: 30px">
            <b-table
              :items="computedMembers"
              :fields="fields"
              :current-page="groupsCurrentPage"
              :per-page="groupsPerPage"
              hover
              responsive
            >
              <template v-slot:cell(fullName)="data">
                <div class="line-feed-business-loc">
                  <span class="col-12 col-sm-6" style="font-weight: bold; padding-left: 0px">{{data.item.fullName}}</span>
                  <span v-if="data.item.address" class="col-12 col-sm-6" style="text-align: right; color: rgba(0, 0, 0, 0.5);">
                    Business Location:
                <span style="font-weight: bold; color: #1C1F39">{{ getLocation(data.item.address).name }}</span>
                  </span>
                </div>

              </template>
            </b-table>
          </div>
          <div class="additional-paginator-wrapper">
            <block-pagination
              :role="'users'"
              :arrayOfSomeone="computedMembers"
              :arrayOfSomeoneFiltered="computedMembers"
              :currentPage="groupsCurrentPage"
              @changedCurrentPage="value => groupsCurrentPage = value"
              :perPage="groupsPerPage"
              @changedPerPage="value => groupsPerPage = value"
            >
            </block-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import BlockPagination from '../utility/block-pagination';
import _ from "lodash";
import {mapGetters} from "vuex";

export default {
  name: "Groups",
  components: {
    BlockPagination,
  },
  data() {
    return {
      selectedGroup: 'ShopManager',
      users: {},
      groupSettings: {},
      groupsCurrentPage: 1,
      groupsPerPage: 10,
      locations: [],
    }
  },
  mounted() {
    this.loadLocations();
    this.getGroupsAndUsers();
  },
  methods: {
    loadLocations() {
      Axios.get('/fe/vendor/locations')
          .then(res => {
            if(res && res.data && res.data._status){
              this.locations = res.data.locations;
            }
          })
          .catch(e => toastr.error(e))
    },
    getLocation(id){
      let data = _.find(this.locations, (item) => {
        return id === item.id;
      });
      if (_.isEmpty(data)) {
        return {name: ''};
      }
      return data;
    },
    selectGroup(group) {
      this.selectedGroup = group;
    },
    changedActiveStatus() {
      Axios.post('/fe/settings/update-groups-settings',
        {
          group: this.selectedGroup,
          settings: {
            memberVisibility: this.groupSettings[this.selectedGroup].memberVisibility,
          }
        }
      )
        .then(res => {
          if (res.data._status) {
          }
        }).catch(er => {
        console.log(er);
      });
    },
    getGroupsAndUsers() {
      Axios.get('/fe/settings/groups-and-users')
        .then(res => {
          if (res.data._status && res.data.users) {
            this.users = res.data.users;
            this.groupSettings = res.data.groupsSettings;
          }
        }).catch(er => {
        console.log(er);
      });
    }
  },
  computed: {
    ...mapGetters({
        isStatusActive: 'isStatusActive',
    }),
    fields() {
      return [
        {
          label: this.computedTitleForTable,
          key: 'fullName',
          tdClass: 'clickable',
        },
      ]
    },
    computedTitleForTable() {
      let fixedGroupName = '';
      if (this.selectedGroup !== 'PDRTechnician') {
        fixedGroupName = this.selectedGroup.replace(/([A-Z])/g, ' $1');
      } else {
        fixedGroupName = 'PDR Techician'
      }
      return 'Group Members - ' + fixedGroupName;
    },
    computedMembers() {
      return this.users[this.selectedGroup]
    }
  }
}
</script>
<style>

</style>
<style scoped>
.groups-page .group-list {
  list-style: none;
  margin-bottom: 30px;
}
.groups-page .group-list .group-list-item {
  border-bottom: 1px solid rgba(27,30,56,.25);
  padding: 10px 10px 10px 15px;
  font-weight: bold;
}
.groups-page .group-list .group-list-item:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
.groups-page .group-list .active {
  background-color: rgba(94,121,255,.1)!important;
}
.groups-page .group-member {
  border-bottom: 1px solid rgba(0,0,0,.2);
}
.right-menu-align-center {
  align-content: center;
}
.line-feed-business-loc {
  display: flex;
}
@media screen and (max-width: 767px) {
  .line-feed-business-loc {
    flex-direction: column;
  }
  .line-feed-business-loc span {
    text-align: left !important;
    padding-left: 0 !important;
    width: 100%;
  }
}
</style>

<style>
  .additional-block-groups-users .b-table-sticky-header, .table-responsive {
    margin-bottom: 5rem !important;
  }
</style>
