<script>
    import {VStepper} from 'vue-stepper-component';
    import GoodWizard from '../../utility/vueGoodWizard';
    import Axios from 'axios';
    import _ from 'lodash';
    import Email from '../../utility/email-validator';
    import {mapGetters} from "vuex";

    export default {
      name: 'invoice-options-send-to-customer',
      data: function () {
        return {
          steps: [
            {
              label: 'Select Customer',
              slot: 'invoice-options-send-to-customers-step-1',
            },
            {
              label: 'Invoice Preview',
              slot: 'invoice-options-send-to-customers-step-2',
            },
          ],
          isCanSend: true,
          new_customer_email: '',
          new_customer_name: '',
          step: 1,
          invoiceToCustomer: {},
          countSubtotal: 0,
          summary: {},
          customerContacts: [],
          isExcess: false,
          isCheckEmail: true,
          isCheckCustomerEmail: true,
        };
      },
      methods: {
        goNext() {
          if (this.isCheckEmail && this.isCheckCustomerEmail) {
            this.$refs.invoiceOptionsSendToCustomer.goNext()
          } else {
            toastr.error('Please check the form fields')
          }
        },
        addNewCustomerContact: function () {
          if (!this.isCheckEmail || !this.isCheckCustomerEmail) {
            toastr.error('Your changes could not be saved, please check the form fields again');
            return;
          }
          this.customerContacts.push({
            name: this.new_customer_name,
            email: this.new_customer_email
          });
          this.new_customer_name = '';
          this.new_customer_email = '';
        },
        isExpandLabour: function (id = null) {
          if (id) {
            if (this.computedExpand && this.computedExpand[id] && this.computedExpand[id].labour) {
              return true;
            }
            return false;
          } else {
            if (this.invoiceToCustomer.invoice.is_expand_labour) {
              return true;
            }
            return false;
          }
        },
        isExpandPart: function (id = null) {
          if (id) {
            if (this.computedExpand && this.computedExpand[id] && this.computedExpand[id].part) {
              return true;
            }
            return false;
          } else {

            if (this.invoiceToCustomer.invoice.is_expand_part) {
              return true;
            }
            return false;
          }
        },
        isExpandOther: function (id = null) {
          if (id) {
            if (this.computedExpand && this.computedExpand[id] && this.computedExpand[id].other) {
              return true;
            }
            return false;
          } else {
            if (this.invoiceToCustomer.invoice.is_expand_other) {
              return true;
            }
            return false;
          }
        },
        isExpandPDR: function (id = null) {
          if (id) {
            if (this.computedExpand && this.computedExpand[id] && this.computedExpand[id].pdr) {
              return true;
            }
            return false;
          } else {
            if (this.invoiceToCustomer.invoice.is_expand_pdr) {
              return true;
            }
            return false;
          }
        },
        loadData() {
          let invoice_id = this.$route.params.invoice_id;
          this.isExcess = this.$route.params.isExcessInvoice;
          let self = this;
          NProgress.start();
          Axios.get(`/fe/invoice/${invoice_id}`)
              .then(response => {
                self.invoiceToCustomer = response.data;
                if (self.isExcessInvoice) {
                  self.invoiceToCustomer.invoice = response.data.excess_invoice;
                }
                self.customerContacts = [];
                self.customerContacts.push(response.data.invoice.customer);
                let countSubtotal = 0;
                _.forEach(response.data.all_line_items, function (lines) {
                  _.forEach(lines.line_items.lines, function (item) {
                    if (item && item.length > 0) {
                      countSubtotal++;
                    }
                  });
                });
                this.countSubtotal = countSubtotal;
                this.summary = response.data.summary;

                this.invoiceToCustomer.all_line_items = _.map(this.invoiceToCustomer.all_line_items, lines => {
                  lines.line_items.lines = _.mapValues(lines.line_items.lines, items => {
                    return _.filter(items, item => item.total != 0);
                  });
                  return lines;
                });
              })
              .catch(error => {
                toastr.error("Error occurred while loading info");
              })
            .finally(() => {
              NProgress.done();
            });
        },
        goToInvoice() {
          if (this.isExcessInvoice) {
            this.$router.push({name: 'InvoiceExcessView', params: {invoice_id: this.$route.params.invoice_id, currentTab: this.$route.params.currentTab}});
          } else {
            this.$router.push({name: 'InvoiceView', params: {invoice_id: this.$route.params.invoice_id, currentTab: this.$route.params.currentTab}});
          }
        },
        clickCancel() {
          if (this.isExcessInvoice) {
            this.$router.push({name: 'InvoiceExcessView', params: {invoice_id: this.$route.params.invoice_id, currentTab: this.$route.params.currentTab}});
          } else {
            this.$router.push({name: 'InvoiceView', params: {invoice_id: this.$route.params.invoice_id, currentTab: this.$route.params.currentTab}});
          }
        },
        clickSend() {
          if (!this.isCanSend) {
            return;
          }

          this.isCanSend = false;

          if (this.new_customer_email) {
            this.addNewCustomerContact();
          }

          let invoice_id = this.$route.params.invoice_id;
          let q = '';
          let expand_url = '';
          let invoice_detail = '';
          let url = '';

          if (this.isExcessInvoice) {
            if (this.invoiceToCustomer.invoice.list_detail) {
              invoice_detail = '?detail';
            } else {
              invoice_detail = '';
            }
            url = `/fe/email/invoice/customer/${invoice_id}${invoice_detail}`;
          } else {
            q = JSON.stringify(this.computedExpand);
            expand_url = '?e=' + encodeURIComponent(q);
            url = `/fe/email/invoice/customer/${invoice_id}${expand_url}`
          }

          NProgress.start();
          Axios({
            method: 'post',
            responseType: 'json',
            url: url,
            validateStatus: function (status) {
              return status < 500;
            },
            data: JSON.stringify({
              customers: this.customerContacts,
            })
          })
              .then(response => {
                if (response.status == 200) {
                  toastr.success(response.data.msg);
                  this.goToInvoice();
                } else {
                  toastr.error(response.data.msg);
                }
                NProgress.done();
              })
              .catch(error => {
                NProgress.done();
                toastr.error(error);
              })
              .finally(() => {
                this.isCanSend = true;
              });
        },

      },
      computed: {
        ...mapGetters({
          'vendorInfo': 'vendorInfo',
          getterBusinessName : 'getBusinessName',
        }),
        computedExpand() {
          if (this.$route.params.expand) {
            return this.$route.params.expand;
          } else {
            return this.invoiceToCustomer.invoice.expand
          }
        },
        isExcessInvoice() {
          return this.isExcess;
        },
        countOfEstimateAndSupps() {
          if (this.invoiceToCustomer && this.invoiceToCustomer.all_line_items) {
            return this.invoiceToCustomer.all_line_items.length;
          }
          return 0
        },
        isEmptyCustomerContacts() {
          return _.isEmpty(this.customerContacts);
        },
        isEmptyInvoiceToCustomer() {
          return _.isEmpty(this.invoiceToCustomer);
        }
      },
      mounted: function () {
        this.loadData();
      },
      components: {
        VStepper,
        GoodWizard,
        Email,
      }
    };

</script>

<template>
  <div class="invoice-options-send-to-customers">
    <div class="page-header">
      <h4>View Invoice<span v-if="invoiceToCustomer && invoiceToCustomer.invoice"> - {{invoiceToCustomer.invoice.number}}</span><span v-if="invoiceToCustomer && invoiceToCustomer.invoice && invoiceToCustomer.invoice.customer && invoiceToCustomer.invoice.customer.name"> | {{invoiceToCustomer.invoice.customer.name}}</span><span v-if="invoiceToCustomer && invoiceToCustomer.card && invoiceToCustomer.card.vehicle && invoiceToCustomer.card.vehicle.rego_number"> | {{invoiceToCustomer.card.vehicle.rego_number}}</span></h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="'/'">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="'/invoices'">View Invoices</router-link>
        </li>
        <li class="breadcrumb-item">
          <a href="" @click.prevent="goToInvoice">
            <span v-if="isExcessInvoice" class="">
              View Excess Invoice
            </span>
            <span v-else class="">
              View Invoice
            </span>
          </a>
        </li>
        <li class="breadcrumb-item">Options</li>
        <li class="breadcrumb-item active">Send To Customer</li>
      </ol>
      <h4 class="header-display-name">{{ getterBusinessName }}</h4>
    </div>
    <div>

      <good-wizard
          :steps="steps"
          :currentStep="step"
          ref="invoiceOptionsSendToCustomer"
      >
        <div slot="invoice-options-send-to-customers-step-1" class="invoice-options-send-to-customers-step-1">
          <div class="wizard-header">
            <div class="wizard-header-name">
              Select Customer
            </div>
            <div class="wizard-subheader-name">
              <div class="wizard-subheader-name-text">
                Choose the customer(s) to send to
              </div>
              <div class="wizard-header-button pull-right">
                <button @click="addNewCustomerContact" class="btn btn-primary button-next">Add Another Name</button>
              </div>
            </div>
          </div>
          <div>
            <table v-if="!isEmptyCustomerContacts" class="table b-table table-hover customer-table table-bordered">
              <thead>
              <tr>
                <th class="th-width-30">Customer</th>
                <th class="th-width-50">Email</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(c, i) in customerContacts" class="clickable">
                <td><input type="text" v-model="c.name" class="form-control"/></td>
                <td class="text-center" scope="row">
                  <email
                      :email="c.email"
                      @onChange="(value) => c.email = value"
                      @checked="(value) => isCheckCustomerEmail = value"
                  ></email>
                </td>
              </tr>
              <tr>
                <td><input type="text" v-model="new_customer_name" class="form-control"/></td>
                <td class="text-center" scope="row">
                  <email
                      :email="new_customer_email"
                      @onChange="(value) => new_customer_email = value"
                      @checked="(value) => isCheckEmail = value"
                  ></email>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div slot="invoice-options-send-to-customers-step-2" class="invoice-options-send-to-customers-step-2 rs-scroll rs-scroll--y">
          <div class="header">
            Invoice Preview
          </div>
          <div class="subheader">
            Confirm the details in the invoice to be sent
          </div>
          <div class="invoice" v-if="!isEmptyInvoiceToCustomer && !isExcessInvoice">
            <header class="invoice-header">
              <div class="invoice-logo">
                <img :src="vendorInfo.logo" style="height: 150px; width: 300px;" alt="">
              </div>
              <div class="invoice-info">
                <h1 class="invoice-title">TAX INVOICE</h1>
                <table class="header-table">
                  <tr>
                    <td class="font-14 font-bold">{{invoiceToCustomer.vendor.business_name}}</td>
                  </tr>
                  <tr>
                    <td><span class="font-bold">ABN</span> : {{invoiceToCustomer.vendor.abn}}</td>
                  </tr>
                  <tr>
                    <td>License : {{invoiceToCustomer.vendor.license_number}}</td>
                  </tr>
                  <tr v-if="invoiceToCustomer.vendor.postal_address">
                    <td>{{invoiceToCustomer.vendor.postal_address}}</td>
                  </tr>
                  <tr v-if="!invoiceToCustomer.vendor.postal_address">
                    <td>{{invoiceToCustomer.vendor.postal_street}} {{invoiceToCustomer.vendor.postal_suburb}}</td>
                  </tr>
                  <tr v-if="!invoiceToCustomer.vendor.postal_address">
                    <td>{{invoiceToCustomer.vendor.postal_state}} {{invoiceToCustomer.vendor.postal_country}}</td>
                  </tr>
                  <tr>
                    <td>Email : {{invoiceToCustomer.vendor.email}}</td>
                  </tr>
                  <tr>
                    <td>Phone : {{invoiceToCustomer.vendor.phone_area_code}} {{invoiceToCustomer.vendor.phone}}</td>
                  </tr>
                </table>
                <table class="header-table">
                  <tr>
                    <td><strong>Invoice To :</strong></td>
                  </tr>
                  <tr>
                    <td>{{invoiceToCustomer.invoice.customer.name}}</td>
                  </tr>
                  <tr v-if="invoiceToCustomer.invoice.customer.address">
                    <td>{{invoiceToCustomer.invoice.customer.address}}</td>
                  </tr>
                  <tr v-if="invoiceToCustomer.invoice.customer.address === null">
                    <td>{{invoiceToCustomer.card.customer.street_name}} {{invoiceToCustomer.card.customer.suburb}}</td>
                  </tr>
                  <tr v-if="invoiceToCustomer.invoice.customer.address === null">
                    <td>{{invoiceToCustomer.card.customer.state}} {{invoiceToCustomer.card.customer.country}}</td>
                  </tr>
                </table>
                <table class="header-table">
                  <tr>
                    <td><strong>Invoice Number:</strong> <span><strong>{{invoiceToCustomer.invoice.number}} </strong> </span></td>
                  </tr>
                  <tr>
                    <td><strong>Estimator:</strong> <span>{{invoiceToCustomer.invoice.estimator}}</span></td>
                  </tr>
                  <tr>
                    <td><strong>Invoice Date:</strong> <span>{{invoiceToCustomer.invoice.created_date}}</span></td>
                  </tr>
                  <tr>
                    <td><strong>Due Date:</strong> <span>{{invoiceToCustomer.invoice.due_date}}</span></td>
                  </tr>
                </table>
              </div>
            </header>

            <main>
              <table class="main-table m0">
                <thead>
                <tr>
                  <th colspan="2">Vehicle &amp; Owner</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th>Owner Details</th>
                  <th>Vehicle Details</th>
                </tr>
                <tr>
                  <td class="w50 vat">
                    <table class="main-inner owner-details">
                      <tr>
                        <td>Owner Name</td>
                        <td>{{invoiceToCustomer.card.customer.name}}</td>
                      </tr>
                      <tr>
                        <td>Home Phone</td>
                        <td>{{invoiceToCustomer.card.customer.phone}}</td>
                      </tr>
                      <tr>
                        <td>Mobile Phone</td>
                        <td>{{invoiceToCustomer.card.customer.mobile}}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{{invoiceToCustomer.card.customer.email}}</td>
                      </tr>
                      <tr v-if="invoiceToCustomer.card.customer.customer_type == 'B'">
                        <td>ABN</td>
                        <td>{{invoiceToCustomer.card.customer.abn}}</td>
                      </tr>
                      <tr>
                        <td>Insurer Name</td>
                        <td>{{invoiceToCustomer.card.insurance.insurer_name}}</td>
                      </tr>
                      <tr>
                        <td>Claim Number</td>
                        <td>{{invoiceToCustomer.card.insurance.claim_number}}</td>
                      </tr>

                    </table>
                  </td>
                  <td class="w50 vat">
                    <table class="vehicle-details">
                      <tr>
                        <td class="w50 vat">
                          <table class="main-inner">
                            <tr>
                              <td>Make</td>
                              <td>{{invoiceToCustomer.card.vehicle.make}}</td>
                            </tr>
                            <tr>
                              <td>Model</td>
                              <td>{{invoiceToCustomer.card.vehicle.model}}</td>
                            </tr>
                            <tr>
                              <td>Series</td>
                              <td v-if="!invoiceToCustomer.properties.isHideSeries">{{invoiceToCustomer.card.vehicle.series}}</td>
                            </tr>
                            <tr>
                              <td>Transmission</td>
                              <td>{{invoiceToCustomer.card.vehicle.transmission}}</td>
                            </tr>
                            <tr>
                              <td>Paint Code</td>
                              <td>{{invoiceToCustomer.card.vehicle.colour}}</td>
                            </tr>
                            <tr>
                              <td>Date Booked (Start)</td>
                              <td>{{invoiceToCustomer.card.booking_date}}</td>
                            </tr>
                          </table>
                        </td>
                        <td class="w50 vat">
                          <table class="main-inner" align="right">
                            <tr>
                              <td>Date Completed</td>
                              <td>{{invoiceToCustomer.card.completion_date}}</td>
                            </tr>
                            <tr>
                              <td>Rego</td>
                              <td>{{invoiceToCustomer.card.vehicle.rego_number}}</td>
                            </tr>
                            <tr>
                              <td>VIN Number</td>
                              <td>{{invoiceToCustomer.card.vehicle.vin}}</td>
                            </tr>
                            <tr>
                              <td>Cylinders</td>
                              <td>{{invoiceToCustomer.card.vehicle.cylinders}}</td>
                            </tr>
                            <tr>
                              <td>Build Date</td>
                              <td>{{invoiceToCustomer.card.vehicle.build_date}}</td>
                            </tr>
                            <tr>
                              <td>Odometer</td>
                              <td>{{invoiceToCustomer.card.vehicle.odometer}}</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                </tbody>
              </table>
              <template v-for="supplement in invoiceToCustomer.all_line_items">
                <table class="main-table m0" v-if="invoiceToCustomer.all_line_items.length > 0 && supplement.isEstimate && isExpandLabour(supplement.id) ">
                  <thead>
                  <tr>
                    <th>Repairs</th>
                  </tr>
                  </thead>
                </table>
                <table class="main-table m0" v-if="invoiceToCustomer.all_line_items.length > 1 && !supplement.isEstimate">
                  <thead>
                  <tr>
                    <th>Supplement - {{supplement.number}}</th>
                  </tr>
                  </thead>
                </table>
                <table class="main-table estimate-repairs m0" v-if="isExpandLabour(supplement.id) && !!supplement.line_items.lines.RR.length">
                  <thead class="bg-white th-fz12">
                  <tr>
                    <th>R&amp;R</th>
                    <th class="text-center">Hrs</th>
                    <th class="text-center">Rate</th>

                    <th class="text-center">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="line in supplement.line_items.lines.RR">
                    <td class="font-bold">{{line.name}}</td>
                    <td class="text-center">{{line.hrs_str}}</td>
                    <td class="text-align-right">{{line.rate_str}}</td>

                    <td class="text-align-right">{{line.total_str}}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="rr-subtotal-hrs">
                    <td class="font-bold text-align-right">Subtotal Hrs</td>
                    <td class="text-center">{{supplement.line_items.summary["RR-Hrs-SubTotal-Str"]}}</td>
                    <td></td>

                    <td></td>
                  </tr>
                  <tr class="rr-subtotal-amount">
                    <td></td>

                    <td></td>
                    <td class="font-bold text-right">Subtotal Amount</td>
                    <td class="text-right font-bold">{{supplement.line_items.summary["RR-Total-SubTotal-Str"]}}</td>
                  </tr>
                  </tfoot>
                </table>
                <table class="main-table estimate-repairs m0" v-if="isExpandLabour(supplement.id) && !!supplement.line_items.lines.Repair.length">
                  <thead class="bg-white th-fz12">
                  <tr>
                    <th>Repair</th>
                    <th class="text-center">Hrs</th>
                    <th class="text-center">Rate</th>

                    <th class="text-center">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="line in supplement.line_items.lines.Repair">
                    <td class="font-bold">{{line.name}}</td>
                    <td class="text-center">{{line.hrs_str}}</td>
                    <td class="text-right">{{line.rate_str}}</td>

                    <td class="text-right">{{line.total_str}}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="rr-subtotal-hrs">
                    <td class="font-bold text-align-right">Subtotal Hrs</td>
                    <td class="text-center">{{supplement.line_items.summary["Repair-Hrs-SubTotal-Str"]}}</td>
                    <td></td>

                    <td></td>
                  </tr>
                  <tr class="rr-subtotal-amount">
                    <td></td>

                    <td></td>
                    <td class="font-bold text-right">Subtotal Amount</td>
                    <td class="text-right font-bold">{{supplement.line_items.summary["Repair-Total-SubTotal-Str"]}}</td>
                  </tr>
                  </tfoot>
                </table>

                <table class="main-table estimate-repairs m0" v-if="isExpandLabour(supplement.id) && !!supplement.line_items.lines.Paint.length">
                  <thead class="bg-white th-fz12">
                  <tr>
                    <th>Paint</th>
                    <th class="text-center">Paint Material</th>
                    <th class="text-center">Hrs</th>
                    <th class="text-center">Rate</th>

                    <th class="text-center">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="line in supplement.line_items.lines.Paint">
                    <td class="font-bold">{{line.name}}</td>
                    <td class="text-center">{{line.paint_type_str}}</td>
                    <td class="text-center">{{line.hrs_str}}</td>
                    <td class="text-right">{{line.rate_str}}</td>

                    <td class="text-right">{{line.total_str}}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="paint-subtotal-hrs">
                    <td colspan="2" class="font-bold text-right">Subtotal Hrs</td>
                    <td class="text-center">{{supplement.line_items.summary["Paint-Hrs-SubTotal-Str"]}}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr class="paint-subtotal-amount">
                    <td></td>

                    <td></td>
                    <td colspan="2" class="text-right font-bold">Subtotal Amount</td>
                    <td class="text-center">{{supplement.line_items.summary["Paint-Total-SubTotal-Str"]}}</td>
                  </tr>
                  </tfoot>
                </table>

                <table class="repairs-total main-table main-total-table"
                       v-if="isExpandLabour(supplement.id) && (!!supplement.line_items.lines.RR.length || !!supplement.line_items.lines.Repair.length || !!supplement.line_items.lines.Paint.length)">
                  <tbody>
                  <tr>
                    <td class="font-bold text-right">Total Labour Hours</td>
                    <td class="text-right">{{supplement.line_items.summary["Labour-Hrs-Str"]}}</td>
                  </tr>
                  <tr>
                    <td class="font-bold text-right">Subtotal Amount</td>
                    <td class="text-right font-bold">{{supplement.line_items.summary["Labour-Cost-Str"]}}</td>
                  </tr>
                  </tbody>
                </table>
                <table class="main-table estimate-parts border-t" v-if="isExpandLabour(supplement.id) && !!supplement.line_items.lines.Materials.length">
                  <thead class="th-fz12">
                  <tr>
                    <th>Materials</th>
                    <th class="text-center">Hrs</th>
                    <th class="text-center">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="line in supplement.line_items.lines.Materials">
                    <td class="font-bold">{{line.name}}</td>
                    <td class="text-center">{{line.hrs_str}}</td>
                    <td class="text-right">{{line.total_str}}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="parts-subtotal-hrs">
                    <td></td>
                    <td class="font-bold text-right">Subtotal Amount</td>
                    <td class="text-right font-bold">{{supplement.line_items.summary["Materials-Total-Str"]}}</td>
                  </tr>
                  </tfoot>
                </table>
                <table class="main-table estimate-repairs border-t" v-if="!isExpandLabour(supplement.id) && supplement.line_items.summary['Labour-Cost-With-Materials'] > 0">
                  <thead class="th-fz12">
                  <tr>
                    <th class="text-left">Repairs</th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="font-bold">Labour As Per Authority</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{supplement.line_items.summary['Labour-Cost-With-Materials-Str']}}</td>
                  </tr>
                  </tbody>
                </table>

                <table class="main-table estimate-parts border-t" v-if="isExpandPart(supplement.id) && !!supplement.line_items.lines.Part.length">
                  <thead class="th-fz12">
                  <tr>
                    <th>Parts</th>
                    <th>Part No</th>
                    <th class="text-center">Qty</th>
                    <th class="text-center">Unit Price</th>
                    <th class="text-center">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="line in supplement.line_items.lines.Part">
                    <td class="font-bold">{{line.name}}</td>
                    <td class="font-bold">{{line.part_num}}</td>
                    <td class="text-center">{{line.qty}}</td>
                    <td class="text-right">{{line.rate_str}}</td>

                    <td class="text-right">{{line.total_str}}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="parts-subtotal-hrs">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="font-bold text-right">Subtotal Amount</td>
                    <td class="text-right font-bold">{{supplement.line_items.summary["Part-Total-SubTotal-Str"]}}</td>
                  </tr>
                  </tfoot>
                </table>
                <table class="table main-table tab-special mb-0" v-if="!isExpandPart(supplement.id) && supplement.line_items.summary['Part-Cost'] > 0">
                  <thead>
                  <tr>
                    <th class="text-left">Parts</th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center">Amount</th>
                  </tr>
                  </thead>
                  <tr class="qtit">
                    <td>Parts</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{supplement.line_items.summary['Part-Cost-Str']}}</td>
                  </tr>
                </table>
                <!--                                Other-->
                <table class="main-table estimate-other border-t" v-if="isExpandOther(supplement.id) && !!supplement.line_items.lines.Other.length">
                  <thead class="th-fz12">
                  <tr>
                    <th>Other</th>
                    <th class="text-right">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="line in supplement.line_items.lines.Other">
                    <td class="font-bold">{{line.name}}</td>
                    <td class="text-right">{{line.total_str}}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="paint-subtotal-amount">
                    <td class="font-bold text-right">Subtotal Amount</td>
                    <td class="text-right font-bold">{{supplement.line_items.summary["Other-Total-SubTotal-Str"]}}</td>
                  </tr>
                  </tfoot>
                </table>
                <table class="main-table estimate-sublet m0 border-t" v-if="isExpandOther(supplement.id) && !!supplement.line_items.lines.Sublet.length">
                  <thead class="th-fz12">
                  <tr>
                    <th>Sublet</th>
                    <th class="text-center">Repairer</th>
                    <th class="text-center">Cost</th>
                    <th class="text-center">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="line in supplement.line_items.lines.Sublet">
                    <td class="font-bold">{{line.name}}</td>
                    <td class="text-center">{{line.repairer}}</td>
                    <td class="text-right">{{line.rate_str}}</td>
                    <td class="text-right">{{line.total_str}}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr class="sublet-subtotal-amount">
                    <td class="text-right font-bold" colspan="3">Subtotal Amount</td>
                    <td class="text-center">{{supplement.line_items.summary["Sublet-Total-SubTotal-Str"]}}</td>
                  </tr>
                  </tfoot>
                </table>
                <table class="table main-table tab-special mb-0" v-if="!isExpandOther(supplement.id) && supplement.line_items.summary['Other-Cost'] > 0">
                  <thead>
                  <tr>
                    <th class="text-left">Sublet & Other</th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="qtit">
                    <td>Sublet & Other</td>
                    <td></td>
                    <td></td>
                    <td class="text-right">{{supplement.line_items.summary['Other-Cost-Str']}}</td>
                  </tr>
                  </tbody>
                </table>
                <!--                                PDR-->
                <div v-if="isExpandPDR(supplement.id)">
                  <table class="main-table estimate-sublet m0 border-t"
                         v-if="supplement.line_items.lines.PDR && supplement.line_items.lines.PDR.length>0">
                    <thead class="th-fz12">
                    <tr>
                      <th>PDR</th>
                      <th class="text-center">Material</th>
                      <th class="text-center">Action</th>
                      <th class="text-center">Qty</th>
                      <th class="text-center">Price</th>
                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(line, index) in supplement.line_items.lines.PDR" :key="index">
                      <td>
                        <div style="white-space: pre-line;">{{line.name}}</div>
                        <div v-if="line.comment">Comment : {{line.comment}}</div>
                      </td>
                      <td class="text-center align-text-top">
                        <template v-if="line.type === 'Panel'">{{ line.material|valuePDRMaterial }}</template>
                      </td>
                      <td class="text-center  align-text-top">
                        <template v-if="line.type === 'Panel'">{{ line.action|valuePDRAction }}</template>
                      </td>
                      <td class="text-center  align-text-top">{{ line.qty }}</td>
                      <td class="text-right  align-text-top">{{ line.price_str }}</td>
                      <td class="text-right  align-text-top">{{ line.total_str }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="paint-subtotal-amount">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="font-bold text-right">Subtotal Amount</td>
                      <td class="text-right font-boldt">{{supplement.line_items.summary["PDR-Total-SubTotal-Str"]}}</td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
                <div v-if="!isExpandPDR(supplement.id)">
                  <table class="table main-table tab-special mb-0" v-if="supplement.line_items.summary['PDR-Cost'] > 0">
                    <thead>
                    <tr>
                      <th class="text-left">PDR</th>
                      <th class="text-center"></th>
                      <th class="text-center"></th>
                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tr class="qtit">
                      <td>PDR</td>
                      <td></td>
                      <td></td>
                      <td>{{supplement.line_items.summary['PDR-Cost-Str']}}</td>
                    </tr>
                  </table>
                </div>
              </template>
              <table class="main-table  m0 divider-total">
                <thead>
                </thead>
                <tbody>
                <tr>
                  <td></td>
                </tr>
                </tbody>
              </table>
              <table class="parts-total main-table main-total-table">
                <tbody>
                <tr v-if="countSubtotal > 1">
                  <td class="font-bold text-right">Total</td>
                  <td class="text-right">{{invoiceToCustomer.summary.Total_str}}</td>
                </tr>
                <tr>
                  <td class="font-bold text-right border-bottom">GST</td>
                  <td class="text-right border-bottom">{{invoiceToCustomer.summary.GST_str}}</td>
                </tr>
                <tr v-if="countOfEstimateAndSupps > 1 && summary.isInsurance && !!summary.Excess">
                  <td class="font-bold text-right">Combined Total (Inc GST)</td>
                  <td class="text-right"><strong>{{invoiceToCustomer.summary.GrandTotal_str}}</strong></td>
                </tr>
                <tr v-else="countOfEstimateAndSupps > 1 && !summary.Excess">
                  <td class="font-bold text-right">GRAND TOTAL (Inc GST)</td>
                  <td class="text-right"><strong>{{invoiceToCustomer.summary.GrandTotal_str}}</strong></td>
                </tr>
                <tr v-if="summary.isInsurance && !!summary.Excess">
                  <td class="font-bold text-right">Less Excess</td>
                  <td class="text-right">{{summary.Excess_str}}</td>
                </tr>
                <tr v-if="summary.isInsurance && !!summary.Discount">
                  <td class="font-bold text-right">Discount Amount</td>
                  <td class="text-right">{{summary.Discount_str}}</td>
                </tr>
                <tr v-if="summary.isInsurance && (!!summary.Discount || !!summary.Excess)">
                  <td class="font-bold text-right"><strong>GRAND TOTAL Invoice</strong></td>
                  <td class="text-right"><strong>{{summary.TotalInvoice_str}}</strong></td>
                </tr>
                </tbody>
              </table>

            </main>
            <footer>
              <table class="footer-table">
                <thead>
                <tr>
                  <th>Terms</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td v-html="invoiceToCustomer.terms"></td>
                </tr>
                </tbody>
              </table>
            </footer>
          </div>
          <div class="invoice" v-else-if="!isEmptyCustomerContacts">
            <header class="invoice-header">
              <div class="invoice-logo">
                <img :src="vendorInfo.logo" style="height: 150px; width: 300px;" alt="">
              </div>
              <div class="invoice-info">
                <h1 class="invoice-title">TAX INVOICE</h1>
                <table class="header-table">
                  <tr>
                    <td class="font-14 font-bold">{{invoiceToCustomer.vendor.business_name}}</td>
                  </tr>
                  <tr>
                    <td><span class="font-bold">ABN</span> : {{invoiceToCustomer.vendor.abn}}</td>
                  </tr>
                  <tr>
                    <td>License : {{invoiceToCustomer.vendor.license_number}}</td>
                  </tr>
                  <tr v-if="invoiceToCustomer.vendor.postal_address">
                    <td>{{invoiceToCustomer.vendor.postal_address}}</td>
                  </tr>
                  <tr v-if="!invoiceToCustomer.vendor.postal_address">
                    <td>{{invoiceToCustomer.vendor.postal_street}} {{invoiceToCustomer.vendor.postal_suburb}}</td>
                  </tr>
                  <tr v-if="!invoiceToCustomer.vendor.postal_address">
                    <td>{{invoiceToCustomer.vendor.postal_state}} {{invoiceToCustomer.vendor.postal_country}}</td>
                  </tr>
                  <tr>
                    <td>Email : {{invoiceToCustomer.vendor.email}}</td>
                  </tr>
                  <tr>
                    <td>Phone : {{invoiceToCustomer.vendor.phone_area_code}} {{invoiceToCustomer.vendor.phone}}</td>
                  </tr>
                </table>
                <table class="header-table">
                  <tr>
                    <td><strong>Invoice To:</strong></td>
                  </tr>
                  <tr>
                    <td>{{invoiceToCustomer.invoice.customer.name}}</td>
                  </tr>
                  <tr v-if="invoiceToCustomer.invoice.customer.address">
                    <td>{{invoiceToCustomer.invoice.customer.address}}</td>
                  </tr>
                  <tr v-if="invoiceToCustomer.invoice.customer.address === null">
                    <td>{{invoiceToCustomer.card.customer.street_name}} {{invoiceToCustomer.card.customer.suburb}}</td>
                  </tr>
                  <tr v-if="invoiceToCustomer.invoice.customer.address === null">
                    <td>{{invoiceToCustomer.card.customer.state}} {{invoiceToCustomer.card.customer.country}}</td>
                  </tr>
                </table>
                <table class="header-table">
                  <tr>
                    <td><strong>Invoice Number:</strong> <span><strong>{{invoiceToCustomer.invoice.number}} </strong> </span></td>
                  </tr>
                  <tr>
                    <td><strong>Estimator:</strong> <span>{{invoiceToCustomer.invoice.estimator}}</span></td>
                  </tr>
                  <tr>
                    <td><strong>Invoice Date:</strong> <span>{{invoiceToCustomer.invoice.created_date}}</span></td>
                  </tr>
                  <tr>
                    <td><strong>Due Date:</strong> <span>{{invoiceToCustomer.invoice.due_date}}</span></td>
                  </tr>
                </table>
              </div>
            </header>

            <main>
              <table class="main-table m0">
                <thead>
                <tr>
                  <th colspan="2">Vehicle &amp; Owner</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th>Owner Details</th>
                  <th>Vehicle Details</th>
                </tr>
                <tr>
                  <td class="w50 vat">
                    <table class="main-inner owner-details">
                      <tr>
                        <td>Owner Name</td>
                        <td>{{invoiceToCustomer.card.customer.name}}</td>
                      </tr>
                      <tr>
                        <td>Home Phone</td>
                        <td>{{invoiceToCustomer.card.customer.phone}}</td>
                      </tr>
                      <tr>
                        <td>Mobile Phone</td>
                        <td>{{invoiceToCustomer.card.customer.mobile}}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{{invoiceToCustomer.card.customer.email}}</td>
                      </tr>
                      <tr v-if="invoiceToCustomer.card.customer.customer_type == 'B'">
                        <td>ABN</td>
                        <td>{{invoiceToCustomer.card.customer.abn}}</td>
                      </tr>
                      <tr>
                        <td>Insurer Name</td>
                        <td>{{invoiceToCustomer.card.insurance.insurer_name}}</td>
                      </tr>
                      <tr>
                        <td>Claim Number</td>
                        <td>{{invoiceToCustomer.card.insurance.claim_number}}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="w50 vat">
                    <table class="vehicle-details">
                      <tr>
                        <td class="w50 vat">
                          <table class="main-inner">
                            <tr>
                              <td>Make</td>
                              <td>{{invoiceToCustomer.card.vehicle.make}}</td>
                            </tr>
                            <tr>
                              <td>Model</td>
                              <td>{{invoiceToCustomer.card.vehicle.model}}</td>
                            </tr>
                            <tr>
                              <td>Series</td>
                              <td v-if="!invoiceToCustomer.properties.isHideSeries">{{invoiceToCustomer.card.vehicle.series}}</td>
                            </tr>
                            <tr>
                              <td>Transmission</td>
                              <td>{{invoiceToCustomer.card.vehicle.transmission}}</td>
                            </tr>
                            <tr>
                              <td>Paint Code</td>
                              <td>{{invoiceToCustomer.card.vehicle.colour}}</td>
                            </tr>
                            <tr>
                              <td>Date Booked (Start)</td>
                              <td>{{invoiceToCustomer.card.booking_date}}</td>
                            </tr>
                          </table>
                        </td>
                        <td class="w50 vat">
                          <table class="main-inner" align="right">
                            <tr>
                              <td>Date Completed</td>
                              <td>{{invoiceToCustomer.card.completion_date}}</td>
                            </tr>
                            <tr>
                              <td>Rego</td>
                              <td>{{invoiceToCustomer.card.vehicle.rego_number}}</td>
                            </tr>
                            <tr>
                              <td>VIN Number</td>
                              <td>{{invoiceToCustomer.card.vehicle.vin}}</td>
                            </tr>
                            <tr>
                              <td>Cylinders</td>
                              <td>{{invoiceToCustomer.card.vehicle.cylinders}}</td>
                            </tr>
                            <tr>
                              <td>Build Date</td>
                              <td>{{invoiceToCustomer.card.vehicle.build_date}}</td>
                            </tr>
                            <tr>
                              <td>Odometer</td>
                              <td>{{invoiceToCustomer.card.vehicle.odometer}}</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                </tbody>
              </table>
              <div>
                <table class="main-table m0">
                  <thead>
                  <tr>
                    <th>Charges</th>
                  </tr>
                  </thead>
                </table>
              </div>
              <div>
                <table class="main-table estimate-repairs m0">
                  <thead class="bg-white th-fz12">
                  <tr>
                    <th>Description</th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>

                    <th class="text-center">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Excess</td>
                    <td></td>
                    <td></td>
                    <td class="text-center" style="padding-left:15px">{{invoiceToCustomer.summary.Excess_str}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <table class="main-table  m0  divider-total">
                <thead>
                </thead>
                <tbody>
                <tr>
                  <td></td>
                </tr>
                </tbody>
              </table>
              <table class="parts-total main-table main-total-table">
                <tbody>

                <tr>
                  <td class="color-dark-grey"><strong>Total</strong></td>
                  <td class="color-dark-grey"><strong>{{invoiceToCustomer.summary.Excess_str}}</strong></td>
                </tr>
                </tbody>
              </table>


            </main>
            <footer>
              <table class="footer-table">
                <thead>
                <tr>
                  <th>Terms</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td v-html="invoiceToCustomer.terms"></td>
                </tr>
                </tbody>
              </table>
            </footer>
          </div>
        </div>
      </good-wizard>

      <div class="wizard__buttons">
        <div class=" pull-right">
          <button
              v-if="$refs.invoiceOptionsSendToCustomer"
              class="btn btn-outline-primary pull-left button-cancel"
              type="button"
              @click="clickCancel"
          >
            Cancel
          </button>
          <button
              v-if="$refs.invoiceOptionsSendToCustomer && $refs.invoiceOptionsSendToCustomer.currentStep != 0"
              class="btn btn-outline-primary-light button-back ml-0"
              type="button"
              @click="$refs.invoiceOptionsSendToCustomer.goBack()"
          >
            Back
          </button>
          <a
              v-if="$refs.invoiceOptionsSendToCustomer && $refs.invoiceOptionsSendToCustomer.currentStep != 1"
              class="btn btn-primary pull-right button-next"
              type="button"
              @click="goNext"
              :tabindex="4"
          >
            Next
          </a>
          <button
              v-if="$refs.invoiceOptionsSendToCustomer && $refs.invoiceOptionsSendToCustomer.currentStep == 1"
              class="btn btn-primary pull-right button-card-estimate"
              type="button"
              :disabled="!isCanSend"
              @click="clickSend"
              style=""
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
    .comment-limit-text {
      color: #A2ABAE;
      padding: 5px;
    }

    .error-text {
      color: red !important;
    }
</style>
<style>
    .V3 .new-card {
      max-width: 1028px;
      margin: 0 auto;
    }

    .V3 .invoice-options-send-to-customers .wizard__body {
      padding: 15px;
      width: 100%;
      margin: 0 auto;
    }

    .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-1 {
      width: 60%;
      margin: 0 auto;
    }

    .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-1 table.customer-table .customer-sms-checkbox,
    .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-1 table.customer-table .customer-email-checkbox {
      width: 25px;
      padding-left: 15px;
    }

    .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-1 table.customer-table .customer-sms-checkbox label.checkbox > span.icon,
    .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-1 table.customer-table .customer-email-checkbox label.checkbox > span.icon {
      margin-right: 0px;

    }

    .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-2 {
      width: 100%;
      padding: 0 5px;
      overflow: auto;
    }


    @media screen and (max-width: 1020px) {
      .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-1 {
        width: 100%;
      }
      .V3 .wizard-header .wizard-header-name {
        margin-right: 150px;
      }
      .V3 .wizard-header .wizard-subheader-name-text {
        margin-right: 150px;
      }
      .V3 .invoice-options-send-to-customers .wizard__buttons {
        width: 100% !important;
      }
    }

    @media screen and (max-width: 460px) {
      .V3 .page-header {
        padding: 5px 0 5px 15px !important;
      }
    }

    .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-1 .table th {
      color: #1C1F39;
      font-size: 13px;
      font-weight: bold;
      line-height: 18px;
    }

    .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-2 .table th {
      color: #1C1F39;
      font-size: 13px;
      font-weight: bold;
      line-height: 18px;
      vertical-align: top;
    }

    .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-1 .table td,
    .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-2 .table td {
      color: #1C1F39;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
    }

    .V3 .invoice-options-send-to-customers .wizard__body .invoice-options-send-to-customers-step-3 {
      width: 100%;
    }

    .V3 .invoice-options-send-to-customers .wizard__buttons {
      width: 60%;
      margin: 0 auto;
      padding-right: 15px;
    }

    </style>
    <style scoped>
    .V3 .card-new-body >>> .card-block {
      padding: 0px;
    }

    .V3 .card-new-body .card-block {
      padding: 0px;
    }

    .V3 .wizard__body {
      padding: 15px 30px 0px 30px;
    }


    .V3 .wizard__buttons .pull-left {
      float: left !important;
      margin-right: 10px;
    }

    .V3 .wizard__buttons .pull-right {
      float: right !important;
      margin-left: 10px;
    }

    .V3 .wizard__buttons .btn {
      font-size: 13px;
      font-weight: bold;
      line-height: 18px;
      height: 38px;
    }

    .V3 .wizard__buttons .button-cancel {
      padding: 10px 21px;
      background: #FFFFFF;
      border: 1px solid rgba(28, 31, 57, 0.25);
      color: #1C1F39;
    }

    .V3 .wizard__buttons .button-card {
      padding: 10px 15px;
      background: #FFFFFF;
      color: #5E79FF;
    }

    .V3 .wizard__buttons .button-card-estimate {
      padding: 10px 15px;
    }

    .V3 .wizard__buttons .button-next {
      padding: 10px 15px;
      width: 83px;
    }

    .V3 .wizard__buttons .button-back {
      padding: 10px 26px;
      background: #FFFFFF;
      border-color: #5E79FF;
      width: 83px;
    }

    .disabled-checkbox-sms {
      cursor: not-allowed;
      background-color: rgba(27, 30, 56, 0.12) !important;
      border: 1px solid rgba(27, 30, 56, 0.12) !important;
    }
</style>
