import { render, staticRenderFns } from "./average-value.vue?vue&type=template&id=4faefb2c&scoped=true"
import script from "./average-value.vue?vue&type=script&lang=js"
export * from "./average-value.vue?vue&type=script&lang=js"
import style0 from "./average-value.vue?vue&type=style&index=0&id=4faefb2c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4faefb2c",
  null
  
)

export default component.exports