<template>
  <div class="invoice-options-mark-as-paid">
    <div class="page-header">
      <h4>Mark as Paid - {{ invoice.number }} <span class="text-muted">|</span> {{ invoice.customerName }} <span class="text-muted">|</span> {{ invoice.vehicleRego }}
      </h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="'/'">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="'/invoices'">View Invoices</router-link>
        </li>
        <li class="breadcrumb-item">
          <a href="" @click.prevent="goToInvoice">
            <span v-if="isExcessInvoice">
              View Excess Invoice
            </span>
            <span v-else>
              View Invoice
            </span>
          </a>
        </li>
        <li class="breadcrumb-item">Options</li>
        <li class="breadcrumb-item active">Mark as Paid</li>
      </ol>
    </div>
    <div>

      <good-wizard
          :steps="steps"
          :currentStep="step"
          ref="invoiceOptionsMarkAsPaid"
      >
        <div slot="invoice-options-mark-as-paid-step-1" class="invoice-options-mark-as-paid-step-1">
          <div class="wizard-header">
            <div class="wizard-header-name">
            </div>
            <div class="wizard-subheader-name">
              <div class="wizard-subheader-name-text">
                Set the payment amount include payment type and date.
              </div>
            </div>
          </div>
          <div class="rs-scroll rs-scroll--y" style="overflow: auto; padding-bottom: 8rem;">
            <table class="table b-table table-hover customer-table table-bordered">
              <thead class="">
              <tr>
                <th>Invoice Number</th>
                <th>Estimate / Supplements Number/s summary</th>
                <th>Payment Type</th>
                <th>Invoice Total</th>
                <th>Amount Due</th>
                <th style="min-width: 105px">Amount Paid</th>
                <th style="min-width: 105px">Date Paid</th>
                <th>Print Receipt</th>
              </tr>
              </thead>
              <tbody>
              <tr class="clickable">
                <td style="padding-top: 16px;">{{ invoice.number }}</td>
                <td style="padding-top: 16px;">{{ card.allEstimateNumbers }}</td>
                <td style="padding-top: 10px; width:150px">
                  <multiselect
                      ref="multipaymenttype"
                      :options="['EFT','Credit Card','Cash','Cheque']"
                      v-model="paid.paymentType"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                  >
                  </multiselect>
                </td>
                <td style="padding-top: 16px;">{{ invoice.amount | formatMoney }}</td>
                <td style="padding-top: 16px;">{{ invoice.due | formatMoney }}</td>
                <td>
                  <number-formatter ref="paid.amount" v-model="paid.amount" type="text" class="form-control" placeholder="Amount" format="$0,0.00"></number-formatter>
                </td>
                <td class="text-center">
                  <date-picker v-model="paid.date"></date-picker>
                </td>
                <td style="text-align: center;">
                  <label class="form-check-inline checkbox">
                    <input class="form-check-input" v-model="flagPrintReceipt" type="checkbox" value="true">
                    <span class="icon"><i class='bx bx-check'></i></span>
                  </label>
                  <!--                                <input type="checkbox" v-model="flagPrintReceipt" class="form-control" style="margin-top: 10px;">-->
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div slot="invoice-options-mark-as-paid-step-2" class="invoice-options-mark-as-paid-step-2 rs-scroll rs-scroll--y">
          <h5 style="margin: 20px 0">
            Set the Status to mark the card
          </h5>
          <div>
            <div class="row no-gutter">
              <div class="col-12 col-sm-6 mb-1">
                <p>Invoice Type</p>
              </div>
              <div class="col-12 col-sm-6 mb-1">
                <input :value="isExcessInvoice? 'Excess Invoice': 'Complete Invoice'" class="form-control" readonly/>
              </div>
            </div>
            <div class="row no-gutter mb-2">
              <div class="col-12 col-sm-6">
                <p>Set Card Status</p>
                <p style="font-size: 11px; color: rgba(0, 0, 0, 0.5);">Select the status you want to set this invoices Card to be.</p>
              </div>
              <div class="col-12 col-sm-6">
                <multiselect
                    v-model="computedCardStatus"
                    :options="ceStatuses"
                    :showLabels="false"
                    :option-height="29"
                    :max-height="203"
                    :close-on-select="true"
                    placeholder="Select Card Status"
                    track-by="ce_status_id"
                    label="original_name"
                    style="opacity: 1"
                ></multiselect>
              </div>
            </div>
            <div class="row no-gutter" v-if="!isExcessInvoice">
              <div class="col-12 col-sm-6">
                <p>Unassign Card</p>
              </div>
              <div class="col-12 col-sm-6">
                <div>
                  <input :value="computedAssignedUser" placeholder="No assigned user" class="form-control" readonly/>
                  <div class="d-flex" style="margin: 15px 0">
                    <label class="d-flex form-check-inline radio filters-checkbox" style="width: 75px;">
                      <input :disabled="card && card.assignedTo && card.assignedTo.id == null" v-model="isUnassignCard" :value="true" class="form-radio-input" type="radio">
                      <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                    </label>
                    <label class="d-flex form-check-inline radio filters-checkbox">
                      <input :disabled="card && card.assignedTo && card.assignedTo.id == null" v-model="isUnassignCard" :value="false" class="form-radio-input" type="radio">
                      <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                    </label>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </good-wizard>

      <div class="wizard__buttons" :class="{ 'invoice-mark-as-paid-step2-btn' : isMounted && $refs.invoiceOptionsMarkAsPaid && $refs.invoiceOptionsMarkAsPaid.currentStep == 1 }">
        <div class=" pull-right">
          <button
              v-if="isMounted && $refs.invoiceOptionsMarkAsPaid"
              class="btn btn-outline-primary pull-left button-cancel"
              type="button"
              @click="clickCancel"
          >
            Cancel
          </button>
          <button
              v-if="isMounted && $refs.invoiceOptionsMarkAsPaid && $refs.invoiceOptionsMarkAsPaid.currentStep != 0"
              class="btn btn-outline-primary-light button-back ml-0"
              type="button"
              @click="$refs.invoiceOptionsMarkAsPaid.goBack()"
          >
            Back
          </button>
          <a
              v-if="isMounted && $refs.invoiceOptionsMarkAsPaid && $refs.invoiceOptionsMarkAsPaid.currentStep != 1"
              class="btn btn-primary pull-right button-next"
              type="button"
              @click="$refs.invoiceOptionsMarkAsPaid.goNext()"
              :tabindex="4"
          >
            Next
          </a>
          <button
              v-if="isMounted && $refs.invoiceOptionsMarkAsPaid && $refs.invoiceOptionsMarkAsPaid.currentStep == 1"
              class="btn btn-primary pull-right button-card-estimate"
              type="button"
              @click="clickPaid"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {VStepper} from 'vue-stepper-component';
import GoodWizard from '../../utility/vueGoodWizard';
import Axios from 'axios';
import Multiselect from "vue-multiselect";
import NumberFormatter from '../../utility/number-formatter';
import DatePicker from '../../utility/date-picker';
import {appConfig} from "../../../config";
import _ from "lodash";

export default {
  name: 'invoice-options-mark-as-paid',
  data: function () {
    return {
      steps: [
        {
          label: 'Set Payment',
          slot: 'invoice-options-mark-as-paid-step-1',
        },
        {
          label: 'Set Status',
          slot: 'invoice-options-mark-as-paid-step-2',
        },
      ],
      step: 1,
      isUnassignCard: true,
      isMounted: false,
      ceStatuses: [],
      invoice: {
        id: '',
        number: '',
        createdDate: '',
        dueDate: '',
        status: null,
        due: 0,
        paid: 0,
        amount: 0,
        isExcessInvoice: false,
        customerName: '',
        vehicleRego: '',
      },
      card: {
        assignedTo: {
          id: null,
          name: null,
        },
        number: '',
        cardStatus: '',
        id: '',
        allEstimateNumbers: '',
        estimateId: 0,
      },
      cardStatus: -1,
      paid: {
        amount: 0,
        due: 0,
        date: '',
        paymentType: '',
      },
      flagPrintReceipt: false,
    };
  },
  methods: {
    loadCEStatuses() {
      this.$store.dispatch('loadCEStatuses').then((res) => {
        if (res.status === 200) {
          this.ceStatuses = res.data;
          this.ceStatuses.unshift(
              {
                ce_status_id: -1,
                original_name: "None",
                name: "None",
              }
          );
          //this.updateCardProgressBar();
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    goToInvoice() {
      if (this.isExcessInvoice) {
        this.$router.push({name: 'InvoiceExcessView', params: {invoice_id: this.$route.params.invoice_id, currentTab: this.$route.params.currentTab}});
      } else {
        this.$router.push({name: 'InvoiceView', params: {invoice_id: this.$route.params.invoice_id, currentTab: this.$route.params.currentTab}});
      }
    },
    clickCancel() {
      if (this.isExcessInvoice) {
        this.$router.push({name: 'InvoiceExcessView', params: {invoice_id: this.$route.params.invoice_id, currentTab: this.$route.params.currentTab}});
      } else {
        this.$router.push({name: 'InvoiceView', params: {invoice_id: this.$route.params.invoice_id, currentTab: this.$route.params.currentTab}});
      }
    },
    clickPaid: function () {
      let invoice_id = this.invoice.id;
      let data = {paid: this.paid, cardStatus: this.cardStatus, isUnassignCard: this.isUnassignCard};
      NProgress.start();
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/invoice/${invoice_id}/mark-as-paid`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: data,
      })
          .then(response => {
            if (response.status == 200) {
              if (response.data && response.data._status) {
                if (this.flagPrintReceipt) {
                  this.downloadReceipt(invoice_id);
                }
                this.goToInvoice();
              } else {
                if (response.data.msg) {
                  toastr.error(response.data.msg);
                }
              }
            }
          })
          .catch(error => {
            if (response.data.msg) {
              toastr.error(response.data.msg);
            }
          })
          .finally(() => {
            NProgress.done();
          });
    },
    downloadReceipt: function (invoice_id) {
      let token = localStorage.getItem('token');
      if (this.isExcessInvoice) {
        window.open(appConfig.baseAPIURL + `/fe/pdf/invoice/${invoice_id}/excess/receipt?at=${token}`);
      } else {
        window.open(appConfig.baseAPIURL + `/fe/pdf/invoice/${invoice_id}/receipt?at=${token}`);
      }
      return;
    },
    loadDataForWizard(invoice_id) {
      NProgress.start();
      Axios.get(`/fe/invoice/${invoice_id}/mark-as-paid`)
          .then(response => {
            this.card = response.data.card;
            this.invoice = response.data.invoice;

            this.loadCEStatuses();

            let today = new Date();
            let month = ('0' + (today.getMonth() + 1)).slice(-2);
            let day = ('0' + today.getDate()).slice(-2);
            let year = today.getFullYear();
            this.paid.amount = this.invoice.due;
            this.paid.due = this.invoice.due;
            this.paid.date = day + '/' + month + '/' + year;
            this.paid.paymentType = 'EFT';
            this.flagPrintReceipt = false;
          })
          .catch(error => {
            console.log(error);
            toastr.error("Invoice not found");
            //this.$router.push('/invoices');
          })
          .finally(() => {
            this.$nextTick(() => {
              NProgress.done();
            });
          });
    },
  },
  computed: {
    computedAssignedUser() {
      if (!(this.card && this.card.assignedTo)) {
        return '';
      }
      if (this.card.assignedTo.id == null) {
        return '';
      } else if (this.card.assignedTo.id == -1) {
        return 'Estimators';
      } else {
        return this.card.assignedTo.name;
      }
    },
    isExcessInvoice() {
      return !!this.invoice.isExcessInvoice;
    },
    computedCardStatus: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.ceStatuses, function (itm) {
            return itm.ce_status_id === vm.cardStatus;
          });
          return item;
        } catch (e) {
          return {};
        }
      },
      set: function (item) {
        this.cardStatus = item.ce_status_id;
      },
    },
  },
  mounted: function () {
    this.loadDataForWizard(this.$route.params.invoice_id);
    this.isMounted = true;
  },
  components: {
    VStepper,
    GoodWizard,
    DatePicker,
    NumberFormatter,
    Multiselect,
  }
};

</script>

<style>
.V3 .invoice-options-mark-as-paid .wizard__body table.b-table thead th {
  vertical-align: middle;
}

.V3 .invoice-options-mark-as-paid .wizard__body {
  padding: 15px;
  width: 100%;
  margin: 0 auto;
}

.V3 .invoice-options-mark-as-paid .wizard__buttons {
  width: 83%;
  margin: 0 0 70px 5px;
  padding-right: 15px;
  margin: 0 auto;
}

.V3 .wizard__buttons .pull-left {
  float: left !important;
  margin-right: 10px;
}

.V3 .wizard__buttons .pull-right {
  float: right !important;
  margin-left: 10px;
}

.V3 .wizard__buttons .btn {
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  height: 38px;
}

.V3 .wizard__buttons .button-cancel {
  padding: 10px 21px;
  background: #FFFFFF;
  border: 1px solid rgba(28, 31, 57, 0.25);
  color: #1C1F39;
}

.V3 .wizard__buttons .button-next {
  padding: 10px 15px;
  width: 83px;
}

.V3 .wizard__buttons .button-back {
  padding: 10px 26px;
  background: #FFFFFF;
  border-color: #5E79FF;
  width: 83px;
}
</style>

<style scoped>
.invoice-options-mark-as-paid-step-1 {
  width: 83%;
  margin: 0 auto;
}

.invoice-options-mark-as-paid-step-2 {
  width: 50%;
  margin: 0 auto;
  margin-top: 15px;
}

.invoice-mark-as-paid-step2-btn {
  width: 50% !important;
}

@media screen and (max-width: 835px) {
  .invoice-options-mark-as-paid-step-1,
  .invoice-options-mark-as-paid-step-2 {
    width: 100%;
  }

  .V3 .invoice-options-mark-as-paid .wizard__buttons,
  .invoice-mark-as-paid-step2-btn {
    width: 100% !important;
  }
}
</style>
