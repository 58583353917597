<template>
  <div style="margin-top: 15px; padding-left: 15px" class="card row no-gutter">
    <div class="col-lg-5 col-12 ">
      <div class="card-header bg-navyblue">
        <strong>Re-booking Settings</strong>
      </div>
      <p class="description-header"> Send your customer a notification to re-book their onsite estimate date.
      </p>
      <div class="card-block bg-white">

        <div class="form-group row no-gutter">
          <label class="col-sm-5 col-form-label">Send by Email</label>
          <div class="col-sm-7">
            <div class="d-flex" style="align-items: center">
              <toggle-button
                  v-model="value.estimatesBooking.reBooking.sendBySmsActive"
                  id="status"
                  class="mb-0 rs-toggle__button"
                  color="#5E79FF"
                  style="opacity: 1"
                  :sync="true"
                  :width="40"
                  :labels="false"/>
              <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                <template v-if="value.estimatesBooking.reBooking.sendBySmsActive">Active</template>
                                <template  v-else>Not Active</template>
                            </span>
            </div>
          </div>
        </div>

        <div class="form-group row no-gutter">
          <label class="col-sm-5 col-form-label">
            <p class="description-text">Add a personalized <br> message to send to your <br> customer.</p>
          </label>
          <div class="col-sm-7">
            <textarea  v-model="value.estimatesBooking.reBooking.emailPersonalizedMessage" style="height: 150px; width: 250px;"  :readonly="!isStatusActive" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="estimage-line"></div>
      <div class="card-block bg-white" style="margin-top: 15px">

        <div class="form-group row no-gutter">
          <label for="estimate_prefix" class="col-sm-5 col-form-label">Send by SMS</label>
          <div class="col-sm-7">
            <div class="d-flex" style="align-items: center">
              <toggle-button
                  v-model="value.estimatesBooking.reBooking.sendByEmailActive"
                  id="status"
                  class="mb-0 rs-toggle__button"
                  color="#5E79FF"
                  style="opacity: 1"
                  :sync="true"
                  :width="40"
                  :labels="false"/>
              <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                <template v-if="value.estimatesBooking.reBooking.sendByEmailActive">Active</template>
                                <template  v-else>Not Active</template>
                            </span>
            </div>
          </div>
        </div>

        <div class="form-group row no-gutter">
          <label class="col-sm-5 col-form-label">
            Use Template
            <p class="description-text">Choose a template form <br> your SMS templates. SMS`s <br> are charged at the standard
              <br> outgoing rate per SMS.</p>
          </label>
          <div class="col-sm-7">
            <multiselect
                :options="templates"
                v-model="computedBookingSmsId"
                :showLabels="false"
                :option-height="29"
                :max-height="203"
                placeholder="Choose SMS Template"
                :close-on-select="true"
                track-by="id"
                label="name"
            ></multiselect>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import _ from "lodash";
export default {
  name: "re-booking",
  components: {
    Multiselect
  },
  props: {
    value: {},
    templates: {
      type: Array,
      default() {
        return []      }
    }
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    }),
    computedBookingSmsId: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.templates, function (itm) {
            return itm.id === vm.value?.estimatesBooking?.reBooking?.smsTemplateId;
          });
          return item;
        } catch (e) {
          return {};
        }
      },
      set: function (item) {
        this.value.estimatesBooking.reBooking.smsTemplateId = item.id;
      },
    },
  }
}
</script>

<style scoped>
.estimage-line {
  width: 100%;
  height: 10px;
  right: 0;
  display: block;
  background: #e3e4e7;
}
.description-text {
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.description-header {
  padding: 15px 15px 0px 15px;
}
</style>
