<template>
  <div>
    <tabs>
      <div class="header no-gutter planner-header" style="display: block !important;">
        <div @click="/*hideDropdown*/" class="d-flex planer-date-period">
          <tab name="All" id="planner-all" :class-name="'tab-content-table'">
            <div class="d-flex" style="margin-right: 20px">
              <div class="weekly-target">
                <div style="margin-bottom: 5px; font-weight: 800">Weekly Totals Target: {{settings.targets.job | formatMoney}}
                </div>
                <div style="font-weight: 600">Current Totals: {{ getCurrentWeekTotal() | formatMoney }}</div>
              </div>
              <div class="d-flex">
                <div style="width: 70px">
                  <i class="bx bx-chevron-left planner-icon" @click="prevWeek"></i>
                  <i class="bx bx-chevron-right planner-icon" @click="nextWeek"></i>
                </div>
                <div class="planer-date-period-text-block">
                  <span class="planer-date-period-text">{{ computedWeekTitle }}</span>
                </div>
              </div>
            </div>
          </tab>
        </div>

        <div class="planner-header-menu-btn-block">
          <div class="planer-period-menu">
            <div class="dropdown dropdown-options show inline">
              <button type="button"
                      class="btn waves-effect waves-light ex-options-button"
                      id="dropdownMenuLink1"
                      data-toggle="dropdown"
                      style="width: 164px"
                      aria-haspopup="true"
                      aria-expanded="true">
                    Week
                <span class="dropdown-arrow pull-right"
                      style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                    <i class="fa fa-chevron-down pull-right"></i>
                    </span>
              </button>
            </div>
          </div>

          <div class="planer-categories-menu">
            <div class="dropdown dropdown-options show inline">
              <button type="button"
                      class="btn waves-effect waves-light ex-categories-button"
                      id="dropdownMenuLink3"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                      @click="/*toggleDropdown*/">
                Categories
                <span class="dropdown-arrow pull-right"
                      style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                      <i class="fa fa-chevron-down pull-right"></i>
                      </span>
              </button>
            </div>
          </div>

          <div class="planer-options-menu">
            <div class="dropdown dropdown-options show inline">
              <button type="button"
                      class="btn waves-effect waves-light ex-options-button"
                      id="dropdownMenuLink2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true">
                Options
                <span class="dropdown-arrow pull-right"
                      style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                      <i class="fa fa-chevron-down pull-right"></i>
                      </span>
              </button>
            </div>
          </div>
        </div>
        <planner-calendar
            ref="plannerCalendar"
            :current-week-dates="currentWeekDates"
            :ceStatuses="ceStatuses"
            :categoriesTotals="computedCategoriesTotals"
            :settings="settings"
            :events="computedEvents"
        ></planner-calendar>
      </div>
    </tabs>
  </div>
</template>

<script >
import dayjs from 'dayjs'

import {mapGetters} from "vuex";
import PlannerCalendar from "@/components/boards/planner/planner-calendar.vue";
import _ from "lodash";

export default {
  name: "planner",
  components: {PlannerCalendar},
  props: {
    ceStatuses: {
      type: Array,
    },
    settings: {
      type: Object,
    },
    events: {
      type:Array,
      default: []
    }
  },
  data() {
    return {
      weekCount: 0,
    }
  },
  methods: {
    getCurrentWeekTotal() {
      let a = 0;
      _.forEach(this.currentWeekDates, (d) => {
        a += this.getCurrentDayTotal(d.dayFormat)
      })
      return a
    },
    getCurrentDayTotal(d) {
      let a = 0
      let evts = _.filter(this.computedEvents, (e) => {
        return dayjs(d).isBetween( dayjs(e.dateStart), dayjs(e.dateEnd), null, '[]')
      })
      _.forEach(evts, (evt) => {
        let estimate = evt.estimate
        if (estimate && estimate.amountJob && Number(estimate.amountJob) && this.getDayAppr(evt)) {
          a += Number(estimate.amountJob) / this.getDayAppr(evt)
        }
      })
      return a
    },
    getDayAppr(event) {
      if (event?.estimate?.productionTime?.daysCount) {
        return Number(event.estimate.productionTime.daysCount)
      }
      if (event?.card?.daysCount) {
        return Number(event.card.daysCount)
      }

      return 0
    },
    nextWeek() {
      this.weekCount++
      if (this.$refs.plannerCalendar) {
        this.$nextTick(() =>{
          this.$refs.plannerCalendar.clearSelected()
        })
      }
    },
    prevWeek() {
      this.weekCount--
      if (this.$refs.plannerCalendar) {
        this.$nextTick(() =>{
          this.$refs.plannerCalendar.clearSelected()
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      getterPlannerBoardType: 'getPlannerBoardType',
    }),
    computedEvents() {
      let events = _.filter(this.events, (ev) => {
        return ev.type == 'vehicle' && (dayjs(ev.dateStart).isBetween( this.computedWeekStart.format('YYYY-MM-DD'),  this.computedWeekEnd.format('YYYY-MM-DD'), null, '[]')
            || dayjs(ev.dateEnd).isBetween( this.computedWeekStart.format('YYYY-MM-DD'),  this.computedWeekEnd.format('YYYY-MM-DD'), null, '[]')
            || dayjs(this.computedWeekStart.format('YYYY-MM-DD')).isBetween( ev.dateStart, ev.dateEnd, null, '[]'))
      })
      return events;
    },
    computedCategoriesTotals() {
      let r = []
      _.forEach(this?.settings?.definitions?.categories, (c) => {
        let events = _.filter(this.computedEvents, (e) => {
          return e?.estimate?.productionTime?.category == c.id || c.colorId == e?.groupId
        })
        r.push({
          ...c,
          events: events,
          eventsCount: events.length,
        })
      })

      let events = _.filter(this.computedEvents, (e) => {
        return !e?.estimate?.productionTime?.category && !e?.groupId
      })
      r.push({
        id: 'Uncategorised',
        name: 'Uncategorised',
        events: events,
        qty: 0,
        eventsCount: events.length,
      })
      return r
    },
    computedWeekStart() {
      let currentMonday = dayjs().day(1)

      if (this.weekCount < 0) {
        currentMonday = currentMonday.subtract(Math.abs(this.weekCount) * 7, 'day')
      } else if (this.weekCount > 0) {
        currentMonday = currentMonday.add(this.weekCount * 7, 'day')
      }
      return currentMonday
    },
    computedWeekEnd() {
      let end = this.computedWeekStart.add(13, 'day')
      for (let i = 0; i < 7; i++) {
        let r = end.subtract(i, 'day')
        if (this.settings.days[r.day()]) {
          return r
        }
      }
    },
    currentWeekDates() {
      let r = []
      let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      for (let i = 0; i < 14; i ++) {
        if (this.settings.days[this.computedWeekStart.add(i, 'day').day()]) {
          let d = this.computedWeekStart.add(i, 'day')
          r.push({
            date: d.get('date'),
            weekD: days[d.day()],
            dayFormat: d.format('YYYY-MM-DD'),
          })
        }
      }
      return r
    },
    computedWeekTitle() {
      let m1 = this.computedWeekStart.month()
      let m2 = this.computedWeekEnd.month()
      let y1 = this.computedWeekStart.year()
      let y2 = this.computedWeekEnd.year()

      if (m1 !== m2 && y1 !== y2) {
        return this.computedWeekStart.format('DD MMM') + ' ' + y1 + ' - ' + this.computedWeekEnd.format('DD MMM') + ' ' + y2
      } else if (m1 !== m2) {
        return this.computedWeekStart.format('DD MMM') + ' - ' + this.computedWeekEnd.format('DD MMM') + ' ' + y2
      }
      return this.computedWeekStart.format('DD') + ' - ' + this.computedWeekEnd.format('DD MMM') + ' ' + y2
    },
  }
}
</script>

<style scoped>
.weekly-target{
  margin-right: 50px;
}
.planer-date-period-text-block{
  width: 350px;
}
.planer-date-period {
  white-space: nowrap;
  position: absolute;
  left: 310px;
}
.V3 .V3.compact-sidebar .planer-date-period{
  left: 160px;
}
.planner-header-menu-btn-block{
  justify-content: flex-end;
}
.planer-period-menu{
  padding-right: 15px;
  padding-left: 15px;
}


@media (max-width: 960px) {
  .weekly-target {
    font-size: 10px;
  }
}

</style>
