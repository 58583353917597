<template>
    <div>
        <span style="position: relative; display: block;">
            <input
                    type="email"
                    class="input-field form-control"
                    :class="{'error': !isCheckEmail}"
                    placeholder="Enter your email address"
                    name="userEmail"
                    v-model="userEmail"
                    @input="checkEmail()"
            />
            <vue-simple-spinner  v-if="isLoading" :size="26"  class="input-spinner" />
         </span>
        <div class="w-100t">
            <span v-if="!isCheckEmail" class="error-text">
                <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;We could not find an account with that email address.
            </span>
        </div>

    </div>
</template>

<script>
    import Spinner from 'vue-simple-spinner'
    import Axios from "axios";
    import _ from "lodash";

    export default {
        name: "Email",
        data() {
            return {
                userEmail: this.value,
                isCheckEmail: true,
                isLoading: false,
            };
        },
        props: {
            value: {
                type: String,
                default: ''
            }
        },
        methods: {
            sendRequest() {
                //NProgress.start();
                Axios({url: '/auth/check-email', data: {email: this.userEmail}, method: 'POST'})
                    .then(resp => {
                        this.isCheckEmail = !(resp && resp.data && resp.data.status === false);
                        let email = (this.isCheckEmail) ? this.userEmail : '';
                        if (this.isCheckEmail) {
                            this.$emit('checkedEmail', this.isCheckEmail);
                        }
                        this.$emit('input', email);
                    })
                    .catch(err => {
                        console.log(err);
                    }).finally(() => {
                    //NProgress.done();
                    this.isLoading = false;
                });
            },
            checkEmail() {
                if (this.userEmail == '') {
                    return;
                }
                this.isLoading = true;
                this.debouncedSendRequest();
                return;
                this.isLoading = true;
                this.userEmail = value;
                let email = value.split('@');

                if (email.length != 2 || email[0].length < 1 || email[1].length < 3) {
                    return;
                }

                NProgress.start();
                Axios({url: '/auth/check-email', data: {email: this.userEmail}, method: 'POST'})
                    .then(resp => {
                        this.isCheckEmail = (resp && resp.data && resp.data.status === true);
                        let email = (this.isCheckEmail) ? this.userEmail : '';
                        if (this.isCheckEmail) {
                            this.$emit('checkedEmail', this.isCheckEmail);
                        }
                        this.$emit('input', email);
                    })
                    .catch(err => {
                        console.log(err);
                    }).finally(() => {
                    NProgress.done();
                });
            },
        },
        created: function () {
            this.debouncedSendRequest = _.debounce(this.sendRequest, 500);
        },
        components:{
            'vue-simple-spinner': Spinner,
        }

    };
</script>

<style scoped>
    .input-spinner {
        position: absolute;
        top: 8px;
        right: 10px;
        z-index: 10;
        color: #5E79FF;
    }

    input::placeholder {
        color: #ADADAD !important;
        font-size: 16px !important;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        opacity: 1 !important;
        padding-left: 2px;
    }

    .V3 .login input {
        padding-left: 15px;
        color: black !important;
    }

    .form-control {
        font-family: "Nunito Sans";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        padding: 9px 13px;
        border: 1px solid rgba(27, 30, 56, 0.2) !important;
        border-radius: 4px !important;
        height: 43px !important;
        color: #1C1F39;
    }
</style>
