import {Actions} from './actions';
import {Mutations} from './mutations';
import {Getters} from './getters';

const state = {
    items: [],
    timestamp: 0,
    sortByForInvoicesAll: '',
    sortDescForInvoicesAll: false,
    sortByForInvoicesAwaitingPayment: '',
    sortDescForInvoicesAwaitingPayment: false,
    sortByForInvoicesPaid: '',
    sortDescForInvoicesPaid: false,
    invoicesPerPage: 50,
    invoicesAllCurrentPage: 1,
    invoicesAwaitingPaymentCurrentPage: 1,
    invoicesPaidCurrentPage: 1,
    searchFilterObj: {
        search: '',
    },
    token: [],
    tokens: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        // 7: [],
    },
};

export default {
    namespaced: true,
    mutations: Mutations,
    actions: Actions,
    state,
    getters: Getters,
};
