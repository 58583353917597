import { render, staticRenderFns } from "./Webtrim.vue?vue&type=template&id=ddccc1ce&scoped=true"
import script from "./Webtrim.vue?vue&type=script&lang=js"
export * from "./Webtrim.vue?vue&type=script&lang=js"
import style0 from "./Webtrim.vue?vue&type=style&index=0&id=ddccc1ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddccc1ce",
  null
  
)

export default component.exports