<script>
    import Axios from 'axios';

    export default {
        name: 'part-view',
        data: function () {
            return {
                parts: [],
                fields: [
                    {label: 'Part Name', key: 'part_name', sortable: true, tdClass: 'text-center clickable number', thClass: 'text-center bg-navyblue number'},
                    {label: 'Part Nbr', key: 'part_number', sortable: true, tdClass: 'clickable customer', thClass: 'bg-navyblue customer'},
                    {label: 'Type', key: 'type', sortable: true, tdClass: 'text-center clickable rego', thClass: 'text-center bg-navyblue rego'},
                    {label: 'Qrd Qty', key: 'qrd_qty', sortable: true, tdClass: 'text-center clickable make', thClass: 'text-center bg-navyblue make'},
                    {label: 'Rcv Qty', key: 'rcv_qty', sortable: true, tdClass: 'text-center clickable model', thClass: 'text-center bg-navyblue model'},
                    {label: 'Status', key: 'status', sortable: true, tdClass: 'text-center clickable colour', thClass: 'text-center bg-navyblue colour'},
                    {label: 'Loc', key: 'loc', sortable: true, tdClass: 'text-center clickable created-on', thClass: 'text-center bg-navyblue created-on'},
                    {label: 'Price', key: 'price', sortable: true, tdClass: 'text-center clickable created-on', thClass: 'text-center bg-navyblue created-on'},
                ],
                columns: [
                    {label: "Part Name", data: "part_name", className: 'text-center clickable'},
                    {label: "Part Nbr", data: "part_number", className: 'text-center clickable'},
                    {label: "Type", data: "type", className: 'clickable'},
                    {label: "Qrd Qty", data: "qrd_qty", className: 'text-center clickable'},
                    {label: "Rcv Qty", data: "rcv_qty", className: 'text-center clickable'},
                    {label: "Status", data: "status", className: 'text-center clickable'},
                    {label: "Loc", data: "loc", className: 'text-center clickable'},
                    {label: "Price", data: "price", className: 'text-center clickable'},
                ],
            };
        },
        computed: {

        },
        methods: {
            loadParts: function (estimate_id) {
                NProgress.start();
                Axios.get(`/fe/parts/ordered/${estimate_id}`)
                    .then(response => {
                        this.parts = response.data;
                        NProgress.done();
                    })
                    .catch(error => {
                        console.log(error);
                        NProgress.done();
                    });
            }

        },
        mounted: function () {
            this.loadParts(this.$route.params.estimate_id);
        },
        components: {
            //   EstimatesEdit,
            //   Paginate,
            //   DataTables
        }
    };
</script>

<template>
    <div>
        <div class="page-header">
        <h4>View Part </h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
            <li class="breadcrumb-item active">View Parts</li>
        </ol>
        </div>
        <div class="row">
            <div class="col-md-12">

                <div class="box box-block bg-white estimates">
                    <tabs>
                        <tab name="Ordered" id="estimates-ordered">
                            <b-table
                                    responsive
                                    hover
                                    :items="parts" :fields="fields" ></b-table>
                            <div class="row">
                                <div class="col-md-6">

                                </div>
                                <div class="col-md-6 text-md-right">

                                </div>
                            </div>
                            <!-- <data-tables id="estimates-table-active" :tableColumns="columns" :tableData="estimatesActive" :tableOrder="[]" @onRowClick="editEstimate" class="table table-striped table-bordered dataTable"></data-tables> -->
                        </tab>


                    </tabs>
                </div>

            </div>

        </div>
    </div>
</template>

<style scoped>
    tbody tr {
        cursor: pointer;
    }
</style>
