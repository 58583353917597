console.log(process.env)

export const appConfig = {
    baseAPIURL: process.env.VUE_APP_BaseAPIURL,
    // baseAPIURL: 'https://api.stg.repair-shop.com.au',
    stripePublicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY || 'pk_test_51KiQqoS4408va4DFWOZwlP8JgYOszccdNUGR5GBu4PuFo7sx8X6jZe07tlEo3uUtYahbxrFSBDWviO5ApD8DOqNd00I8jG2lmP', // 'pk_test_51LaGyMKy1JdZHImYQafe3OkgstEkzIqUAHasXIj7yItQelnoJI8szbGohUw2LEmNgkTkCRnpD5iMPTMpP9I8gwZG00WR1iKC48',
    appURL: process.env.VUE_APP_APPURL ? process.env.VUE_APP_APPURL : 'https://app.stg.repair-shop.com.au',
    homeURL: process.env.VUE_APP_HomeURL ? process.env.VUE_APP_HomeURL : 'https://stg.repair-shop.com.au',
    appPartsSearchURL: process.env.VUE_APP_APP_PARTS_SEARCH_URL ? process.env.VUE_APP_APP_PARTS_SEARCH_URL : 'https://stg.partssearch.com.au',
    isHideOldLogin: !!(process.env.VUE_APP_isHideOldLogin && process.env.VUE_APP_isHideOldLogin === 'true'),
    appName: process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : 'Repair-Shop.com.au',
    estimageOnlineAppUrl: process.env.VUE_APP_ESTIMAGE_ONLINE_APP_URL ? process.env.VUE_APP_ESTIMAGE_ONLINE_APP_URL : 'https://repairer.estimage.com',
};

