<template>
  <div class="tl-content box box-block display-block" style="background-color: #FAFAFA;">
    <span class="arrow left" style="border-color: #FAFAFA;"></span>
    <div class="table-and-actions">
      <table class="activity-table">

        <template v-if="a.action_code == 'EstimageOnlineNewRfq'">
          <tr>
            <td>Request for Quote</td>
          </tr>
          <tr>
            <td>Insurance Claim Number</td>
            <td v-if="a && a.info">{{a.info.insuranceClaimNumber}}</td>
          </tr>
          <tr>
            <td>Received</td>
            <td>Success</td>
          </tr>
          <tr>
            <td>Sent by</td>
            <td>Estimage Online</td>
          </tr>
        </template>

        <template v-if="a.action_code == 'EstimageOnlineRepairsAuthorized'">
          <tr>
            <td>Estimate Update Received</td>
            <td>ESTIMAGE ONLINE</td>
          </tr>
          <tr>
            <td>Insurance Claim Number</td>
            <td v-if="a && a.info">{{a.info.insuranceClaimNumber}}</td>
          </tr>
        </template>

        <template v-if="a.action_code == 'EstimageOnlineRepairsCancelled'">

        </template>

        <template v-if="a.action_code == 'EstimageOnlineRegoUpdated'">

        </template>

        <template v-if="a.action_code == 'EstimageOnlineExcessUpdate'">

        </template>

        <template v-if="a.action_code == 'EstimageOnlineInvoiceExported'">

        </template>

        <template v-if="a.action_code == 'EstimageOnlineQuoteSent'">
          <tr>
            <td>Estimate Sent To</td>
            <td>ESTIMAGE ONLINE</td>
          </tr>
          <tr>
            <td>Job ID</td>
            <td v-if="a && a.info">{{a.info.jobId}}</td>
          </tr>
          <tr>
            <td>Insurance Claim Number</td>
            <td v-if="a && a.info">{{a.info.insuranceClaimNumber}}</td>
          </tr>
          <tr>
            <td>Sent Status</td>
            <td>Success</td>
          </tr>
          <tr>
            <td>Sent by</td>
            <td v-if="a && a.info">{{a.info.sentBy}}</td>
          </tr>
        </template>

        <tr class="tl-date text-muted mt-1">
          <td>Date</td>
          <td><span v-html="a.entry_date.replaceAll('-', '/')"></span> - <span v-html="a.entry_time"></span></td>
        </tr>

      </table>
      <div v-if="isStatusActive"
           class="navbar-nav nav dropdown dropdown-options show float-sm-right text-right additional-action-menu-style"
           style="margin-right:1.25rem; position: absolute; right: 7px">
                 <span class="btn ml-0-25 waves-effect waves-light ex-options-button additional-action-menu-style"
                       style="margin-top: -10px; padding-left: 10px !important;" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="true">Actions
                    <span class="dropdown-arrow  pull-right"
                          style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);"><i
                        class="fa fa-chevron-down pull-right-action"></i></span>
                 </span>
        <div class="dropdown-menu dropdown-menu-right animated fadeInUp drop-down-menu-actions">
          <div class="dropdownLinks-actions-links">
            <a v-if="viewed == 1" @click="markAsUnRead(activity)"
               class="dropdown-item text-primary clickable dropdown-action-item dropdown-action-item-with-icon"><i
                class="fa fa-eye-slash activtyPopupIcon" aria-hidden="true"></i> Mark As Unread</a>
            <a v-else @click="markAsRead(activity)"
               class="dropdown-item text-primary clickable dropdown-action-item dropdown-action-item-with-icon"><i
                class="fa fa-eye activtyPopupIcon" aria-hidden="true"></i> Mark As Read</a>
          </div>
          <div class="dropdownLinks-actions-links">
            <a v-if="isProductionManagerUser || isShopManagerUser || isAdministrator"
               @click="$refs.deleteActivityModal.show()"
               class="dropdown-item text-primary clickable dropdown-action-item">Delete Entry</a>
          </div>
        </div>
      </div>
      <modal-wizard ref="deleteActivityModal" cancelButtonText="Cancel" finishButtonText="Ok"
                    @on-complete="deleteActivity" class="delete-activity-modal">
        <modal-wizard-tab title="Delete Activity" description="" title-icon-class="ti-align-right">
          <template>
            <div class="pt-2 pb-2">Do you want to Delete this Activity list entry?</div>
          </template>
        </modal-wizard-tab>
      </modal-wizard>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'activity-list-eio',
  props: ['viewed', 'activity', 'index', 'cardId', 'viewed',],
  data: function () {
    return {
      a: this.activity,
      isMinimized: !!this.viewed,
    };
  },
  methods: {
    deleteActivity: function () {
      this.$emit('deleteActivity', this.a.activity_id);
      this.$refs.deleteActivityModal.hide();
    },
    markAsUnRead: function (a) {
      this.$emit('unread', a);
    },
    markAsRead: function (a) {
      this.$emit('read', a);
    },
  },
  computed: {
    ...mapGetters({
      isShopManagerUser: 'isShopManagerUser',
      isProductionManagerUser: 'isProductionManagerUser',
      isAdministrator: 'isAdministrator',
      isStatusActive: 'isStatusActive',
    }),
  },
  watch: {},
  components: {},
  mounted: function () {
  }
};
</script>

<style scoped>
.dropdown .dropdownLinks-links .dropdown-action-item {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 38px;
  padding: 10px 14px 8px 14px;
  clear: both;
  color: #1C1F39;
  text-align: inherit;
  white-space: normal;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  background: 0 0;
  border: 0;
  cursor: pointer;
}

.dropdown .dropdownLinks-links .dropdown-action-item:hover {
  background-color: rgba(94, 121, 255, 0.1);
  color: #5E79FF;
  font-weight: bold;
}

.dropdownLinks-actions-links {
  width: 115px;
}

.drop-down-menu-actions {
  width: 115px !important;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgb(27 30 56 / 15%);
  min-width: 115px !important;
  margin: -13px -14px 0 !important;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  z-index: 110;
}

.dropdown-action-item {
  text-align: center !important;
  min-height: 20px !important;
}

.pull-right-action {
  padding-right: 3px !important;
  margin-left: 10px;
}
</style>
