import _ from 'lodash'

export const Mutations = {
  setToken(state, ui) {
    state.token = ui;
  },
  setSnapshotDateFilter(state, ui) {
    state.snapshotDateFilter = ui;
  },
  setSearchFilterObj (state, ui) {
    state.searchFilterObj = ui;
  },
  setSortBy (state, ui) {
    state.sortBy = ui
  },
  setSortDesc (state, ui) {
    state.sortDesc = ui
  },
  setEstimatesPerPage (state, ui) {
    state.estimatesPerPage = ui
  },
  setEstimatesAllCurrentPage (state, ui) {
    state.estimatesAllCurrentPage = ui
  },
  loadEstimates (state, ui) {
    state.items = _.unionBy(ui, state.items, 'estimateId')
    console.log('LOADED.loadEstimates')
    return;
    // let data = _.union(ui, state.items)
    // let res = _.uniqWith(data, function (first, second) {
    //   return (first.estimateId != second.estimateId) ? false : true
    // })

    // res = _.map(res, (i) => {
    //   i.estimate_id = Number(i.estimate_id)
    //   return i
    // })

    // state.items = res
    // console.log('LOADED.loadEstimates')
  },
  setTimestamp (state, timestamp = 0) {
    console.log('timestamp', timestamp)
    state.timestamp = timestamp
  },
  logout (state) {
    state.timestamp = 0
    state.items = []
  },
  estimatesUpdated (state, ui) {
    // let newEstimates = _.map(ui, (i) => {
    //   i.estimate_id = Number(i.estimate_id)
    //   return i
    // })
    state.items = _.unionBy(ui, state.items, 'estimateId')
  },
  deleteEstimateById (state, id) {
    let estimate = _.filter(state.items, function (estimate) {
      return parseInt(estimate.estimateId) === parseInt(id)
    })

    for (let i = 0; i < estimate.length; i++) {
      let index = state.items.indexOf(estimate[i])
      state.items.splice(index, 1)
    }
  },
  deleteEstimateByCardId (state, id) {
    let estimate = _.filter(state.items, function (estimate) {
      return parseInt(estimate.card_id) === parseInt(id)
    })

    for (let i = 0; i < estimate.length; i++) {
      let index = state.items.indexOf(estimate[i])
      state.items.splice(index, 1)
    }
  }
}
