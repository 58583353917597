import _ from "lodash";

export const Mutations = {
    deleteInvoiceById(state, id){
        let invoice = _.filter(state.items, function(i) {
            return i.id == id;
        });
        let index = state.items.indexOf(invoice[0]);
        state.items.splice(index, 1);
    },
    setSearchFilterObj(state, ui) {
        state.searchFilterObj = ui;
    },
    setTokensNew(state, ui) {
        state.tokens = ui
    },
    setToken(state, ui) {
        state.token.splice(0, state.token.length);
        _.forEach(ui, itm => {
            state.token.push(itm)
        })
    },
    setTokens(state, ui) {
        for (let key in state.tokens) {
            delete state.tokens.key
        }
        for (let key in ui) {
            state.tokens[key] = ui[key];
        }
    },
    setSortByForInvoicesAll(state, ui) {
      state.sortByForInvoicesAll = ui;
    },
    setSortDescForInvoicesAll(state, ui) {
        state.sortDescForInvoicesAll = ui;
    },
    setSortByForInvoicesAwaitingPayment(state, ui) {
        state.sortByForInvoicesAwaitingPayment = ui;
    },
    setSortDescForInvoicesAwaitingPayment(state, ui) {
        state.sortDescForInvoicesAwaitingPayment = ui;
    },
    setSortByForInvoicesPaid(state, ui) {
        state.sortByForInvoicesPaid = ui;
    },
    setSortDescForInvoicesPaid(state, ui) {
        state.sortDescForInvoicesPaid = ui;
    },
    setInvoicesPerPage(state, ui) {
        state.invoicesPerPage = ui;
    },
    setInvoicesAllCurrentPage(state, ui) {
        state.invoicesAllCurrentPage = ui;
    },
    setInvoicesAwaitingPaymentCurrentPage(state, ui) {
        state.invoicesAwaitingPaymentCurrentPage = ui;
    },
    setInvoicesPaidCurrentPage(state, ui) {
        state.invoicesPaidCurrentPage = ui;
    },
    loadInvoices(state, ui) {
        let data = _.union(ui, state.items);
        let res = _.uniqWith(data, function (first, second) {
            return (first.id != second.id) ? false : true;
        });
        res = _.map(res, (i) => {
            i.id = Number(i.id);
            return i;
        });
        state.items = res;
        console.log('LOADED.loadInvoices')
    },
    setTimestamp(state, timestamp = 0) {
        console.log('timestamp', timestamp);
        state.timestamp = timestamp;
    },
    logout(state) {
        state.timestamp = 0;
        state.items = [];
    },
    invoicesUpdated (state, ui) {
      let newInvoices = _.map(ui, (i) => {
            i.id = Number(i.id);
            return i;
        });
      state.items = _.unionBy(newInvoices, state.items, "id");
    }
}
