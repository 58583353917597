<script>
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import toastUi from './toastui'
import draggable from 'vuedraggable'
import { isMobile } from '../../deviceDetect/index'
import Vue from 'vue'
import {mapGetters} from "vuex";
Vue.directive('downloadImage', {
  bind: (el, binding, vnode) => {
    el.onclick = () => {
      binding.value()
    }
  },
  inserted: (dir)=> {
  }
})
Vue.directive('editImage', {
  bind: (el, binding, vnode) => {
    el.onclick = () => {
      binding.value()
    }
  },
  inserted: (dir)=> {
  }
})
const _buttonsWrap = Vue.component('download-edit', {
    data: () => {
      return {
        isMobile
      }
    },
    template:'<div class="download_edit_wrap">' +
      '<button v-download-image="()=>someFunc()" class="btn btn-primary">Download</button> <button v-edit-image="()=>openEditor()" class="btn btn-secondary">Edit</button>' +
      '</div>',
    methods: {
      someFunc (){
        console.log(1)
      },
      openEditor (){
        console.log('edit')
      }
    },
    created(){
        this.$on('download-image', event => {
          this.someFunc()
        })
    },
})
const DownloadEditButton = Vue.extend(_buttonsWrap)
const _instance = new DownloadEditButton()
_instance.$mount() // pass nothing

export default {
	name: 'image-lightbox',
	props: ['imageList', 'path', 'position', 'fromComponent', 'isPreviewImage'],
    data: function (){
        return {
            imagePath: null
        }
    },
    computed:{
      ...mapGetters({
        isStatusActive: 'isStatusActive',
      })
    },
    methods: {
        recoverToUpperCase: function(item){
            return  item.replace(/_/g, ' ').split(' ').map(_ => _.replace(_.charAt(0), _.charAt(0))).join(' ')
        },
        isDraggedIMG: function (evt){
            if(evt.to.classList.contains('no_images_drag_area') && evt.to.children.length){
              evt.to.classList.remove('no_images_drag_area')
            }
            if(!evt.from.classList.contains('no_images_drag_area') && evt.from.children.length === 0){
                evt.from.classList.add('no_images_drag_area')
            }
            let _getAttr = evt.item.getAttribute('data-itemArray').split(',')
          let _item_info = this.imageList[_getAttr[1]].filter(e => e.id === _getAttr[0])
          this.$emit('updateCategoryFunc', {imgInfo:(_item_info) ? _item_info[0] : null, categoryName:evt.to.getAttribute('data-status-wrap')})
        }
    },
	mounted: function () {
	    console.log('preview');
	    console.log(this.isPreviewImage);
	    console.log(this.fromComponent);
		var vm = this
		$(this.$el)
		.magnificPopup({
			delegate: 'a',
			type: 'image',
			tLoading: 'Loading image #%curr%...',
			mainClass: 'mfp-img-mobile',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0,1] // Will preload 0 - before current, and 1 after the current image
			},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
				titleSrc: function(item) {
					return '';
				}
			},
            callbacks: {
                open: function () {
                  document.querySelector('.mfp-bottom-bar').appendChild(_instance.$el)
                },
                elementParse: function(item) {
                  vm.imagePath = item.src || null
                }
            }
		})
	},
	destroyed: function () {
		$(this.$el).off().magnificPopup('destroy')
	},
    components:{
        draggable,
        'toast-ui-editor':toastUi
    }
}

</script>

<template>
<div class="col-xs-12" :class="position">
    <toast-ui-editor v-if="imagePath" :pathOpenedPhoto="imagePath"></toast-ui-editor>
    <template v-if="fromComponent && (fromComponent === 'estimate' || fromComponent === 'card')">
        <div v-for="(item, index) in Object.keys(imageList)" class="each_row_images">
            <h3 class="categories_title">{{recoverToUpperCase(item)}}</h3>
            <draggable
                    :data-status-wrap="recoverToUpperCase(item)"
                    :class="{'no_images_drag_area': imageList[item].length === 0}"
                    element="div"
                    :disabled="!isStatusActive"
                    :options="{
                      animation:200,
                      group:'people',
                      delay: isMobile ? 400 : false
                    }"
                    @start="drag=true"
                    @end="isDraggedIMG"
                    list:item>
                    <div :data-itemArray="[img.id, item]" :key="ind" v-for="(img, ind) in imageList[item]" class="item_div_drag col-4">
                        <div v-if="img.src" class="box box-block bg-grey">
                            <button @click="$emit('removeImage', {key:item, id:img.id})" class="delete" type="button"><i class="ti-trash" title="Remove this image"></i></button>
                            <a :href="img.src.lastIndexOf('data',0) === 0 ? img.src : path + img.src" v-if="isPreviewImage">
                                <img :src="img.previewW220.lastIndexOf('data',0) === 0 ? img.previewW220 : path + img.previewW220" class="wrap img-fluid">
                            </a>
                            <a :href="img.src.lastIndexOf('data',0) === 0 ? img.src : path + img.src" v-else>
                                <img :src="img.src.lastIndexOf('data',0) === 0 ? img.src : path + img.src" class="wrap img-fluid">
                            </a>
                        </div>
                    </div>
            </draggable>
        </div>
    </template>
    <template v-else>
        <div v-for="(img, index) in imageList" class="col-sm-3" :key="index">
		      <div v-if="img" class="box bg-grey img-box">
            <button @click="$emit('removeImage', index)"
                    class="action-button"
                    type="button"
                    v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip--bottomleft', html: true, title:'Remove this image', placement:'bottomleft'}">
              <svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="pointer-events: none">
                <!-- Generator: sketchtool 60.1 (101010) - https://sketch.com -->
                <title>EFD4E02B-E531-48FD-903C-7C1EC7568772</title>
                <desc>Created with sketchtool.</desc>
                <g id="UI-Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Dashboard-Redesign-(Detail-Cards)Files" transform="translate(-1264.000000, -933.000000)" fill="#FF5E5E" fill-rule="nonzero">
                    <g id="Group-4" transform="translate(265.000000, 933.000000)">
                      <g id="trash" transform="translate(999.000000, 0.000000)">
                        <path d="M6,2 L6,1 C6,0.44771525 6.44771525,0 7,0 L11,0 C11.5522847,0 12,0.44771525 12,1 L12,2 L16,2 C17.1045695,2 18,2.8954305 18,4 L18,5 C18,6.1045695 17.1045695,7 16,7 L15.867,7 L15.187,17.2 C15.081727,18.775582 13.773095,20 12.194,20 L5.826,20 C4.24834545,20 2.94028051,18.7780028 2.833,17.204 L2.137,7 L2,7 C0.8954305,7 0,6.1045695 0,5 L0,4 C0,2.8954305 0.8954305,2 2,2 L6,2 Z M16,4 L2,4 L2,5 L16,5 L16,4 Z M4.141,7 L4.828,17.068 C4.86376888,17.5927957 5.29998676,18.0001655 5.826,18 L12.194,18 C12.7207338,18 13.1572356,17.5915853 13.192,17.066 L13.862,7 L4.142,7 L4.141,7 Z M7,8 C7.55228475,8 8,8.44771525 8,9 L8,16 C8,16.5522847 7.55228475,17 7,17 C6.44771525,17 6,16.5522847 6,16 L6,9 C6,8.44771525 6.44771525,8 7,8 Z M11,8 C11.5522847,8 12,8.44771525 12,9 L12,16 C12,16.5522847 11.5522847,17 11,17 C10.4477153,17 10,16.5522847 10,16 L10,9 C10,8.44771525 10.4477153,8 11,8 Z" id="Shape"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>

            <a :href="img.lastIndexOf('data',0) === 0 ? img : path + img">
              <img :src="img.lastIndexOf('data',0) === 0 ? img : path + img">
            </a>
          </div>
        </div>
    </template>
</div>
</template>
<style>
    .download_edit_wrap{
        line-height: normal;
        position: relative;
        text-align: right;
    }
    .download_edit_wrap button{
        cursor: pointer;
        margin: 0 0 0 10px;
        min-width: 83px;
    }
    .download_edit_wrap .download_btn{
        background-color: #142742;
    }
</style>
<style scoped>
  div.box {
      text-align: center;
  }
  div.box img {
      /*display: block;*/
      height: 9rem;
  }
  .img-box {
    position: relative;
    width: 100%;
    height: 250px;
  }
  .img-box a {
    display: inline-block;
    height: 250px;
  }
  .img-box a img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .img-box:hover button.delete {
    background-color: #efefef;
    bottom: 0;
    right: 0;
    position: absolute;
    padding: 0;
    margin-bottom: -4px;
    font-size: 1.5em;
    opacity: 1;
    border: none;
    color: #ff0000;
    cursor: pointer;
    padding: 3px 3px 0 3px;
  }
    button.delete {
      bottom: 0;
      right: 0;
      position: absolute;
      opacity: 0;
    }
    .each_row_images > div{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -8px;
        min-height: 140px;
    }
    .each_row_images > div .item_div_drag{
        width: 25%;
        padding: 0 8px;
    }
    .pin_images .each_row_images > div .item_div_drag{
        width: 100%;
        margin-bottom: 0;
        margin-top: 15px;
    }
    .pin_images .each_row_images > div .item_div_drag:first-child {
      margin-top: 0;
    }
    .pin_images .box {
      margin-bottom: 0;
    }
    @media screen and (max-width: 992px) and (min-width:576px) {
        .each_row_images > div .item_div_drag{
            width: 33.3%;
        }
    }
    @media screen and (max-width: 576px) {
        .each_row_images > div .item_div_drag{
            width: 100%;
        }
    }
    .each_row_images{
        margin-top: 15px;
    }
    .categories_title:after{
        content: "";
        position: absolute;
        left: 0px;
        bottom: -10px;
        width: 100%;
        height: 2px;
        background: #e5e5e5;
    }
    .categories_title {
        margin: 0 0 20px 0;
        position: relative;
        font-weight: 400;
        text-transform: capitalize;
    }
    .pin_images .categories_title:after{
        left: 0;
        display: none;
    }
    .pin_images .categories_title{
        margin-bottom: 8px;
        padding-left: 0;
        opacity: 0.5;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
        text-transform: uppercase;
    }
    .pin_images button.delete {
      display: none;
    }
    .pin_images div.box {
      padding: 0;
      text-align: left;
    }
    .pin_images .img-fluid {
      width: 100%;
      height: auto;
      border-radius: 5px;
      border-width: 0;
    }
    .pin_images .no_images_drag_area {
      height: 160px;
      width: 100%;
      border-radius: 5px;
      background-color: #FFFFFF;
      border-width: 0;
      margin: 0;
    }
    .pin_images .no_images_drag_area::after {
      content: "Empty";
      vertical-align: middle;
      text-align: center;
      opacity: 0.5;
      color: #1C1F39;
      font-size: 14px;
      line-height: 19px;
      margin: auto;
    }
    .pin_images {
      padding: 0;
    }
    .no_images_drag_area {
       background: #f9f9f9;
    }
</style>

<style>
  .V3 .estimates-template .img-box .action-button {
    border: 0;
    position: absolute;
    display: block;
    opacity: 0;
    transition: opacity 250ms cubic-bezier(0.0, 0.0, 0.2, 1);
    height: 40px;
    width: 40px;
    top: 18px;
    right: 5px;
    border-radius: 3px;
    overflow: hidden;
    padding-top: 7px;
  }

  .V3 .estimates-template .img-box:hover .action-button {
    opacity: 1;
    background-color: #efefef;
  }

  .V3 .estimates-template .action-button:hover {
    cursor: pointer;
    background-color: #f8f8f8;
  }
</style>
