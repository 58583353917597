<script>
    import Axios from 'axios';

    export default {
        name: 'invoice-excess-new',
        data: function () {
            return {
            };
        },
        methods: {
            loadInvoice: function (card_id) {
                NProgress.start();
                Axios.get(`/fe/invoice/new/${card_id}/excess`)
                    .then(response => {
                        NProgress.done();
                        if (response.data.status && response.data.id > 0){
                            this.$router.push('/invoice/view/'+response.data.id+'/excess');
                        }else{
                            this.$router.push('/invoices');
                        }
                    })
                    .catch(error => {
                        toastr.error("Error");
                        NProgress.done();
                        this.$router.push('/invoices');
                    });
            },
        },
        mounted: function () {
            this.loadInvoice(this.$route.params.card_id);
        },

    };
</script>
<template>
    <div>

    </div>
</template>