<template>
  <div style="margin-top: 15px; padding-left: 15px" class="card row no-gutter">
    <div class="col-lg-5 col-12 ">
      <div class="card-header bg-navyblue">
        <strong>Claimlinq</strong>
      </div>
      <div class="card-block bg-white">
        <div class="form-group row no-gutter">
          <label for="claimlinq-api-input"
                 tabindex="0"
                 class="col-sm-3 col-form-label">API Key</label>
          <div class="col-sm-9">
            <input id="claimlinq-api-input"
                   class="form-control"
                   :readonly="!isStatusActive"
                   placeholder="API Key"
                   v-model="value.claimlinq.api_key">
          </div>
        </div>

        <div class="form-group row no-gutter rs-toggle">
          <label class="col-sm-3 col-form-label"
                 for="ps-enabled-input">
            Active
          </label>
          <div class="col-sm-9">
            <div class="d-flex">
              <toggle-button v-model="value.claimlinq.enabled"
                             id="ps-enabled-input"
                             class="mb-0 rs-toggle__button"
                             color="#5E79FF"
                             :sync="true"
                             :disabled="!isStatusActive"
                             :width="40"
                             :labels="false"/>
              <span class="form-text text-active d-inline-block col-form-label mt-0 ml-0-25">
                                <template v-if="value.claimlinq.enabled">Active</template>
                                <template v-else>Not Active</template>
                            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Claimlinq",
  props: {
    value: {}
  },
  computed: {
    ...mapGetters({
      isStatusActive: 'isStatusActive',
    }),
  }
}
</script>

<style scoped>

</style>
