<script>
    import {mapGetters, mapState} from 'vuex';
    import "@toast-ui/editor/dist/toastui-editor.css";
    import '@toast-ui/editor/dist/toastui-editor-viewer.css';
    import {Editor} from '@toast-ui/vue-editor';
    import {Viewer} from '@toast-ui/vue-editor';
    import Multiselect from 'vue-multiselect';
    import DatePicker from './../date-picker';
    import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
    import moment from 'moment';

    export default {
        name: 'activity-list-task',
        props: ['task', 'newTask', 'assignedToList', 'viewed', 'activity', 'openNewTaskForm'],
        data: function () {
            return {
                isOpenNewTaskForm: !!this.openNewTaskForm,
                dataTask: Object.assign({}, this.task),
                isMinimized: !!this.viewed,
                // Notify Assignee Comment
                showCommentBox: false,
                isCommentAndNotifyClicked: false,
                comments: [],
                comment: '',
                notifyTo: null,
            };
        },
        methods: {
            saveTask: function () {
                if (!this.isStatusActive) {
                  return;
                }
                console.log('saveTask', {
                    task: Object.assign({}, this.dataTask),
                    activityId: (this.activity && this.activity.activity_id > 0) ? this.activity.activity_id : 0,
                });
                // return;
                this.$emit('save', {
                    task: Object.assign({}, this.dataTask),
                    activityId: (this.activity && this.activity.activity_id > 0) ? this.activity.activity_id : 0,
                });
                this.dataTask.newComment = null;
            },
            saveTaskComment: function (comment) {
                this.$emit('save', {
                    task: {
                        id: this.task.id,
                        comments: [
                            comment,
                        ]
                    },
                });

            },
            closeTask: function () {
                this.dataTask = Object.assign({}, this.task);
                this.$emit('close');
            },
            clickMinimize: function () {
                this.isMinimized = !this.isMinimized;
            },
            markAsUnRead: function (a) {
                this.$emit('unread', a);
            },
            markAsRead: function (a) {
                this.$emit('read', a);
            },
            deleteTask: function (a) {
                if (!a) {
                    return this.closeTask();
                }
                this.$emit('delete', a.task);
                this.$refs.deleteActivityModal.hide();
            },
            destroyMe: function () {
                Vue.$destroy(this);
            },
            openDetails() {
                if (this.isMinimized) {
                    this.isMinimized = false;
                }
            },
            onNotesChange() {
                console.log('onNotesChange', this.$refs.notesEditor.invoke('getHTML'));
                this.dataTask.notes = this.$refs.notesEditor.invoke('getHTML');
            },
            clickAddCommentAndNotify(activity) {
                this.showCommentBox = true;
                // this.showCommentBox = !this.showCommentBox;
                this.isCommentAndNotifyClicked = true;
            },
            clickAddComment(activity) {
                this.showCommentBox = true;
                //  this.showCommentBox = !this.showCommentBox;
                this.isCommentAndNotifyClicked = false;
            },
            sendCommentAndNotify() {
                this.$emit('save', {
                    task: {
                        id: this.task.id,
                        newComment: {
                            text: this.comment,
                        },
                        isNotify: true,
                        notifyTo: this.notifyTo,
                    }
                });
                // reset comment
                this.showCommentBox = false;
                this.comment = '';
                // open the details to show new comment
                this.isMinimized = false;
            },
            saveComment() {
                this.$emit('save', {
                    task: {
                        id: this.task.id,
                        newComment: {
                            text: this.comment,
                        },
                        isNotify: false,
                    }
                });
                // reset comment
                this.showCommentBox = false;
                this.comment = '';
                // open the details to show new comment
                this.isMinimized = false;
            },
            cancelComment() {
                this.showCommentBox = false;
                this.comment = '';
            },
            formatDatetime(ms) {
                return moment(ms).format('DD-MM-YYYY hh:mm A');
            },
            formatCreatedDate(ms) {
              return moment(ms).format('DD-MM-YYYY hh:mm A');
            },
            onActionsDropdownOpen(e) {
                // set comment box state
                this.showCommentBox = false;
                this.comment = '';
            },
            onCommentEdit(comment) {
                if (!this.isStatusActive) {
                  return
                }
                if (!comment.edit) {
                    comment.tempText = comment.text;
                    comment.edit = true;
                } else {
                    this.onCommentEditCancel(comment);
                }
            },
            onCommentEditCancel(comment) {
                delete comment.tempText;
                comment.edit = false;
            },
            onCommentSave(comment) {
                comment.text = comment.tempText;
                comment.edit = false;
                this.saveTaskComment(comment);
            },
            open() {
                this.isMinimized = false;
                this.$nextTick(() => {
                    // scroll to this element
                    this.$el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest'
                    });
                });
            },
        },
        computed: {
            ...mapGetters({
                isProductionManagerUser: 'isProductionManagerUser',
                isShopManagerUser: 'isShopManagerUser',
                isAdministrator: 'isAdministrator',
                isCustomerUser: 'isCustomerUser',
                isStatusActive: 'isStatusActive'
            }),
            ...mapState({
                userInfo: 'userInfo',
            }),
            computedLogs() {
              return this.task.logs;
            },
            isCanBeEdited() {
              return this.isProductionManagerUser || this.isShopManagerUser || this.isAdministrator || this.isTaskOwner;
            },
             computedComments() {
               return  this.task.comments;
            },
            isTaskOwner() {
                return !!(Number(this.task.created_by) == Number(this.userInfo.user_id) || (!this.task.id));
            },
            sortedComments() {
                return this.task.comments.sort((a, b) => {
                    return b.timestamp - a.timestamp;
                });
            },
            priorityColors() {
                return {
                    'text-danger': this.dataTask.priority && this.dataTask.priority.key === 'high',
                    'text-warning': this.dataTask.priority && this.dataTask.priority.key === 'medium',
                    'text-secondary': this.dataTask.priority && this.dataTask.priority.key === 'low'
                };
            },
            computedNotifyTo: {
                get() {
                    if (!this.notifyTo) {
                        this.notifyTo = this.dataTask.created_by;
                    }
                    let defaultNotifyTo = this.notifyTo;

                    let user = _.find(this.assignedToList, item => {
                        return Number(item.id) === Number(defaultNotifyTo);
                    });
                    if (user) {
                        return user;
                    }
                    return {id: 0, name: 'None'};
                },
                set(value) {
                    console.log('value',value)
                    if (value.id) {
                        this.notifyTo = value.id;
                    } else {
                        this.notifyTo = null;
                    }

                }
            },
        },
        watch: {},
        components: {
            DatePicker,
            'toast-editor': Editor,
            'toast-viewer': Viewer,
            Multiselect,
            VueTimepicker,
        },
        mounted: function () {
            // set listener when actions dropdown open
            $(`#actions-${this.task.id}`).on('show.bs.dropdown', this.onActionsDropdownOpen);

          let params = this.$route.params;
          let vm = this;
          this.$nextTick(() => {
            if (params && params.action && params.action == 'goToTask' && this.task.id == params.taskId) {
              vm.isMinimized = false;
              vm.$nextTick(() => {
                setTimeout(() => {
                  vm.$el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: "center",
                  });
                }, 100)
              });
            } else if (params && params.action && params.action == 'goToComment' && this.task.id == params.taskId) {
              vm.isMinimized = false;
              vm.$nextTick(() => {
                let ref = 'comment-' + params.commentId;
                console.log('ref.comment', ref);
                if (vm.$refs[ref][0]) {
                  setTimeout(() => {
                    vm.$refs[ref][0].scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: "center",
                    });
                  }, 100)
                }
              });

            }
          });
        },
        unmounted() {
            // unset listener when actions dropdown open
            $(`#actions-${this.task.id}`).off('show.bs.dropdown', this.onActionsDropdownOpen);
        },
    };
</script>

<template>
    <div :id="(activity && activity.activity_id>0)?`activity-${activity.activity_id}`:`newtask-1`" class="activity-log-task">
        <div class="tl-content box box-block display-block" style="background-color: #FAFAFA;">
            <span class="arrow left" style="border-color: #FAFAFA;"></span>
            <div class="row d-flex justify-content-between px-1-25">

                <!-- priority label -->
                <div style="flex: 0 0 50%;">
                    <div :class="priorityColors" v-if="dataTask.priority && dataTask.priority.key !== 'none'">
                        <i class="fa fa-dot-circle-o"></i> {{ dataTask.priority.value }}
                    </div>
                    <div v-else>&nbsp;</div>
                </div>

                <!-- actions -->
                <div v-if="isStatusActive" :id="`actions-${this.task.id}`" class="navbar-nav nav dropdown dropdown-options show float-sm-right text-right w-100 mr-0">
                    <span class="btn ml-0-25 waves-effect waves-light additional-action-menu-style"
                          style="margin-top: -5px; right: -23px;"
                          data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="true">Actions
                          <span class="dropdown-arrow  pull-right" style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);"><i class="fa fa-chevron-down pull-right-action"></i></span>
                    </span>
                    <div class="dropdown-menu dropdown-menu-right animated fadeInUp drop-down-menu-actions">
                        <div class="dropdownLinks-actions-links">
                            <template v-if="!isOpenNewTaskForm">
                                <a v-if="viewed == 1" @click="markAsUnRead(activity)" class="dropdown-item text-primary clickable">
                                    Mark As Unread</a>
                                <a v-else @click="markAsRead(activity)" class="dropdown-item text-primary clickable">
                                    Mark As Read
                                </a>
                            </template>
                            <a v-if="isProductionManagerUser || isShopManagerUser || isAdministrator" @click="$refs.deleteActivityModal.show()" class="dropdown-item text-primary clickable">
                                Delete Task
                            </a>
                            <div v-if="!isOpenNewTaskForm" class="btn-group dropleft">
                                <a @click.prevent.stop="clickAddCommentAndNotify" type="button" class="dropdown-item p-1 clickable" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Comment & Notify
                                </a>
                                <a @click.prevent.stop="clickAddComment" type="button" class="dropdown-item p-1 clickable" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Comment
                                </a>
                                <div v-if="showCommentBox" class="dropdown-menu block dropdown-comment-box" style="display: block;">
                                    <b-form>
                                        <div class="dropdown-comment-box__container">
                                            <div class="mb-1"><strong>Comment: </strong></div>
                                            <b-textarea
                                                    v-model="comment"
                                                    rows="6"
                                                    max-rows="10"
                                                    class="mb-1"
                                                    autofocus
                                            />
                                            <div v-if="isCommentAndNotifyClicked" class="form-group row px-0 mt-0" style="padding-right:10px; margin-top:5px;">
                                                <label class="col-form-label" style="padding-right:10px;">Notify To</label>
                                                <multiselect
                                                        v-model="computedNotifyTo"
                                                        :options="assignedToList"
                                                        :showLabels="false"
                                                        :option-height="29"
                                                        :max-height="203"
                                                        :close-on-select="true"
                                                        track-by="id"
                                                        label="name"
                                                />
                                            </div>
                                            <div class="dropdown-comment-box__actions">
                                                <button v-if="isCommentAndNotifyClicked" @click.prevent="sendCommentAndNotify" class="btn btn-md btn-primary default-width btn-color-blue">Send</button>
                                                <button v-else @click.prevent="saveComment" class="btn btn-md btn-primary default-width btn-color-blue">Save</button>
                                                <button @click.prevent="cancelComment" class="btn btn-sm btn-secondary default-width" style="margin-left: 10px;">Cancel</button>
                                            </div>
                                        </div>
                                    </b-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row form-group">
                <label class="col-form-label col-md-3 col-xs-6">Task Name</label>
                <div class="col-md-9 col-xs-18">
                    <input
                            class="form-control"
                            type="text"
                            :disabled="!isCanBeEdited && !isOpenNewTaskForm"
                            v-model="dataTask.name"
                            style="opacity: 1"
                            @keyup="openDetails"
                            :readonly="(!isCanBeEdited  && !isOpenNewTaskForm) || !isStatusActive"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-4">
                    <div  v-if="dataTask.createdBy" class="row form-group d-flex">
                        <label class="col-form-label col-xs-12 col-md-6 px-0">Task Created By</label>
                        <div class="col-xs-12 col-md-6 pt-0-5 px-0 px-md-15">{{dataTask.createdBy}}</div>
                    </div>
                </div>
                <div class="col-xs-3">
                    <div class="row form-group d-flex">
                        <label class="col-form-label col-xs-12 col-md-4 px-0">Task Status</label>
                        <div class="col-xs-12 col-md-6 pt-0-5 px-0 px-md-15">{{dataTask.status.value}}</div>
                    </div>
                </div>
                <div class="col-xs-5">
                  <div v-if="dataTask.created_at" class="row form-group d-flex" >
                    <label class="col-form-label col-md-4 px-0">Created Date</label>
                    <div class="col-md-6 pt-0-5 pr-0 px-md-15">
                      {{formatCreatedDate(dataTask.created_at)}}
                    </div>
                  </div>
                </div>
            </div>
            <div class="row activity-calendar-wrapper">
                <label class="col-form-label col-md-3 col-xs-12">Due Date</label>
                <div class="col-md-3 col-xs-6">
                    <div class="input-group date additional-data-picker-activity-block">
                        <date-picker
                                :is-disabled="(!isCanBeEdited && !isOpenNewTaskForm) || !isStatusActive"
                                v-model="dataTask.date"
                                @onChange="openDetails"
                        />
                        <div class="input-group-addon">
                            <span class="fa fa-calendar"></span>
                        </div>
                    </div>

                </div>
                <div class="col-md-3 col-xs-6 mb-1">
                    <div class="input-group d-flex">
                        <vue-timepicker :disabled="(!isCanBeEdited && !isOpenNewTaskForm) || !isStatusActive" :class="{'disabled-field': (!isCanBeEdited && !isOpenNewTaskForm) || !isStatusActive}" v-model="dataTask.time" drop-direction="up"/>
                        <div class="input-group-addon w-auto">
                            <span class="fa fa-clock-o" aria-hidden="true"></span>
                        </div>
                    </div>
                </div>

            </div>

            <!-- option toggle -->
            <a class="content-minimizer d-inline-block px-1-25"
               @click="clickMinimize"
               v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'View Task', placement:'bottomright'}"
            >....</a>

            <div v-if="!isMinimized" class="tl-content box box-block display-block box-block-comment-style" style="background-color: #FAFAFA;border: 0; padding: 0">
                <div v-if="(isCanBeEdited || isOpenNewTaskForm) && isStatusActive">
                    <toast-editor
                            ref="notesEditor"
                            :initialValue="dataTask.notes"
                            :options="{
                                minHeight: '300px',
                                language: 'en-US',
                                useCommandShortcut: true,
                                usageStatistics: false,
                                hideModeSwitch: false,
                                toolbarItems: [
                                    ['heading', 'bold', 'italic', 'strike'],
                                    ['hr', 'quote'],
                                    ['ul', 'ol', 'task', 'indent', 'outdent'],
                                    ['table', 'image', 'link'],
                                    ['code', 'codeblock'],
                                    ['scrollSync'],
                                ]
                        }"
                            :visible="true"
                            initialEditType="wysiwyg"
                            height="300px"
                            @change="onNotesChange"
                    />
                </div>
                <div v-else visible="visible" style="height: 300px;">
                    <div class="toastui-editor-defaultUI">
                        <div class="toastui-editor-main toastui-editor-ww-mode">
                            <div class="toastui-editor-main-container">
                                <div class="toastui-editor-ww-container">
                                    <div class="toastui-editor ww-mode" style="min-height: 252px;">
                                        <div class="ProseMirror" style="padding: 10px;">
                                            <toast-viewer
                                                    ref="notesViewer"
                                                    :initialValue="dataTask.notes"
                                                    height="300px"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isMinimized && computedComments && task.comments.length > 0" class="tl-content box box-block display-block pb-0-5" style="background-color: #FAFAFA; border: none">
                <div v-for="comment of computedComments" v-bind:key="comment.id" class="row" style="position: relative; z-index: 20;">
                    <div :id="`comment-${comment.id}`" :ref="`comment-${comment.id}`" style="margin-bottom: 15px" class="col comment-box position-relative">
                        <b-button
                                @click="onCommentEdit(comment)"
                                variant="link"
                                :disabled="!comment.editAllowed"
                                style="text-decoration: none; position:absolute; right: 5px; top: 5px; color: #333333; font-weight: 600;"
                                v-b-tooltip.hover.topleft title="Edit Comment">
                            <i class="fa fa-pencil"></i>
                        </b-button>
                        <div class="mb-1">
                            <strong>{{ comment.createdBy }}</strong>
                            —
                            {{ formatDatetime(comment.createdOn) }}
                        </div>
                        <div v-show="!comment.edit">
                            {{ comment.text }}
                        </div>
                        <div v-show="comment.edit">
                            <b-form-textarea no-resize rows="3" style="height: auto" v-model="comment.tempText" class="mb-1"></b-form-textarea>
                            <b-button variant="primary" @click="onCommentSave(comment)">Save</b-button>
                            <b-button variant="default" @click="onCommentEditCancel(comment)">Cancel</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isMinimized" class="tl-content box box-block display-block px-0 box-block-style" style="background-color: #FAFAFA;">
                <div class="row pt-1 " style="position: relative; z-index: 20;">
                    <div class="form-group col-md-2 col-xs-4">
                        <label>Type</label>
                        <multiselect
                                v-model="dataTask.type"
                                :options="[{key:'to-do',value:'ToDo'}, {key:'call', value:'Call'}, {key:'email',value:'Email'}]"
                                :showLabels="false"
                                :option-height="29"
                                :max-height="203"
                                :close-on-select="true"
                                track-by="key"
                                :disabled="!isStatusActive"
                                style="opacity: 1"
                                label="value"
                                class="type-additional-block"
                        ></multiselect>
                    </div>
                    <div class="form-group col-md-4 col-xs-8">
                        <label>Assigned To</label>
                        <multiselect
                                v-model="dataTask.assignedTo"
                                :options="assignedToList"
                                :showLabels="false"
                                :option-height="29"
                                :max-height="203"
                                :disabled="!isStatusActive"
                                style="opacity: 1"
                                :close-on-select="true"
                                track-by="id"
                                label="name"
                        ></multiselect>
                    </div>
                    <div class="form-group col-md-3 col-xs-6">
                        <label>Priority</label>
                        <multiselect
                                v-model="dataTask.priority"
                                :options="[{key:'none',value:' '}, {key:'low', value:'Low'}, {key:'medium',value:'Medium'}, {key:'high',value:'High'}]"
                                :showLabels="false"
                                :option-height="29"
                                :max-height="203"
                                :disabled="!isStatusActive"
                                style="opacity: 1"
                                :close-on-select="true"
                                track-by="key"
                                label="value"
                        ></multiselect>
                    </div>
                    <div class="form-group col-md-3 col-xs-6">
                        <label>Task Status</label>
                        <multiselect
                                v-model="dataTask.status"
                                :options="[{key:'none',value:'None'}, {key:'new',value:'New'}, {key:'in-progress', value:'In Progress'}, {key:'completed', value:'Completed'}]"
                                :showLabels="false"
                                :option-height="29"
                                :max-height="203"
                                :disabled="!isStatusActive"
                                style="opacity: 1"
                                :close-on-select="true"
                                track-by="key"
                                label="value"
                        ></multiselect>
                    </div>
                </div>
            </div>
            <div v-if="!isMinimized" class="tl-content box box-block display-block box-block-style" style="background-color: #FAFAFA;">
                <button type="submit" class="btn btn-primary mb-15" style="margin-right: 13px;" @click="saveTask">Save</button>
                <button type="submit" class="btn mb-15" @click="closeTask">Cancel</button>
            </div>
            <div v-if="!isMinimized && computedLogs && computedLogs.length > 0" class="tl-content box box-block display-block pb-0-5" style="background-color: #FAFAFA; border: none; padding: 0px 15px 1px 15px !important;">
              <div  class="row" style="position: relative; z-index: 20;">
                <div style="margin-bottom: 15px" class="col comment-box position-relative">
                  <p v-for="log of computedLogs" v-bind:key="log.id">
                    {{ log.text }}  — {{ formatDatetime(log.createdOn) }}
                  </p>
                </div>
              </div>
            </div>
        </div>
        <modal-wizard ref="deleteActivityModal" cancelButtonText="Cancel" finishButtonText="Ok" @on-complete="deleteTask(activity)" class="delete-activity-modal">
        <modal-wizard-tab title="Delete Activity" description="" title-icon-class="ti-align-right">
          <template>
            <div class="pt-2 pb-2">Do you want to Delete this Task?</div>
          </template>
        </modal-wizard-tab>
      </modal-wizard>
    </div>
</template>

<style>
    .activity-log-task span.vue__time-picker input.display-time {
      color: #1C1F39 !important;
    }
    .activity-log-task .additional-data-picker-activity-block .form-control {
      opacity: 1;
    }

    .activity-log-task .disabled-field {
      cursor: not-allowed;
    }

    .timeline .activity-log-task .tl-content {
        margin-bottom: -1px;
    }

    .activity-log-task hr {
        -moz-border-bottom-colors: none;
        -moz-border-image: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        border-color: #EEEEEE -moz-use-text-color #FFFFFF; /* TODO: check this line please. I think it is incorrect */
        border-style: solid none;
        border-width: 1px 0;
        margin: 18px 0;
    }

    .activity-log-task .content-minimizer, .content-minimizer:visited, .content-minimizer:hover {
        color: #34495E;
        font-size: 24px;
        font-weight: bolder;
    }

    .toastui-editor-mode-switch {
        display: none !important;
    }

    .toastui-editor-toolbar-icons {
        transform: scale(0.7);
        -moz-transform: scale(0.7);
    }

    .content-minimizer {
        display: inline-block;
        cursor: pointer;
    }

    .toastui-editor-defaultUI .toastui-editor-defaultUI-toolbar {
        background-color: #ECEEEF;
    }

    .additional-data-picker-activity-block {
        position: absolute !important;
    }

    .activity-calendar-wrapper .vc-popover-content-wrapper {
        z-index: 101 !important;
    }
</style>

<style scoped>
    .dropdown-menu.dropdown-comment-box {
        /* left: -250px; */
        left: -62vw;
        top: 0px;
        box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        min-width: 62vw;
        max-width: 100%;
        background: #FFFFFF;
        border-radius: 6px;
    }

    @media screen and (min-width: 425px) {
        .dropdown-menu.dropdown-comment-box {
            left: -44vw;
            min-width: 44vw;
        }
    }

    @media screen and (min-width: 512px) {
        .dropdown-menu.dropdown-comment-box {
            left: -50vw;
            min-width: 50vw;
        }
    }

    @media screen and (min-width: 992px) {
        .dropdown-menu.dropdown-comment-box {
            left: -30vw;
            min-width: 30vw;
        }
    }

    .dropdown-menu .dropdown-comment-box__container {
        display: flex;
        flex-flow: column nowrap;
        padding: 0 10px;
    }

    .dropdown-menu .dropdown-comment-box__textarea {
        border: 0;
        padding: 0;
    }

    .dropdown-menu .dropdown-comment-box__actions {
        display: flex;
        justify-content: flex-end;
    }

    .comment-box {
        background-color: #FFFFFF;
        border: 1px solid #DEDEDE;
        padding: 15px 15px;
        border-radius: 8px;
        margin-bottom: 15px;
    }

    .comment-box:last-child {
        margin-bottom: 0px;
    }

    .box-block-style {
        margin: 15px !important;
    }

    .box-block-comment-style {
        margin-bottom: 15px !important;
    }

    .mb-15 {
        margin-bottom: 15px;
    }

    .dropdownLinks-actions-links {
        width: 115px;
    }

    .drop-down-menu-actions {
        width: 115px !important;
        border-radius: 3px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgb(27 30 56 / 15%);
        min-width: 115px !important;
        margin: -15px -23px 0 !important;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        z-index: 110;
    }

    .dropdown-action-item {
        text-align: center !important;
        min-height: 20px !important;
    }

    .pull-right-action {
        padding-right: 3px !important;
        margin-left: 10px;
    }

    .default-width {
        width: auto !important;
    }

    .btn-color-blue {
        background-color: #5E79FF !important;
        color: white !important;
    }

    .type-additional-block {
        white-space: nowrap;
    }

    .vc-popover-content-wrapper {
        position: absolute !important;
        z-index: 99999;
    }
</style>
