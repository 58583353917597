
export const Mutations = {
  setIsShowReportList(state, ui) {
    state.isShowReportsList = ui;
  },
  setSelectedOption(state, ui) {
    state.selectedOption = ui;
  },

  loadEPS(state, ui) {
    state.estimate.processedSummary = ui;
  },
  loadAEV(state, ui) {
    state.estimate.averageValue = ui;
  },
  loadEVS(state, ui) {
    state.estimate.valueSummary = ui;
  },
  loadEINS(state, ui) {
    state.estimate.valuesByInsurerAndNonInsuranceSummary = ui;
  },
  loadEVI(state, ui) {
    state.estimate.valuesByInsurer = ui;
  },

  loadIPS(state, ui) {
    state.invoice.processedSummary = ui;
  },
  loadAIV(state, ui) {
    state.invoice.averageValue = ui;
  },
  loadIVS(state, ui) {
    state.invoice.valueSummary = ui;
  },
  loadIINS(state, ui) {
    state.invoice.valuesByInsurerAndNonInsuranceSummary = ui;
  },
  loadIVI(state, ui) {
    state.invoice.valuesByInsurer = ui;
  }
}
