<script>
    import Axios from 'axios';
    import GoogleLocation from './utility/google-location';
    import {email} from 'vuelidate/lib/validators';

    export default {
        name: 'users-new',
        data() {
            return {
                user: {
                    id: '',
                    email: '',
                    first_name: '',
                    last_name: '',
                    address: '',
                    work_phone: '',
                    mobile_phone: '',
                    username: '',
                    role: '',
                    password: '',
                    is_active: '',
                }
            };
        },
        validations: {
            user: {
                email: {
                    email,
                },
            },
        },
        methods: {
            validUserEmail: function(value){
                this.user.email = value
                this.$v.user.email.$touch()
            },
            loadUser: function () {
                NProgress.start();
                let user_id = this.$route.params.user_id;
                Axios.get(`/fe/users/${user_id}`)
                    .then(response => {
                        this.user = response.data.user;
                        this.user.password = '';
                        NProgress.done();
                        this.$v.user.email.$touch()
                    })
                    .catch(error => {
                        toastr.error("User not found");
                        NProgress.done();
                        this.$router.push('/settings/users');
                    });
            },
            saveUser: function () {
                if (!this.validateForm()) {
                    return false;
                }
                if (this.$v.$anyError) {
                    toastr.error('Your changes could not be saved, please check the form fields again');
                    return;
                }
                let user_id = this.$route.params.user_id;
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/users/${user_id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: this.user
                })
                    .then(response => {
                        if (response.status === 200) {
                            toastr.success('The user updated!');
                            this.$router.push('/settings/users');
                            return;
                        } else {
                            toastr.error(response.data.msg);
                        }
                    })
                    .catch(error => {
                        toastr.error(response.data.msg);
                    });
            },
            validateForm: function () {
                if (this.user.first_name == '') {
                    toastr.error('The First Name must be specified');
                    return false;
                }
                if (this.user.last_name == '') {
                    toastr.error('The Last Name must be specified');
                    return false;
                }
                if (this.user.role == '') {
                    toastr.error('The Role must be specified');
                    return false;
                }
                if (this.user.is_active == '') {
                    toastr.error('The Active User must be specified');
                    return false;
                }
                return true;
            },
        },
        mounted: function () {
            this.loadUser();
        },
        components: {
            GoogleLocation
        }
    };
</script>

<template>
    <div>
        <div class="page-header">
            <h4>Edit User</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/settings/users'">Users</router-link>
                </li>
                <li class="breadcrumb-item active">Edit User</li>
            </ol>
        </div>
        <div class="rs-btn-group float-sm-right">
            <button type="button" @click="saveUser" class="btn btn-primary w-min-sm waves-effect waves-light">Save User</button>
        </div>
        <ul class="nav nav-tabs nav-tabs-2 pb-1"></ul>
        <div class="row">
            <div class="col-md-12">
                <div class="box bg-white">
                    <div class="settting-card tab-content">
                        <div class="px-0">
                            <div id="card-details" class="tab-pane active">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card ex-card ex-card-details">
                                            <div class="card-header bg-navyblue">
                                                <strong>User Details</strong>
                                            </div>
                                            <div class="card-block bg-white">

                                                <div class="form-group row">
                                                    <label for="username" class="col-lg-3 col-form-label">Username</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" class="form-control" v-model="user.username" id="username" disabled="disabled">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-lg-3 col-form-label">Email Address</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" class="form-control" :value="user.email" @change="validUserEmail($event.target.value)">
                                                        <small class="form-text show-help-for-body" v-if="!$v.user.email.email" >
                                                            Please enter a valid email address
                                                        </small>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="name" class="col-lg-3 col-form-label">First Name</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" class="form-control" v-model="user.first_name" id="first_name">
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="name" class="col-lg-3 col-form-label">Last Name</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" class="form-control" v-model="user.last_name" id="last_name">
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="location" class="col-lg-3 col-form-label">Location</label>
                                                    <div class="col-lg-9">
                                                        <google-location type="text" v-model="user.address" class="form-control" id="address" placeholder="Search for an Address"></google-location>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="work_phone" class="col-lg-3 col-form-label">Work Phone</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" class="form-control" v-model="user.work_phone" id="work_phone">
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="mobile_phone" class="col-lg-3 col-form-label">Mobile Phone</label>
                                                    <div class="col-lg-9">
                                                        <input type="text" class="form-control" v-model="user.mobile_phone" id="mobile_phone">
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-lg-3 col-form-label">Role</label>
                                                    <div class="col-lg-9">
                                                        <select class="form-control" id="transmission" v-model="user.role">
                                                            <option value="">Select role</option>
                                                            <option value="2">Shop Manager</option>
                                                            <option value="3">Estimator</option>
                                                            <option value="3">PDR Technician</option>
                                                            <option value="4">Technician</option>
                                                            <option value="5">Customer Service</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="password" class="col-lg-3 col-form-label">Password</label>
                                                    <div class="col-lg-9">
                                                        <input type="password" class="form-control" id="password" v-model="user.password">
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="" class="col-lg-3 col-form-label">Active User</label>
                                                    <div class="col-lg-9 ex-customer-type-container">
                                                        <label class="form-check-inline">
                                                            <input class="form-check-input" type="radio" name="user_active" v-model="user.is_active" id="user_active_yes" value="1"> Yes
                                                        </label>
                                                        <label class="form-check-inline">
                                                            <input class="form-check-input" type="radio" name="user_active" v-model="user.is_active" id="user_active_no" value="0"> No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .card-header strong {
        font-size: 14px;
        font-weight: bold;
    }

    .form-group {
        margin-bottom: 1.5rem !important;
    }

    .col-form-label {
        line-height: 1.3;
    }
</style>
