<script>
export default {
    name: 'SupplierMakes',
    props: {
        chips: {
            type: Array,
            required: true
        },
        isRemoveShow: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            chipsData: []
        };
    },
    watch: {
        chips: function (newVal) {
            this.generateChips(newVal);
        }
    },
    methods: {
        generateChips(newVal) {
            this.chipsData = _.sortBy(newVal);
        },
        removeChip(ind) {
            this.$emit('remove', this.chipsData[ind]);
            this.chipsData.splice(ind, 1);
        }
    }
};
</script>
<template>
    <div class="chips__supplied">
        <span class="chip__wrap" v-for="(item, index) in chipsData" :key="item">{{item}}
            <i v-if="isRemoveShow" @click="removeChip(index)" class="ti-close"></i>
        </span>
    </div>
</template>
<style>
.chips__supplied {
    width: 100%;
    min-height: 80px;
    border-radius: 3px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, .25);
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
}

.chip__wrap i {
    position: relative;
    left: 7px;
    font-size: 10px;
    cursor: pointer;
}

.chip__wrap {
    display: inline-block;
    padding: 5px 15px;
    background-color: rgb(198,199,205);
    margin: 0 5px 5px 0;
    color: black;
    border-radius: 3px;
    font-weight: 700;
}
</style>
