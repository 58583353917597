<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "RouterContainer",
  methods: {}
};
</script>

<style>
  /*login form*/

  .form-control.input-field, .input-field {
    height: 43px;
    border: 1px solid rgba(27, 30, 56, 0.2);
    border-radius: 4px;
    width: 100%;
    background: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .input-field-search {
    position: relative;
  }

  .input-field-search i {
    position: absolute;
    right: 17px;
    top: 9px;
    height: 20px;
    width: 20px;
    font-size: 27px;
    color: rgba(39, 42, 67, 0.5);
  }

  .form-control.input-field.error, .input-field.error {
    border: 2px solid red;
  }

  .form-control.input-field::placeholder, .input-field::placeholder {
    color: #1C1F39;
    opacity: 0.5;
    font-weight: 600;
  }

  .form-control.input-field, .input-field:focus {
    background-image: none;
  }

  .input-checkbox {
    position: relative;
    padding-left: 30px;
    line-height: 25px;
  }

  .input-checkbox input[type='checkbox'] {
    display: none;
  }

  .input-checkbox .checkmark {
    height: 25px;
    width: 25px;
    border: 1px solid rgba(27, 30, 56, 0.25);
    border-radius: 3px;
    background-color: rgba(27, 30, 56, 0.03);
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
  }

  .input-checkbox input[type='checkbox']:checked ~ .checkmark {
    background: #29BBC1;
    border: none;
  }

  .input-checkbox input[type='checkbox']:checked ~ .checkmark:before {
    content: '';
    display: block;
    width: 14px;
    height: 10px;
    position: absolute;
    left: 6px;
    top: 7px;
    /*background: url('../assets/img/check.svg');*/
  }

  .input-btn {
    height: 48px;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    background: #29BBC1;
    border-color: #29BBC1;
    width: 100%;
    color: #FFFFFF;
  }

  .input-btn[disabled] {
    background: #CCCCCC;
    border: none;
    color: #212529;
  }

  .input-btn[disabled]:hover {
    color: #212529;
  }

  .error-text {
    display: flex;
    align-items: center;
    color: #FF5E5E;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .input-btn.cancel {
    background-color: #FFFFFF;
    color: #1C1F39;
    border: 1.5px solid rgba(28, 31, 57, 0.25)
  }

  .input-btn.cancel:hover {
    color: #1C1F39;
    background-color: #EEEEEE;
    border-color: #AAAAAA;
  }

</style>
