<template>
    <div ref="container" class="ripple-container" @click="createRipple">
        <slot>
            <button>{{ name }}</button>
        </slot>
        <div
            ref="ripple"
            class="ripple"
            :class="{ 'is-active': isActive }"
            :style="rippleStyle"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'ripple',
    props: {
        name: {
            type: String,
            default: 'Click Me'
        },
        duration: {
            type: Number,
            default: 830
        }
    },
    data() {
        return {
            isActive: false,
            timeoutId: null
        }
    },
    computed: {
        rippleStyle() {
            if (this.isActive) {
                return {
                    animation: `ripple ${this.duration}ms`
                }
            }
            return ''
        }
    },
    methods: {
        createRipple(event) {
            this.isActive = false

            const container = this.$refs.container
            const containerRect = container.getBoundingClientRect()
            const ripple = this.$refs.ripple

            const diameter = Math.max(container.clientWidth, container.clientHeight)
            const radius = diameter / 2
            ripple.style.width = `${diameter}px`
            ripple.style.height = `${diameter}px`
            ripple.style.left = `${event.clientX - containerRect.left - radius}px`
            ripple.style.top = `${event.clientY - containerRect.top - radius}px`
            this.isActive = true

            console.log('createRipple', event.clientX, containerRect.left, radius, ripple.style.left)
            const reset = () => {
                // remove the ripple
                this.isActive = false
                ripple.style.width = 0
                ripple.style.height = 0
                ripple.style.left = 0
                ripple.style.top = 0
            }
            
            this.timeoutId = setTimeout(reset, this.duration)
        }
    }
}
</script>

<style>
.ripple-container {
    position: relative;
    overflow: hidden;
}
.ripple {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    border-radius: 50%;
    /* background-color: rgba(255, 255, 255, 0.6); */
    background-color: rgba(94, 121, 255, 0.3);
}
.ripple.is-active {
    transform: scale(0);
}
@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}
</style>
