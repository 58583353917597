<template>
  <div>
    <div class="card-header">Scanner</div>
    <div class="card-block" style="padding-left: 0px">
      <div class="form-group row">
        <div class="col-sm-8 col-8 float-sm-right">
          <a href="#" class="btn btn-primary" @click.prevent="connectToScanner">Connect to Scanner</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {appConfig} from "../../../config";

export default {
  name: "Scanner",
  methods: {
    connectToScanner() {
      window.open(`${appConfig.appURL}/test/scanner`, 'xero-auth-window', 'width=800,height=600,location=on');
    }
  }
}
</script>

<style scoped>

</style>
